import EncDec from "../app-enc/encryption";


export default function localStorageFunctions(objKey, value) {
  var updatedValue;

  if (value !== null && objKey === "a1ysQM3xOEe9nGpQg5D5g") {
    //means this function is for writing something to local storage && app-enc object
    updatedValue = EncDec("enc", JSON.stringify(value).toString());
    localStorage.setItem("a1ysQM3xOEe9nGpQg5D5g", updatedValue);
  } else if (value !== null && objKey !== "a1ysQM3xOEe9nGpQg5D5g") {
    updatedValue = EncDec("enc", value);
    localStorage.setItem(objKey, updatedValue);
  } else if (value === null && objKey === "a1ysQM3xOEe9nGpQg5D5g") {
    //means this function is for reading something from local storage
    updatedValue = localStorage.getItem("a1ysQM3xOEe9nGpQg5D5g");
    if (updatedValue !== null) {
      updatedValue = EncDec("dec", updatedValue);
      updatedValue = JSON.parse(updatedValue);
    }
    return updatedValue
  } else if (value === null && objKey !== "a1ysQM3xOEe9nGpQg5D5g") {
    //means this function is for reading something from local storage
    updatedValue = localStorage.getItem(objKey);
    if (updatedValue !== null) {
      updatedValue = EncDec("dec", updatedValue);
    }
    return updatedValue
  }

}