import React from 'react';
import {Helmet} from "react-helmet";
import png13 from "../../../resources/images/seoArticle/1-3.png";
import webp13 from "../../../resources/images/seoArticle/1-3.webp";
import png21 from "../../../resources/images/seoArticle/2-1.png";
import webp21 from "../../../resources/images/seoArticle/2-1.webp";
import png22 from "../../../resources/images/seoArticle/2-2.png";
import webp22 from "../../../resources/images/seoArticle/2-2.webp";
import png23 from "../../../resources/images/seoArticle/2-3.png";
import webp23 from "../../../resources/images/seoArticle/2-3.webp";
import pngc1 from "../../../resources/images/seoArticle/complex-1.png";
import webpc1 from "../../../resources/images/seoArticle/complex-1.webp";
import pngc2 from "../../../resources/images/seoArticle/complex-2.jpg";
import webpc2 from "../../../resources/images/seoArticle/complex-2.webp";
import pngc3 from "../../../resources/images/seoArticle/complex-3.jpg";
import webpc3 from "../../../resources/images/seoArticle/complex-3.webp";
import pngc4 from "../../../resources/images/seoArticle/complex-4.png";
import webpc4 from "../../../resources/images/seoArticle/complex-4.webp";
import pngc5 from "../../../resources/images/seoArticle/complex-5.jpg";
import webpc5 from "../../../resources/images/seoArticle/complex-5.webp";
import pngc6 from "../../../resources/images/seoArticle/complex-6.jpg";
import webpc6 from "../../../resources/images/seoArticle/complex-6.webp";
import LazyLoad from 'react-lazyload';
import WhatIsMatboard from "../page-howItWorks/whatIsMatboard";
import InstagramLoader from "../page-landing/instagramLoader";

export default class SeoKeywordArticle extends React.Component {

    render() {

        var productName = [
            "matboards",
            "custom mats",
            "customized frames and mats",
            "custom frames & mats",
            "frames & mats",
            "customized matboards",
            "customized frames and matboards",
            "frames & matboards combo",
            "custom mats and frames",
            "mat boards"
        ];
        var secondImageSeriesPng = [png13, png21, png22, png23];
        var secondImageSeriesWebp = [webp13, webp21, webp22, webp23];
        var complexImageSeriesPng = [pngc1, pngc2, pngc3, pngc4, pngc5, pngc6];
        var complexImageSeriesWebp = [webpc1, webpc2, webpc3, webpc4, webpc5, webpc6];

        return <div className={`blog-app-container`}>
            <Helmet>
                <title> CustomMat.ca - {this.props.keyword} • Free shipping across Canada and the USA $49+</title>
                <meta name="description" content="Customized matting & framing with special options for polaroids. Design and order online now. $9.95 flat rate shipping to anywhere in Canada & the USA. Free shipping over $49."/>
            </Helmet>

            <div className={"blog-article-content"}>

                <LazyLoad>
                    <picture>
                        <source type="image/webp" srcSet={secondImageSeriesWebp[Math.floor(Math.random() * secondImageSeriesWebp.length)]}/>
                        <source type="image/png" srcSet={secondImageSeriesPng[Math.floor(Math.random() * secondImageSeriesPng.length)]}/>
                        <img className="blog-content-photo" src={secondImageSeriesWebp[Math.floor(Math.random() * secondImageSeriesWebp.length)]} alt={"Showing CustomMat.ca's service."}/>
                    </picture>
                </LazyLoad>

                <h1 className="carousel-subject-head">
                    {this.props.keyword} • Free shipping across Canada $49+
                </h1><br/>

                We started our business to help our customers to get their {productName[Math.floor(Math.random() * 10)]} without leaving their home. We made this easy.<br/><br/>
                Get your {productName[Math.floor(Math.random() * 10)]} online. Your {productName[Math.floor(Math.random() * 10)]} will be produced within one business day, and your will have them at your door in days.<br/><br/>

                <WhatIsMatboard/>

                <b>Here’s our promise to you:</b>
                <br/><br/>
                1. 100% Customized – your colour & design<br/>
                2. No minimum order quantity<br/>
                3. Quick production<br/>
                4. 100% customer satisfaction<br/>
                5. Quality Materials & Cut<br/><br/>

                Here are some recent examples from our customers.
                You can see how much difference can be made by matboards with perfect fit.<br/><br/>

                <div className={"product-demo-page-v2"}>
                    <InstagramLoader/>
                </div>

                <br/><br/>
                <b>Our Promise</b><br/><br/>
                <b>1. 100% Custom: Your colour, size, shape & layout</b><br/>
                <br/>
                Our matting designs are completely customized.
                Use our design tool to come up with your own design – find your colour, size, shape, and layout.<br/><br/>
                If our tool is not advanced enough, then you can use our drawing canvas to submit your own design.<br/><br/>
                They are the same price – we don’t charge extra for your creative design.
                We can cut advanced designs & layouts – here are some examples that we have created for our customers:<br/><br/>

                <b>2. No minimum order quantity</b>
                <br/><br/>
                <b>No minimum order quantity:</b> Our artworks are not always same in size – we might just need one for many different sizes.
                We saw the need for our customers and removed minimum order quantity. We do not have the minimum order quantity for our {productName[Math.floor(Math.random() * 10)]}.
                You can start from a single mat and/or frame.<br/><br/>
                <b>Get quantity discount:</b> No minimum order quantity doesn’t mean there’s no quantity discount. You can pool up various mats and still get discounts. For example, if you order one matboard sized at 20 x 20 and one at 30 x 30, you will still get 20% quantity discount on both items. We got rid of your minimum order burden but kept your quantity discount benefits.<br/><br/>

                <b>3. Fast service & easy shipping</b><br/><br/>
                It takes days, if not weeks for many craft & framing stores to process your custom matboards or frames. We made that into one business day.<br/><br/>
                We process most of our orders within one business day. We do our best for you to have them within four business days anywhere in Canada.<br/><br/>
                For shipping, you can enjoy our free shipping, if you order more than $49. Otherwise, it’s $9.95 flat rate shipping across Canada for any size or any quantity.

                <br/><br/>

                <b>4. 100% customer satisfaction policy:</b>
                <br/><br/>
                If you find any issues with our products, please let us know. Whether it’s your mistake or our fault, we will work with you until you are satisfied.
                <br/><br/>

                <b>5. Quality Mats & Cutting Machines</b>
                <br/><br/>
                To meet your preservation needs, we use acid-free materials. All our mats are acid-neutralized on their surface, core, and backing for artwork preservation.<br/><br/>
                They are 4-ply thick, with coherent colouring layer. All our mats are cut with 45-degree bevel, unless requested others. We have 54-degree and 90-degree bevel cutting option as well.<br/><br/>
                For cutters, we use industry leader’s most advanced matboard cutter. They are fully automated and computerized. Once your order comes in, our machines cuts your matboards in your specified design & size. This eliminates the human error and miscalibration from semi-automatic cutters.
            </div>
        </div>
    }
}