export default function updateWindowSizeCallBack(parentCurrentState, frameState, renderingToolHeight, windowId, dictKey, dictValue, multipleWindow, rotationDegrees) {
  //this.state.window
  var objectToUpdate = {...parentCurrentState};

  var rowCount = parentCurrentState.rowCount;
  var columnCount = parentCurrentState.columnCount;

  var stateToUpdate = parentCurrentState[windowId];

  if (isNaN(dictValue) || dictValue === 0 || dictValue === 1) {
    var dictValuePlaceholder = 6;
    if (dictKey === "width") {
      objectToUpdate[windowId] = {
        width: dictValue,
        height: stateToUpdate.height,
        marginLeft: (frameState.width - dictValuePlaceholder)/2/columnCount,
        marginRight: (frameState.width - dictValuePlaceholder)/2/columnCount,
        marginTop: stateToUpdate.marginTop/rowCount,
        marginBottom: stateToUpdate.marginBottom/rowCount,
        showModal: true,
        showBackgroundImageModal: false,
        backgroundImage: stateToUpdate.backgroundImage,
        rotationDegrees: stateToUpdate.rotationDegrees,
      };
    } else if (dictKey === "height") {
      objectToUpdate[windowId] = {
        width: stateToUpdate.width,
        height: dictValue,
        marginLeft: stateToUpdate.marginLeft/columnCount,
        marginRight: stateToUpdate.marginRight/columnCount,
        marginTop: (frameState.height - dictValuePlaceholder)/2/rowCount,
        marginBottom: (frameState.height - dictValuePlaceholder)/2/rowCount,
        showModal: true,
        showBackgroundImageModal: false,
        backgroundImage: stateToUpdate.backgroundImage,
        rotationDegrees: stateToUpdate.rotationDegrees,
      };
    }
  } else {
    if (dictKey === "width") {
      objectToUpdate[windowId] = {
        width: dictValue,
        height: stateToUpdate.height,
        marginLeft: (frameState.width - dictValue)/2/columnCount,
        marginRight: (frameState.width - dictValue)/2/columnCount,
        marginTop: stateToUpdate.marginTop/rowCount,
        marginBottom: stateToUpdate.marginBottom/rowCount,
        showModal: true,
        showBackgroundImageModal: false,
        backgroundImage: stateToUpdate.backgroundImage,
        rotationDegrees: stateToUpdate.rotationDegrees,
      };
    } else if (dictKey === "height") {
      objectToUpdate[windowId] = {
        width: stateToUpdate.width,
        height: dictValue,
        marginLeft: stateToUpdate.marginLeft/columnCount,
        marginRight: stateToUpdate.marginRight/columnCount,
        marginTop: (frameState.height - dictValue)/2/rowCount,
        marginBottom: (frameState.height - dictValue)/2/rowCount,
        showModal: true,
        showBackgroundImageModal: false,
        backgroundImage: stateToUpdate.backgroundImage,
        rotationDegrees: stateToUpdate.rotationDegrees,
      };
    }
  }

  if (dictKey === "backgroundImage") {
    objectToUpdate[windowId] = {
      width: stateToUpdate.width,
      height: stateToUpdate.height,
      marginLeft: stateToUpdate.marginLeft,
      marginRight: stateToUpdate.marginRight,
      marginTop: stateToUpdate.marginTop,
      marginBottom: stateToUpdate.marginBottom,
      showModal: false,
      showBackgroundImageModal: false,
      backgroundImage: dictValue,
      rotationDegrees: rotationDegrees,
    }
  }

  // (rowCount * columnCount).map((item, key) => {
  //   objectToUpdate[item + 1] = objectToUpdate[windowId];
  // })
  // var i;
  // for (i = 1; i <= (rowCount * columnCount); i++) {
  //   objectToUpdate[`window${i}`] = objectToUpdate[windowId];
  // }



  return objectToUpdate;
}