import React from 'react';
import ProductOffering from "../page-landing/comp-landingPage/productOffering";
import {productDB} from "../comp-pricing/productDB";

export default class SelectProduct extends React.Component {
  render() {

    return <div className={`select-product-container`} >
      <h1 className="landing-page-banner">
        <span>We ship to Canada &amp; the USA.
          <span className="mobile-no-display"> </span>
          <span className="mobile-no-display">
            ${productDB.matboardOnlyPricing.xLarge.shippingCosts[0]} Flat rate shipping. Free shipping over $49.
          </span>
        </span>
      </h1>
      <ProductOffering productOfferingSection={true} language={"en"}/>
    </div>
  }
}