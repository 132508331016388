import React from 'react';
import backgorundPhoto from "../../../resources/images/blog/vintageFrames/IMG_5124.JPG";
import photo1 from "../../../resources/images/blog/vintageFramesStomped/Eclectic+Gallery+Wall+DIY+Tutorial_0015.jpeg";
import photo2 from "../../../resources/images/blog/vintageFramesStomped/Eclectic Gallery Wall DIY Tutorial_0014.jpg";
import photo3 from "../../../resources/images/blog/vintageFramesStomped/Eclectic Gallery Wall DIY Tutorial_0006.jpg";
import photo4 from "../../../resources/images/blog/vintageFramesStomped/Eclectic+Gallery+Wall+DIY+Tutorial_0010.jpeg";
import photo5 from "../../../resources/images/blog/vintageFrames/IMG_4733.JPG";
import photo6 from "../../../resources/images/blog/vintageFrames/IMG_4728.JPG";
import photo7 from "../../../resources/images/blog/vintageFrames/IMG_4945.JPG";
import photo8 from "../../../resources/images/blog/vintageFrames/IMG_4996.JPG";
import photo9 from "../../../resources/images/blog/vintageFrames/application.png";
import photo10 from "../../../resources/images/blog/vintageFramesStomped/Eclectic+Gallery+Wall+DIY+Tutorial_0012.jpeg";
import photo11 from "../../../resources/images/blog/vintageFramesStomped/Eclectic+Gallery+Wall+DIY+Tutorial_0015.jpeg";
import photo12 from "../../../resources/images/blog/vintageFramesStomped/Eclectic Gallery Wall DIY Tutorial_0017.jpg";
import photo13 from "../../../resources/images/blog/vintageFramesStomped/Eclectic+Gallery+Wall+DIY+Tutorial_0011.jpeg";

import {Helmet} from "react-helmet";
import LazyLoad from 'react-lazyload';

export default class GalleryWallWithVintageFrames extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        };
    };

    componentDidMount() {

    }

    render() {
        return <div className={"blog-app-container"}>

            <Helmet>
                <title>Vintage Gallery Wall with Thrift Shop Frames</title>
                <meta name="description" content="Finding the perfect gallery wall for your home is easy with these simple steps. You can get an amazing looking space without breaking a sweat or spending too much money by following my advice on where to start and what you need!"/>
            </Helmet>

            <div className={"carousel-subject-head"} style={{textAlign: "center"}}>
                Vintage Gallery Wall with Thrift Shop Frames
            </div>

            <div className={"carousel-subject-subhead-type2"}>
                Vintage Frame Gallery Wall
            </div>
            <div className={"carousel-subject-subhead-type3"}>
                Project created by Kendra H<br/>Project Blogger <a href={"https://www.kendrafoundit.com/blog/gallery-wall-template-oversized-mat-frames-with-ikea-ramsborg"}>@KendraFoundIt</a><br/>
                This article is also posted <a href={"https://www.kendrafoundit.com/blog/diy-eclectic-vintage-gallery-wall"}>here</a>
            </div>

            <LazyLoad>
                <img src={backgorundPhoto} className={"blog-carousel-photo"}/>
            </LazyLoad>

            <div className={"blog-article-content"}>

                Finding the perfect gallery wall for your home is easy with these simple steps. You can get an amazing looking space without breaking a sweat or spending too much money by following my advice on where to start and what you need!

                <br/><br/>

                <LazyLoad>
                    <img src={photo1} className={"blog-content-photo"}/>
                </LazyLoad>

                <br/><br/>

                Vintage is back and this time it's not going anywhere! If you're feeling the same way, then read on for our tutorial about how to get an eclectic gallery wall without breaking your budget. We'll take some thrifted picture frames from around town (or maybe even yours) elevate them with custom matting designed just for us by Custom Mat--and voila: A unique piece of artwork that will fit right into any decorating style imaginable while still remaining true vintage at heart

                <br/><br/>

                <LazyLoad>
                    <img src={photo4} className={"blog-content-photo"}/>
                </LazyLoad>

                <br/><br/>

                <b style={{textTransform: "uppercase"}}>Step One: Find the Vintage Frames from Thrift Shop</b>

                <br/><br/>

                The idea for this project came when I collected vintage frames over the course of a few weeks at different thrift stores. The cost varied from $1-8, but one store had an entire bin full free ones so that made it even more worth while!

                <br/><br/>

                When looking for thrifted frames, it's important to focus on the frame itself and not anything else. For example, if you liked a particular color or pattern but were unhappy with how dated everything looked in general then don't worry--we'll take care of that!

                <br/><br/>

                Once I had enough frames, I traced them on to kraft paper so that all of their configurations would be templates. We stuck these plans up against the wall using painter’s tape for easy reference when moving forward with step two-numbering each piece in order not confusion later down line!

                <br/><br/>

                To create a cohesive look, try to space your photos equally apart and/or line them up at common edge. Since this is an eclectic gallery wall with many different styles of art in one room, I didn't want perfect unity so instead chose 2 1/2 inch intervals for most frames while lining up some tops or centers as well - there's no magic formula here! It really depends on what you like best but be patient; it takes time before everything looks great from every angle because each person sees differently.

                <br/><br/>

                <LazyLoad>
                    <img src={photo5} className={"blog-content-photo"}/>
                    <img src={photo6} className={"blog-content-photo"}/>
                    <img src={photo7} className={"blog-content-photo"}/>
                    <img src={photo8} className={"blog-content-photo"}/>
                </LazyLoad>

                <br/><br/>

                <b>STEP TWO: PURCHASE CUSTOM MATTING TO ELEVATE YOUR FRAMES</b>

                <br/><br/>

                The next step is to purchase your custom matting. I took out the backing of each frame, measured it, and noted the dimensions down on a piece of paper. I also noted the overall size of each frame.

                <br/><br/>

                Then I input these measurements into Custom Mat's interactive matboard designer tool and played around with the size of artwork window in each frame; you can test different photo sizes live as you make them.

                <br/><br/>

                This time, I went with Custom Mat’s Smooth White for the matting color. As it turned out my vintage art and frames were more than enough to make an analytical contrast between modern-day pieces without being too stark or overwhelming - so complementing them well was key!

                <br/><br/>

                To add even further depth into this decorating scheme, I also decided try colored matboards on top; specifically Seafoam which complements all prints beautifully while still keeping its own unique identity as well.

                <br/><br/>

                <LazyLoad>
                    <img src={photo9} className={"blog-content-photo"}/>
                    <img src={photo10} className={"blog-content-photo"}/>
                </LazyLoad>

                <b>STEP THREE: CHOOSE & PRINT YOUR ARTWORK</b>

                <br/><br/>

                Choosing and printing your artwork is a fun process! You can get vintage-inspired oil paintings or other types of printable art from North Prints. The site has many different options for every budget, so you should have no problem finding something that fits into what style YOU want in YOUR home decorating endeavor!!

                <br/><br/>

                I was so excited to get my print order processed! I took the downloadable files and printed them at Staples with help from their staff. The large sizes were on 100 pound paper, while smaller ones had a higher quality finish; because there's such variety in these prints--from small graphics all of way up towards oversized paintings-I recommend going into person if you want an accurate count or anything else done correctly during checkout process too (like Insurance).

                <br/><br/>


                <LazyLoad>
                    <img src={photo13} className={"blog-content-photo"}/>
                </LazyLoad>

                <b>STEP FOUR: SWAP OUT THE MATTING & ADD THE PHOTOS</b>


                <br/><br/>

                The fourth step is where all the magic happens! If you haven't already, clean your frames and remove any old artwork. Put in new matting or prints with tabs that allow them to seal themselves back up again-and voila: stunning piece art for hanging on walls everywhere. I used these types myself; they're super easy to use which as well means this project will be done before we know it :)

                <br/><br/>

                <LazyLoad>
                    <img src={photo12} className={"blog-content-photo"}/>
                </LazyLoad>

                <b>STEP FIVE: HANG YOUR GALLERY WALL</b>

                <br/><br/>

                The final step in my gallery wall tutorials is always the hardest - hanging it.  I chose to hang our gallery wall in our open-concept living room, but these frames would also make a great behind-the-couch gallery wall, stairway gallery wall, family photo gallery wall….well, you get the idea - the possibilities are endless.


                <br/><br/>

                <div className={"visit-custom-mat-button"}>
                    <a href={"/"} className={"carousel-read-more-button"}>
                        Visit Custom Mat
                    </a>
                </div>

                <div className={"carousel-subject-subhead-type2"}>
                    Photo Gallery
                </div>

                <LazyLoad>
                    <img src={backgorundPhoto} className={"blog-content-photo"}/>
                    <img src={photo1} className={"blog-content-photo"}/>
                    <img src={photo2} className={"blog-content-photo"}/>
                    <img src={photo3} className={"blog-content-photo"}/>
                    <img src={photo4} className={"blog-content-photo"}/>
                    <img src={photo5} className={"blog-content-photo"}/>
                    <img src={photo6} className={"blog-content-photo"}/>
                    <img src={photo7} className={"blog-content-photo"}/>
                    <img src={photo8} className={"blog-content-photo"}/>
                    <img src={photo9} className={"blog-content-photo"}/>
                </LazyLoad>

                <div className={"visit-custom-mat-button"}>
                    <a href={"/"} className={"carousel-read-more-button"}>
                        Visit Custom Mat
                    </a>
                </div>


            </div>



            <div>
                <div className="elfsight-app-7688ab8e-8d1b-4130-9172-7c7f3e0d45b4"></div>
            </div>



        </div>
    }
}