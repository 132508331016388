import React from "react";
import PromoCodeValidation from "./promoCodeValidation";
import FetchItemList from "../comp-cart/fetchItemList";
import landingPageArrowLeft from "../../../resources/logo/landing-page-arrow-left.svg";

export default class ShoppingSummary extends React.Component {

  constructor() {
    super();
    this.state = {
      showShoppingCartItems: false,
    };
  };

  componentDidMount() {

  }




  fetchShippingChargesPrompt(shippingCost, shippingOrPickup, shoppingSubtotal) {
    if (shoppingSubtotal >= 49 && shippingOrPickup === "shipping") {
      return "Free"
    } else if (shippingCost === 0 && shippingOrPickup === "pickup") {
      return "Free Pickup"
    } else {
      return `$${shippingCost.toFixed(2)}`
    };
  };

  render() {


    return <div className={this.props.focusedModal === "summary" ? "delivery-payments-section-shipping-payments-container" : "no-display"}
                id={"shopping-summary-container"}
                style={{opacity: this.props.focusedModal === "summary" ? "1" : "0.1"}}>
      <div className={"review-section-container-header"}>
        <div className={"summary-review-button-left"}>
          {this.props.french ? "Révision" : "Review"}
          <img
              className={this.state.showShoppingCartItems ? "support-arrow-arrow-v2-inverse" : "support-arrow-arrow-v2"}
              src={landingPageArrowLeft}
              onClick={() => this.setState({showShoppingCartItems: !this.state.showShoppingCartItems})}
              alt={this.props.french ? "Cliquez pour afficher les détails du panier." : "Click to view shopping cart details."}
          />
        </div>

        <div className={"summary-review-button-right"}>
          {this.props.getTotalItemCount()} {this.props.getTotalItemCount() > 1 ?
            this.props.french ? "Articles" : "Items" :
            this.props.french ? "Article" : "Item"}
        </div>

      </div>

      <div onClick={this.props.turnOffErrorChecker === false ? () => {this.setState({focusedModal: "summary"})} : null}>

        <div className={this.state.showShoppingCartItems ? "display" : "no-display"}>
          <FetchItemList
              shoppingCartItems={this.props.shoppingCartItems}
              updateItemList={this.props.updateItemList}
              getDiscountMultiplier={this.props.getDiscountMultiplier}
              fetchLanguage={this.props.fetchLanguage}
              french={this.props.french}
              fxConversion={this.props.fxConversion}
              location={this.props.location}
              french={this.props.french}
          />
        </div>

        <div className={"subtotal-calculator-container"}>

          <div className={"shipping-cost-container"}>
            <div className={"shipping-summary-header"}>{this.props.french ? "Sous-total" : "Subtotal"}</div>
            <div className={this.props.preDiscountPrice ? "shipping-summary-amount" : "no-display"} style={{textDecoration: "line-through"}}>
              ${this.props.fxConversion(parseFloat(this.props.preDiscountPrice)).toFixed(2)}
            </div>
            <div className={this.props.preDiscountPrice ? "shipping-summary-header" : "no-display"} style={{color: "#F59276"}}>
              {this.props.promoCodeInfo.discountRate*100}% {this.props.french ? "de réduction" : "off"} :)
            </div>
            <div className={"shipping-summary-amount"} style={{float: "right"}}>
              ${this.props.fxConversion(this.props.shoppingSubtotal).toFixed(2)}
            </div>
          </div>

          <div className={ (this.props.focusedModal === "summary" || this.props.focusedModal === "payment") ? "shipping-and-taxes-container" : "no-display"}>

            <div className={"shipping-cost-container"}>
              <div className={"shipping-summary-header"}>{this.props.french ? "Frais d’expédition" : "Shipping Charges"}</div>
              <div className={"shipping-summary-amount"}>{this.fetchShippingChargesPrompt(this.props.fxConversion(this.props.shippingCost), this.props.pickUpOrShipping, this.props.shoppingSubtotal)}</div>
            </div>

            <div className={"shipping-cost-container shipping-summary-add-bottom-line"}>
              <div className={"shipping-summary-header"}>Taxes</div>
              <div className={"shipping-summary-amount"}>${this.props.fxConversion(this.props.taxes).toFixed(2)}</div>
            </div>

            <div className={"shipping-cost-container"}>
              <div className={"shipping-summary-header-total"}>Total</div>
              <div className={"shipping-summary-amount-total"}>{this.props.currency === "US$" ? "US$" : "CA$"}{isNaN(this.props.grandTotal) ? 0 : this.props.fxConversion(this.props.grandTotal).toFixed(2)}</div>
            </div>

          </div>

        </div>

        <PromoCodeValidation
            promoCodeInfo={this.props.promoCodeInfo}
            evaluatePromoCode={this.props.evaluatePromoCode}
            french={this.props.french}
            fxConversion={this.props.fxConversion}
            onGoingPromotion={this.props.onGoingPromotion}
            promotionRate={this.props.promotionRate}
            promoCode={this.props.promoCode}
        />

      </div>

    </div>
  }
}