import React from 'react';
import photo1 from "../../../../resources/images/blog/diploma/Background Photo.png"
import photo2 from "../../../../resources/images/blog/diploma/Diploma mat Single.jpg";
import photo4 from "../../../../resources/images/blog/diploma/univ-edited.jpg";
import photo5 from "../../../../resources/images/blog/diploma/Single Mat with Logo.png";
import photo6 from "../../../../resources/images/blog/diploma/Double Matting No Writing.png";
import photo7 from "../../../../resources/images/blog/diploma/Diploma Mat Double.jpg";


import LazyLoad from "react-lazyload";
import {Helmet} from "react-helmet";
import blogArticle1Regular32 from "../../../../resources/images/gallery/1-articleOne/reduced/3-2.png";
import {TikTok} from "react-tiktok";



export default class UniversityGeneral extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    };

    componentDidMount() {

    }

    render() {
        return <div className={"blog-app-container"}>

            <Helmet>
                <title>How to Professionally Frame Your Diploma on a Budget: Clever Tips and Tricks / DIY Hack</title>
                <meta name="description" content="Instead of spending over $200 dollars for a custom diploma frame or official diploma frames, you can do it for about $30-60 dollars with us at Custom Mat. You can customize your own design, and get the high quality, 100% custom designed frames and matboards delivered to your door. "/>
            </Helmet>

            <div className={"carousel-subject-head"} style={{textAlign: "center"}}>
                How to Professionally Frame Your Diploma on a Budget: Clever Tips and Tricks / DIY Hack
            </div>

            <div className={"carousel-subject-subhead-type2"}>
                Diplomas & Certificates
            </div>

            <div className={"carousel-subject-subhead-type3"}>
                Written by Daniel S<br/>Custom Mat Project Blogger
            </div>

            <LazyLoad>
                <img src={photo1} className={"blog-carousel-photo"}/>
            </LazyLoad>

            <div className={"blog-article-content"}>

                Congratulations {this.props.schoolName} Alums! You’ve worked diligently for your degree. Did you feel the weight of your achievement at your college graduation? You will receive your diploma in your mailbox within the coming weeks.

                <br/><br/>

                Congratulations on your hard work and success! Let's amplify your qualifications and hard earned certificates to a profession and working office level.

                <br/><br/>

                Display your diploma proudly within your home or future office. It's not the same without a graduation frame.

                <br/><br/>

                <TikTok url="https://www.tiktok.com/@custommat/video/7106680001117916422?is_from_webapp=1&sender_device=pc&web_id=7101339422562797062" />

                <br/><br/>

                Instead of spending over $250 dollars for a custom diploma frame or official diploma frames, you can do it for just under $30-60 dollars by using Custom Mat (www.custommt.ca). You can customize your own design and get the high quality photo mats, 100% custom designed with your personal school information (at your preference).

                <br/><br/>

                Our custom cut photo mat boards are 100% guaranteed to not only fit but suit any retail or custom frame you desire. Offering 25+ color options, our double mating services, combined with our bright 45-degree crisp white core beveled edged guarantees ultimate display exposure. Diploma longevity is also extended do to our acid free products, preventing bleeding and fading.

                <br/><br/>

                Matboards delivered to your door with a guarantee to fit any frame and elevate your diploma on a truly professional level. (View design gallery from past customers - <a href={"/inspiration"}>link</a>)

                <b>
                    How does this work?
                </b>

                <br/><br/>

                <b>How do you frame your diploma within a budget?</b>

                <br/><br/>

                Here’s how to save money on professional and elevating framing for your bachelor’s, master's, or doctoral degree or any general certificate frames:

                <br/><br/>

                <b>1. Get a standard-sized picture frame.</b>

                <br/><br/>

                You can get picture frames or document frames easily from Amazon or retail stores — like Costco, IKEA, Target, Walmart, or Homesense.

                They come in many different sizes and designs.

                We have recommended sizes for your {this.props.schoolName} diplomas below.

                <br/><br/>

                <b>2. Get a custom mat board.</b>

                <br/><br/>

                Customize your matboard with us! Try double mats, combine your school colors. You can add your school logo and name also! Or, perhaps you just desire your name and sorority name for a more custom piece. We have a lot of options and we deliver to your door anywhere in Canada and the United States!

                <LazyLoad>
                    <video className={"what-is-mat-image-v2"} loop="true" autoPlay="autoplay" controls muted
                           src="https://s3.ca-central-1.amazonaws.com/custommat.landing.v2/0_What-is-a-matboard_1920x1290_v1+(1).mp4"></video>
                </LazyLoad>

                <b>3. Put them together at home.</b>

                <br/><br/>

                It takes only 2.5 minutes. Much easier than you think. That’s it!

                <br/><br/>

                <b>Let's get this started.</b>

                {/*<div className={"visit-custom-mat-button"}>*/}
                {/*    <a href={"/diploma-frame-matboards"} className={"carousel-read-more-button"}>*/}
                {/*        Visit Custom Mat*/}
                {/*    </a>*/}
                {/*</div>*/}

                <LazyLoad>
                    <img src={blogArticle1Regular32} className={"blog-carousel-photo"}/>
                </LazyLoad>


                <br/><br/>

                <b>1. Frame</b>

                <br/><br/>

                University diplomas diplomas are sized at 11x8.5 inches. Here are the recommended document frames for your diploma. They come in various sizes, colors, frame moldings (metal/wood frames) and glass options (including glass and plexiglass acrylic), you can find your favorite design based on your home décor. They can be placed on table top or wall mounted.

                <br/><br/>
                **Please measure the width and height of your diploma as diploma sizes may change every year.


                <br/><br/>

                20x16 inch frame
                <ul>
                    <li>IKEA 20x16 inch frame series  - <a href={"https://www.ikea.com/ca/en/cat/picture-photo-frames-18746/?filters=f-typed-reference-measurement%3A16%20%C2%BCx20%20%22_frames%7C16x20%20%22_frames"}>link</a></li>
                    <li>Amazon's Opposite Wall Frame Series - <a href={"https://www.amazon.ca/stores/OppositeWall/page/5F8F0336-ACA0-41D9-AA96-073D690CE213?ref_=ast_bln"}>link</a></li>
                </ul>

                16x12 inch frame
                <ul>
                    <li>IKEA 16x12 inch frame series  - <a href={"https://www.ikea.com/ca/en/cat/picture-photo-frames-18746/?filters=f-typed-reference-measurement%3A16%20%C2%BCx20%20%22_frames%7C16x20%20%22_frames"}>link</a></li>
                    <li>Amazon's Opposite Wall Frame Series - <a href={"https://www.amazon.ca/stores/OppositeWall/page/5F8F0336-ACA0-41D9-AA96-073D690CE213?ref_=ast_bln"}>link</a></li>
                </ul>

                18x14 inch frame
                <ul>
                    <li>Amazon's Opposite Wall Frame Series - <a href={"https://www.amazon.ca/stores/OppositeWall/page/5F8F0336-ACA0-41D9-AA96-073D690CE213?ref_=ast_bln"}>link</a></li>
                </ul>

                <br/>

                These frames come in various sizes, colors, frame mouldings (metal / wood frames), and glass options (including glass and plexiglass acrylic), you can find your favorite design based on your home decor. They can be placed on table top or wall mounted.

                <br/><br/>

                <b>2. Custom Matboard</b>

                <br/><br/>

                Based on the frame size above, you will decide the matboard size. If you chose IKEA's 20x16 inch frame, you can go for 20x16 inch matboard with 10.75x8.25 inch opening.

                <br/><br/>

                Please note that your opening should be a quarter inch smaller than your diploma size minimum, as your matboard and your diploma needs an overlap. For instance, as diplomas sizes are 11x8.5 inches, you can enter 10.75x8.25 as your opening.

                <br/><br/>

                <LazyLoad>
                    <img src={photo4} className={"blog-content-photo"}/>
                </LazyLoad>

                <br/><br/>

                Colors:

                <br/><br/>

                Depending on your school colours we can customize your matboard. We can do the primary mat in our Smooth White or China White (off-white) and secondary mat in gold to give some lift on your diploma.

                <br/><br/>

                Alternatively, we can also do smooth white or china white as primary mat and have your school colours as your secondary mat.

                <br/><br/>

                <LazyLoad>
                    <img src={photo5} className={"blog-content-photo"}/>
                </LazyLoad>

                <br/><br/>

                Text & Logo:

                <br/><br/>

                If you would like to your school name, logo, or your name plus degree designations (like BA, BS, RN, NP, MD, PharmD, DDS, JD, LLM etc), we recommend you to go with gold, silver, or same color as secondary matting as your text color.

                <br/><br/>


                <LazyLoad>
                    <img src={photo6} className={"blog-content-photo"}/>
                </LazyLoad>

                <br/><br/>

                More Options:

                <br/><br/>

                Going further, you can add more things to your frame. For instance, you can add your tassel, graduation photos, medallion, other certificate of honors to your college degree frames. You just have to get a bigger frame and add additional openings like below. Custom Mat can provide these window opening. Instead of having a separate photo frame for your graduation photo, you can display a photo alongside your tassel and degree. It will be a memorable graduation gift to yourself.

                <br/><br/>

                If you are have masters, you can actually frame your bachelor’s and master’s degrees al together. You can create a double diploma frame. Most of the college diploma frames are mass produced for single diploma needs. With 100% custom design and truly highest quality materials, you can get yourself a graduation gifts at a bargain.

                <br/><br/>

                If you are have a masters, you can actually frame your bachelor’s and master’s degrees together. Create a double diploma frame with the Custom Mat user friendly design feature.

                {/*<a href={"https://www.custommat.ca/diploma-frame-matboards"}>link</a>. Most of the college diploma frames are mass produced for single diploma needs. With 100% custom design and truly the highest quality materials, give yourself a graduation gifts at a bargain. This item will be an investment for future working office credential displays that doubles as stylish décor.*/}

                <br/><br/>

                <LazyLoad>
                    <img src={photo7} className={"blog-content-photo"}/>
                </LazyLoad>

                <br/><br/>

                <b>3. Putting it all together</b>

                <br/><br/>

                In a couple of days, you will get your frames (that you ordered separately) and your matboard (from us). Once your frames and matboards arrive, you can put them together easily. (view our how to videos on our design blog! <a href={"/design-blog-main"}>Design Blog</a>

                <br/><br/>

                Most of the time, you don't need to use the tape or glue to put them in to the frame. You can just use the pressure between your glass and your backing board of your frame to put them there.

                <br/><br/>

                If the pressure is not adequate, you can get an easily removable artist tapes to adhere to the back of the matboard. You want to make your that your tape can be removed easily, just in case you might need to take the diploma out in the future for other purposes.

                <br/><br/>

                **Just a quick note -- it is a good practice to take a picture or scan your diploma as you might need to send the file when you change your career/verify your qualifications. :)

                <br/><br/>

                That's it! You now know how to frame your diploma without spending too much money!

                <br/><br/>

                If you have any questions, please feel free to reach out us at hello@custommat.ca. We would be more than happy to help you with your custom framing needs! :)

                <br/><br/>

                CustomMat runs with 100% Happiness Guaranteed Policy – if you are not happy with your order, let us know and we will make it right. Review our fair return policy. If there are any issues with your matboard, we’ve got you covered.


                {/*<div className={"visit-custom-mat-button"}>*/}
                {/*    <a href={"/diploma-frame-matboards"} className={"carousel-read-more-button"}>*/}
                {/*        Visit Custom Mat*/}
                {/*    </a>*/}
                {/*</div>*/}

                <div className={"carousel-subject-subhead-type2"}>
                    Photo Gallery
                </div>

                <LazyLoad>
                    <img src={photo1} className={"blog-content-photo"}/>
                    <img src={photo2} className={"blog-content-photo"}/>
                    <img src={photo4} className={"blog-content-photo"}/>
                    <img src={photo5} className={"blog-content-photo"}/>
                    <img src={photo6} className={"blog-content-photo"}/>
                    <img src={photo7} className={"blog-content-photo"}/>
                </LazyLoad>

                <div className={"visit-custom-mat-button"}>
                    <a href={"/"} className={"carousel-read-more-button"}>
                        Visit Custom Mat
                    </a>
                </div>



            </div>

            <div>
                <div className="elfsight-app-7688ab8e-8d1b-4130-9172-7c7f3e0d45b4"></div>
            </div>



        </div>
    }
}