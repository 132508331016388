import React from 'react';
import photo1 from "../../../resources/images/blog/pressedFlowerPhotoSet/photo1.jpg";
import photo2 from "../../../resources/images/blog/pressedFlowerPhotoSet/photo2.PNG";
import photo3 from "../../../resources/images/blog/pressedFlowerPhotoSet/photo3.PNG";
import photo6 from "../../../resources/images/blog/pressedFlowerPhotoSet/photo6.jpg";
import photo7 from "../../../resources/images/blog/pressedFlowerPhotoSet/photo7.PNG";
import photo8 from "../../../resources/images/blog/pressedFlowerPhotoSet/photo8.jpg";
import photo9 from "../../../resources/images/blog/pressedFlowerPhotoSet/photo9.jpg";
import LazyLoad from "react-lazyload";
import {Helmet} from "react-helmet";

export default class PressedFlowerFraming extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        };
    };

    componentDidMount() {

    }

    render() {
        return <div className={"blog-app-container"}>

            <Helmet>
                <title>A Guide to your very own Pressed Leaves or Pressed Flower DIY at Home!</title>
                <meta name="description" content="Beautiful, Framed and Hand Pressed... I purchased a glorious set of picture frames and saved huge with a quick trick. The frames I gathered cost significantly less for the quality because they did not have matboard."/>
            </Helmet>

            <div className={"carousel-subject-head"} style={{textAlign: "center"}}>
                A Guide to your very own Pressed Leaves or Pressed Flower DIY at Home!
            </div>

            <div className={"carousel-subject-subhead-type2"}>
                Picture Framing Ideas
            </div>
            <div className={"carousel-subject-subhead-type3"}>
                Written by Danielle M<br/>Custom Mat Project Blogger
            </div>

            <LazyLoad>
                <img src={photo1} className={"blog-carousel-photo"} alt={"DIY flower press wall art tutorial"}/>
            </LazyLoad>


            <div className={"blog-article-content"}>

                <i>Beautiful, Framed and Hand Pressed...</i>

                <br/><br/>

                The easiest and most stunning DIY project ever. A guide to your very own Pressed leaves or Pressed Flower DIY at Home!

                <br/><br/>

                Valentine’s day has passed and a lot of us are sitting with some beautiful flowers just begging to be preserved. Flower art has the most wonderful way of bringing a soft touch to home decor. A picture frame, a pair of tweezers, a DIY flower press wall art tutorial and boom, your botanical art is on its way. If you need wall decor or a custom frame accent these DIY pressed flower pieces bring framed art to a whole new level.

                <br/><br/>

                Make fresh flowers last. Get a head start on Mothers Day or take advantage of the spring flowers to come. Test this DIY on cut flowers, wedding bouquets, wildflowers. The botanical options are limitless. Just consider the aesthetic elements you are trying to achieve.

                <br/><br/>

                Easy DIY to quickly bring beautiful colour to your living room photo gallery, or a delicate touch to a powder room. Elevate your bedroom nightstand or leave a tiny, framed token on your coffee table and desk. Brighten up your office space or simply save on the cost of photos and prints decorating a business or home. Classically timeless, botanical imagery is always satisfying to the minds eye and can fit in any room. Use these materials to effortlessly elevate and calm your living environment.

                <br/><br/>

                <u>Materials needed to make dried flowers or pressed flowers:</u><br/>
                1. Tweezers<br/>
                2. Wax Paper<br/>
                3. Heavy Books<br/><br/>

                <u>Materials needed to make pressed flower frame:</u><br/>
                1. Any hanging frame, photo frame or shadow box<br/>
                2. 4 ply matboard <br/>
                3. Acrylic or glass frame insert <br/><br/>

                <u>Where do I source my flower art materials:</u><br/>
                My thumbs are green for about a minute each year and then that Canadian winter quickly wipes clean the bright halo of grasses and leaves away. What is left behind in my garden are the dead heads of the gorgeous plants I put so much effort into year after year. Moreover, how about the delicate flower arrangements my fiancé so thoughtfully gifted me? Or the daisy’s from the bridesmaid bouquet of my brothers wedding? I don’t just want to preserve these whimsical little gifts before they become skeletal, I want to display them!

                <br/><br/>

                <u>My Inspiration:</u><br/>
                I see gorgeous framed dry flowers politely placed within living room wall galleries and independent galleries in striking 16x20 wood frames matted to 11x14 colorful flax or pastel toned picture mats. The beautiful thing about working with flowers is the frame and photo opening can be any size and the matboard can be any colour.
                <br/><br/>
                For those who do not know what matboard is, this material comes with many names! Picture mat, Mat board, “matte”, picture matting. To my French-Canadian brothers and sisters and classically trained artist or photographers matboard is the passe-partout. Quel est passe partout, and where can I find it!? This is a durable paper-based layer that is positioned between the frame and artwork! It can be sourced online by matboard cutting workshops to cut any size or shape mat in a variety of color options to any frame.
                <br/><br/>

                <LazyLoad>
                    <video className={"what-is-mat-image-v2"} loop="true" autoPlay="autoplay" controls muted
                           src="https://s3.ca-central-1.amazonaws.com/custommat.landing.v2/0_What-is-a-matboard_1920x1290_v1+(1).mp4"></video>
                </LazyLoad>

                <u>Process of matboard and where I chose to source</u><br/>
                (PRO TIP:  this thick 4 ply matboard is what brings the high-quality element to your flower art regardless of the frame selection.)

                <br/><br/>

                This was a great discovery because I didn't really want to buy new frames! I just wanted to utilize the ones that I had in my possession. My grandmother left some gorgeous antique frames behind.
                <br/><br/>

                Unfortunately, the photo matting was fabric, so it aged a faded yellow rather then the glorious bright and vibrant color I found under the parts of the frame that did not suffer from exposure.
                <br/><br/>
                The frame was pretty and had good bones. The mat just needed to be updated! This would be a perfect start to my project. Various colours and sized as well as custom fit matting that suited my living room gallery!

                <br/><br/>

                If you are not looking to spend a lot on this project and you want to be cost effective visit the local thrift store. Its painful how my beautiful frames I find there and spend $8 upgrading!
                <br/><br/>

                <LazyLoad>
                    <img src={photo2} className={"blog-content-photo"} alt={'We have all passed these rows of second-hand frames without considering their potential. You do not have to utilize your antique finds for this project'}/>
                </LazyLoad>



                <br/><br/>

                We have all passed these rows of second-hand frames without considering their potential. You do not have to utilize your antique finds for this project. These frames suit full scale gallery walls for any theme. I just want to have a few that are floral to bring brightness and artistic outlet to my interior design styles.

                <br/><br/>

                See my spray paint tutorial to learn how to really achieve the impossible with upcycled frames!

                <br/><br/>

                <LazyLoad>
                    <img src={photo3} className={"blog-content-photo"} alt={"I have stunning strands of blue painted eucalyptus that I wanted to use. This plant is the dream of anyone who loves botanical sketches, except truly brought to life."}/>
                </LazyLoad>


                <br/><br/>

                I have stunning strands of blue painted eucalyptus that I wanted to use. This plant is the dream of anyone who loves botanical sketches, except truly brought to life.

                <br/><br/>

                <LazyLoad>
                    <img src={photo6} className={"blog-content-photo"} alt={"I was surprised the heavy impact some girth made with the addition of matboard."}/>
                </LazyLoad>



                <br/><br/>

                I have also thrown away dozens of fern stems when I receive roses. These single botanical stems look so chic when elevated with white backdrop and white matboard. I was surprised the heavy impact some girth made with the addition of matboard.

                <br/><br/>

                <LazyLoad>
                    <img src={photo7} className={"blog-content-photo"} alt-={"A perfect green fern became a thing of beauty."}/>
                </LazyLoad>



                <br/><br/>

                A perfect green fern became a thing of beauty. This single white mat with green fern is so "clean" and refreshing to look at. Try placing it in your bathroom or get several and hang in an entrance way.

                <br/><br/>

                Wayfair and Etsy offer the same products for a whopping $160-$400! Since my initial desire was the preserve the flowers that hold sentimental value to me or come from my own garden grown by my hand I decided to tackle my DIY

                <br/><br/>

                <LazyLoad>
                    <img src={photo8} className={"blog-content-photo"} alt={"Gorgeous and sleek, and 100% something you can DIY. "}/>
                </LazyLoad>



                <br/><br/>

                Gorgeous and sleek, and 100% something you can DIY. Still kicking myself for all the flowers and valuable decorative grasses or perns I tossed vs. utilizing to enhance my space.

                <br/><br/>

                <LazyLoad>
                    <img src={photo9} className={"blog-content-photo"} alt={"These Wayfair products are just gorgeous so art the other ones I saw in Pinterest. "}/>
                </LazyLoad>



                <br/><br/>

                These Wayfair products are just gorgeous so art the other ones I saw in Pinterest. I was super excited to create something sold at this price point on my own home.
                <br/><br/>
                The Home Depot website offered me 13 methods of flower preservation and I decided on the one that best fit my desired timeline for my own pressed flower DIY project Here is the method I chose to press and my method for framing!

                <br/><br/>

                <u>How to press flowers and frame them:</u><br/>
                <ol>
                    <li>
                        Create flower arrangement if using cutting on waxed paper. I placed a single fern on some paper as well.
                    </li>
                    <li>
                        Place heavy books on top. Leave for a few hours if you have an iron. If you do not have an iron leave on wax paper under heavy books for at least 3 days.
                    </li>
                    <li>
                        If you own an iron, then heat it up on a low temp and iron the arrangement encased within the wax paper carefully.
                    </li>
                    <li>
                        Decide on the backdrop colours and matboard you want to use for your best result while you wait. Remember to pick the custom window opening size that you desire for your matboard.
                    </li>
                    <li>
                        If using second-hand frames that have been painted wait one week before starting assembly.
                    </li>
                    <li>
                        Assemble frame with matboard and botanical art.
                    </li>
                    <li>
                        Enjoy your stunning DIY.
                    </li>
                </ol>

                <div className={"visit-custom-mat-button"}>
                    <a href={"/"} className={"carousel-read-more-button"}>
                        Visit Custom Mat
                    </a>
                </div>

                <div className={"carousel-subject-subhead-type2"}>
                    Photo Gallery
                </div>

                <LazyLoad>
                    <img src={photo1} className={"blog-content-photo"}/>
                    <img src={photo2} className={"blog-content-photo"}/>
                    <img src={photo3} className={"blog-content-photo"}/>
                    <img src={photo6} className={"blog-content-photo"}/>
                    <img src={photo7} className={"blog-content-photo"}/>
                    <img src={photo8} className={"blog-content-photo"}/>
                    <img src={photo9} className={"blog-content-photo"}/>
                </LazyLoad>

                <div className={"visit-custom-mat-button"}>
                    <a href={"/"} className={"carousel-read-more-button"}>
                        Visit Custom Mat
                    </a>
                </div>


            </div>


            <div>
                <div className="elfsight-app-7688ab8e-8d1b-4130-9172-7c7f3e0d45b4"></div>
            </div>




        </div>
    }
}