import React from 'react';
import fetchImage from "../comp-image/fetchImage";

import polaroidRegular from "../../../resources/images/polaroidDimensions/PolaroidNormal3.5by4.2.png";
import polaroidWide from "../../../resources/images/polaroidDimensions/PolaroidWide4.0by4.1.png";
import instaxMini from "../../../resources/images/polaroidDimensions/FujiInstaxMini3.39by2.13.png";
import instaxWide from "../../../resources/images/polaroidDimensions/FujiInstaxWide4.25by3.39.png";

import polaroidRegularWebp from "../../../resources/images/polaroidDimensions/PolaroidNormal3.5by4.2_2.webp";
import polaroidWideWebp from "../../../resources/images/polaroidDimensions/PolaroidWide4.0by4.1_1.webp";
import instaxMiniWebp from "../../../resources/images/polaroidDimensions/FujiInstaxMini3.39by2.13_13.webp";
import instaxWideWebp from "../../../resources/images/polaroidDimensions/FujiInstaxWide4.25by3.39_39.webp";
import localStorageFunctions from "../comp-localStorage/localStroage";

export default class PolaroidTypeSelector extends React.Component {

  fetchFilms() {
    var polaroidObject = {
      fujiInstaxMini: {
        img: instaxMini,
        webp: instaxMiniWebp,
        company: "Fuji Instax",
        film: "Mini",
        dimensions: ["2.13 x 3.39 in", "1.81 x 2.44 in"],
      },
      fujiInstaxWide: {
        img: instaxWide,
        webp: instaxWideWebp,
        company: "Fuji Instax",
        film: "Wide",
        dimensions: ["4.25 x 3.39 in", "3.90 x 2.44 in"],
      },
      polaroidRegular: {
        img: polaroidRegular,
        webp: polaroidRegularWebp,
        company: "Polaroid",
        film: "",
        dimensions: ["3.5 x 4.2 in", "3.1 x 3.1 in"],
      },
      polaroidWide: {
        img: polaroidWide,
        webp: polaroidWideWebp,
        company: "Polaroid",
        film: "Original",
        dimensions: ["4.0 x 4.1 in", "3.5 x 2.9 in"],
      },
    };


    var polaroidList = Object.keys(polaroidObject);

    return polaroidList.map((item, key) => {
      return <div className={`frame-product-container`} >
        <div className={"frame-product-container"} key={`frame-product-container=${key}`}>
          {fetchImage(polaroidObject[item].img, polaroidObject[item].webp, `frame-product-preview-photo`, `This is a product photoshoot for ${item}`, "", "", "", "")}
          <div className={"frame-product-description"}>
            <div className="product-divider"></div>
            <div className={"product-title product-listing-header"}>
              {polaroidObject[item].company} {polaroidObject[item].film}
            </div>
            <div className={"product-description product-listing-desc"}>
              <div className={"navbar-column-title"}>Dimensions</div>
              Photo Area | {polaroidObject[item].dimensions[1]}<br/>
              Total Area | {polaroidObject[item].dimensions[0]}
            </div>
            {/*<div className={"product-price product-listing-price"}>*/}
            {/*  CA${this.props.productDBFrame.frameLists[item].productPriceLowerBound} - CA${this.props.productDBFrame.frameLists[item].productPriceUpperBound}<br/>*/}
            {/*</div>*/}
            <div className="product-listing-action-button product-listing-action-button-bottom-margin" onClick={() => this.selectPolaroidType(item)}>This is mine</div>
            <div className={"product-description product-listing-desc product-listing-desc-select-option"}>
              {/*<a>Cameras that use this film</a>*/}
            </div>
          </div>
        </div>
      </div>
    })
  }

  selectPolaroidType(filmSize) {
    this.setState({selectedFilmSize: filmSize}, ()=> {localStorageFunctions("CD7BCA387F92B43781990FDQX50BA04082795J83", filmSize)}, window.location.href="/select-polaroid-frames")
  }

  render() {
    return <div className={"select-frame-product-preview"}>

      <div className={"select-frame-title-container"}>
        Select your film type
      </div>

      <div className="product-description product-listing-desc">
        Not all polaroid film is the same. Make sure you select the correct film size.
      </div>

      {this.fetchFilms()}
    </div>
  }
}