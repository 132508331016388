export default function priceTesting () {
    var min = Math.ceil(1);
    var max = Math.floor(3);

    if (localStorage.getItem("B50A23F61860P0353E50I82873C6CD2RDDC401B1")) {
        return localStorage.getItem("B50A23F61860P0353E50I82873C6CD2RDDC401B1");
    } else {
        var rand = Math.floor(Math.random() * (max - min + 1)) + min;
        localStorage.setItem("B50A23F61860P0353E50I82873C6CD2RDDC401B1", `${rand}`);
        return rand;
    }

}
//add if total product price goes over 50 waive the shipping cost