import React from "react";
import fbdb from '../database/cre';
import EncDec from "../app-enc/encryption";
import SelectMat from "../app-matsize-rendering/selectMat";
import emailEnc from "../app-enc/emailEnc";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

export default class WorkFlow extends React.Component {

  constructor() {
    super();
    this.state = {
      authEmail: null,
      dataLoaded: false,
      copyValueToClipboard: "",
      activeOrderIndex: -1,
      emailEntered: "",
      emailEnteredEncrypted: "",
    };

    this.getDiscountMultiplier = this.getDiscountMultiplier.bind(this);
  }

  updateEmail(email) {
    this.setState({authEmail: email}, () => this.confirmEmail())
  }

  login() {
    var provider = new fbdb.auth.GoogleAuthProvider();
    fbdb.auth().signInWithPopup(provider).then(function(result) {
      var user = result.user;
      this.updateEmail(user.email);
    }.bind(this)).catch(function(error) {

    });
  };

  callUserRecFromDB() {

    var encryptedEmail = emailEnc(this.state.emailEntered)
    var destDirectory = `prod/paid-prod/${encryptedEmail}`;
    var valueLoaded = fbdb.database().ref(destDirectory);


    valueLoaded.once('value').then((snapshot) => {
      this.setState({ordersToShow: JSON.stringify(snapshot.val()).toString()})
    }).then(() => this.setState({dataLoaded: true}));
  }

  fetchData() {
    return <div className={this.state.authEmail === null ? "no-display" : "display"}>
      <TextField
          id={"inspectletIgnore"}
          placeholder={"Enter"}
          value={this.state.emailEntered}
          onChange={(evt) => this.setState({emailEntered: evt.target.value})}
      />
      <Button onClick={() => this.callUserRecFromDB()}>
          Search
      </Button>
    </div>



  }

  fetchOrderList(list) {
    return list.map((item, key) => {
      return <div className={`workflow-order-container-order-items`}>
        <div className={`workflow-order-container-order-leader`}>
          <span className={"workflow-section-title"}>Overview</span><br/>
          <span className={`workflow-section-subtitle`}>Unit</span>{item.aMeasuringUnit}<br/>
          <span className={`workflow-section-subtitle`}>Ordered Quantity</span>{item.qItemQuantity}<br/>
        </div>

        <div className={`workflow-order-container-pricing`}>
          <span className={"workflow-section-title"}>Price</span><br/>
          <span className={`workflow-section-subtitle`}>Primary Matting</span>${item.kPrimaryMattingPrice}<br/>
          <span className={`workflow-section-subtitle`}>Primary Cutting</span>${item.lMatCuttingPrice}<br/>
          <span className={`workflow-section-subtitle`}>Double Matting</span>${item.oSecondMattingPrice}<br/>
          <span className={`workflow-section-subtitle`}>Double Matting Price</span>${item.pSecondMatCuttingPrice}<br/>
          <span className={`workflow-section-subtitle`}>Shipping</span>${item.rShippingCost}<br/>
        </div>

        <div className={`workflow-order-container-primary-matting`}>
          <span className={"workflow-section-title"}>Primary Matting</span><br/>
          <span className={`workflow-section-subtitle`}>Dimensions</span>{item.bPrimaryMatWidth} x {item.cPrimaryMatHeight} {item.aMeasuringUnit}<br/>
          <span className={`workflow-section-subtitle`}>Color</span>{item.jPrimaryColour}<br/>
        </div>

        <div className={`workflow-order-container-primary-window`}>
          <span className={"workflow-section-title"}>Window</span><br/>
          <span className={`workflow-section-subtitle`}>Dimensions</span>{item.dPrimaryWindowWidth} x {item.ePrimaryWindowHeight} {item.aMeasuringUnit}<br/>
          <span className={`workflow-section-subtitle`}>Top</span>{item.fPrimaryWindowTop} {item.aMeasuringUnit}<br/>
          <span className={`workflow-section-subtitle`}>Bottom</span>{item.gPrimaryWindowBottom} {item.aMeasuringUnit}<br/>
          <span className={`workflow-section-subtitle`}>Left</span>{item.hPrimaryWindowLeft} {item.aMeasuringUnit}<br/>
          <span className={`workflow-section-subtitle`}>Right</span>{item.iPrimaryWindowRight} {item.aMeasuringUnit}<br/>
        </div>

        <div className={`workflow-order-container-primary-secondary`}>
          <span className={"workflow-section-title"}>Secondary Matting</span><br/>
          <span className={`workflow-section-subtitle`}>Dimensions</span>{item.mSecondaryMatWidth} {item.aMeasuringUnit}<br/>
          <span className={`workflow-section-subtitle`}>Color</span>{item.nSecondaryMatColour}<br/>
        </div>

      </div>
    })
  }

  updateStatus(status, email, date) {
    var doubleCheck = window.confirm(status + " is complete?");

    if (doubleCheck === true) {
      if (status === "prodComplete") {
        var update = {jDm9LcBpCl2JWkDYHgwJEw: EncDec("enc", "Production Complete / Shipping next Business Day")};
        fbdb.database().ref(`prod/paid/${email}/${date}`).update(update);
      } else if (status === "shipped") {
        update = {jDm9LcBpCl2JWkDYHgwJEw: EncDec("enc", `Shipped on ${new Date().toString().substring(0,15)}`)};
        fbdb.database().ref(`prod/paid/${email}/${date}`).update(update);
      } else if (status === "inProgress") {
        update = {jDm9LcBpCl2JWkDYHgwJEw: EncDec("enc", `Order in Progress`)};
        fbdb.database().ref(`prod/paid/${email}/${date}`).update(update);
      }
    } else {
      return null;
    }
  };

  checkDataAndDecrypt(data, key) {
    if (data[key]) {
      return EncDec("dec", data[key]);
    } else {
      return "Info not entered."
    }
  }

  getDiscountMultiplier() {
    //if a user buys 2 items, you get 20% off. 4 items 30% off, 10 items, then 40% off.

    var validItemCount = 0;

    if (this.state.shoppingCartItems) {
      //reflecting second matting price to the total price calculation

      this.state.shoppingCartItems.map((item, key) => {

        if (item.prodType !== "sampleRequest") {
          validItemCount = validItemCount + item.orderQuantity;
        }

      });

      return validItemCount;
    }

    if (validItemCount === 1) {
      return 1

    } else if (validItemCount >= 2 && validItemCount < 4) {
      return 0.8

    } else if (validItemCount >= 4 && validItemCount < 10) {
      return 0.7

    } else if (validItemCount >= 10 ) {
      return 0.6

    }

  };

  fetchWindowDetails(window) {}

  fetchShoppingCartItems(shoppingCart) {

    return shoppingCart.map((cartItem, key) => {

      return <div>
        <div>Ordered Item Info</div>
        <div>Product: {cartItem.prodType}</div>
        <div>Quantity: {cartItem.orderQuantity}</div>

        <div>Frame Info</div>
        <div><b>Type: {cartItem.frameType}</b></div>
        <div>Size: {cartItem.frameSize}</div>
        <div>Dimension: {cartItem.frameWidth} x {cartItem.frameHeight} in</div>

        <div>Mat & Window Info</div>
        <div>Mat Colour: {cartItem.matColour}</div>
        <div>Count: {cartItem.window.rowCount * cartItem.window.columnCount}</div>
        <div>Window Detailed Info: {`${cartItem.window}`}</div>
        <div>
          <b>Backing Mats: {`${cartItem.backingMatboard}`}</b>
        </div>
        <div>Canvas Drawing: {cartItem.canvasDrawing ?
        <div className={"custom-mat-design-diagram"} style={{width: "40%"}}>
                <img style={{width: "100%"}} src={cartItem.canvasDrawing} alt={"Canvas Drawing"}/>
            </div>
         : "false"}</div>
        <div>
          <b>Additional Notes:
            <div style={{color: "red"}}>{`${cartItem.additionalNoteContent}`}</div>
          </b>
        </div>

        <SelectMat
            workflow={true}
            designFor={cartItem.prodType}
            frameType={cartItem.frameType}
            frameSize={cartItem.frameSize}
            frameWidth={cartItem.frameWidth}
            frameHeight={cartItem.frameHeight}
            matColour={cartItem.matColour}
            price={cartItem.price}
            window={cartItem.window}
            secondMatPrice={cartItem.secondMatPrice ? cartItem.secondMatPrice : "NO SECOND MAT SELECTED"}
            secondMatColour={cartItem.secondMatColour ? cartItem.secondMatColour : "NO SECOND MAT SELECTED"}
            secondMatWidth={cartItem.secondMatWidth ? cartItem.secondMatWidth : 0}
            isWindowOval={cartItem.isWindowOval}
            getDiscountMultiplier={this.getDiscountMultiplier}
            currency={this.props.currency}
            fxConversion={this.props.fxConversion}
            changeCurrency={this.props.changeCurrency}
            fetchDiscountTier={this.props.fetchDiscountTier}
            getDiscountMultiplier={this.props.getDiscountMultiplier}
        />
      </div>
    });

  }

  copyAddressFields(value) {
    const el = document.createElement('textarea');
    el.value = value;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
  }

  displayOrderByOrderDates(singleClientOrderData, index) {
    //second then we fetch the individual user by the timestamp

    var singleClientOrderData = JSON.parse(singleClientOrderData);

    var singleClientOrderArray = Object.keys(singleClientOrderData);

    return singleClientOrderArray.map((singleOrder, key) => {

      //detailed user info
      var shippingAddressRecipientName = this.checkDataAndDecrypt(singleClientOrderData[singleOrder], "Gn2OgGjCiGWGOKNk5wphSOeKpfDjvbRn7cb6yfofK4");
      var userEmail = this.checkDataAndDecrypt(singleClientOrderData[singleOrder], "eApOtsVDv3CBJxgQ074pEw");
      var userPhoneNumber = this.checkDataAndDecrypt(singleClientOrderData[singleOrder], "yofareqwrEewqrgTGwbfxIIhBDewqrwekrjkjlkr");

      var pickUpOrShipping = this.checkDataAndDecrypt(singleClientOrderData[singleOrder], "F5299BDA449B72EJJ8526ECS93579EB5C4CE14E2J7");
      var shippingAddressStreet = this.checkDataAndDecrypt(singleClientOrderData[singleOrder], "yofarEil3YNaQAINbRgdiYy6vebJKr5G8fZXPCIxc");
      var shippingAddressSuite = this.checkDataAndDecrypt(singleClientOrderData[singleOrder], "yofarEil3YNaQAINbRgTGwbfxIIhBDXDjRhwh3Dpc");
      var shippingAddressCity = this.checkDataAndDecrypt(singleClientOrderData[singleOrder], "MJqZAX6AVWn4sIXbGOUCDL0OUwgni95nOx8Lyn6qdZk");
      var shippingAddressPostalCode = this.checkDataAndDecrypt(singleClientOrderData[singleOrder], "ziDZfAze4WuKoqGIu2xxzor0XLkV8rLXaNSdU78");
      var shippingAddressProvince = this.checkDataAndDecrypt(singleClientOrderData[singleOrder], "ziDZfAze4WuKoqGIu2xyRRJlIXMAHwrIjworrSZE");

      var paymentCurrency = this.checkDataAndDecrypt(singleClientOrderData[singleOrder], "XD671979A1B043B8FKJLS41D8ECA38124521C95F416");
      var shoppingSubtotal = this.checkDataAndDecrypt(singleClientOrderData[singleOrder], "JX8A56C8KC922DAFECKKD1E8019730DF6EE3233CF29");
      var shippingCost = this.checkDataAndDecrypt(singleClientOrderData[singleOrder], "A1B5E2CDA16E8BCD313BB213K10ACF2B11CDF2D746");
      var taxes = this.checkDataAndDecrypt(singleClientOrderData[singleOrder], "XT1B211887KJ1C7BA4A955318DB3CAA2001C5954064");
      var grandTotal = this.checkDataAndDecrypt(singleClientOrderData[singleOrder], "CU4B798322B2F5626DD285BC60CCA0CA2785643BCD");

      var promoRateApplied = this.checkDataAndDecrypt(singleClientOrderData[singleOrder], "JUX7AA083A5BLB26E8BC965A30JX6398E7BA3DE6");
      var promoCodeUsed = this.checkDataAndDecrypt(singleClientOrderData[singleOrder], "JKLCJ7D0D0A9E18871A032FA6A30JX9E901CBBDE8");
      var discountRate = this.checkDataAndDecrypt(singleClientOrderData[singleOrder], "eApOtsVDv3CBJxgQ074pDisc");

      var parseShoppingCart = this.checkDataAndDecrypt(singleClientOrderData[singleOrder], "CJ92A38CCJXAE8C379C6F79758BQJXB88D14FEJKB") === "Info not entered." ? "[]" : this.checkDataAndDecrypt(singleClientOrderData[singleOrder], "CJ92A38CCJXAE8C379C6F79758BQJXB88D14FEJKB");
      var shoppingCart = JSON.parse(parseShoppingCart);

      return <div style={{borderBottom: "0.1vw solid", padding: "20vh 0"}}>
        <div style={{width: "50%", float: "left"}}>
          <div style={{fontWeight: "600", marginBottom: "1vw"}}>Customer Info</div>
          <div onClick={() => this.copyAddressFields(`${shippingAddressRecipientName}`)}>Name: {shippingAddressRecipientName}</div>
          <div>Email: {userEmail}</div>
          <div>Phone Number: {userPhoneNumber}</div>
        </div>

        <div style={{width: "50%", float: "left"}}>
          <div style={{fontWeight: "600", marginBottom: "1vw"}}>Delivery Methods -- {pickUpOrShipping}</div>
          <div onClick={() => this.copyAddressFields(`${shippingAddressRecipientName}`)}>Name: {shippingAddressRecipientName}</div>
          <div onClick={() => this.copyAddressFields(`${shippingAddressStreet}, ${shippingAddressSuite}`)}>{shippingAddressStreet}, {shippingAddressSuite}</div>
          <div onClick={() => this.copyAddressFields(`${shippingAddressCity}`)}>{shippingAddressCity}</div>
          <div>{shippingAddressProvince}</div>
          <div onClick={() => this.copyAddressFields(`${shippingAddressPostalCode}`)}>{shippingAddressPostalCode}</div><br/>
        </div>

        <div style={{width: "50%", float: "left"}}>
          <div style={{fontWeight: "600", marginBottom: "1vw"}}>Payment Info</div>
          <div>Currency: {paymentCurrency}</div>
          <div>Shopping Total: {shoppingSubtotal}</div>
          <div>Shipping Cost: {shippingCost}</div>
          <div>Total Taxes: {taxes}</div>
          <div>Grant Total: {grandTotal}</div>
        </div>

        <div style={{width: "50%", float: "left"}}>
          <div style={{fontWeight: "600", marginBottom: "1vw"}}>Promotion Used?</div>
          <div>Rates: {promoRateApplied} | {discountRate}</div>
          <div>Code: {promoCodeUsed}</div>
        </div>

        <button onClick={() => this.setState({activeOrderIndex: index})}>Click here to see the design</button>
        {this.state.activeOrderIndex === index ? this.fetchShoppingCartItems(shoppingCart): null}

      </div>
    })
  }

  displayOrderDataByUser() {
    var dbValues = this.state.ordersToShow;
    return this.displayOrderByOrderDates(dbValues);
  }

  confirmEmail() {
    return this.state.authEmail === "to.richard.hong@gmail.com" ? this.fetchData() : null
  };

  render() {
    return <div className={`workflow-section`}>
      Workflow Section
      <button className={this.state.authEmail === null ? "display" : "no-display"} onClick={() => this.login()}>Login</button>
      <div className={this.state.authEmail === null ? "no-display" : "display"}>Signed In</div>

      {this.fetchData()}

      {this.state.dataLoaded && this.displayOrderDataByUser()}
    </div>
  }

}