import React from "react";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import localStorageFunctions from "../comp-localStorage/localStroage";


export default class ShippingInfo extends React.Component {

  componentDidMount() {



  }


  updatePriceInfoToLocalStorage(shoppingSubtotal, shippingCost, taxes, grandTotal) {
    //this will be updated for the USD dynamically for the future purposes
    localStorageFunctions("XD671979A1B043B8FKJLS41D8ECA38124521C95F416", "CAD");
    localStorageFunctions("JX8A56C8KC922DAFECKKD1E8019730DF6EE3233CF29", `${shoppingSubtotal}`);
    localStorageFunctions("A1B5E2CDA16E8BCD313BB213K10ACF2B11CDF2D746", `${shippingCost}`);
    localStorageFunctions("XT1B211887KJ1C7BA4A955318DB3CAA2001C5954064", `${taxes}`);
    localStorageFunctions("CU4B798322B2F5626DD285BC60CCA0CA2785643BCD", `${grandTotal}`);
  };

  moveToPaymentSection(shoppingSubtotal, shippingCost, taxes, grandTotal) {
    this.props.parentSetStateCallback("focusedModal", "payment");
    this.updatePriceInfoToLocalStorage(shoppingSubtotal, shippingCost, taxes, grandTotal);
    // this.updateTheAmountToStripe
    this.props.parentSetStateCallback("totalCost", grandTotal);

    window.scrollTo(0, 0);

  };

  dispatchSectionHeader(text) {
    return <div className={`shipping-form-container-section-header`}>
      {text}
    </div>
  };

  deliverOrPickUpSelector() {
    return <FormControl component="fieldset">
      <RadioGroup aria-label="position" name="position" value={this.props.pickUpOrShipping}
                  onChange={(evt) => {this.props.parentSetStateCallback("pickUpOrShipping", evt.target.value)}} row>
        <FormControlLabel
            value="shipping"
            control={<Radio color="primary" />}
            label="Shipping"
            labelPlacement="end"
            className={"country-selector-label"}
        />

      </RadioGroup>
    </FormControl>
  };

  deliveryForms() {
    return <div className={this.props.pickUpOrShipping === "shipping" ? "shipping-address-section-wrapper" : "no-display"}>
      {this.props.addTextFields("shipping-address-recipient-name shipping-name inspectletIgnore", this.props.french ? "Nom et prénom" : "First and Last", "Gn2OgGjCiGWGOKNk5wphSOeKpfDjvbRn7cb6yfofK4", this.props.showErrorInput("Gn2OgGjCiGWGOKNk5wphSOeKpfDjvbRn7cb6yfofK4"), this.props.french ? "Nom" : "Name*")}
      {this.props.addTextFields("shipping-address-street inspectletIgnore", "", "yofarEil3YNaQAINbRgdiYy6vebJKr5G8fZXPCIxc", this.props.showErrorInput("yofarEil3YNaQAINbRgdiYy6vebJKr5G8fZXPCIxc"), this.props.french ? "Adresse*" : "Address*")}
      {this.props.addTextFields("shipping-address-suite inspectletIgnore", this.props.french ? "À l'attention de, Bureau n°" : "Attn, Suite #", "yofarEil3YNaQAINbRgTGwbfxIIhBDXDjRhwh3Dpc", false,  this.props.french ? "Adresse 2" : "Addres 2*")}
      {this.props.addTextFields("shipping-address-city inspectletIgnore", "", "MJqZAX6AVWn4sIXbGOUCDL0OUwgni95nOx8Lyn6qdZk", this.props.showErrorInput("MJqZAX6AVWn4sIXbGOUCDL0OUwgni95nOx8Lyn6qdZk"),  this.props.french ? "Ville*" : "City*")}



      <FormControl className={"province-state-selector-container"}>
        <div className={"add-text-fields-text-field-title"}>
          {this.props.french ? "Pays*" : "Country*"}
        </div>
        <Select
            variant={"outlined"}
            value={this.props.MJqZAX6AVWn4sIXbGOUCDPGCFU2WpgFGnoK8uagWmYw}
            onChange={(evt) => {this.props.parentSetStateCallback("MJqZAX6AVWn4sIXbGOUCDPGCFU2WpgFGnoK8uagWmYw", evt.target.value)}}
        >
          <MenuItem value="CA">Canada</MenuItem>
          <MenuItem value="US">{this.props.french ? "États Unis" : "United States"}</MenuItem>
        </Select>
      </FormControl>

      {this.props.selectProvinceOrState()}
      {this.props.addTextFields("shipping-address-postal-code", "", "ziDZfAze4WuKoqGIu2xxzor0XLkV8rLXaNSdU78", this.props.showErrorInput("ziDZfAze4WuKoqGIu2xxzor0XLkV8rLXaNSdU78"),
          this.props.MJqZAX6AVWn4sIXbGOUCDPGCFU2WpgFGnoK8uagWmYw === "US" ? "Zip Code*" : this.props.french ? "Code Postal*" : "Postal Code*")}

      <div className={"delivery-timeline-notification"}>
        {this.props.french ? "Expédié de notre atelier dans les 2 prochains jours ouvrables. " : "Shipped from our workshop within the next 2 business days. "}
        <br className={"mobile-no-display"}/>
        {this.props.french ? "Par Postes Canada, UPS et USPS. La livraison prend environ 1 à 5 jours." : "Via Canada Post, UPS, and USPS. Delivery takes about 1-5 days."}
      </div>
    </div>
  };

  pickUpInstructions() {
    return <div className={this.props.pickUpOrShipping === "pickup" ? "shipping-or-pickup-options" : "no-display"}>
      You will receive an email when your order is ready (1-2 Business Days).<br/><br/>
      You can pick up at our location at<br/>
      <a href={"https://goo.gl/maps/dPjv4zVVCxnKXQCi8"} rel="noopener noreferrer" target={"_blank"} alt="Google Maps link to our location">1147 Grant Street, Vancouver, BC, V6A 2J7</a><br/><br/>
      Mon - Fri 9:30AM - 5PM or email us to arrange your pickup window.<br/>
    </div>
  };

  render() {
    return <div className={this.props.focusedModal === "shipping" ? `delivery-payments-section-shipping-payments-container` : "no-display"} style={{opacity: this.props.focusedModal === "shipping" ? "1" : "0.1"}}>

      <div className={ `shipping-form-container`} onClick={() => {this.props.parentSetStateCallback("focusedModal", "shipping")}}>
        <div className={"payment-section-dividers inspectletIgnore"}>

          <div className={"shipping-address-section-wrapper"}>
            {this.dispatchSectionHeader(this.props.french ? "Coordonnées" : "Contact Info")}
            {this.props.addTextFields("shipping-address-email inspectletIgnore", "", "eApOtsVDv3CBJxgQ074pEw", this.props.showErrorInput("eApOtsVDv3CBJxgQ074pEw"), "Email*")}
            {this.props.addTextFields("shipping-address-recipient-phone inspectletIgnore", "", "yofareqwrEewqrgTGwbfxIIhBDewqrwekrjkjlkr", false, this.props.french ? "Numéro de Téléphone" : "Phone Number")}
          </div>

          {this.dispatchSectionHeader(this.props.french ? "Adresse de Livraison" : "Shipping Address")}
          {this.deliveryForms()}



        </div>

        <div className={"payment-section-dividers inspectletIgnore no-display"}>
          {this.dispatchSectionHeader("Delivery Info")}
          {this.deliverOrPickUpSelector()}

          {this.pickUpInstructions()}
        </div>



      </div>

      <div className={`checkout-shop-more-button canvas-app-add-to-cart-button important-action-button`}
           onClick={() => {this.props.shippingInfoCheck(1);}}>
        {this.props.french ? "Continuer" : "Continue"}
      </div>
    </div>
  }
}