import React from 'react';
import photo1 from "../../../resources/images/blog/photoset2/photo1.jpg";
import photo2 from "../../../resources/images/blog/photoset2/photo2.jpg";
import photo3 from "../../../resources/images/blog/photoset2/photo3.jpg";
import photo4 from "../../../resources/images/blog/photoset2/photo4.jpg";
import photo5 from "../../../resources/images/blog/photoset2/photo5.jpg";
import photo6 from "../../../resources/images/blog/photoset2/photo6.jpg";
import photo7 from "../../../resources/images/blog/photoset2/photo7.jpg";
import photo8 from "../../../resources/images/blog/photoset2/photo8.png";
import photo9 from "../../../resources/images/blog/photoset2/photo9.jpg";
import photo10 from "../../../resources/images/blog/photoset2/photo10.jpg";

import photo11 from "../../../resources/images/blog/photoset2/photo11.jpg";
import photo12 from "../../../resources/images/blog/photoset2/photo12.jpg";
import photo13 from "../../../resources/images/blog/photoset2/photo13.jpg";
import photo14 from "../../../resources/images/blog/photoset2/photo14.jpg";
import photo15 from "../../../resources/images/blog/photoset2/photo15.jpg";
import photo16 from "../../../resources/images/blog/photoset2/photo16.jpg";
import photo17 from "../../../resources/images/blog/photoset2/photo17.jpg";
import photo18 from "../../../resources/images/blog/photoset2/photo18.jpg";
import photo19 from "../../../resources/images/blog/photoset2/photo19.jpg";
import photo20 from "../../../resources/images/blog/photoset2/photo20.jpg";
import photo21 from "../../../resources/images/blog/photoset2/photo21.PNG";
import photo22 from "../../../resources/images/blog/photoset2/photo22.PNG";
import photo23 from "../../../resources/images/blog/photoset2/23.k.brooke.home.PNG";
import {Helmet} from "react-helmet";
import LazyLoad from "react-lazyload";

export default class BlogHomeOffice extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        };
    };

    componentDidMount() {

    }

    fetchImage(img) {
        return <LazyLoad>
            <img src={img} className={"blog-content-photo"}/>
        </LazyLoad>
    }

    render() {
        return <div className={"blog-app-container"}>

            <Helmet>
                <title>How to Define and Stylize Your Home Office Space to Promote Productivity</title>
                <meta name="description" content="How does one go about creating the perfect, modern and productive home office? And where to start?"/>
            </Helmet>

            <div className={"carousel-subject-head"} style={{textAlign: "center"}}>
                How to Define and Stylize Your Home Office Space to Promote Productivity
            </div>

            <div className={"carousel-subject-subhead-type2"}>
                Home Office
            </div>
            <div className={"carousel-subject-subhead-type3"}>
                Written by Danielle M<br/>Custom Mat Project Blogger
            </div>

            <LazyLoad>
                <img src={photo1} className={"blog-carousel-photo"}/>
            </LazyLoad>



            <div className={"blog-article-content"}>

                The pandemic has been globally isolating. Weather you are in California looking out the window or a tiny picturesque  English village in the U.K, we have all felt claustrophobic and unproductive.

                I owe the lousy, isolating feeling to the lack of defined workspace. Your once temporary small home office doesn't have to be. Create a healthy working office space to enjoy.

                <br/><br/>

                {this.fetchImage(photo2)}

                Recently I have accepted these makeshift set-ups in our living room or bedroom are no longer temporary.

                After working remotely from home for a few years I have finally committed accepting that it's time to dig in for the long haul.

                However how does one go about creating the perfect, modern and productive home office? And where to start?

                <br/><br/>

                <u>A few important questions to answer for home office interior design projects are:</u><br/>

                1. Where do I find custom home office layout ideas for my furniture and photo gallery?<br/>

                2. How do tweak my office with budget friendly home office pictures or update an old frame?<br/>

                3. How do I dedicate this once borrowed space to my small home office?<br/>

                4. How do I commit to a home office background and do I even need to commit with paint?<br/>

                <br/><br/>
                {this.fetchImage(photo7)}

                To make things simple start with the layout, fill in the essential portions of the design.

                For example, your home office desk location, shelving dimensions and photo or print dimensions.

                <br/><br/>
                {this.fetchImage(photo23)}
                <div className={"blog-article-photo-description"}>
                    Customer photo from <a href={"https://www.instagram.com/p/CZKUlMYO21A/"}>@kbrooke.home</a>
                </div>

                The volume of things you would like to display and the true boundary lines of your dedicated space.

                Also be sure to consider the locations of your electrical outlets and power sources before committing to a layout.

                Functionality is still the main focus of an office, make sure you have access to the light fixture switches as well.

                <br/><br/>



                <br/><br/>

                {/*{this.fetchImage("https://media.architecturaldigest.com/photos/5877e0303b0c054842624be7/master/w_1920%2Cc_limit/chandigarh-chair-217-RD_Kardashian_6403_F.jpg")}*/}
                {/*<div className={"blog-article-photo-description"}>*/}
                {/*    <u><a href={"https://www.architecturaldigest.com/gallery/home-offices-slideshow"}>Photo Credit: Roger Davies / Architectural Digest</a></u>*/}
                {/*</div>*/}

                If you are having trouble finding home office inspiration for your newly dedicated space try an interior design app.

                Many interior design apps allow users to plan their own layouts using real dimensions, prop furniture and just your smart phone camera. Architecture and interior design magazines could be a great source as well.

                For instance, a curated home office layout ideas can be found in <a href={"https://www.architecturaldigest.com/gallery/home-offices-slideshow"}><u>Architectural Digest</u></a> if you want to subscribe.

                <br/><br/>

                {this.fetchImage(photo3)}

                Personally, I prefer to pull my home office layout ideas from the app-based software I can find on Google Play or in the Apple Store for free (I find Hauzz and Homes Design 3D from the google play store really good for modern home office ideas).

                This is simply because I can afford to source the linked products compared to boutique price. Again, when doing layout do not forget to consider wall art.

                <br/><br/>

                {this.fetchImage(photo4)}

                Subtle placement of some art-deco pieces shelved in a show case with a minimalist frame and professionally sourced matboard can give a gallery feel.

                Be sure to plan placement for these things in your layout design. This is your professional but PERSONAL home work space.

                <br/><br/>

                Next it is wise to explore trending Small home office ideas for Home office décor, for added inspiration.

                Often when things begin to trend, they can be found mass produced at a lower cost for consumer purchase.

                Also staying on trend through décor means any new items added to the office can be updateable with the times, always staying modern.

                {this.fetchImage(photo5)}

                <br/><br/>

                A dedicated custom cut mat board in a photo frame is much easier and cheaper to source and update then repainting your office the colours of the season.

                Use your gallery walls and custom mat boards to define the space.

                Defining your space as a home office using home office wall décor prevents the space from becoming a sloppy after thought in your beloved living rooms and shared spaces.

                <br/><br/>

                Next plan your budget. After a budget has been decided start to source essential items you do not have.

                With your design ideas in mind stay organized to create an unwavering personal aesthetic and home office setup.

                Remember that to create an aesthetic we do not need to spend thousands on a chandelier or Italian leather seats or custom photo framing.

                <br/><br/>

                {this.fetchImage(photo6)}

                Alternative options always exist to achieve the looks we desire. we can always incorporate small custom touches to have personalized home office sets and stationery.

                Don't be afraid to even ask for home office gifts over flowers and cakes if your birthday is coming up!

                <br/><br/>

                The most expensive personal décor in an office is usually the custom framing... these framing services "cut custom frames" and charge like they are running a build a bear workshop.

                You must pay for the frame, the glass, the backboard, the matboard, the hardware and they profit off every little piece along the way.

                <br/><br/>

                {this.fetchImage(photo7)}

                I knew a cheaper alternative had to exist. Retailers like Walmart and Ikea or Michaels have perfectly good frames for use.

                Then I discovered custom mat. Their company states: "Beautiful picture frames should be budget-friendly. We are introducing a new alternative way of custom framing."

                That is a pretty bold statement isn't it? after exploring the webpage it was true.

                <br/><br/>

                {this.fetchImage(photo8)}

                This website can create any mat board to fit any size frame. And staying on the theme of budget they offer bundle packages that never go away.

                This was perfect because I wanted to hang many oversized frames and I just learned I could get a $750 look for $49.

                See below on how to work with custom mat board to create a perfect office background.

                Now that the most expensive bits have been significantly scaled down in cost more attention can be given to the desired aesthetic.

                <br/><br/>

                {this.fetchImage(photo9)}

                If you have a Brooklyn budget and Beverly hills taste that is okay, have you been to New York city? It's personal and cool everywhere.

                Or maybe you live in an old brownstone in Los Angeles, but you want to feel like you are surrounded by the aesthetics of 18th-century Paris.

                Even if your decorating vibe is Parisian, perfect. Bring Paris to that small empty Manhattan apartment you squeezed into.

                Define your taste to streamline modern home office ideas you have. once tastes are defined anything can be achieved using colours and symmetry without overspending or crowding.

                <br/><br/>

                <u>Home office essentials:</u><br/>

                A bookcase, bookshelves, showcases, or general shelving are essential to prevent crowding when creating a dedicated space.

                Do not clutter your home office desk like a coffee table as this will isolate your workspace. Invest in a low sitting side table if necessary.

                <br/><br/>

                {/*{this.fetchImage("https://media.architecturaldigest.com/photos/58d5504146c96f35ad8972fe/master/w_1920%2Cc_limit/modern-office-library-new-york-city-201107_1000-watermarked.jpg")}*/}

                {/*<div className={"blog-article-photo-description"}>*/}
                {/*    <u><a href={"https://www.architecturaldigest.com/gallery/home-offices-slideshow"}>Photo Credit: Joshua McHugh / Architectural Digest</a></u>*/}
                {/*</div>*/}

                Weather you are in a penthouse, or your office is borrowing a few square-feet for workspace from a living room or another room, space must be defined using items to guide the eye.

                If you do not have room to house shelving, try this trick! Use a floor lamp or a slim cocktail table and centre your office using these items and an office gallery wall.

                <br/><br/>

                {this.fetchImage(photo1)}

                A gallery wall is the perfect home office background. With a few frames from Ikea and my favourite decorating hack again, mat boards are a game changer and wallet saver.

                Use custommat.ca to source your mat board. Custom mat offers bundle packages and literally every colour I could imagine in their colour guide.

                Not to mention the colour guide really helped me to set my theme. For example, this year’s trending colour is off-white colour that blends in with the overall decor.

                <br/><br/>

                For my office i decided to buy IKEA's 16x20 inch, silver metallic Lomviken frames and inlay custom cut off-white into my pretty basic frames.

                I skipped the framing shop expense by using the super user-friendly tools provided by custom mats website to get EXACTLY what I wanted.

                <br/><br/>

                {this.fetchImage(photo13)}

                <u>TIP:</u> <br/>
                Use a print on demand web service for your artwork instead of buying in-store as the cost is lower, and the quality is the same and sizing and printing mediums can be customized.

                Please note this is not an intelligent service to use through an online full framing service that acts as a one stop shop simply because these businesses are not focused on printing.

                <br/><br/>

                They are focused on selling the expensive oak frame to you over the regular frames that look the same from online or offline retail shops.

                Be wise the first time you decorate, pay the company that exclusively does printing and then a company that exclusively does cutting and "Bade Bing bade boom", you never needed that frame for $400.

                <br/><br/>


                {this.fetchImage(photo9)}
                <div className={"blog-article-photo-description"}>
                    Left: Custom Cut matboard | Right: IKEA's default matobard
                </div>

                Unfortunately, I could not get away with using the inlays of pre-cut matboard from Ikea.

                They just did not centre my office or my gallery images in a polished way.

                Desperate to upgrade my little townhouse, I just followed the custom design guide from the custom mat website to learn how to measure my artwork windows.

                <br/><br/>

                {this.fetchImage(photo10)}
                <div className={"blog-article-photo-description"}>
                    Left: Custom matboard | Right: IKEA's default matboard
                </div>

                I was left feeling like an interior designer from the custom mat tools on their website.

                As a new home owner working remotely during the pandemic I have not committed to paint yet, especially considering I just decided to make this office space dedicated as stated above.

                What is a matboard? Matboard is a durable paper based layer that is positioned between the frame and the artwork.


                <br/><br/>

                {this.fetchImage(photo21)}
                <div className={"blog-article-photo-description"}>
                    Customer photo from <a href={"https://www.instagram.com/p/CN6c0-kBWuM/"}>@cindylaneart</a>
                </div>

                Home office design mistakes: If you want to showcase your diplomas on your wall be sure not to leave them in just a plane jane frame.

                These items naturally look distressed and dated and not in a trendy way. Update your educational and legal documents so you do not create an office backdrop from a hospital decorating playbook.

                <br/><br/>

                {this.fetchImage(photo22)}
                <div className={"blog-article-photo-description"}>
                    Customer photo from <a href={"https://www.instagram.com/p/CO53edPB3jy/"}>@jules_interior</a>
                </div>

                Elevate the necessities you find crucial to your workspace that naturally take the air out of the room alone.

                Use an oversized simple frame and ivory white mat board custom cut with the image insert dimensions hugging your diploma.

                <br/><br/>

                {this.fetchImage(photo11)}

                Remember, if you have more than one frame for your gallery wall, then the website provided (www.custommat.ca) is perfect because of their bundle packages.

                Instead of spending hundreds, you can get a similar visual effect by combining any regular picture frame with custom mat board.

                <br/><br/>

                {this.fetchImage(photo12)}


                They offer 40% off on their bundle packages and often have great promotions not to mention free shipping on orders over $49.

                The also are willing to negotiate substantial discounts on bulk purchases.

                <br/><br/>

                {this.fetchImage(photo13)}

                This service saved me 80% off retail customization or through a private framing shop online or otherwise because matboard is their specialty.

                And apparently mine as well because i am obsessed with the ease and high-quality result of a single decorating element.

                Mat board as a product was the key to bringing professional edge to my once semi permanent remote workstation now turned into a chic dedicated home office space.

                <div className={"visit-custom-mat-button"}>
                    <a href={"/"} className={"carousel-read-more-button"}>
                        Visit Custom Mat
                    </a>
                </div>

                <div className={"carousel-subject-subhead-type2"}>
                    Photo Gallery
                </div>

                {this.fetchImage(photo1)}
                {this.fetchImage(photo2)}
                {this.fetchImage(photo3)}
                {this.fetchImage(photo4)}
                {this.fetchImage(photo5)}
                {this.fetchImage(photo6)}
                {this.fetchImage(photo7)}
                {this.fetchImage(photo8)}
                {this.fetchImage(photo9)}
                {this.fetchImage(photo10)}
                {this.fetchImage(photo11)}
                {this.fetchImage(photo12)}
                {this.fetchImage(photo13)}
                {this.fetchImage(photo14)}
                {this.fetchImage(photo15)}
                {this.fetchImage(photo16)}
                {this.fetchImage(photo17)}
                {this.fetchImage(photo18)}
                {this.fetchImage(photo19)}
                {this.fetchImage(photo20)}

                <div className={"visit-custom-mat-button"}>
                    <a href={"/"} className={"carousel-read-more-button"}>
                        Visit Custom Mat
                    </a>
                </div>


            </div>


            <div>
                <div className="elfsight-app-7688ab8e-8d1b-4130-9172-7c7f3e0d45b4"></div>
            </div>

        </div>
    }
}