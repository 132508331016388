var CryptoJS = require("crypto-js");

export default function EncDec(encOrDec, message) {

  var input = message;
  var passphrase = "Arman Asemani";

  if (encOrDec === "enc") {
    var output = CryptoJS.AES.encrypt(input, passphrase).toString();
    return output;
  } else if (encOrDec === "dec") {
    var bytes  = CryptoJS.AES.decrypt(input, passphrase);
    output = bytes.toString(CryptoJS.enc.Utf8);
    return output;
  }

};
