import ikea1
  from "../../../resources/images/gallery/0-generalGallery/reduced/1-custom-cut-mat-board-for-ikea-SILVERHOJDEN-frame-(36in-x-27in-91cm-x-61cm).png";
import ikea2
  from "../../../resources/images/gallery/0-generalGallery/reduced/2-custom-cut-mat-board-for-ikea-SILVERHOJDEN-frame-(16in-x-12in-(41cm-x-31cm).png";
import ikea3
  from "../../../resources/images/gallery/0-generalGallery/reduced/3-custom-cut-passepartout-board-for-ikea-SILVERHOJDEN-frame-(16in-x-12in-(41cm-x-31cm).png";
import ikea4
  from "../../../resources/images/gallery/0-generalGallery/reduced/4-custom-cut-mat-board-or-passepartout-for-IKEA-LOMVIKEN-frame-(16in-x-12in-41cm-x-31cm).png";
import ikea5
  from "../../../resources/images/gallery/0-generalGallery/reduced/5-custom-cut-passepartout-online-delivery-service-canada-and-usa.jpg";
import ikea6
  from "../../../resources/images/gallery/0-generalGallery/reduced/6-custom-cut-mat-board-online-delivery-service-canada-and-usa.png";
import ikea7
  from "../../../resources/images/gallery/0-generalGallery/reduced/7-custom-cut-passepartout-board-for-ikea -Ribba-frame-(36in-x-27in-91cm-x61cm).png";
import ikea8
  from "../../../resources/images/gallery/0-generalGallery/reduced/8-custom-cut-mat-board-for-ikea-Ribba-frame-(36in-x-27in -91cm-x-61cm).png";
import ikea9
  from "../../../resources/images/gallery/0-generalGallery/reduced/9-custom-cut-passepartout-board-for-ikea-SILVERHOJDEN-frame-(36in-x-27in-(91cm-x-61cm).png";

import ikea1Webp
  from "../../../resources/images/gallery/0-generalGallery/webp/1-custom-cut-mat-board-for-ikea-SILVERHOJDEN-frame-(36in-x-27in-91cm-x-6cm).webp";
import ikea2Webp
  from "../../../resources/images/gallery/0-generalGallery/webp/2-custom-cut-mat-board-for-ikea-SILVERHOJDEN-frame-(16in-x-12in-(41cm-x-31cm).webp";
import ikea3Webp
  from "../../../resources/images/gallery/0-generalGallery/webp/3-custom-cut-passepartout-board-for-ikea-SILVERHOJDEN-frame-(16in-x-12in-(41cm-x-31cm).webp";
import ikea4Webp
  from "../../../resources/images/gallery/0-generalGallery/webp/4-custom-cut-mat-board-or-passepartout-for-IKEA-LOMVIKEN-frame-(16in-x-12in-41cm-x-31cm).webp";
import ikea5Webp
  from "../../../resources/images/gallery/0-generalGallery/webp/5-custom-cut-passepartout-online-delivery-service-canada-and-usa.webp";
import ikea6Webp
  from "../../../resources/images/gallery/0-generalGallery/webp/6-custom-cut-mat-board-online-delivery-service-canada-and-usa.webp";
import ikea7Webp
  from "../../../resources/images/gallery/0-generalGallery/webp/7-custom-cut-passepartout-board-for-ikea -Ribba-frame-(36in-x-27in-91cm-x61cm).webp";
import ikea8Webp
  from "../../../resources/images/gallery/0-generalGallery/webp/8-custom-cut-mat-board-for-ikea-Ribba-frame-(36in-x-27in -91cm-x-61cm).webp";
import ikea9Webp
  from "../../../resources/images/gallery/0-generalGallery/webp/9-custom-cut-passepartout-board-for-ikea-SILVERHOJDEN-frame-(36in-x-27in-(91cm-x-61cm).webp";

import blogArticle1Regular11 from "../../../resources/images/gallery/1-articleOne/reduced/1-1.png";
import blogArticle1Regular12 from "../../../resources/images/gallery/1-articleOne/reduced/1-2.png";
import blogArticle1Regular13 from "../../../resources/images/gallery/1-articleOne/reduced/1-3.png";
import blogArticle1Regular21 from "../../../resources/images/gallery/1-articleOne/reduced/2-1.png";
import blogArticle1Regular22 from "../../../resources/images/gallery/1-articleOne/reduced/2-2.png";
import blogArticle1Regular23 from "../../../resources/images/gallery/1-articleOne/reduced/2-3.png";
import blogArticle1Regular24 from "../../../resources/images/gallery/1-articleOne/reduced/2-4.png";
import blogArticle1Regular31 from "../../../resources/images/gallery/1-articleOne/reduced/3-1.png";
import blogArticle1Regular32 from "../../../resources/images/gallery/1-articleOne/reduced/3-2.png";
import blogArticle1Regular41 from "../../../resources/images/gallery/1-articleOne/reduced/4-1.png";
import blogArticle1Regular42 from "../../../resources/images/gallery/1-articleOne/reduced/4-2.jpg";

import blogArticle1Webp11 from "../../../resources/images/gallery/1-articleOne/webp/1-1.webp";
import blogArticle1Webp12 from "../../../resources/images/gallery/1-articleOne/webp/1-2.webp";
import blogArticle1Webp13 from "../../../resources/images/gallery/1-articleOne/webp/1-3.webp";
import blogArticle1Webp21 from "../../../resources/images/gallery/1-articleOne/webp/2-1.webp";
import blogArticle1Webp22 from "../../../resources/images/gallery/1-articleOne/webp/2-2.webp";
import blogArticle1Webp23 from "../../../resources/images/gallery/1-articleOne/webp/2-3.webp";
import blogArticle1Webp24 from "../../../resources/images/gallery/1-articleOne/webp/2-4.webp";
import blogArticle1Webp31 from "../../../resources/images/gallery/1-articleOne/webp/3-1.webp";
import blogArticle1Webp32 from "../../../resources/images/gallery/1-articleOne/webp/3-2.webp";
import blogArticle1Webp41 from "../../../resources/images/gallery/1-articleOne/webp/4-1.webp";
import blogArticle1Webp42 from "../../../resources/images/gallery/1-articleOne/webp/4-2.webp";

import blogArticle2Regular1 from "../../../resources/images/gallery/2-articleTwo/1.matboardSizingAndArtworkWindowOptions.png";
import blogArticle2Webp1 from "../../../resources/images/gallery/2-articleTwo/1.matboardSizingAndArtworkWindowOptions.webp";
import blogArticle2Regular3 from "../../../resources/images/gallery/2-articleTwo/3.tapingAreaDesc.png";
import blogArticle2Webp3 from "../../../resources/images/gallery/2-articleTwo/3.tapingAreaDesc.webp";
import blogArticle2Regular2 from "../../../resources/images/gallery/2-articleTwo/2.artworkWindowOverlapping.png";
import blogArticle2Webp2 from "../../../resources/images/gallery/2-articleTwo/2.artworkWindowOverlapping.webp";
import blogArticle2Regular4 from "../../../resources/images/gallery/2-articleTwo/4.smallArtworkWindowOverlapping.png";
import blogArticle2Webp4 from "../../../resources/images/gallery/2-articleTwo/4.smallArtworkWindowOverlapping.webp";
import blogArticle2Regular5 from "../../../resources/images/gallery/2-articleTwo/5.artworkExtension.png";
import blogArticle2Webp5 from "../../../resources/images/gallery/2-articleTwo/5.artworkExtension.webp";
import blogArticle9Photo from "../../../resources/images/gallery/9-articleNine/article9Photo.png";

import blogArticle3Regular12 from "../../../resources/images/gallery/3-articleThree/reduced/1-2.png";
import blogArticle3Regular13 from "../../../resources/images/gallery/3-articleThree/reduced/1-3.png";
import blogArticle3Regular14 from "../../../resources/images/gallery/3-articleThree/reduced/1-4.png";
import blogArticle3Regular15 from "../../../resources/images/gallery/3-articleThree/reduced/1-5.png";
import blogArticle3Regular16 from "../../../resources/images/gallery/3-articleThree/reduced/1-6.png";
import blogArticle3Regular21 from "../../../resources/images/gallery/3-articleThree/reduced/2-1.png";
import blogArticle3Regular22 from "../../../resources/images/gallery/3-articleThree/reduced/2-2.png";
import blogArticle3Regular23 from "../../../resources/images/gallery/3-articleThree/reduced/2-3.png";

import blogArticle3Webp12 from "../../../resources/images/gallery/3-articleThree/webp/1-2.webp";
import blogArticle3Webp13 from "../../../resources/images/gallery/3-articleThree/webp/1-3.webp";
import blogArticle3Webp14 from "../../../resources/images/gallery/3-articleThree/webp/1-4.webp";
import blogArticle3Webp15 from "../../../resources/images/gallery/3-articleThree/webp/1-5.webp";
import blogArticle3Webp16 from "../../../resources/images/gallery/3-articleThree/webp/1-6.webp";
import blogArticle3Webp21 from "../../../resources/images/gallery/3-articleThree/webp/2-1.webp";
import blogArticle3Webp22 from "../../../resources/images/gallery/3-articleThree/webp/2-2.webp";
import blogArticle3Webp23 from "../../../resources/images/gallery/3-articleThree/webp/2-3.webp";

import blogArticleReduced11 from "../../../resources/images/gallery/4-articleFour/reduced/printer.jpg";
import blogArticleReduced12 from "../../../resources/images/gallery/4-articleFour/reduced/iPhone.jpg";
import blogArticle4Webp11 from "../../../resources/images/gallery/4-articleFour/webp/printer.webp";
import blogArticle4Webp12 from "../../../resources/images/gallery/4-articleFour/webp/iPhone.webp";

import blogArticle7Webp11 from "../../../resources/images/gallery/7-articleSeven/webp/1.webp";
import blogArticle7Webp12 from "../../../resources/images/gallery/7-articleSeven/webp/2.webp";

import blogArticle7Reduced11 from "../../../resources/images/gallery/7-articleSeven/reduced/1.png";
import blogArticle7Reduced12 from "../../../resources/images/gallery/7-articleSeven/reduced/2.png";
import blogArticle7Reduced13 from "../../../resources/images/gallery/7-articleSeven/reduced/3.jpg";

import galleryWallStockPng from "../../../resources/images/gallery/10-articleTen/gallery-wall-stock-photo.png";

import article11Stock from "../../../resources/images/gallery/11-articleEleven/11-image1.jpg";

import article12Stock from "../../../resources/images/gallery/12-articleTwelve/photo1.jpg";

export const galleryArticles = {
  article11: {
    category: "Our Advice",
    hashTags: ["#ourAdvice", "#customerStory"],
    title: '[Tips] DIY OVERSIZED MAT & SQUARE FAMILY PHOTO FRAMES',
    subtitle: "BACKGROUND: DIY OVERSIZED MAT & SQUARE FAMILY PHOTO FRAMES",
    photos: [article12Stock, article12Stock],
    photosUnCompressed: [article12Stock, article12Stock],
    contents: [
      `“How can I frame large artwork without spending hundreds?”`,
      `This is a question that we get almost every day. We don’t really ship any frames that are bigger than 28 x 20 inches because of shipping difficulties. For the same reason, that’s why larger frames are difficult to find from our online stores.`,
      `That’s why we are here to share some tips on how to frame large artworks on a budget.`,
      `<b>Check out used furniture / liquidation / thrift shop.</b> <br><br> Based on our experience, the easiest way to get a larger picture frame on a budget is to check out the used furniture stores. I know the idea of being “second-handed” is not the most appealing thing ever, but how many times do you touch picture frames in your offices and hotel rooms? `,
      `Most of the frames from furniture liquidation stores come from hotels and offices. Unlike bed or sofa, frames are basically pristine. Wouldn’t it be a bargain if you could get those frames for $40, instead of $400 at a custom framing shop?`,
      `<b>How to do this?</b><br><br>
          <li>Call up the liquidation store and ask if they have picture frame at your artwork size.</li>
          <li>If you have a frame that fits exactly your artwork size, that is terrific!</li>
          <li>If not, you can bridge the gaps between your artwork and the frame through matboard.</li>
        `,
      `Sometimes, those frames already come with artworks. You can easily change the artworks with the simple, home-use hardware tools. If you find a frame that you like, you can replace the artwork & matboards easily. This simple little trick saves you hundreds! 
           <br><br>
           <b>Here are examples from our customers:
         `,
      `
          <div class="blog-article-photo-two">
  
            <picture>
              <source type="image/webp" srcSet="${blogArticle7Webp11}"/>
              <source type="image/png" srcSet="${blogArticle7Reduced13}" />
              <img class="blog-article-photo-two-image" src="${blogArticle7Reduced13}"/>
            </picture>
            <picture/>
              <source type="image/webp" srcSet="${blogArticle7Reduced12}"/>
              <source type="image/png" srcSet="${blogArticle7Webp12}" />
              <img class="blog-article-photo-two-image" src="${blogArticle7Reduced12}"/>
            </picture>
          </div>
        `,
      `imageSubscript-type2-Photo credit - Arman form Philadelphia`,
      `When Arman fit his new matboard into the second handed frame that he got from a thrift store, he realized that he got a great deal. His frame now looks perfect with his diploma. Something that costed hundreds were done for $18. `,
      `
      <div class="blog-article-photo-one">
          <picture>
            <source type="image/webp" srcSet="${blogArticle9Photo}"/>
            <source type="image/png" srcSet="${blogArticle9Photo}"/>
            <img class="blog-article-photo-one-image" src="${blogArticle9Photo}"/>
          </picture>
        </div>
      `,
      `imageSubscript-type1-Photo credit - Linda form Calgary`,
      `
Linda was trying to fit her beautiful print into the used frame she got. The frame was a little bit bigger than the artwork. The frame was 40 x 32 inches, and her artwork was 38 x 30 inches.<br><br> The gap was thin, but significantly noticeable. That’s why she bridged the gap between the frame and the artwork. See how nicely it turned out with a small investment?`,

      `<b>Summary</b><br><br>With a careful thinking, your framing budget can go very far! Like furniture, frames are designed to last decades. If you can find a frame that you like, you can easily fit your artwork into the frame. Without spending hundreds of dollars on custom framing, you can get a similar visual effect with fractional costs.<br><br>
Here are some stores that we found out that carry second handed frames. Make sure you call them up and check out if they have any frame in stock! 
`,
      `<b>List of second hand furniture shops:<br></b><br/>
           <b>Toronto Metro Area</b><br>
          <li>The Barn - 1675 Lakeshore Rd. W. Mississauga, ON</li>
          <li>Moveline Liquidation - 2130 S Service Road W. Oakville, ON</li>
          <li>Creekbank Liquidation Centre - 5135 Creekbank Rd. Mississauga, ON</li>
          <li>Advance Furniture Limited - 2500 Lawrence Avenue E. Scarborough, ON</li>
          <li>ELV Store - 2005 Clark Blvd, Brampton, Ontario</li>
          <li>Rescued & Restored - 248 Freelton Rd. Freelton, ON</li>
          <li>Next Time Around - 376 Hespeler Rd, Cambridge, ON</li>
          <li>Just like New - 111 Sherwood Dr #36/37, Brantford, ON</li><br/><br/>
          <b>Ottawa</b><br>
          <li>Trove Décor - 2039 Robertson Rd, Nepean, ON</li>
          <li>Total Home Consignment - 1860 Bank St #4, Ottawa, ON</li>
          <li>Snazzy Seconds - 2405 St Joseph Blvd, Orléans, ON</li><br><br>
          <b>Montreal</b><br>
          <li>Eco Depot Montreal - Suite 108 B, 150 Boulevard Montréal-Toronto, Lachine, QC</li>
          <li>Meubles du Grand Berger - 2510 Rue Centre, Montréal, QC</li>
          <li>Snazzy Seconds - 2405 St Joseph Blvd, Orléans, ON</li><br><br>
          <b>Vancouver</b>
          <li>Anizco Hotel Furniture and Liquidators - 250 Terminal Ave, Vancouver, BC</li>
          <li>Consignment Canada - 171 Pemberton Ave, North Vancouver, BC</li>
          <li>North Vancouver ReStore - 344 Lynn Ave #340, North Vancouver, BC</li>
          <li>Carriage House - 1533 W 7th Ave, Vancouver, BC</li>
`



    ],
    thumbnail: article12Stock,
    url: "/diy-oversized-mat-amp-square-family-photo-frames",
  },
  article12: {
    category: "Our Advice",
    hashTags: ["#ourAdvice", "#customerStory"],
    title: '[Tips] GALLERY WALL TEMPLATE',
    subtitle: "OFFSET OVERSIZED MAT RAMSBORG FRAMES",
    photos: [article11Stock, article11Stock],
    photosUnCompressed: [article11Stock, article11Stock],
    contents: [
      `“How can I frame large artwork without spending hundreds?”`,
      `This is a question that we get almost every day. We don’t really ship any frames that are bigger than 28 x 20 inches because of shipping difficulties. For the same reason, that’s why larger frames are difficult to find from our online stores.`,
      `That’s why we are here to share some tips on how to frame large artworks on a budget.`,
      `<b>Check out used furniture / liquidation / thrift shop.</b> <br><br> Based on our experience, the easiest way to get a larger picture frame on a budget is to check out the used furniture stores. I know the idea of being “second-handed” is not the most appealing thing ever, but how many times do you touch picture frames in your offices and hotel rooms? `,
      `Most of the frames from furniture liquidation stores come from hotels and offices. Unlike bed or sofa, frames are basically pristine. Wouldn’t it be a bargain if you could get those frames for $40, instead of $400 at a custom framing shop?`,
      `<b>How to do this?</b><br><br>
          <li>Call up the liquidation store and ask if they have picture frame at your artwork size.</li>
          <li>If you have a frame that fits exactly your artwork size, that is terrific!</li>
          <li>If not, you can bridge the gaps between your artwork and the frame through matboard.</li>
        `,
      `Sometimes, those frames already come with artworks. You can easily change the artworks with the simple, home-use hardware tools. If you find a frame that you like, you can replace the artwork & matboards easily. This simple little trick saves you hundreds! 
           <br><br>
           <b>Here are examples from our customers:
         `,
      `
          <div class="blog-article-photo-two">
  
            <picture>
              <source type="image/webp" srcSet="${blogArticle7Webp11}"/>
              <source type="image/png" srcSet="${blogArticle7Reduced13}" />
              <img class="blog-article-photo-two-image" src="${blogArticle7Reduced13}"/>
            </picture>
            <picture/>
              <source type="image/webp" srcSet="${blogArticle7Reduced12}"/>
              <source type="image/png" srcSet="${blogArticle7Webp12}" />
              <img class="blog-article-photo-two-image" src="${blogArticle7Reduced12}"/>
            </picture>
          </div>
        `,
      `imageSubscript-type2-Photo credit - Arman form Philadelphia`,
      `When Arman fit his new matboard into the second handed frame that he got from a thrift store, he realized that he got a great deal. His frame now looks perfect with his diploma. Something that costed hundreds were done for $18. `,
      `
      <div class="blog-article-photo-one">
          <picture>
            <source type="image/webp" srcSet="${blogArticle9Photo}"/>
            <source type="image/png" srcSet="${blogArticle9Photo}"/>
            <img class="blog-article-photo-one-image" src="${blogArticle9Photo}"/>
          </picture>
        </div>
      `,
      `imageSubscript-type1-Photo credit - Linda form Calgary`,
      `
Linda was trying to fit her beautiful print into the used frame she got. The frame was a little bit bigger than the artwork. The frame was 40 x 32 inches, and her artwork was 38 x 30 inches.<br><br> The gap was thin, but significantly noticeable. That’s why she bridged the gap between the frame and the artwork. See how nicely it turned out with a small investment?`,

      `<b>Summary</b><br><br>With a careful thinking, your framing budget can go very far! Like furniture, frames are designed to last decades. If you can find a frame that you like, you can easily fit your artwork into the frame. Without spending hundreds of dollars on custom framing, you can get a similar visual effect with fractional costs.<br><br>
Here are some stores that we found out that carry second handed frames. Make sure you call them up and check out if they have any frame in stock! 
`,
      `<b>List of second hand furniture shops:<br></b><br/>
           <b>Toronto Metro Area</b><br>
          <li>The Barn - 1675 Lakeshore Rd. W. Mississauga, ON</li>
          <li>Moveline Liquidation - 2130 S Service Road W. Oakville, ON</li>
          <li>Creekbank Liquidation Centre - 5135 Creekbank Rd. Mississauga, ON</li>
          <li>Advance Furniture Limited - 2500 Lawrence Avenue E. Scarborough, ON</li>
          <li>ELV Store - 2005 Clark Blvd, Brampton, Ontario</li>
          <li>Rescued & Restored - 248 Freelton Rd. Freelton, ON</li>
          <li>Next Time Around - 376 Hespeler Rd, Cambridge, ON</li>
          <li>Just like New - 111 Sherwood Dr #36/37, Brantford, ON</li><br/><br/>
          <b>Ottawa</b><br>
          <li>Trove Décor - 2039 Robertson Rd, Nepean, ON</li>
          <li>Total Home Consignment - 1860 Bank St #4, Ottawa, ON</li>
          <li>Snazzy Seconds - 2405 St Joseph Blvd, Orléans, ON</li><br><br>
          <b>Montreal</b><br>
          <li>Eco Depot Montreal - Suite 108 B, 150 Boulevard Montréal-Toronto, Lachine, QC</li>
          <li>Meubles du Grand Berger - 2510 Rue Centre, Montréal, QC</li>
          <li>Snazzy Seconds - 2405 St Joseph Blvd, Orléans, ON</li><br><br>
          <b>Vancouver</b>
          <li>Anizco Hotel Furniture and Liquidators - 250 Terminal Ave, Vancouver, BC</li>
          <li>Consignment Canada - 171 Pemberton Ave, North Vancouver, BC</li>
          <li>North Vancouver ReStore - 344 Lynn Ave #340, North Vancouver, BC</li>
          <li>Carriage House - 1533 W 7th Ave, Vancouver, BC</li>
`



    ],
    thumbnail: article11Stock,
    url: "/oversized-matboard-gallery-wall-grid",
  },
  article10: {
    category: "Our Advice",
    hashTags: ["#ourAdvice", "#customerStory"],
    title: '[Tips] How to create a gallery wall on budget?',
    subtitle: "Total cost per picture was about $50 for a high-end designer look.",
    photos: [article11Stock, article11Stock],
    photosUnCompressed: [article11Stock, article11Stock],
    contents: [
      `“How can I frame large artwork without spending hundreds?”`,
      `This is a question that we get almost every day. We don’t really ship any frames that are bigger than 28 x 20 inches because of shipping difficulties. For the same reason, that’s why larger frames are difficult to find from our online stores.`,
      `That’s why we are here to share some tips on how to frame large artworks on a budget.`,
      `<b>Check out used furniture / liquidation / thrift shop.</b> <br><br> Based on our experience, the easiest way to get a larger picture frame on a budget is to check out the used furniture stores. I know the idea of being “second-handed” is not the most appealing thing ever, but how many times do you touch picture frames in your offices and hotel rooms? `,
      `Most of the frames from furniture liquidation stores come from hotels and offices. Unlike bed or sofa, frames are basically pristine. Wouldn’t it be a bargain if you could get those frames for $40, instead of $400 at a custom framing shop?`,
      `<b>How to do this?</b><br><br>
          <li>Call up the liquidation store and ask if they have picture frame at your artwork size.</li>
          <li>If you have a frame that fits exactly your artwork size, that is terrific!</li>
          <li>If not, you can bridge the gaps between your artwork and the frame through matboard.</li>
        `,
      `Sometimes, those frames already come with artworks. You can easily change the artworks with the simple, home-use hardware tools. If you find a frame that you like, you can replace the artwork & matboards easily. This simple little trick saves you hundreds! 
           <br><br>
           <b>Here are examples from our customers:
         `,
      `
          <div class="blog-article-photo-two">
  
            <picture>
              <source type="image/webp" srcSet="${blogArticle7Webp11}"/>
              <source type="image/png" srcSet="${blogArticle7Reduced13}" />
              <img class="blog-article-photo-two-image" src="${blogArticle7Reduced13}"/>
            </picture>
            <picture/>
              <source type="image/webp" srcSet="${blogArticle7Reduced12}"/>
              <source type="image/png" srcSet="${blogArticle7Webp12}" />
              <img class="blog-article-photo-two-image" src="${blogArticle7Reduced12}"/>
            </picture>
          </div>
        `,
      `imageSubscript-type2-Photo credit - Arman form Philadelphia`,
      `When Arman fit his new matboard into the second handed frame that he got from a thrift store, he realized that he got a great deal. His frame now looks perfect with his diploma. Something that costed hundreds were done for $18. `,
      `
      <div class="blog-article-photo-one">
          <picture>
            <source type="image/webp" srcSet="${blogArticle9Photo}"/>
            <source type="image/png" srcSet="${blogArticle9Photo}"/>
            <img class="blog-article-photo-one-image" src="${blogArticle9Photo}"/>
          </picture>
        </div>
      `,
      `imageSubscript-type1-Photo credit - Linda form Calgary`,
      `
Linda was trying to fit her beautiful print into the used frame she got. The frame was a little bit bigger than the artwork. The frame was 40 x 32 inches, and her artwork was 38 x 30 inches.<br><br> The gap was thin, but significantly noticeable. That’s why she bridged the gap between the frame and the artwork. See how nicely it turned out with a small investment?`,

      `<b>Summary</b><br><br>With a careful thinking, your framing budget can go very far! Like furniture, frames are designed to last decades. If you can find a frame that you like, you can easily fit your artwork into the frame. Without spending hundreds of dollars on custom framing, you can get a similar visual effect with fractional costs.<br><br>
Here are some stores that we found out that carry second handed frames. Make sure you call them up and check out if they have any frame in stock! 
`,
      `<b>List of second hand furniture shops:<br></b><br/>
           <b>Toronto Metro Area</b><br>
          <li>The Barn - 1675 Lakeshore Rd. W. Mississauga, ON</li>
          <li>Moveline Liquidation - 2130 S Service Road W. Oakville, ON</li>
          <li>Creekbank Liquidation Centre - 5135 Creekbank Rd. Mississauga, ON</li>
          <li>Advance Furniture Limited - 2500 Lawrence Avenue E. Scarborough, ON</li>
          <li>ELV Store - 2005 Clark Blvd, Brampton, Ontario</li>
          <li>Rescued & Restored - 248 Freelton Rd. Freelton, ON</li>
          <li>Next Time Around - 376 Hespeler Rd, Cambridge, ON</li>
          <li>Just like New - 111 Sherwood Dr #36/37, Brantford, ON</li><br/><br/>
          <b>Ottawa</b><br>
          <li>Trove Décor - 2039 Robertson Rd, Nepean, ON</li>
          <li>Total Home Consignment - 1860 Bank St #4, Ottawa, ON</li>
          <li>Snazzy Seconds - 2405 St Joseph Blvd, Orléans, ON</li><br><br>
          <b>Montreal</b><br>
          <li>Eco Depot Montreal - Suite 108 B, 150 Boulevard Montréal-Toronto, Lachine, QC</li>
          <li>Meubles du Grand Berger - 2510 Rue Centre, Montréal, QC</li>
          <li>Snazzy Seconds - 2405 St Joseph Blvd, Orléans, ON</li><br><br>
          <b>Vancouver</b>
          <li>Anizco Hotel Furniture and Liquidators - 250 Terminal Ave, Vancouver, BC</li>
          <li>Consignment Canada - 171 Pemberton Ave, North Vancouver, BC</li>
          <li>North Vancouver ReStore - 344 Lynn Ave #340, North Vancouver, BC</li>
          <li>Carriage House - 1533 W 7th Ave, Vancouver, BC</li>
`



    ],
    thumbnail: article11Stock,
    url: "/oversized-matboard-gallery-wall-grid",
    urlFr: "/oversized-matboard-gallery-wall-grid-fr"
  },
  article9: {
    category: "Our Advice",
    hashTags: ["#ourAdvice", "#customerStory"],
    title: '[Tips] How to create a gallery wall on budget?',
    subtitle: "Total cost per picture was about $50 for a high-end designer look.",
    photos: [galleryWallStockPng, galleryWallStockPng],
    photosUnCompressed: [galleryWallStockPng, galleryWallStockPng],
    contents: [
      `“How can I frame large artwork without spending hundreds?”`,
      `This is a question that we get almost every day. We don’t really ship any frames that are bigger than 28 x 20 inches because of shipping difficulties. For the same reason, that’s why larger frames are difficult to find from our online stores.`,
      `That’s why we are here to share some tips on how to frame large artworks on a budget.`,
      `<b>Check out used furniture / liquidation / thrift shop.</b> <br><br> Based on our experience, the easiest way to get a larger picture frame on a budget is to check out the used furniture stores. I know the idea of being “second-handed” is not the most appealing thing ever, but how many times do you touch picture frames in your offices and hotel rooms? `,
      `Most of the frames from furniture liquidation stores come from hotels and offices. Unlike bed or sofa, frames are basically pristine. Wouldn’t it be a bargain if you could get those frames for $40, instead of $400 at a custom framing shop?`,
      `<b>How to do this?</b><br><br>
          <li>Call up the liquidation store and ask if they have picture frame at your artwork size.</li>
          <li>If you have a frame that fits exactly your artwork size, that is terrific!</li>
          <li>If not, you can bridge the gaps between your artwork and the frame through matboard.</li>
        `,
      `Sometimes, those frames already come with artworks. You can easily change the artworks with the simple, home-use hardware tools. If you find a frame that you like, you can replace the artwork & matboards easily. This simple little trick saves you hundreds! 
           <br><br>
           <b>Here are examples from our customers:
         `,
      `
          <div class="blog-article-photo-two">
  
            <picture>
              <source type="image/webp" srcSet="${blogArticle7Webp11}"/>
              <source type="image/png" srcSet="${blogArticle7Reduced13}" />
              <img class="blog-article-photo-two-image" src="${blogArticle7Reduced13}"/>
            </picture>
            <picture/>
              <source type="image/webp" srcSet="${blogArticle7Reduced12}"/>
              <source type="image/png" srcSet="${blogArticle7Webp12}" />
              <img class="blog-article-photo-two-image" src="${blogArticle7Reduced12}"/>
            </picture>
          </div>
        `,
      `imageSubscript-type2-Photo credit - Arman form Philadelphia`,
      `When Arman fit his new matboard into the second handed frame that he got from a thrift store, he realized that he got a great deal. His frame now looks perfect with his diploma. Something that costed hundreds were done for $18. `,
      `
      <div class="blog-article-photo-one">
          <picture>
            <source type="image/webp" srcSet="${blogArticle9Photo}"/>
            <source type="image/png" srcSet="${blogArticle9Photo}"/>
            <img class="blog-article-photo-one-image" src="${blogArticle9Photo}"/>
          </picture>
        </div>
      `,
      `imageSubscript-type1-Photo credit - Linda form Calgary`,
      `
Linda was trying to fit her beautiful print into the used frame she got. The frame was a little bit bigger than the artwork. The frame was 40 x 32 inches, and her artwork was 38 x 30 inches.<br><br> The gap was thin, but significantly noticeable. That’s why she bridged the gap between the frame and the artwork. See how nicely it turned out with a small investment?`,

      `<b>Summary</b><br><br>With a careful thinking, your framing budget can go very far! Like furniture, frames are designed to last decades. If you can find a frame that you like, you can easily fit your artwork into the frame. Without spending hundreds of dollars on custom framing, you can get a similar visual effect with fractional costs.<br><br>
Here are some stores that we found out that carry second handed frames. Make sure you call them up and check out if they have any frame in stock! 
`,
      `<b>List of second hand furniture shops:<br></b><br/>
           <b>Toronto Metro Area</b><br>
          <li>The Barn - 1675 Lakeshore Rd. W. Mississauga, ON</li>
          <li>Moveline Liquidation - 2130 S Service Road W. Oakville, ON</li>
          <li>Creekbank Liquidation Centre - 5135 Creekbank Rd. Mississauga, ON</li>
          <li>Advance Furniture Limited - 2500 Lawrence Avenue E. Scarborough, ON</li>
          <li>ELV Store - 2005 Clark Blvd, Brampton, Ontario</li>
          <li>Rescued & Restored - 248 Freelton Rd. Freelton, ON</li>
          <li>Next Time Around - 376 Hespeler Rd, Cambridge, ON</li>
          <li>Just like New - 111 Sherwood Dr #36/37, Brantford, ON</li><br/><br/>
          <b>Ottawa</b><br>
          <li>Trove Décor - 2039 Robertson Rd, Nepean, ON</li>
          <li>Total Home Consignment - 1860 Bank St #4, Ottawa, ON</li>
          <li>Snazzy Seconds - 2405 St Joseph Blvd, Orléans, ON</li><br><br>
          <b>Montreal</b><br>
          <li>Eco Depot Montreal - Suite 108 B, 150 Boulevard Montréal-Toronto, Lachine, QC</li>
          <li>Meubles du Grand Berger - 2510 Rue Centre, Montréal, QC</li>
          <li>Snazzy Seconds - 2405 St Joseph Blvd, Orléans, ON</li><br><br>
          <b>Vancouver</b>
          <li>Anizco Hotel Furniture and Liquidators - 250 Terminal Ave, Vancouver, BC</li>
          <li>Consignment Canada - 171 Pemberton Ave, North Vancouver, BC</li>
          <li>North Vancouver ReStore - 344 Lynn Ave #340, North Vancouver, BC</li>
          <li>Carriage House - 1533 W 7th Ave, Vancouver, BC</li>
`



    ],
    thumbnail: galleryWallStockPng,
    url: "/diy-gallery-wall",
    urlFr: "/diy-gallery-wall-fr",
  },
  article1: {
    category: "IKEA Frames",
    hashTags: ["#IKEAFrames", "#customerStories"],
    title: 'Gallery of our success stories!',
    subtitle: "Frames look beautiful & professional in custom mat boards",
    photos: [ikea1, ikea2, ikea3, ikea4, ikea5, ikea6, ikea7, ikea8, ikea9,],
    photosUnCompressed: [ikea1Webp, ikea2Webp, ikea3Webp, ikea4Webp, ikea5Webp, ikea6Webp, ikea7Webp, ikea8Webp, ikea9Webp,],
    contents: [
        `Your art is unique, but retail frames are mass produced. Custom frames look better, but they cost a lot. It is hard to find retail frames that fit well. Getting a nice frame for a good price is weirdly hard…<br><br>but it's not impossible.`,
        `Create a cool look with a custom mat board!`,
        `This is some hacker level stuff! You get any frame you like, and make it look better. All you have to do is to get a custom mat board cut for your art. Experiment with different colors to change the mood! A flower picture looks very different with a pink mat board versus a blue one. Watch the mat board elevate a simple metal frame!`,
        `Well, that's why we are here. We are the mat board experts. We custom cut your mat board and deliver it to your door. With our custom mat boards, your artwork will look awesome.`,
        `
        <div class="blog-article-photo-one">
          <picture>
            <source type="image/webp" srcSet="${blogArticle1Webp13}"/>
            <source type="image/png" srcSet="${blogArticle1Regular13}"/>
            <img class="blog-article-photo-one-image" src="${blogArticle1Regular13}"/>
          </picture>
        </div>
        `,
          `imageSubscript-type1-Framing a large poster doesn't always have to be expensive.`,
          `Mat Boarding: A Huge Poster`,
          `<li>20' x 30' poster</li>
         <li><a href="https://www.ikea.com/ca/en/catalog/products/60297437/#/80298290" target="_blank">IKEA SILVERHÖJDEN Frame:</a> 36' x 27' (91 cm x 61 cm)</li>
         <li>Custom Mat Boards from <a href="https://www.custommat.ca">custommat.ca</a></li>
        `,
          `
        <div class="blog-article-photo-two">
          <source type="image/webp" srcSet="${blogArticle1Webp11}"/>
            <source type="image/png" srcSet="${blogArticle1Regular11}"/>
            <img class="blog-article-photo-two-image" src="${blogArticle1Regular11}"/>
          </picture>
          <picture>
            <source type="image/webp" srcSet="${blogArticle1Webp12}"/>
            <source type="image/png" srcSet="${blogArticle1Regular12}"/>
            <img class="blog-article-photo-two-image" src="${blogArticle1Regular12}"/>
          </picture>
        </div>
        `,
          `imageSubscript-type2-Before and after`,
          `This poster depicting Canada is bought from a paper shop in Granville Island. It is evident from the seasonal inventory carried by such paper shops that 20' x 30' posters are very popular. Yet, as you can see, the existing mat board that comes with the IKEA frame doesn't fit. It leaves some random whitespace at the top and the bottom, and the sides are super tight.`,
          `Overlooked, these little gaps make the presentation look unprofessional.`,
          `That's why we get a custom mat board. The custom mat board makes the picture sit really well in the frame. For the frame above, we also updated our mat board color from white to cream to create a smoother visual experience.`,
          `With less than $50, we were able to achieve a custom frame look, which would cost over $200 at a custom frame shop. If you don't believe that, call the nearest frame shop and ask them how much it costs to get a frame for a 20' x 30' poster. When you hear their answers, you will fall in love - head over heels - with custom mat boards.`,
          `Mat Boarding: Medium Sized Artwork`,
          `<li>Medium size paintings</li>
         <li><a href="https://www.ikea.com/ca/en/catalog/products/60297437/#/80298290" target="_blank">IKEA SILVERHÖJDEN</a> Frame: 16' x 12' inches (41 cm x 31 cm)</li>
         <li>Custom Mat Board from <a href="https://www.custommat.ca" target="_blank">custommat.ca</a></li>
        `,
          `
        <div class="blog-article-photo-two">
          <source type="image/webp" srcSet="${blogArticle1Webp21}"/>
            <source type="image/png" srcSet="${blogArticle1Regular21}"/>
            <img class="blog-article-photo-two-image" src="${blogArticle1Regular21}"/>
          </picture>
          <picture>
            <source type="image/webp" srcSet="${blogArticle1Webp22}"/>
            <source type="image/png" srcSet="${blogArticle1Regular22}"/>
            <img class="blog-article-photo-two-image" src="${blogArticle1Regular22}"/>
          </picture>
        </div>
        `,
        `imageSubscript-type2-Left: Artwork presented in IKEA's original mat board | Right: Presented with a custom cut mat board`,
        `The painting above was given as a present. The signature was getting cropped off with the original mat board. We wanted to showcase the signature, and needed a custom mat board.`,
        `<div class="blog-article-photo-two">
            <source type="image/webp" srcSet="${blogArticle1Webp23}"/>
              <source type="image/png" srcSet="${blogArticle1Regular23}"/>
              <img class="blog-article-photo-two-image" src="${blogArticle1Regular23}"/>
            </picture>
            <picture>
              <source type="image/webp" srcSet="${blogArticle1Webp24}"/>
              <source type="image/png" srcSet="${blogArticle1Regular24}"/>
              <img class="blog-article-photo-two-image" src="${blogArticle1Regular24}"/>
            </picture>
          </div>
          `,
          `imageSubscript-type2-Left: Artwork presented in IKEA's original mat board | Right: Presented with a custom cut mat board`,
          `In this picture, we expanded the window with the custom mat board. For around $20 (slightly around $10 each, whether you are in US or Canada) both of the pictures looked how we wanted.`,
          `Accent Matting`,
          `<li>Diploma! (Congratulations)</li>
           <li><a href="https://www.ikea.com/ca/en/catalog/products/60297437/#/80298290" target="_blank">IKEA LOMVIKEN Frame:</li> 16' x 12' inches (41 cm x 31 cm)
           <li>Custom Mat Board from <a href="https://www.custommat.ca" target="_blank">custommat.ca</a></li>
          `,
          `<div class="blog-article-photo-two">
            <source type="image/webp" srcSet="${blogArticle1Webp31}"/>
              <source type="image/png" srcSet="${blogArticle1Regular31}"/>
              <img class="blog-article-photo-two-image" src="${blogArticle1Regular31}"/>
            </picture>
            <picture>
              <source type="image/webp" srcSet="${blogArticle1Webp32}"/>
              <source type="image/png" srcSet="${blogArticle1Regular32}"/>
              <img class="blog-article-photo-two-image" src="${blogArticle1Regular32}"/>
            </picture>
          </div>
          `,
          `imageSubscript-type2-Left: Artwork presented without a mat board | Right: Presented with a custom cut mat board (CAD $11.95, USD $8.95)`,
          `The mat board that comes with the IKEA frame is completely unusable in this case. The frame is pretty cool, but it doesn't fit the diploma at all. The diploma is floating.`,
          `Now.. check out the snazzy amber look! A mood is created by the amber mat board. It makes the diploma look cooler. It is like a halo, or an aura.`,
          `Accent Matting`,
          `<li>Any Existing Frame</li>
           <li>Any Art associated with the said Existing Frame</li>
           <li>Custom Mat Board from <a href="https://www.custommat.ca" target="_blank">custommat.ca</a></li>
          `,
          `<div class="blog-article-photo-two-portrait">
            <source type="image/webp" srcSet="${blogArticle1Webp41}"/>
              <source type="image/png" srcSet="${blogArticle1Regular41}"/>
              <img class="blog-article-photo-two-image-portrait" src="${blogArticle1Regular41}"/>
            </picture>
            <picture>
              <source type="image/webp" srcSet="${blogArticle1Webp42}"/>
              <source type="image/png" srcSet="${blogArticle1Regular42}"/>
              <img class="blog-article-photo-two-image-portrait" src="${blogArticle1Regular42}"/>
            </picture>
          </div>
          `,
          `imageSubscript-type1-Left: Artwork presented without a mat board | Right: Presented with a custom cut mat board (CAD $11.95, USD $8.95)`,
          `Our tastes and budgets move along with time, hopefully increasing at every breakpoint. Every couple of years, we want to give our space a new source of energy. Sometimes, we want to upgrade without losing the essence.`,
          `Sarah, a purchaser of a custom mat board, had recently came across a beautiful picture of Whistler that she decided to rescue. She already had a frame that she thought would fit. All she needed was a custom mat board.`,
          `That's how Sarah became our customer. We got her a mat board that is tailored for her art. She was able to repurpose her old frame for $11. She left with a new source of vitality in her space.`,
          `Try A Custom Mat Board. It's easy.`,
          `All you need to is measure your artwork & order online at custommat.ca! We process your order within a business day.<br/><br/>Enjoy customization for a fraction of the cost.`,
      ],
    thumbnail: ikea1,
    url: "/custom-picture-mats-make-difference",
    urlFr: "/custom-picture-mats-make-difference-fr",
  },
  article2: {
    category: "Our Advice",
    hashTags: ["#ourAdvice", "#creativeTweaks"],
    title:'How to measure your matboard like an artist.',
    subtitle: "Measuring your artwork may seem like a dauting task, but with our diagrams, you’ll be done in no time.",
    photos: [blogArticle2Regular1, blogArticle2Regular2, blogArticle2Regular3, blogArticle2Regular4],
    photosUnCompressed: [blogArticle2Webp1, blogArticle2Webp2, blogArticle2Webp3, blogArticle2Webp4],
    contents: [
      `<b>Q1: What's the difference between "matboard size" and "artwork window size?"</b>`,
      `<div class="blog-article-photo-one">
        <picture>
          <source type="image/webp" srcSet="${blogArticle2Webp1}"/>
          <source type="image/png" srcSet="${blogArticle2Regular1}"/>
          <img class="blog-article-photo-one-image" src="${blogArticle2Regular1}"/>
        </picture>
      </div>`,
      `The diagram above should explain the difference. Matboard size refers to the overall matboard size. Artwork window size refers to the size of the area, where you art will be displayed. The matboard size is usually shown at the frame packaging (or seller's website).`,
      `<b>Q2: How do I measure the artwork window?`,
        `Artwork window sizing really depends on your preference. An important aspect is to have at least 1/8 inch overlap between the matboard and the artwork. See the diagram below :).`,
      `
        <div class="blog-article-photo-one">
          <picture>
            <source type="image/webp" srcSet="${blogArticle2Webp2}"/>
            <source type="image/png" srcSet="${blogArticle2Regular2}"/>
            <img class="blog-article-photo-one-image" src="${blogArticle2Regular2}"/>
          </picture>
        </div>
      `,
        `We overlap the highlighted area to adhere the artwork to the matboard (with artist tape). Overlapping area's size does not matter, if each side is overlapped by at least 1/8 inch. <br><br> As shown below, we will tape over the highlighted area. This will make sure your artwork will remain stretched.`,
      `
        <div class="blog-article-photo-one">
          <picture>
            <source type="image/webp" srcSet="${blogArticle2Webp3}"/>
            <source type="image/png" srcSet="${blogArticle2Regular3}"/>
            <img class="blog-article-photo-one-image" src="${blogArticle2Regular3}"/>
          </picture>
        </div>
      `,
      `<b>Q3: What if my artwork does not have any extra whitespace on the edge?`,
        `If your artwork does not have any whitespace on the edge (mostly printed photography or posters), you will have to let in 1/8 inch on each side. Unfortunately, your matboard will cover a small amount of your artwork. The good news is, this small tweak does not affect overall art, so don’t worry!`,
      `

        <div class="blog-article-photo-one">
          <picture>
            <source type="image/webp" srcSet="${blogArticle2Webp4}"/>
            <source type="image/png" srcSet="${blogArticle2Regular4}"/>
            <img class="blog-article-photo-one-image" src="${blogArticle2Regular4}"/>
          </picture>
        </div>
      `,
      `<b>Q4: What if I have a special element at the edge of my artwork?`,
        `If your artwork contains special elements (like signature or description) on the edge, you may not want to let go of that area. If that’s the case, you will have to “extend” the artwork.`,
      `
        <div class="blog-article-photo-one">
          <picture>
            <source type="image/webp" srcSet="${blogArticle2Webp5}"/>
            <source type="image/png" srcSet="${blogArticle2Regular5}"/>
            <img class="blog-article-photo-one-image" src="${blogArticle2Regular5}"/>
          </picture>
        </div>
      `,
      `For this, you will have to order a set of two matboards. One <b>with</b> opening and one <b>without</b> a window.`,
      `You will glue your artwork on top of the matboard that does not have a window (matboard 1). After that, you will place the matboard with a window (matboard 2) on top of the matboard 1.`,
        `The diagram above should explain what you should do. For the first matboard, you can order one from us by selecting 0 inch for the width and height of the artwork window. `,
      `<b>Summary`,
        `That’s it! The hardest part is over and you can now move on to our easy-to-use design tool to create your perfect matboard. Once you’ve done that, all that’s left to do is sit back and wait! Happy framing!`,

    ],
    thumbnail: blogArticle2Regular1,
    url: "/how-to-measure-a-mat-board",
    urlFr: "/how-to-measure-a-mat-board-fr",
  },
  article3: {
    category: "Bargain Hunting",
    hashTags: ["#bargainHunting", "#creativeTweaks"],
    title: "How to get a 45' TV sized picture frames for $50",
    subtitle: "Our tips to frame your artwork with your budget",
    photos: [blogArticle3Webp14, blogArticle3Webp15, blogArticle3Webp16, blogArticle3Webp22, blogArticle3Webp23],
    photosUnCompressed: [blogArticle3Regular14, blogArticle3Regular15, blogArticle3Regular16, blogArticle3Regular22, blogArticle3Regular23],
    contents: [
      `Fit is the key! Zara introduced clothes with high end fits with every day materials. Zara soon became popular. Clothes look good when they have a fit. Same story for picture framing.`,
      `Any picture frames can look professional as long as they have a right fit. It's the mat board that creates a right fit between your artwork and your frame. Whether your frame is from Amazon, IKEA, or Crate & Barrel, it can have a custom, professional look, as long as their mat boards fit their artworks seamlessly.`,
      `
        <div class="blog-article-photo-two">
          <source type="image/webp" srcSet="${blogArticle3Webp14}"/>
            <source type="image/png" srcSet="${blogArticle3Regular14}"/>
            <img class="blog-article-photo-two-image" src="${blogArticle3Regular14}"/>
          </picture>
          <picture>
            <source type="image/webp" srcSet="${blogArticle3Webp15}"/>
            <source type="image/png" srcSet="${blogArticle3Regular15}"/>
            <img class="blog-article-photo-two-image" src="${blogArticle3Regular15}"/>
          </picture>
        </div>
      `,
      `imageSubscript-type2-It is possible to find a perfect picture frame under $60. It is a very good price. Usually, custom framing an 45-inch TV sized artwork cost hundreds.`,
      `Today, we are going to frame a large sized artwork (30' x 20') around $50. How can you get a perfect picture frame sized at 45-inch TV for that price? You will see! :)`,
      `What we will use:`,
      `
        <li>32' x 22' painting</li>
        <li><a href="https://www.ikea.com/ca/en/catalog/products/90301621/" target="_blank">IKEA Ribba Frame:</a> 36' x 27' (91 cm x 61 cm) (CAD $24.99, USD $19.99)</li>
        <li>Custom Mat Board from <a href="https://www.custommat.ca" rel="noopener noreferrer" target="_blank">custommat.ca</a> (CAD $38.99, USD $28.99)</li>
      `,
      `<a href="https://www.ikea.com/ca/en/catalog/products/90301621/" rel="noopener noreferrer" target="_blank">Ribba</a> frames come in large sizes and great in quality. It's competitive price makes them popular among wise consumers.`,
      `
        <div class="blog-article-photo-two">
          <source type="image/webp" srcSet="${blogArticle3Webp13}"/>
            <source type="image/png" srcSet="${blogArticle3Regular13}"/>
            <img class="blog-article-photo-two-image" src="${blogArticle3Regular13}"/>
          </picture>
          <picture>
            <source type="image/webp" srcSet="${blogArticle3Webp12}"/>
            <source type="image/png" srcSet="${blogArticle3Regular12}"/>
            <img class="blog-article-photo-two-image" src="${blogArticle3Regular12}"/>
          </picture>
        </div>
      `,
      `imageSubscript-type2-IKEA Ribba frames come with default white mat. The only challenge is, standard size mat boards don't always accommodate your artwork.`,
      `<a href="https://www.ikea.com/ca/en/catalog/products/90301621/" target="_blank" rel="noopener noreferrer">Ribba</a> frame comes with a default mat board from IKEA. The default mat board almost fit the artwork, except, it was just a little bit too wide. When we zoom in the circled area, we will see that IKEA's default mat board was a little bit too wide for us to properly attache the artwork on the mat board.`,
      `
        <div class="blog-article-photo-two">
          <source type="image/webp" srcSet="${blogArticle3Webp14}"/>
            <source type="image/png" srcSet="${blogArticle3Regular14}"/>
            <img class="blog-article-photo-two-image" src="${blogArticle3Regular14}"/>
          </picture>
          <picture>
            <source type="image/webp" srcSet="${blogArticle3Webp15}"/>
            <source type="image/png" srcSet="${blogArticle3Regular15}"/>
            <img class="blog-article-photo-two-image" src="${blogArticle3Regular15}"/>
          </picture>
        </div>
      `,
      `imageSubscript-type2-With a custom mat board, we can make any artwork look great in any premade picture frames.`,
      `That's why we decided to get a custom mat board. To accommodate this uniquely sized artwork, we have reduced the window width and height by two inches respectively.`,
      `The gap between the artwork and the mat board has disappeared. The artwork has been straightened. Our artwork now looks more professional than ever. And it took only about $50.`,
      `Want more colors introduced to your space?`,
      `<a href="https://www.ikea.com/ca/en/catalog/products/90301621/" rel="noopener noreferrer" target="_blank">Ribba</a> only comes in black and white, but IKEA has more to offer. Try out the <a href="https://www.ikea.com/ca/en/catalog/products/60297437/#/80298290" target="_blank">Silverhojden</a> frame. They come in gold and silver.`,
      `
        <div class="blog-article-photo-one">
          <picture>
            <source type="image/webp" srcSet="${blogArticle3Webp22}"/>
            <source type="image/png" srcSet="${blogArticle3Regular22}"/>
            <img class="blog-article-photo-one-image" src="${blogArticle3Regular22}"/>
          </picture>
        </div>
      `,
      `imageSubscript-type1-If you want more colorful option than black and white, try out IKEA's Silverhojden frames. They come in large sizes and other colors.`,
      `What we will use:`,
      `
        <li>30' x 20' poster</li>
        <li><a href="https://www.ikea.com/ca/en/catalog/products/60297437/#/80298290" target="_blank">IKEA Silverhojden Frame:</a> 36' x 27' (91 cm x 61 cm) (CAD $24.99, USD $19.99)</li>
        <li>Custom Mat Board from <a href="https://www.custommat.ca" target="_blank">custommat.ca</a> (CAD $38.99, USD $28.99)</li>
      `,
      `IKEA's <a href="https://www.ikea.com/ca/en/catalog/products/60297437/#/80298290" rel="noopener noreferrer" target="_blank">Silverhojden</a>  frames come with a mat board. However, the default mat board is not designed to fit unique posters. It leaves some white space at the top and bottom of the poster. These little gaps often make your picture frame unprofessional.`,
      `
        <div class="blog-article-photo-two">
          <source type="image/webp" srcSet="${blogArticle3Webp21}"/>
            <source type="image/png" srcSet="${blogArticle3Regular21}"/>
            <img class="blog-article-photo-two-image" src="${blogArticle3Regular21}"/>
          </picture>
          <picture>
            <source type="image/webp" srcSet="${blogArticle3Webp23}"/>
            <source type="image/png" srcSet="${blogArticle3Regular23}"/>
            <img class="blog-article-photo-two-image" src="${blogArticle3Regular23}"/>
          </picture>
        </div>
      `,
      `imageSubscript-type2-Note that excess white space at the top and bottom of the poster has been removed with a custom mat board. Custom mat board also offers variety of colors to blend in with your artwork better.`,
      `That's why you need a custom mat board. The mat board makes the picture to sit well in the frame. For the new mat board, we also changed our mat board color from white to cream to reflect the creamy color of the map. Now, the mat board and the poster blend better than ever!`,
      `With about $50, we were able to get a picture frame that fits your poster perfectly. $50 includes the frame and the mat board. To frame a poster sized at 30'x20', it usually cost over $200 at a frame shop.`,
      `If you are curious, call the nearest frame shop and ask how much would it cost to frame a 30'x20' poster with 3' matting on each side. Their cheapest frame material would cost over $200. When you hear their answers, you will fall in love - head over heels - with custommat.ca.`,
    ],
    thumbnail: blogArticle3Regular15,
    url: "/custom-mat-board-for-ikea-ribba-and-silverhojden-36in-26in-91cm-61cm",
    urlFr: "/custom-mat-board-for-ikea-ribba-and-silverhojden-36in-26in-91cm-61cm-fr",
  },
  article4: {
    category: "Our Advice",
    hashTags: ["#ourAdvice", "#creativeTweaks"],
    title: 'I don\'t have a ruler! How can I measure?',
    subtitle: "There are many ways to measure precisely without a ruler.",
    photos: [blogArticle4Webp11, blogArticle4Webp12],
    photosUnCompressed: [
      blogArticleReduced11, blogArticleReduced12,
    ],
    contents: [
      `No ruler? No problem! :) Not everyone has a ruler at home. Especially in this digital age. :) We have come up with various creative approaches for you to measure precisely without a ruler!`,
      `
        <div class="blog-article-photo-one">
          <picture>
            <source type="image/webp" srcSet="${blogArticle4Webp11}"/>
            <source type="image/png" srcSet="${blogArticleReduced11}"/>
            <img class="blog-article-photo-one-image" src="${blogArticleReduced11}"/>
          </picture>
        </div>
      `,
      `Printed Ruler + Paper Combination`,
      `
      <li><a href="http://www.vendian.org/mncharity/dir3/paper_rulers/UnstableURL/ruler_foot.pdf" target="_blank">Paper Ruler</a>: Print out! You can fold the paper and use as a proper ruler.</li>
      <li>Letter paper measures (8.5' x 11'). Legal paper measures (8.5' x 14')</li>
      `,
      `Do you have a printer? It's easy then! First, see how many letter or legal papers can your frame fit. Second, for the remaining bits, you can use the paper ruler to measure. Our team was able to replicate the ruler very precisely.`,
      `If you don't have a paper, then you may also use any <a href="https://www.envelopes.com/envelope-sizes" target="_target">envelopes. Envelopes</a> with bills or statements all come in similar sizes.`,
      `No ruler? No problem! :) Not everyone has a ruler at home. Especially in this digital age. :) We have come up with various creative approaches for you to measure precisely without a ruler!`,
      `
        <div class="blog-article-photo-one">
          <picture>
            <source type="image/webp" srcSet="${blogArticle4Webp12}"/>
            <source type="image/png" srcSet="${blogArticleReduced12}"/>
            <img class="blog-article-photo-one-image" src="${blogArticleReduced12}"/>
          </picture>
        </div>
      `,
      `2. iPhone/iPad ruler app + Paper Combination`,
      `First, see how many pieces of letter paper can you fit in your artwork? Second, you can use your iPhone ruler app to get the precise measure.`,
      `
      <li><a href="https://itunes.apple.com/us/app/ruler-app-ar-tape-measure/id1020133524?mt=8">iPhone/iPad Ruler app</a></li>
      <li><a href="https://play.google.com/store/apps/details?id=org.nixgame.ruler&hl=en">Android Phone/Tablet Ruler app</a></li>
      `,
      `Summary`,
      `We have tried these approaches and could come up with a very accurate measures. Let's do it together! It's actually much easier than you think! Happy Measuring :)`
    ],
    thumbnail: blogArticleReduced11,
    url: "/how-to-measure-mat-board-without-a-ruler",
    urlFr: "/how-to-measure-mat-board-without-a-ruler-fr",
  },
  article5: {
    category: "Our Advice",
    hashTags: ["#ourAdvice", "#creativeTweaks"],
    title: 'Ordering a custom passe-partuout (mat board) in US & Canada',
    subtitle: "We started our business to make your framing experience easy",
    photos: [ikea1, ikea2, ikea3, ikea4, ikea5, ikea6, ikea7, ikea8, ikea9,],
    photosUnCompressed: [ikea1Webp, ikea2Webp, ikea3Webp, ikea4Webp, ikea5Webp, ikea6Webp, ikea7Webp, ikea8Webp, ikea9Webp,],
    contents: [
      `Passe-partuout, better known as mat board, is a key for picture framing. While mat boards are everywhere, it’s surprisingly hard to find a decently priced ones. You often have to run to an art supply store, place an order, and wait couple of days to pick them up.`,
      `
        <div class="blog-article-photo-two">
          <source type="image/webp" srcSet="${blogArticle3Webp14}"/>
            <source type="image/png" srcSet="${blogArticle3Regular14}"/>
            <img class="blog-article-photo-two-image" src="${blogArticle3Regular14}"/>
          </picture>
          <picture>
            <source type="image/webp" srcSet="${blogArticle3Webp15}"/>
            <source type="image/png" srcSet="${blogArticle3Regular15}"/>
            <img class="blog-article-photo-two-image" src="${blogArticle3Regular15}"/>
          </picture>
        </div>
      `,
      `imageSubscript-type2-It is possible to find a perfect picture frame under $60. It is a very good price. Usually, custom framing an 45-inch TV sized artwork cost hundreds.`,
      `That’s why we started our business. If we can order everything online, why can’t we order a passe-partout? How can we serve our every customer without a minimum order quantity and high shipping cost? We worked hard for an answer.`,
      `
        <div class="blog-article-photo-two">
          <source type="image/webp" srcSet="${blogArticle3Webp22}"/>
            <source type="image/png" srcSet="${blogArticle3Regular22}"/>
            <img class="blog-article-photo-two-image" src="${blogArticle3Regular22}"/>
          </picture>
          <picture>
            <source type="image/webp" srcSet="${blogArticle1Webp32}"/>
            <source type="image/png" srcSet="${blogArticle1Regular32}"/>
            <img class="blog-article-photo-two-image" src="${blogArticle1Regular32}"/>
          </picture>
        </div>
      `,
      `imageSubscript-type2-It is possible to find a perfect picture frame under $60. It is a very good price. Usually, custom framing an 45-inch TV sized artwork cost hundreds.`,
      `We have a large variety of passe-partout. We cut them for your dimensions with our advanced devices. Typically, we cut our mats on the same day and ship them on the next day. Our mat boards will reach your door in days. Anywhere in USA & Canada.`,
      `You can create beautiful frames with customized passe-partout. We are here to make that experience more enjoyable. Try our products at <a href="/">www.CustomMat.ca</a>.`,
    ],
    thumbnail: blogArticle1Regular42,
    url: "/ordering-custom-passepartout-matboard-in-canada-usa",
    urlFr: "/ordering-custom-passepartout-matboard-in-canada-usa-fr",
  },
  article6: {
    category: "Bargain Hunting",
    hashTags: ["#ourAdvice", "#acidFreeMatBoard"],
    title: 'What does acid-free mat board mean?',
    subtitle: "Should I use an acid-free mat board? What does acid-free mean?",
    photos: [blogArticle3Webp14, blogArticle3Webp15, blogArticle3Webp16, blogArticle3Webp22, blogArticle3Webp23],
    photosUnCompressed: [blogArticle3Regular14, blogArticle3Regular15, blogArticle3Regular16, blogArticle3Regular22, blogArticle3Regular23],
    contents: [
      `<div class="intro">Acid Free Mat Board: By definition, acid free mat boards yield neutral or basic (pH 7 or higher) pH values. With a scale of 0 – 14 pH values, 0 - 7 pH values are considered acidic and 7 – 14 pH values are considered non-acidic. Acid free mat boards are used to preserve the documents and artworks for longer period.</div>`,
      `Here at custom mat boards, our mission is to provide you with custom, quality mat boards at a great price. We only provide you with the PH neutral mat boards. All or mat boards are PH Neutral with around pH values of 8.2.`,
      `Framed and presented under right conditions, our mat boards are expected to protect your artwork for at least many years, if not into decades. Enjoy framing with our service. We deliver customized mat board to anywhere in Canada & US for no or very low shipping costs. We also don’t require any minimum orders.
      <br><br><i>*Free shipping to anywhere in Canada or USA CA$99+ (US$75) orders. Our regular shipping costs start at CA$3.99.</i>`,
    ],
    thumbnail: blogArticle3Webp14,
    url: "/acid-free-matboard-definition",
    urlFr: "/acid-free-matboard-definition-fr",
  },
  article7: {
    category: "Customer Story",
    hashTags: ["#customerStory", "#ourAdvice"],
    title: '[Earth Day] Rescuing Thrift Shop Frames: Fitting border for art frames',
    subtitle: "How much difference can you make by getting a fitting border for art frames",
    photos: [blogArticle7Reduced12],
    photosUnCompressed: [blogArticle7Webp12],
    contents: [
      `Today is the Earth Day! We want to appreciate every individual who is participating protecting our only planet. As an Earth Day special, we would like to feature an environmentally conscious customer, who reduced carbon footprints by rescuing the old picture frame from a thrift shop.`,
      `Few years ago, Arman got his diploma. It has been in his drawer for many years. He didn’t like the design or quality of typical graduation frames. He wanted something shinier and environmentally friendly. Above all, it had to be an affordable option. That’s why he went to his usual destination – a thrift shop.`,
      `Arman loves thrift shops. Thrift shops are where Arman’s budget and environmentally conscious minds dovetail. There were several framing options, and he saw a gilded frame that was marked at $8. When he got the frame, Arman was so excited that this diploma might look perfect in it. Close, very close.`,
      `
      <div class="blog-article-photo-one">
          <picture>
            <source type="image/webp" srcSet="${blogArticle7Webp11}"/>
            <source type="image/png" srcSet="${blogArticle7Reduced11}"/>
            <img class="blog-article-photo-one-image" src="${blogArticle7Reduced11}"/>
          </picture>
        </div>
      `,
      `When he put his diploma inside frame, he was a bit disappointed. There were gaps between the frame border and the diploma. He decided to keep the diploma inside frame since it’s still better than a drawer.`,
      `One day, a friend of Arman visited his place over the weekend and told him, “it’s time for you to get a custom mat board.” At the time, Arman didn’t know what mat board meant. His friend explained mat board in layman’s term – “matboard is the white part that bridges the gap between your frame and the artwork. It’s important to get a fitting border for any picture frames.”`,
      `His friend was right. He googled mat board and found us. After reading our posts, he wondered, if he could get the large artworks matted and framed for $50, he can fit his diploma for $10. Absolutely correct! `,
      `
      <div class="blog-article-photo-one">
          <picture>
            <source type="image/webp" srcSet="${blogArticle7Webp12}"/>
            <source type="image/png" srcSet="${blogArticle7Reduced12}"/>
            <img class="blog-article-photo-one-image" src="${blogArticle7Reduced12}"/>
          </picture>
        </div>
      `,
      `When Arman fit his new mat board into his rescued frame, he realized that he got a great deal. His frame now looks perfect with this diploma. Something that could have costed upwards $100, Arman was able to get a high-quality frame and the mat board for $18.`,
      `There are many ways we can find a middle ground among price, quality, looks, and sustainability. Rescuing a frame from a thrift shop can be one of them. Rescuing picture frames with environmentally sustainable mat board is an option that we can choose for the Earth Day.`,
    ],
    thumbnail: blogArticle7Reduced13,
    url: "/thrift-shop-frames-fitting-border-for-art-frames",
    urlFr: "/thrift-shop-frames-fitting-border-for-art-frames-fr",
  },
  article8: {
    category: "Our Advice",
    hashTags: ["#ourAdvice", "#customerStory"],
    title: '[Tips] How to "custom frame" from a secondhand furniture store?',
    photos: [blogArticle9Photo, blogArticle7Reduced12],
    photosUnCompressed: [blogArticle9Photo, blogArticle7Webp12],
    contents: [
        `“How can I frame large artwork without spending hundreds?”`,
        `This is a question that we get almost every day. We don’t really ship any frames that are bigger than 28 x 20 inches because of shipping difficulties. For the same reason, that’s why larger frames are difficult to find from our online stores.`,
        `That’s why we are here to share some tips on how to frame large artworks on a budget.`,
        `<b>Check out used furniture / liquidation / thrift shop.</b> <br><br> Based on our experience, the easiest way to get a larger picture frame on a budget is to check out the used furniture stores. I know the idea of being “second-handed” is not the most appealing thing ever, but how many times do you touch picture frames in your offices and hotel rooms? `,
        `Most of the frames from furniture liquidation stores come from hotels and offices. Unlike bed or sofa, frames are basically pristine. Wouldn’t it be a bargain if you could get those frames for $40, instead of $400 at a custom framing shop?`,
        `<b>How to do this?</b><br><br>
          <li>Call up the liquidation store and ask if they have picture frame at your artwork size.</li>
          <li>If you have a frame that fits exactly your artwork size, that is terrific!</li>
          <li>If not, you can bridge the gaps between your artwork and the frame through matboard.</li>
        `,
        `Sometimes, those frames already come with artworks. You can easily change the artworks with the simple, home-use hardware tools. If you find a frame that you like, you can replace the artwork & matboards easily. This simple little trick saves you hundreds! 
           <br><br>
           <b>Here are examples from our customers:
         `,
        `
          <div class="blog-article-photo-two">
  
            <picture>
              <source type="image/webp" srcSet="${blogArticle7Webp11}"/>
              <source type="image/png" srcSet="${blogArticle7Reduced13}" />
              <img class="blog-article-photo-two-image" src="${blogArticle7Reduced13}"/>
            </picture>
            <picture/>
              <source type="image/webp" srcSet="${blogArticle7Reduced12}"/>
              <source type="image/png" srcSet="${blogArticle7Webp12}" />
              <img class="blog-article-photo-two-image" src="${blogArticle7Reduced12}"/>
            </picture>
          </div>
        `,
      `imageSubscript-type2-Photo credit - Arman form Philadelphia`,
        `When Arman fit his new matboard into the second handed frame that he got from a thrift store, he realized that he got a great deal. His frame now looks perfect with his diploma. Something that costed hundreds were done for $18. `,
      `
      <div class="blog-article-photo-one">
          <picture>
            <source type="image/webp" srcSet="${blogArticle9Photo}"/>
            <source type="image/png" srcSet="${blogArticle9Photo}"/>
            <img class="blog-article-photo-one-image" src="${blogArticle9Photo}"/>
          </picture>
        </div>
      `,
      `imageSubscript-type1-Photo credit - Linda form Calgary`,
        `
Linda was trying to fit her beautiful print into the used frame she got. The frame was a little bit bigger than the artwork. The frame was 40 x 32 inches, and her artwork was 38 x 30 inches.<br><br> The gap was thin, but significantly noticeable. That’s why she bridged the gap between the frame and the artwork. See how nicely it turned out with a small investment?`,

`<b>Summary</b><br><br>With a careful thinking, your framing budget can go very far! Like furniture, frames are designed to last decades. If you can find a frame that you like, you can easily fit your artwork into the frame. Without spending hundreds of dollars on custom framing, you can get a similar visual effect with fractional costs.<br><br>
Here are some stores that we found out that carry second handed frames. Make sure you call them up and check out if they have any frame in stock! 
`,
        `<b>List of second hand furniture shops:<br></b><br/>
           <b>Toronto Metro Area</b><br>
          <li>The Barn - 1675 Lakeshore Rd. W. Mississauga, ON</li>
          <li>Moveline Liquidation - 2130 S Service Road W. Oakville, ON</li>
          <li>Creekbank Liquidation Centre - 5135 Creekbank Rd. Mississauga, ON</li>
          <li>Advance Furniture Limited - 2500 Lawrence Avenue E. Scarborough, ON</li>
          <li>ELV Store - 2005 Clark Blvd, Brampton, Ontario</li>
          <li>Rescued & Restored - 248 Freelton Rd. Freelton, ON</li>
          <li>Next Time Around - 376 Hespeler Rd, Cambridge, ON</li>
          <li>Just like New - 111 Sherwood Dr #36/37, Brantford, ON</li><br/><br/>
          <b>Ottawa</b><br>
          <li>Trove Décor - 2039 Robertson Rd, Nepean, ON</li>
          <li>Total Home Consignment - 1860 Bank St #4, Ottawa, ON</li>
          <li>Snazzy Seconds - 2405 St Joseph Blvd, Orléans, ON</li><br><br>
          <b>Montreal</b><br>
          <li>Eco Depot Montreal - Suite 108 B, 150 Boulevard Montréal-Toronto, Lachine, QC</li>
          <li>Meubles du Grand Berger - 2510 Rue Centre, Montréal, QC</li>
          <li>Snazzy Seconds - 2405 St Joseph Blvd, Orléans, ON</li><br><br>
          <b>Vancouver</b>
          <li>Anizco Hotel Furniture and Liquidators - 250 Terminal Ave, Vancouver, BC</li>
          <li>Consignment Canada - 171 Pemberton Ave, North Vancouver, BC</li>
          <li>North Vancouver ReStore - 344 Lynn Ave #340, North Vancouver, BC</li>
          <li>Carriage House - 1533 W 7th Ave, Vancouver, BC</li>
`



    ],
    thumbnail: blogArticle9Photo,
    url: "/saving-tips-for-custom-framing",
    urlFr: "/saving-tips-for-custom-framing-fr",
  },

  // article7: {
  //   category: "Creative Tweaks",
  //   hashTags: ["", ""],
  //   title: 'Beyond IKEA Frames',
  //   subtitle: "IKEA Frames + art prints can make a big difference to your space",
  //   photos: ["https://images.ctfassets.net/g8qtv9gzg47d/1yysclfknqrijYAPnRTx9L/9f648ffe561f6c109875e9dbee5ec230/190228_ITG_SirJohn_0197.JPG?fl=progressive&fm=jpg&q=80&w=1100", "https://images.ctfassets.net/g8qtv9gzg47d/nfClRRSOgTQADk8gNJAxt/e32a35bd59dbc91a70857ff92dd69b4c/kitty-kash-11.JPG?fl=progressive&fm=jpg&q=80&w=1100"],
  //   photosUnCompressed: [
  //     ikea1Webp, ikea2Webp, ikea3Webp, ikea4Webp, ikea5Webp, ikea6Webp, ikea7Webp, ikea8Webp, ikea9Webp,
  //   ],
  //   contents: "",
  //   thumbnail: "https://images.ctfassets.net/g8qtv9gzg47d/3BR0aJsbgbXLnUnugdKlUZ/ca01235dd21e8b40da08d928a25fa3f0/cbd-feed.jpg?fl=progressive&fm=jpg&q=80",
  //   url: "",
  // },
  // article8: {
  //   category: "Customer Stories",
  //   hashTags: ["", ""],
  //   title: 'Emre – A special gift from his girlfriend',
  //   subtitle: "Framing and cherishing personal artworks before it’s too late",
  //   photos: ["https://images.ctfassets.net/g8qtv9gzg47d/1yysclfknqrijYAPnRTx9L/9f648ffe561f6c109875e9dbee5ec230/190228_ITG_SirJohn_0197.JPG?fl=progressive&fm=jpg&q=80&w=1100", "https://images.ctfassets.net/g8qtv9gzg47d/nfClRRSOgTQADk8gNJAxt/e32a35bd59dbc91a70857ff92dd69b4c/kitty-kash-11.JPG?fl=progressive&fm=jpg&q=80&w=1100"],
  //   photosUnCompressed: [
  //     ikea1Webp, ikea2Webp, ikea3Webp, ikea4Webp, ikea5Webp, ikea6Webp, ikea7Webp, ikea8Webp, ikea9Webp,
  //   ],
  //   contents: "",
  //   thumbnail: "https://images.ctfassets.net/g8qtv9gzg47d/3BR0aJsbgbXLnUnugdKlUZ/ca01235dd21e8b40da08d928a25fa3f0/cbd-feed.jpg?fl=progressive&fm=jpg&q=80",
  //   url: "",
  // },
  // article9: {
  //   category: "Customer Stories",
  //   hashTags: ["", ""],
  //   title: 'Sarah – Repurposing old frames with new artworks',
  //   subtitle: "",
  //   photos: ["https://images.ctfassets.net/g8qtv9gzg47d/1yysclfknqrijYAPnRTx9L/9f648ffe561f6c109875e9dbee5ec230/190228_ITG_SirJohn_0197.JPG?fl=progressive&fm=jpg&q=80&w=1100", "https://images.ctfassets.net/g8qtv9gzg47d/nfClRRSOgTQADk8gNJAxt/e32a35bd59dbc91a70857ff92dd69b4c/kitty-kash-11.JPG?fl=progressive&fm=jpg&q=80&w=1100"],
  //   photosUnCompressed: [
  //     ikea1Webp, ikea2Webp, ikea3Webp, ikea4Webp, ikea5Webp, ikea6Webp, ikea7Webp, ikea8Webp, ikea9Webp,
  //   ],
  //   contents: "",
  //   thumbnail: "https://images.ctfassets.net/g8qtv9gzg47d/3BR0aJsbgbXLnUnugdKlUZ/ca01235dd21e8b40da08d928a25fa3f0/cbd-feed.jpg?fl=progressive&fm=jpg&q=80",
  //   url: "",
  // }

};