import {productDB} from "../../../comp-pricing/productDB";

export default function windowCssRendering(windowWidth, windowHeight, frameWidth, frameHeight, renderingStandardLength, renderingToolHeight, backgroundImage, marginTop, marginBottom, marginLeft, marginRight, secondMatWidth, secondMatColour, isWindowOval) {

  var mobileWindowSizingMultiplier = 1;

  if (window.innerWidth >= 0 && window.innerWidth <= 767) {
    mobileWindowSizingMultiplier = 0.65;
  }

  var objectToReturn = {};
  //second mat width and height are the same
  objectToReturn.width = `${(windowWidth) / renderingStandardLength * renderingToolHeight * mobileWindowSizingMultiplier}px`;
  objectToReturn.height = `${(windowHeight) / renderingStandardLength * renderingToolHeight * mobileWindowSizingMultiplier}px`;
  objectToReturn.backgroundImage = backgroundImage;
  //will be replaced with backgroundImage here
  objectToReturn.marginLeft = `calc(${(marginLeft - secondMatWidth) / renderingStandardLength * renderingToolHeight * mobileWindowSizingMultiplier}px)`;
  objectToReturn.marginRight = `calc(${(marginRight - secondMatWidth) / renderingStandardLength * renderingToolHeight * mobileWindowSizingMultiplier}px)`;
  objectToReturn.marginTop = `calc((${(marginTop - secondMatWidth) / renderingStandardLength * renderingToolHeight * mobileWindowSizingMultiplier}px)`;
  objectToReturn.marginBottom =  `calc((${(marginBottom - secondMatWidth) / renderingStandardLength * renderingToolHeight * mobileWindowSizingMultiplier}px)`;

  objectToReturn.marginLeftMinus = `calc(${ -1 * (marginLeft) / renderingStandardLength * renderingToolHeight * mobileWindowSizingMultiplier}px)`;
  objectToReturn.marginRightMinus = `calc(${ -1 * (marginRight) / renderingStandardLength * renderingToolHeight * mobileWindowSizingMultiplier}px)`;
  objectToReturn.marginTopMinus = `calc(${-1 * (marginTop) / renderingStandardLength * renderingToolHeight * mobileWindowSizingMultiplier}px)`;
  objectToReturn.marginBottomMinus =  `calc(${ -1 * (marginBottom) / renderingStandardLength * renderingToolHeight * mobileWindowSizingMultiplier}px)`;

  objectToReturn.padding = `calc(${secondMatWidth / renderingStandardLength * renderingToolHeight * mobileWindowSizingMultiplier}px)`;

  objectToReturn.marginIndicatorLeft = `calc(${(marginLeft) / renderingStandardLength * renderingToolHeight * mobileWindowSizingMultiplier}px)`;
  objectToReturn.marginIndicatorRight = `calc(${(marginRight) / renderingStandardLength * renderingToolHeight * mobileWindowSizingMultiplier}px)`;
  objectToReturn.marginIndicatorTop = `calc((${(marginTop) / renderingStandardLength * renderingToolHeight * mobileWindowSizingMultiplier}px)`;
  objectToReturn.marginIndicatorBottom =  `calc((${(marginBottom) / renderingStandardLength * renderingToolHeight * mobileWindowSizingMultiplier}px)`;


  objectToReturn.backgroundImage = `url(${productDB.matboards[secondMatColour] ? productDB.matboards[secondMatColour].img : ""})`;
  objectToReturn.borderRadius = isWindowOval ? "100%" : "0";

  return objectToReturn;
}