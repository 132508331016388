import React from "react";
import {Helmet} from "react-helmet";

export default class PricingAndDiscountPage extends React.Component {

    render() {
        return <div>
            <Helmet>
                <title>Custom Mat Canada • Discounted Photo Mats • No Minimum Order Quantity</title>
                <meta name="description" content="Customized matting & framing with special options for polaroids. Design and order online now. Affordable, fast shipping anywhere in Canada & the USA." />
            </Helmet>
            {this.props.fetchDiscountPlans()}
            {this.props.fetchPrices()}
        </div>
    }
}