export const precutBackingSizes = [
    "5x7",
    "9x9",
    "8x10",
    "11x14",
    "12x16",
    "14x18",
    "16x20",
    "18x24",
    "19.75x19.75",
    "20x20",
    "19.75x27.5",
    "20x28",
    "24x35.75",
    "24x36",
    "32x40",
]