import React from 'react';
import ArticleFetcher from "../articleFetcher";
import {galleryArticles} from "../articles";
import {Helmet} from "react-helmet";
import analyticsDataToDB from "../../comp-analyticsLog/dbLogging";

export default class BlogArticleTwo extends React.Component {

  constructor(props) {
    super(props);
    var article = galleryArticles.article2;
    this.state = {
      category: article.category,
      hashTags: article.hashTags,
      title: article.title,
      subtitle: article.subtitle,
      photos: article.photos,
      contents: article.contents,
      thumbnail: article.thumbnail,
    }
  }

  componentDidMount(){
    this.logToDbUserRecord();
  }

  logToDbUserRecord() {
    analyticsDataToDB("fetch-article", "article-2-how-to-measure");
  }

  render() {
    return <div className={`gallery-container`}>
      <Helmet>
        <title>Custom Cut Photo Mat Board • Online & Delivery • US & Canada </title>
        <meta name="description" content="We cut custom mat boards for your Amazon & IKEA frames. Picture frames look professional with customized mat board."/>
      </Helmet>

      <ArticleFetcher article={this.state}/>
    </div>
  }
}