import React from 'react';
import backgorundPhoto from "../../../resources/images/blog/oversizedMatRamsborgFrames/IMG_3419.JPG";
import photo1 from "../../../resources/images/blog/oversizedMatRamsborgFrames/photo1.jpg";
import photo2 from "../../../resources/images/blog/oversizedMatRamsborgFrames/photo2.jpg";
import photo3 from "../../../resources/images/blog/oversizedMatRamsborgFrames/photo3.jpg";
import photo4 from "../../../resources/images/blog/oversizedMatRamsborgFrames/photo4.png";
import photo5 from "../../../resources/images/blog/oversizedMatRamsborgFrames/photo5.jpg";
import photo6 from "../../../resources/images/blog/oversizedMatRamsborgFrames/photo6.jpg";
import photo7 from "../../../resources/images/blog/oversizedMatRamsborgFrames/photo7.jfif";
import photo8 from "../../../resources/images/blog/oversizedMatRamsborgFrames/photo8.jpg";
import photo9 from "../../../resources/images/blog/oversizedMatRamsborgFrames/photo9.jpg";
import {Helmet} from "react-helmet";
import LazyLoad from 'react-lazyload';

export default class OffsetOversizedRamsborgFrames extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        };
    };

    componentDidMount() {

    }

    render() {
        return <div className={"blog-app-container"}>

            <Helmet>
                <title>Gallery Wall Template: Offset Oversized Mat IKEA Ramsborg Frames</title>
                <meta name="description" content="Whether you’re looking for a wedding gallery wall, a behind-the-couch gallery wall, or some gallery wall templates, you’ve landed in the right place. Welcome to my gallery wall and picture frame series, where I show you how to get the most beautiful gallery walls on a budget."/>
            </Helmet>

            <div className={"carousel-subject-head"} style={{textAlign: "center"}}>
                Gallery Wall Template: Offset Oversized Mat IKEA Ramsborg Frames
            </div>

            <div className={"carousel-subject-subhead-type2"}>
                Grid Gallery Wall
            </div>
            <div className={"carousel-subject-subhead-type3"}>
                Written by Kendra H<br/>Project Blogger <a href={"https://www.kendrafoundit.com/blog/gallery-wall-template-oversized-mat-frames-with-ikea-ramsborg"}>@KendraFoundIt</a>
            </div>

            <LazyLoad>
                <img src={backgorundPhoto} className={"blog-carousel-photo"}/>
            </LazyLoad>




            <div className={"blog-article-content"}>

                Whether you’re looking for a wedding gallery wall, a behind-the-couch gallery wall, or some gallery wall templates, you’ve landed in the right place. Welcome to my gallery wall and picture frame series, where I show you how to get the most beautiful gallery walls on a budget.
                <br/><br/>
                We all love a good #ikeahack, right? So today I’m showing you how to create a wedding photo gallery wall with <a href={"https://www.ikea.com/ca/en/p/dalskaerr-frame-wood-effect-light-brown-40387603/"}><u>IKEA Ramsborg frames</u></a>.  It of course doesn’t have to be a wedding photo gallery wall - it could be a gallery wall of family photos, an engagement shoot gallery wall, a travel gallery wall….well, you get the idea.
                <br/><br/>
                <LazyLoad>
                    <img src={photo1} className={"blog-content-photo"}/>
                </LazyLoad>



                <br/><br/>
                As always, I am using super affordable frames and elevating them instantly by swapping out the matting for some custom matting I designed with Custom Mat. If you love the current trend of large matting with small photos, this is how you do it on a budget.
                <br/><br/>
                I’m going to give you my exact process and measurements, so you can easily recreate this DIY on your own. Let’s get started!
                <br/><br/>

                <LazyLoad>
                    <img src={photo2} className={"blog-content-photo"}/>
                </LazyLoad>

                <br/><br/>
                <b>STEP ONE: PURCHASE YOUR AFFORDABLE PICTURE FRAMES</b>
                <br/><br/>
                For this above-the-couch gallery wall, I chose IKEA’s 16x20" Ramsborg frames.  I saw these frames while shopping for my last gallery wall, and at just $23 Canadian (or $17 US), I knew I had to use them for my next project. The rustic wood frame is a total steal for the price and they look even better in person.
                <br/><br/>
                I started by measuring the wall I wanted to put the gallery wall on and envisioning what the frames would look like within that space. If you’re having trouble visualizing this, you can use painter’s tape (I did this on my last project here) or kraft paper to mark out the frames and play around with the configuration. I chose to go with 2 rows of three and to space out the picture frames 1.75" apart.
                <br/><br/>

                <LazyLoad>
                    <img src={photo3} className={"blog-content-photo"}/>
                </LazyLoad>


                <b>STEP TWO: PURCHASE CUSTOM MATTING TO ELEVATE YOUR FRAMES</b>
                <br/><br/>
                IKEA frames come with basic matting, but we are going to create our own in order to get the offset, oversized matting look.  Below are the exact measurements I used for my 16x20" Ramsborg frames with room for an 8x12" photo.  To get this exact look, just put the details below into <a href={"/customize-matboards"}><u>Custom Mat’s interactive matboard designer tool</u></a>.
                <br/><br/>
                Mat colour: Smooth White<br/>
                Opening for a 8x12" photo: 7.75x11.75" (the photo window size needs to be a bit smaller than your photo)<br/>
                Horizontal Margins: 4.13"<br/>
                Vertical Margins: 3.13" (top), 5.13" (bottom)<br/>
                Size of overall matting: 16x20"<br/>
                Frames: IKEA 16x20" Ramsborg frames
                <br/><br/>
                <LazyLoad>
                    <img src={photo5} className={"blog-content-photo"}/>
                </LazyLoad>


                <br/><br/>
                Note that I wanted the photo slightly offset in the frame, which is why my top and bottom margins are different. If you’d prefer to have your photo centered, just set your vertical margins to 4.13" for both.
                <br/><br/>
                Custom Mat offers a quantity discount of up to 40% off, so the more mats you purchase, the bigger the discount. I ordered 6, so my mats came to just over $16 each. If you order 10 or more, you’ll get an even bigger discount. Bonus: they ship to Canada and the USA with free shipping over $49.
                <br/><br/>
                <LazyLoad>
                    <img src={photo6} className={"blog-content-photo"}/>
                </LazyLoad>
                <br/><br/>
                <b>STEP THREE: SWAP OUT THE MATTING & ADD THE PHOTOS</b>
                <br/><br/>
                This is where it gets fun! Once your matting arrives in the mail, swap out the matting and see your frames transform before your eyes. In the photo below, the left is the Custom Mat matting and the right is the standard IKEA matting.
                <br/><br/>
                <LazyLoad>
                    <img src={photo7} className={"blog-content-photo"}/>
                    <img src={photo8} className={"blog-content-photo"}/>
                </LazyLoad>

                <br/><br/>
                <b>STEP FOUR: HANG YOUR GALLERY WALL</b>
                Honestly, this is the hardest part of the entire tutorial. When it comes to gallery walls, spacing is everything; I chose to evenly space my frames 1.75" apart (top to bottom and side to side).
                <br/><br/>
                Isn’t DIY the best?! Similar frame sets like this sell for over $900, and we just did it for under $250. If you make this gallery wall, please DM me on Instagram and send me photos; I love to see how you shop big and spend small with me.
                <br/><br/>
                <LazyLoad>
                    <img src={photo9} className={"blog-content-photo"}/>
                </LazyLoad>

                <br/><br/>

                <div className={"visit-custom-mat-button"}>
                    <a href={"/"} className={"carousel-read-more-button"}>
                        Visit Custom Mat
                    </a>
                </div>

                <div className={"carousel-subject-subhead-type2"}>
                    Photo Gallery
                </div>

                <LazyLoad>
                    <img src={backgorundPhoto} className={"blog-content-photo"}/>
                    <img src={photo1} className={"blog-content-photo"}/>
                    <img src={photo2} className={"blog-content-photo"}/>
                    <img src={photo3} className={"blog-content-photo"}/>
                    <img src={photo4} className={"blog-content-photo"}/>
                    <img src={photo5} className={"blog-content-photo"}/>
                    <img src={photo6} className={"blog-content-photo"}/>
                    <img src={photo7} className={"blog-content-photo"}/>
                    <img src={photo8} className={"blog-content-photo"}/>
                    <img src={photo9} className={"blog-content-photo"}/>
                </LazyLoad>

                <div className={"visit-custom-mat-button"}>
                    <a href={"/"} className={"carousel-read-more-button"}>
                        Visit Custom Mat
                    </a>
                </div>


            </div>



            <div>
                <div className="elfsight-app-7688ab8e-8d1b-4130-9172-7c7f3e0d45b4"></div>
            </div>



        </div>
    }
}