import React from "react";
import {Helmet} from "react-helmet";
import WhatIsMatboard from "./whatIsMatboard";
import Explainer from "./explainer";
import QuantityDiscount from "./quantityDiscount";
import FetchPrices from "./fetchPrices";
import ReviewComponent from "../app-review/reviewComponent";
import LandingPageReviewStars from "../page-landing/landingPageReviewStars";

export default class HowItWorksV2 extends React.Component {

    constructor() {
        super();
        this.state = {
            french: false,
        }
    }

    componentDidMount() {
        this.setState({
            french: (window.location.pathname.slice(-2) === "fr" || this.props.language === "fr") ? true : false
        })
    }

    render() {
        return <div>
            <Helmet>
                <title>
                    {this.state.french ? " Ajustez vos œuvres d'art dans n'importe quel cadre avec des passe-partouts sur mesure" :
                        "How It Works: Fit Your Artwork in Any Frame with Custom Matboards"}
                </title>
                <meta name="description" content={
                    this.state.french ? "Découvrez comment nos passe-partouts sur mesure vous aident à ajuster vos œuvres d'art dans n'importe quel cadre. Apprenez-en plus sur les passe-partouts en tant que couche de papier artistique spécialisé et notre processus d'assemblage en quelques étapes faciles. Personnalisez la taille, la forme et la couleur de votre passe-partout. Profitez d'une expédition rapide en moins de 48 heures et d'une garantie de satisfaction à 100%." :
                        `Discover how our custom matboards help you fit your artwork in any frame. Learn about matboards as a specialty art paper layer and our easy step-by-step assembly process. Customize your matboard's size, shape, and ${this.props.location === "us" ? " color" : " colour"}. Enjoy fast shipping within 48 hours and a 100% satisfaction guarantee.`
                } />
            </Helmet>

            <WhatIsMatboard
                language={this.props.language}
            />

            <LandingPageReviewStars
                french={this.state.french}
            />

            <Explainer
                language={this.props.language}
                french={this.state.french}
            />

            <FetchPrices
                language={this.props.language}
                french={this.state.french}
            />

            <QuantityDiscount
                language={this.props.language}
                french={this.state.french}
            />

            <div className={"landing-page-review-transition-ending-v3"}>
                <div className={"landing-page-review-transition-ending-header-v3"}>
                    {this.state.french ? "Bonheur garanti à 100 %" : "100% SATISFACTION GUARANTEED"}
                </div>
                <div className={"landing-page-review-transition-ending-button-v3"}>
                    {this.state.french ? "Parfois (moins de 2 % du temps), nos clients ne mesurent pas correctement les cadres et les œuvres. Cela se produit. Nous comprenons parfaitement. Si nos passe-partout ne sont pas adaptés, faites-le-nous savoir, et nous le corrigerons gratuitement. Lisez nos évaluations. Nous travaillons avec nos clients jusqu’à ce qu’ils soient pleinement satisfaits!" : "Sometimes (less than 2% of the time), our customers measure frames & artworks incorrectly. It happens. We totally understand. If our mats don't fit, let us know, and we will make it right for free of charge. Read our reviews. We work with our customers until they are full satisfied!"}
                </div>
            </div>

            <ReviewComponent
                french={this.state.french}
            />

            <div className={"landing-page-review-transition-ending-v2"}>
                <div className={"landing-page-review-transition-ending-header-v2"}>
                    {this.state.french ? "FAITES QUE VOS SOUVENIRS PARAISSENT AUSSI BIEN QUE CE QU’ILS VOUS RAPPELLENT" : "MAKE MEMORIES LOOK AS GOOD AS THEY FEEL"}

                </div>
                <a href={this.state.french ? "customize-matboards-fr" : "customize-matboards"}>
                    <div className={"landing-page-review-transition-ending-button-v2"}>
                        {this.state.french ? "Je crée" : "Start Your Custom Mat"}
                    </div>
                </a>

            </div>

        </div>
    }
}