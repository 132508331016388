export default function addMultipleWindowsClicked(row, column, frameWidth, frameHeight, renderingToolWidth, renderingToolHeight, frameCssHeight, multipleWindow, multipleWindowWidth, multipleWindowHeight) {

  var stateObjectToUpdate = {
    rowCount: row,
    columnCount: column,
  };



  //for temporary solutions, we will have to do ask users what the each of the size will be and then let them provide us with the matrix

  // for instance, we got a value of 4 and 4 for each box for matrix of 1 * 3

  // var totalMattingWidth  = (0.2 * frameWidth).toFixed(0);
  // var totalMattingHeight = (0.2 * frameHeight).toFixed(0);

  var totalMattingWidth = frameWidth - multipleWindowWidth * column;
  var totalMattingHeight = frameHeight - multipleWindowHeight * row;

  var width = multipleWindowWidth;
  var height = multipleWindowHeight;
  var marginLeft = totalMattingWidth / column / 2;
  var marginRight = totalMattingWidth / column / 2;
  var marginTop = totalMattingHeight / row / 2;
  var marginBottom = totalMattingHeight / row / 2;


  // var standardMatting = frameWidth > frameHeight ? frameWidth / 6 : frameHeight / 6
  // var totalMattingWidth  = (0.2 * frameWidth).toFixed(0);
  // var totalMattingHeight = (0.2 * frameHeight).toFixed(0);
  //
  // var width = (frameWidth - totalMattingWidth) / column;
  // var height = (frameHeight - totalMattingHeight) / row;
  // var marginLeft = totalMattingWidth / column / 2;
  // var marginRight = totalMattingWidth / column / 2;
  // var marginTop = totalMattingHeight / row / 2;
  // var marginBottom = totalMattingHeight / row / 2;


  for (var i = 1; i <= row * column; i++) {
    stateObjectToUpdate[`window${i}`] = {
      width: width,
      height: height,
      marginTop: marginTop,
      marginBottom: marginBottom,
      marginLeft: marginLeft,
      marginRight: marginRight,
    };
  }

  return stateObjectToUpdate;

  // if (multipleWindow === true) {
  //   for (var i = 1; i <= row * column; i++) {
  //     stateObjectToUpdate[`window${i}`] = {
  //       width: width,
  //       height: height,
  //       marginTop: marginTop,
  //       marginBottom: marginBottom,
  //       marginLeft: marginLeft,
  //       marginRight: marginRight,
  //     };
  //   }
  //
  //   return stateObjectToUpdate;
  //
  //
  // } else if (multipleWindow === false) {
  //   for (var i = 1; i <= row * column; i++) {
  //     stateObjectToUpdate[`window${i}`] = {
  //       width: width,
  //       height: height,
  //       marginTop: marginTop,
  //       marginBottom: marginBottom,
  //       marginLeft: marginLeft,
  //       marginRight: marginRight,
  //     };
  //   }
  //
  //   return stateObjectToUpdate;
  // }




};