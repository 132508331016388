import React from "react";
import {galleryArticles} from "./articles";
import moveToArticle from "./moveToArticle";

export default class ArticleIndexFetcher extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      articleList: galleryArticles,
      categories: ["All", "Bargain Hunting", "Creative Tweaks", "Customer Story", "IKEA Frames", "Our Advice"],
      selectedCategory: "All",
    }
  }


  fetchCategorySelector() {
    return this.state.categories.map((category, key) => {
      return <div onClick={() => this.setState({selectedCategory: category})}
        className={this.state.selectedCategory === category ? `article-list-container-option article-list-selected` : `article-list-container-option`}>
        {category}
      </div>
    })
  }

  showFilteredArticles(selectedList) {
    return selectedList.map((articleKey, key) => {
      return <div className={`article-list-item`} onClick={() => moveToArticle(this.state.articleList[articleKey].url)}>
        <div className={`article-list-left`}>
          <img className={`article-list-image`}
               alt={"Please click here for the article."}
               src={`${this.state.articleList[articleKey].thumbnail}`}/>
        </div>
        <div className={`article-list-right`}>
          <div className={`article-list-right-category`}>{this.state.articleList[articleKey].category}</div>
          <div className={`article-list-right-title`}>{this.state.articleList[articleKey].title}</div>
        </div>
      </div>
    })
  }

  fetchArticles() {
    var articleList = Object.keys(this.state.articleList);
    var selectedList = [];

    if (this.state.selectedCategory === "All") {
      selectedList = articleList;
    } else {
      articleList.map((articleKey) => {
        if (this.state.articleList[articleKey].category === this.state.selectedCategory) {
          selectedList.push(articleKey);
        }
      })
    }
    return this.showFilteredArticles(selectedList);
  }

  render() {
    return <div className={`overview-section-right`}>
      <div className={`article-list-container`}>

        <div className={`article-list-header`}>
          Topics
        </div>

        <div className={`article-list-container-selector`}>
          {this.fetchCategorySelector()}
        </div>

        <div className={`article-list-container-selector`}>
          {this.fetchArticles()}
        </div>
      </div>
    </div>
  }
}