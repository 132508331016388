import fbdb from '../database/cre';
import timestampEnc from "../app-enc/timestampEnc";

export default function connectToDB(action, directory, userSessionEmail, content, successCallback, failureCallback) {
  var datetime = new Date();
  var date = `${datetime.getFullYear()}-${datetime.getMonth()+1}-${datetime.getDate()}`;
  var time = `${datetime.getHours()}-${datetime.getMinutes()}-${datetime.getSeconds()}`;
  datetime = date + "-" + time;
  var shortenedEncEmail = `${userSessionEmail}`;
  shortenedEncEmail.toString();
  if (action === "r") {
    var destDirectory = `prod/${directory}/${shortenedEncEmail}`;
    var valueLoaded = fbdb.database().ref(destDirectory);
    valueLoaded.on('value', (snapshot) => {
      if (snapshot.val() !== null) {
        localStorage.setItem("prevOrderedItems", JSON.stringify(snapshot.val()).toString());
        successCallback();
      } else {
        failureCallback();
      }
    });
  }
  else if (action === "postAddress") {
    destDirectory = `prod/${directory}/${userSessionEmail}`;
    fbdb.database().ref(destDirectory).set(content);
  }
  else if (action === "w") {
    destDirectory = `prod/${directory}/${shortenedEncEmail}/${timestampEnc("enc", datetime)}`;
    fbdb.database().ref(destDirectory).set(content);
  }
  else if (action === "readReviews") {
    destDirectory = `prod/user-review/`;
    var valueLoaded = fbdb.database().ref(destDirectory);
    var reviewsLoaded;

    //here if paiginate once, then it will be 99 to 89, twice 89 - 79 etc etc.


    valueLoaded.on('value', (snapshot) => {
      if (snapshot.val() !== null) {

        localStorage.setItem("totalReviewCount", JSON.stringify(snapshot.numChildren().toString()));

        reviewsLoaded = snapshot.val();
        successCallback(reviewsLoaded);

        // successCallback();
      } else {
        // failureCallback();
      }
    });

    return reviewsLoaded;

  }
}

