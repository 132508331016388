import React from "react";

export default function fetchImage(img, webp, className, altName, classId, onMouseEnterFunction, onMouseLeaveFunction, onClickFunction) {
  return <picture>
    <source type="image/webp" srcSet={webp}/>
    <source type="image/png" srcSet={img}/>
    <img className={className}
         src={img}
         onMouseEnter={onMouseEnterFunction === "" ? null : onMouseEnterFunction}
         onMouseLeave={onMouseLeaveFunction === "" ? null : onMouseLeaveFunction}
         onClick={onClickFunction === "" ? null : onClickFunction}
         alt={altName}
         id={classId}
    />
  </picture>
}