import React from 'react';
import photo1 from "../../../resources/images/blog/socialLandingPg1/socialLandingPhoto1.png"

import photo2 from "../../../resources/images/blog/socialLandingPg1/socialLandingPhoto2.png"
import photo3 from "../../../resources/images/blog/photoset2/photo1.jpg";
import photo4 from "../../../resources/images/blog/photoset2/photo8.png";
import photo5 from "../../../resources/images/blog/photoset2/photo9.jpg";
import photo6 from "../../../resources/images/blog/photoSet1/IMG_4253.JPG";
import photo7 from "../../../resources/images/blog/ikeaSeries/oversized-mat-photo-frame-3.jpg";
import photo8 from "../../../resources/images/blog/photoset2/photo1.jpg";
import photo9 from "../../../resources/images/blog/vintageFrames/IMG_5124.JPG";
import photo10 from "../../../resources/images/blog/diploma/Background Photo.png";
import photo11 from "../../../resources/images/blog/jerseys/3.jpeg";



import LazyLoad from "react-lazyload";
import {Helmet} from "react-helmet";

export default class FrameMatLanding extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        };
    };

    componentDidMount() {

    }

    render() {
        return <div className={"blog-app-container"}>

            <Helmet>
                <title>Here's how you can save hugh on framing -- CustomMat.ca</title>
                <meta name="description" content="You can order your standard sized frames from your favorite retail stores or Amazon. You can order your customized matboards from us and get them delivered to your door. It’s much easier than you think. :)"/>
            </Helmet>

            <div className={"carousel-subject-head"} style={{textAlign: "center"}}>
                Here's how you can save <br className={"mobile-no-display tablet-no-display"}/> HUGE on picture framing.
            </div>

            <div className={"carousel-subject-subhead-type2"}>
                Picture Framing Tips
            </div>

            <div className={"carousel-subject-subhead-type3"}>
                Written by Danielle M.<br/>Custom Mat Project Blogger
            </div>

            <LazyLoad>
                <img src={photo1} className={"blog-content-photo"}/>
            </LazyLoad>


            <div className={"blog-article-content"}>
                <b>Here's how you can save HUGE on picture framing.</b>

                <br/><br/>

                <b>STEP 1: Get a standard-sized picture frame from Amazon or a retail store. </b>


                <br/><br/>

                <b>STEP 2: Get a custom matboard from us. Enter frame & artwork size.</b>

                <br/><br/>

                <b>STEP 3: Easy assembly. In under 2 minutes. Ready to hang.</b>

                <br/><br/>

                It's much easier than you think. Fit your artwork in any picture frame for less than $40 per piece.
                You can achieve the same visual effect as custom framing -- which costs over $200 per piece.

                <br/><br/>

                <LazyLoad>
                    <video className={"what-is-mat-image-v2"} loop="true" autoPlay="autoplay" controls muted
                           src="https://s3.ca-central-1.amazonaws.com/custommat.landing.v2/0_What-is-a-matboard_1920x1290_v1+(1).mp4"></video>
                </LazyLoad>

                <br/>

                <b>What is a matboard?</b>

                <br/><br/>

                Matboard is a dense art paper that sits between your frame and your artwork. Matting protects your artwork and enhances visual appeal of your picture display. With a custom matboard, you can fit your artwork in any standard-sized picture frames.

                <br/><br/>

                <b>How to order custom matboards?</b>

                <br/><br/>

                You can order custom matboards from us - <a href={"/"}>www.CustomMat.ca</a>. Get them delivered to your door anywhere in Canada & the USA. Easy order. Easy returns. Guaranteed to match your dimensions.

                <br/><br/>

                <b>Where to order picture frames?</b>

                <br/><br/>

                For standard-sized picture frames, you can find them easily on Amazon, IKEA, Michael's Costco, Target, Walmart, or any major retail store.

                <br/><br/>

                <b>What's your return policy?</b>

                <br/><br/>

                We have a generous return policy backed by our 100% Happiness Guarantee -- If you're not happy with your order, let us know. We'll make it right or issue you a refund.

                <br/><br/>
                
                We work with thousands of happy customers every month. Please refer to our 1,000+ 5-star reviews.

                <br/><br/>

                <b>Need some design inspirations?</b>

                <br/><br/>

                <LazyLoad>
                    <img src={photo2} className={"blog-content-photo"}/>
                </LazyLoad>

                <br/><br/>

                Here are some examples of our actual work. Click each link to learn more about the project.

                <ol>
                    <li>Classic, Single Artwork Framing</li>
                    <li>Double Matting</li>
                    <li>Gallery Wall - Hotel Lobby Style</li>
                    <li>Gallery Wall - Grid Style</li>
                    <li>Multi-colour Picture Frames</li>
                    <li>Multiple Opening</li>
                    <li>Unique Shaped Art Framing</li>
                    <li>Modern, Large Matting Style</li>
                    <li>Diploma & Certificate Framing</li>
                    <li>Sports Framing</li>
                </ol>

                <br/>

                <b>Any Questions? </b>
                <br/>
                <ol>
                    <li>Please send us an email to hello@CustomMat.ca</li>
                    <li>Or, text us at 604-345-8428</li>
                </ol>



                <div className={"visit-custom-mat-button"}>
                    <a href={"/"} className={"carousel-read-more-button"}>
                        Visit Custom Mat
                    </a>
                </div>

                <div className={"visit-custom-mat-button"}>
                    <a href={"/inspiration"} className={"carousel-read-more-button"}>
                        Customer Photos
                    </a>
                </div>

                <div className={"visit-custom-mat-button"}>
                    <a href={"/design-blog-main"} className={"carousel-read-more-button"}>
                        Design Blog
                    </a>
                </div>


            </div>

            <div>
                <div className="elfsight-app-7688ab8e-8d1b-4130-9172-7c7f3e0d45b4"></div>
            </div>

        </div>
    }
}