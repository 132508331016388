import React from "react";
import {galleryArticles} from "./articles";
import renderImg from "../comp-renderImg/renderImg";
import moveToArticle from "./moveToArticle";

export default class ArticleListFetcher extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      articleList: galleryArticles,
      articleTypeOne: ["article1"],
      articleTypeTwo: ["article3", "article4", "article5", "article6", "article7", "article8", "article9"],
      articleTypeThree: ["article21"],
    }
  }

  fetchArticleTypeOne(articleKey) {
    return <div className={`gallery-left-item-container`} onClick={() => moveToArticle(this.state.articleList[articleKey].url)}>
      {renderImg(this.state.articleList[articleKey].photosUnCompressed[0], this.state.articleList[articleKey].photos[0], "overview-section-left-image", "")}
      {/*<img className={`overview-section-left-image`}*/}
      {/*     src={this.state.articleList[articleKey].photos[0]}/>*/}
      <div className={`overview-section-header-container`}>
        <div className={`overview-section-header`}>
          {this.state.articleList[articleKey].title}
        </div>
        <div className={`overview-section-subheader`}>
          {this.state.articleList[articleKey].subtitle}
        </div>
      </div>
    </div>
  };

  fetchArticleTypeTwo(articleKey) {
    var category = this.state.articleList[articleKey].category.split(" ");
    return <div className={`gallery-left-item-container`} onClick={() => moveToArticle(this.state.articleList[articleKey].url)}>
      <div className={`gallery-left-item-container-left`}>
        <div className={`gallery-left-item-section`}>{category[1][0]}</div>
        <span>{category[0]}<br/>{category[1]}</span>
      </div>
      <div className={`gallery-left-item-container-right`}>
        {renderImg(this.state.articleList[articleKey].photosUnCompressed[0], this.state.articleList[articleKey].photos[0], "overview-section-left-image", "")}

        {/*<img className={`overview-section-left-image`}*/}
        {/*     src={this.state.articleList[articleKey].photos[0]}/>*/}
        <div className={`overview-section-header-container-alternative`}>
          <div className={`overview-section-header-alternative`}>
            {this.state.articleList[articleKey].title}
          </div>
          <div className={`overview-section-subheader-alternative`}>
            {this.state.articleList[articleKey].subtitle}
          </div>
        </div>
      </div>
    </div>
  };

  fetchArticleTypeThree(articleKey) {
    var category = this.state.articleList[articleKey].category.split(" ");
    return <div className={`gallery-left-item-container`} onClick={() => moveToArticle(this.state.articleList[articleKey].url)}>
      <div className={`gallery-left-item-container-left`}>
        <div className={`gallery-left-item-section`}>{category[1][0]}</div>
        <span>{category[0]}<br/>{category[1]}</span>
      </div>
      <div className={`gallery-left-item-container-right`}>
        {renderImg(this.state.articleList[articleKey].photosUnCompressed[0], this.state.articleList[articleKey].photos[0], "overview-section-left-image-alternative", "")}

        {/*<img className={`overview-section-left-image-alternative`}*/}
        {/*     src={this.state.articleList[articleKey].photos[0]}/>*/}
        <div className={`overview-section-header-container-alternative-two`}>
          <div className={`overview-section-header-alternative`}>
            {this.state.articleList[articleKey].title}
          </div>
          <div className={`overview-section-subheader-alternative`}>
            {this.state.articleList[articleKey].subtitle}
          </div>
        </div>
      </div>
    </div>
  };

  fetchArticle() {
    var articleList = Object.keys(this.state.articleList);
    return articleList.map((articleKey, key) => {
      if (this.state.articleTypeOne.includes(articleKey)) {
        return this.fetchArticleTypeOne(articleKey);
      } else if (this.state.articleTypeTwo.includes(articleKey)) {
        return this.fetchArticleTypeTwo(articleKey);
      } else if (this.state.articleTypeThree.includes(articleKey)) {
        return this.fetchArticleTypeThree(articleKey);
      } else {
        return null
      }
    })
  };

  render() {
    return <div className={`overview-section-left`}>
      {this.fetchArticle()}
    </div>
  }
}