import React from 'react';
import photo1 from "../../../resources/images/designInspiration/1.png"
import photo2 from "../../../resources/images/designInspiration/2.png"
import photo3 from "../../../resources/images/designInspiration/3.png"
import photo4 from "../../../resources/images/designInspiration/4.png"
import photo5 from "../../../resources/images/designInspiration/5.png"
import photo6 from "../../../resources/images/designInspiration/6.png"
import photo7 from "../../../resources/images/designInspiration/7.png"
import photo8 from "../../../resources/images/designInspiration/8.png"
import photo9 from "../../../resources/images/designInspiration/9.png"
import photo10 from "../../../resources/images/designInspiration/10.png"
import photo11 from "../../../resources/images/designInspiration/11.png"
import photo12 from "../../../resources/images/designInspiration/12.png"
import photo13 from "../../../resources/images/designInspiration/13.png"
import photo14 from "../../../resources/images/designInspiration/14.png"

import LazyLoad from "react-lazyload";
import {Helmet} from "react-helmet";

export default class HomeArtGalleryWall extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        };
    };

    componentDidMount() {

    }

    render() {
        return <div className={"blog-app-container"}>

            <Helmet>
                <title>Home Art Gallery Wall Design Inspirations -- Top 14</title>
                <meta name="description" content="Our design team has done some research and came up with a wall decor shortlist. We hope we can be a source of your home decor inspiration."/>
            </Helmet>

            <div className={"carousel-subject-head"} style={{textAlign: "center"}}>
                Home Art Gallery Wall <br className={"mobile-no-display tablet-no-display"}/> Design Inspirations -- Top 14
            </div>

            <div className={"carousel-subject-subhead-type2"}>
                Design Inspirations
            </div>

            <div className={"carousel-subject-subhead-type3"}>
                Written by Danielle M<br/>Custom Mat Project Blogger
            </div>

            <LazyLoad>
                <img src={photo1} className={"blog-content-photo"}/>
            </LazyLoad>


            <div className={"blog-article-content"}>

                <b>Home Art Gallery Wall Design Inspirations -- Top 14</b>

                <br/><br/>

                Wall decor is a key element of interior design. How you decide to fill your wall can lead to transformative wall decor ideas. You can do so many with your wall decor — your imagination is your limit. But, sometimes, you don't know where to start.
                <br/><br/>

                We all have been there. Browsing through websites or magazines like HouseBeautiful, HGTV, Architectural Digest, and Style at Home may give you some design inspiration. They will show timeless classic styles as well as trending designs as well.

                <br/><br/>

                When you browse through the photos, if you find the design you like online, you can steal it immediately. It doesn't have to be the exact replica of the wall art or furniture pieces.

                <br/><br/>

                You can find similar items at a much lower price from Amazon or IKEA. Also, many wall decor pieces, like paint colour, accent wallpaper, and wall art frames, can be done with your DIY Skills. It could be a fun weekend project.

                <br/><br/>

                Our design team has done some research and came up with a wall decor shortlist. We hope we can be a source of your home decor inspiration.

                <br/><br/>

                <LazyLoad>
                    <img src={photo1} className={"blog-content-photo"}/>
                </LazyLoad>

                <br/>

                <div className="blog-article-photo-description">
                    Photo: You can utilize the high ceiling by stacking up several frames. Frame Stacking is in trend, whether in a loft or a traditional house with a high roof. By breaking an ample wall space into several smaller areas, you get to finish your interior design on a budget and create a compelling wall art collection.

                    <br/><br/>

                    {this.props.blogDirectLinkToMatLayout("Photo Left: 18x24 inch standard sized frames from Amazon being combined with our off-center 18x24 inch matboard with opening for 8x10 inch standard photo prints.", "Link to Matboard Layout", "/customize-matboards?mWidth=18&mHeight=24&wWidth=7.75&wHeight=9.75&mTop=5.125&mLeft=5.125&mRight=5.125&mBottom=9.125&mColor=smoothWhite&dmColor=none&dmWidth=0&backing=false&oval=false&ctr=false")}

                    <br/>

                    {this.props.blogDirectLinkToMatLayout("Photo Right: 20x20 inch standard sized frames from Amazon being combined with our square 20x20 inch matboard with opening for 8x8 inch square photo prints.", "Link to Matboard Layout", "/customize-matboards?mWidth=20&mHeight=20&wWidth=7.75&wHeight=7.75&mTop=6.125&mLeft=6.125&mRight=6.125&mBottom=6.125&mColor=smoothWhite&dmColor=none&dmWidth=0&backing=false&oval=false&ctr=true")}

                </div>


                <br/>

                <b>1. Stacking Frames</b>

                <br/><br/>

                Frame stacking is trending lately. New buildings are getting bigger and higher — so is our framing. So instead of leaving ample white space on top and bottom of your artwork, by stacking 2-3 frames, we can avoid having an awkward white space.

                <br/><br/>

                Typically, square frames are recommended for stacking as they are more convenient with dimensions, but you can play with them. Here's the list of Amazon and IKEA's most standard frame sizes.

                <br/><br/>

                <div>
                    <table>
                        <tr>
                            <th>Portrait (in inches)</th>
                            <th>Landscape (in inches)</th>
                        </tr>
                        <tr>
                            <th>5x7</th>
                            <th>7x5</th>
                        </tr>
                        <tr>
                            <th>9x9</th>
                            <th>9x9</th>
                        </tr>
                        <tr>
                            <th>8x10</th>
                            <th>10x8</th>
                        </tr>
                        <tr>
                            <th>11x14</th>
                            <th>14x11</th>
                        </tr>
                        <tr>
                            <th>12x12</th>
                            <th>12x12</th>
                        </tr>
                        <tr>
                            <th>12x16</th>
                            <th>16x12</th>
                        </tr>
                        <tr>
                            <th>14x18</th>
                            <th>18x14</th>
                        </tr>
                        <tr>
                            <th>16x16</th>
                            <th>16x16</th>
                        </tr>
                        <tr>
                            <th>16x20</th>
                            <th>20x16</th>
                        </tr>
                        <tr>
                            <th>18x24</th>
                            <th>24x18</th>
                        </tr>
                        <tr>
                            <th>20x20</th>
                            <th>20x20</th>
                        </tr>
                        <tr>
                            <th>20x28 (19.75x27.5 for IKEA)</th>
                            <th>28x20 (27.5x19.75 for IKEA)</th>
                        </tr>
                        <tr>
                            <th>22x28</th>
                            <th>28x22</th>
                        </tr>
                        <tr>
                            <th>24x36 (24x35.75 for IKEA)</th>
                            <th>36x24 (35.75x24 for IKEA)</th>
                        </tr>
                        <tr>
                            <th>27x40</th>
                            <th>40x27</th>
                        </tr>
                    </table>
                </div>

                <br/><br/>

                <LazyLoad>
                    <img src={photo2} className={"blog-content-photo"}/>
                </LazyLoad>

                <br/>

                <div className="blog-article-photo-description">
                    Photo: Consistency is vital for creating minimalist home décor. The key is finding the general colour theme from the furniture and blank wall (or accent wall) colour. The left gallery wall highlights the classic white – which connects the dining chairs, light fixture, wall, and picture frames. The right one builds a harmony of chestnut wood and white colours.

                    <br/><br/>

                    {this.props.blogDirectLinkToMatLayout("Photo Left: 16x20 inch standard sized frames from IKEA being combined with our off-center 16x20 inch matboard with opening for 8x10 inch standard photo prints.", "Link to Matboard Layout", "/customize-matboards?mWidth=16&mHeight=20&wWidth=7.75&wHeight=9.75&mTop=5.125&mLeft=4.125&mRight=4.125&mBottom=5.125&mColor=smoothWhite&dmColor=none&dmWidth=0&backing=false&oval=false&ctr=true")}

                    <br/>

                    {this.props.blogDirectLinkToMatLayout("Photo Right: 20x20 inch standard sized frames from Amazon being combined with our square 20x20 inch matboard with opening for 14x14 inch square photo prints.", "Link to Matboard Layout", "/customize-matboards?mWidth=20&mHeight=20&wWidth=13.75&wHeight=13.75&mTop=3.125&mLeft=3.125&mRight=3.125&mBottom=3.125&mColor=smoothWhite&dmColor=none&dmWidth=0&backing=false&oval=false&ctr=true")}


                </div>

                <br/>

                <b>2. Stay Consistent</b>

                <br/><br/>

                Scandinavian minimalist looks have been trending for two decades and will likely continue. It is extraordinary how much IKEA has transformed our interior design. IKEA's Swedish taste has changed the interior design taste of the world. Stay simple and stick with one tone of colour. You can never go wrong with being classic.

                <br/><br/>

                <LazyLoad>
                    <img src={photo3} className={"blog-content-photo"}/>
                </LazyLoad>

                <br/>

                <div className="blog-article-photo-description">
                    Photo: Vintage looks mainly consist of chestnut, maple, gold, and silver. Combining them with the modern minimalist look, you will a second life to vintage designs. Not just the colours, you can combine the rectangular and square frames with other organic shapes – like circles and ovals. You can even add mirrors to create a reflection point.
                </div>

                <br/>

                <b>3. Switch up shapes and colours with vintage frames.</b>

                <br/><br/>

                When the minimalist look is popular, some people want the other — more traditional, eclectic vintage gallery wall is also gaining popularity.

                <br/><br/>

                Suppose the minimalist look above is there to create a harmony of interior design through similarities. In that case, traditional vintage looks want balance in ornaments.

                <br/><br/>

                Those classic ornamental frames may seem expensive, but you can find those frames at thrift stores. Or you can get gold and chestnut frames (widely used colours) from Amazon or IKEA. At the end of the day, you never really touch your picture frames once they are up. So, colour coordination is the key.

                <br/><br/>

                <LazyLoad>
                    <img src={photo4} className={"blog-content-photo"}/>
                </LazyLoad>
                <LazyLoad>
                    <img src={photo5} className={"blog-content-photo"}/>
                </LazyLoad>

                <br/>

                <div className="blog-article-photo-description">
                    Photo: You can cover your walls by creating a gallery wall with large matting frames. We want to strike the right balance between your home being empty and cluttered. Large matting, hotel-style gallery wall does that.

                    <br/><br/>

                    {this.props.blogDirectLinkToMatLayout("Photo Top Left: 24x35 3/4 (24x35.75) inch standard sized IKEA Ribba frames being combined with our off-center 24x35.75 inch matboard with opening for 14x11 inch standard photo prints.", "Link to Matboard Layout", "/customize-matboards?mWidth=24&mHeight=35.75&wWidth=13.75&wHeight=10.75&mTop=5.125&mLeft=5.125&mRight=5.125&mBottom=19.875&mColor=smoothWhite&dmColor=none&dmWidth=0&backing=false&oval=false&ctr=false")}

                    <br/>

                    {this.props.blogDirectLinkToMatLayout("Photo Top Right: 24x35 3/4 (24x35.75) inch standard sized frames from Amazon being combined with our off-center 24x35.75 inch matboard with opening for 14x11 inch standard photo prints (more matting on top compard to the top left).", "Link to Matboard Layout", "/customize-matboards?mWidth=24&mHeight=35.75&wWidth=13.75&wHeight=10.75&mTop=9&mLeft=5.125&mRight=5.125&mBottom=16&mColor=smoothWhite&dmColor=none&dmWidth=0&backing=false&oval=false&ctr=false")}

                    <br/>

                    {this.props.blogDirectLinkToMatLayout("Photo Bottom Left: 19 3/4 x 27 1/2 (19.75x27.5) inch standard sized IKEA Ribba Frames combined with our off-center 19.75x27.5 inch matboard with opening for 14x11 inch standard photo prints.", "Link to Matboard Layout", "/customize-matboards?mWidth=19.75&mHeight=27.5&wWidth=9.75&wHeight=7.75&mTop=5&mLeft=5&mRight=5&mBottom=14.75&mColor=smoothWhite&dmColor=none&dmWidth=0&backing=false&oval=false&ctr=false")}

                    <br/>

                    {this.props.blogDirectLinkToMatLayout("Photo Right: 20x20 inch standard sized frames from Amazon being combined with our square 20x20 inch matboard with opening for 10x10 inch square photo prints.", "Link to Matboard Layout", "/customize-matboards?mWidth=20&mHeight=20&wWidth=9.75&wHeight=9.75&mTop=5.125&mLeft=5.125&mRight=5.125&mBottom=5.125&mColor=smoothWhite&dmColor=none&dmWidth=0&backing=false&oval=false&ctr=true")}


                </div>

                <br/>

                <b>4. Double up, and triple up with gallery-style frames</b>

                <br/><br/>

                There's some convergence among the vacation rentals (including hotels and Airbnbs) and our homes. As those vacation rentals are becoming more "homey," our homes remind us more of vacation homes. Large bar tables, kitchen islands, and gazebos are all by-products of that.

                <br/><br/>

                Gallery-style wall frames used to be the product of hotel lobbies and high-end Home Rentals. Now they are trending for interior home designs. As average American and Canadian homes get bigger, the wall decor style is also getting more spacious.

                <br/><br/>

                Gallery wall seems overwhelming and expensive, but they are not. You can finish a large wall for under $150, including four frames, prints, and matboards.

                <br/><br/>

                <LazyLoad>
                    <img src={photo6} className={"blog-content-photo"}/>
                </LazyLoad>

                <br/>

                <div className="blog-article-photo-description">
                    Photo: This is an example of a living room decor with a core signature piece. Four smaller pieces with family photos surround the central art piece. It is designed to create harmony with the leading art.

                    <br/><br/>

                    {this.props.blogDirectLinkToMatLayout("Photo Right: 19 3/4 x 19 3/4 (19.75x19.75) inch standard sized frames from IKEA being combined with our square 19.75x19.75 inch matboard with opening for 8x8 inch square photo prints.", "Link to Matboard Layout", "/customize-matboards?mWidth=19.75&mHeight=19.75&wWidth=9.75&wHeight=9.75&mTop=5&mLeft=5&mRight=5&mBottom=5&mColor=smoothWhite&dmColor=none&dmWidth=0&backing=false&oval=false&ctr=true")}
                </div>



                <br/>

                <b>5. Large, Signature Piece</b>

                <br/><br/>

                Sometimes, you need a signature piece or two that backs up your entire space. You can find your favourite prints and fill up your space with them. Simple and large art piece exactly fulfills this purpose. As we say, we want to strike the sweet spot between being empty and cluttered.

                <br/><br/>

                <LazyLoad>
                    <img src={photo7} className={"blog-content-photo"}/>
                </LazyLoad>

                <br/>

                <div className="blog-article-photo-description">
                    Photo: By creating a gallery wall art for your stairs, you can avoid a dull, blank wall around the stairs. You can either create an ascending gallery wall (Left) or a series of gallery walls that level with your top floor (Right). Generally, ascending gallery wall (Left) is recommended for the quarter-turn stairs. Single-level gallery wall (Right) is recommended for straight stairs. Both are trending in the interior design world.
                </div>

                <br/>

                <b>6. Finish your stairs</b>

                <br/><br/>

                    Staircase walls are the most underutilized ones. Many remain empty while we use them multiple times a day. Try to make it a focal point of your home decor. For example, instead of having a blank wall on your staircase, you can put photos of your family and beloved memories on the staircase wall. That way, you celebrate those moments multiple times daily, going up and down the stairs.

                <br/><br/>

                <LazyLoad>
                    <img src={photo8} className={"blog-content-photo"}/>
                </LazyLoad>

                <br/>

                <div className="blog-article-photo-description">
                    Photo: The options have become more affordable than ever with Amazon and IKEA's LED wall lights. They are inexpensive and consume very little electricity.
                </div>

                <br/>

                <b>7. Light it up.</b>

                <br/><br/>

                We cannot emphasize the importance of lighting — they are essential to not just the wall decor but overall home decor. If your space is dark, try to brighten up with the lighting. When you pick your room, always start with lighting, and go from there.

                <br/><br/>

                Creating good lighting for your walls and paintings can impact the room's overall vibe — especially at night. Instead of keeping your space dark, you can keep the art lighting on. The options have become more affordable than ever with Amazon and IKEA's LED wall lights.

                <br/><br/>


                <LazyLoad>
                    <img src={photo9} className={"blog-content-photo"}/>
                </LazyLoad>

                <br/>

                <div className="blog-article-photo-description">
                    Photo: Instead of blending colours, you can introduce some popping colours to your room. That way, you can draw your attention to your happy memories. For example, do you have a nice vacation photo you love? Why don't you have that piece at the center of your space? That way, you get to have more variety in your home decor.
                </div>

                <br/>

                <b>8. Create a High Contrast</b>

                <br/><br/>

                If blending is a key, sometimes you can create an unusual attraction and harmony with the contrasts. Sometimes, opposites attract. High contrast complements each other well and creates a very energetic vibe to your space.

                <br/><br/>

                For instance, if your space is dominant in white and off-white tones, you can try to brighten the room with coloured art pieces. To do this, you have to like the art piece as your work will stand out from the rest.

                <br/><br/>


                <LazyLoad>
                    <img src={photo10} className={"blog-content-photo"}/>
                </LazyLoad>

                <br/>

                <div className="blog-article-photo-description">
                    Photo: Maybe, you already have a lot of colour in your room. If that's the case, you can tone it down with minimalist grayscale. In interior design, you can never go wrong with grayscale artwork. They blend well with wall paint and accent wallpapers.
                </div>

                <br/>

                <b>9. Go Grayscale.</b>

                <br/><br/>

                Remember those Instagram photos you were playing with colours, and they became perfect when you made them black and white? Those moments exist for wall decor as well.

                <br/><br/>

                When your room has a lot of colourful pieces, you want to go black and white or grayscale. You want to create a living room wall art in grayscale to maintain the just right amount of visual interest.

                <br/><br/>

                <LazyLoad>
                    <img src={photo11} className={"blog-content-photo"}/>
                </LazyLoad>

                <br/>

                <div className="blog-article-photo-description">
                    Photo: The entryway should be welcoming. By having a gallery wall of your beloved family members, you can create a gallery wall inspiration with welcoming family members. You don't want it to be cold and empty when you walk into your home. You can create a more welcoming vibe for your home by eliminating the blank wall at the entryway.
                </div>

                <br/>

                <b>10. Orchestrating vibe with functionality</b>

                <br/><br/>

                Let's set the right vibe for your home. You want to make the living room welcoming to you and your family. The office or study room should be quiet yet inspirational. The dining room needs to be relaxing. The doorway should be confidence-boosting for you to have a great day outside.

                <br/><br/>

                Based on your preference, you can find the right art on Online or in Etsy stores. You can order the print online directly. Also, you can save a lot by downloading the copyrights, printing them online, and getting them delivered to your door.

                <br/><br/>

                Here are some artwork inspirations for you:
                <ul>
                    <li>Unsplash.com</li>
                    <li>Etsy</li>
                </ul>

                <br/>

                Here is a list that you can use to print out photos and poster prints:
                <ul>
                    <li>Walmart Photo Center </li>
                    <li>Costco Photo Center</li>
                    <li>Vistaprint.com</li>
                </ul>

                <br/>

                <LazyLoad>
                    <img src={photo12} className={"blog-content-photo"}/>
                </LazyLoad>

                <br/>

                <div className="blog-article-photo-description">
                    Photo: Both left and right is the same home, the same paint colour, and the same living room, yet they radiate a completely different vibe. You can orchestrate a different vibe by switching up the colour and photos.
                </div>

                <br/>

                <b>11. Rearrange what you have already</b>

                <br/><br/>

                True creativity comes out when you have restricted options. And maybe that's what you need if you already have a decent set of frames and signature furniture. But, perhaps, you need some creativity and perspective change.

                <br/><br/>

                Do you have a decent set of frames and article pieces? Maybe all you need is some creativity and a change in perspective. You can also change the artwork of your frames. If the existing frames and mats suit your new artwork, that would be great — if not, you can repurpose them by ordering new matboards for your new artwork size.

                <br/><br/>

                <LazyLoad>
                    <img src={photo13} className={"blog-content-photo"}/>
                </LazyLoad>

                <br/>

                <div className="blog-article-photo-description">
                    Photo: You don't want to drill your wall — especially if your wall is made of tile or limestones. You can achieve the same effect by leaning them on the shelves or floors.
                </div>

                <br/>

                <b>12.Lean on Desk, Fireplace, Shelves, and Bedside Tables</b>

                <br/><br/>

                Let that be a fireplace, window, radiator cover, or bookshelf. If you have a nice wedge to present your artwork, go ahead and do it. It's simple, elegant, and classic.

                <br/><br/>

                <LazyLoad>
                    <img src={photo14} className={"blog-content-photo"}/>
                </LazyLoad>

                <br/>

                <div className="blog-article-photo-description">
                    Photo: The beauty of these Bird and Plant prints is that they give life to your wall and home decor. It may sound exaggerated, but on an unconscious level, you connect the dots and start having those plants and birds animated together in your brain. So I guess it is a natural gallery wall inspiration that you can give to yourself.                </div>
                <br/>

                <b>13. Frame Birds and Plants</b>

                <br/><br/>

                If you have some prints of birds or something similar, that would be great. If not, there are many affordable options on Etsy you can buy for a dollar or two and download. There are a lot of open-source media images for you to use as well.

                <br/><br/>

                You can print them out from the online printing services — or offline printing at Walmart or Costco and get them framed with a standard-sized frame combined with a custom mat board for your print.

                <br/><br/>

                <b>14. Mirrors are the key</b>

                <br/><br/>

                For mirrors, we would strongly recommend you stay simple and classic. Simple white or black colours would be the best. At the end of the day, this article should serve practical needs and should not draw your attention. Nothing in the mirror would set the overall tone of your room decor.
                <br/>


                <LazyLoad>
                    <video className={"what-is-mat-image-v2"} loop="true" autoPlay="autoplay" controls muted
                           src="https://s3.ca-central-1.amazonaws.com/custommat.landing.v2/0_What-is-a-matboard_1920x1290_v1+(1).mp4"></video>
                </LazyLoad>

                <br/><br/>


                <b>What we do</b>

                <br/><br/>

                Here at CustomMat, we specialize in custom mat boards. Matboard is a dense layer of specialty art paper that sits between your frame's glass and artwork.

                <br/><br/>

                Matboards serve to bridge the gap between your artwork and your picture frame. That way, your artwork can fit into any standard-sized picture frames widely available at Amazon or retail stores.

                <br/><br/>

                — How it works —

                <br/>

                Step 1 - Get a standard-sized picture from Amazon or a store.<br/>
                Step 2 - Get a custom matboard from us. Enter dimensions.<br/>
                Step 3 - Easy assembly. In under 2 minutes. Ready to hang.

                <br/><br/>

                If you have any questions, please email us at hello@custommat.ca


                <div className={"visit-custom-mat-button"}>
                    <a href={"/"} className={"carousel-read-more-button"}>
                        Visit Custom Mat
                    </a>
                </div>

                <div className={"carousel-subject-subhead-type2"}>
                    Photo Gallery
                </div>

                <LazyLoad>
                    <img src={photo1} className={"blog-content-photo"}/>
                    <img src={photo2} className={"blog-content-photo"}/>
                    <img src={photo3} className={"blog-content-photo"}/>
                    <img src={photo4} className={"blog-content-photo"}/>
                    <img src={photo5} className={"blog-content-photo"}/>
                    <img src={photo6} className={"blog-content-photo"}/>
                    <img src={photo7} className={"blog-content-photo"}/>
                    <img src={photo8} className={"blog-content-photo"}/>
                    <img src={photo9} className={"blog-content-photo"}/>
                    <img src={photo10} className={"blog-content-photo"}/>
                    <img src={photo11} className={"blog-content-photo"}/>
                    <img src={photo12} className={"blog-content-photo"}/>
                    <img src={photo13} className={"blog-content-photo"}/>
                    <img src={photo14} className={"blog-content-photo"}/>
                </LazyLoad>

                <div className={"visit-custom-mat-button"}>
                    <a href={"/"} className={"carousel-read-more-button"}>
                        Visit Custom Mat
                    </a>
                </div>


            </div>

            <div>
                <div className="elfsight-app-7688ab8e-8d1b-4130-9172-7c7f3e0d45b4"></div>
            </div>

        </div>
    }
}