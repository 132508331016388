import React from 'react';
import RegionFetcher from "../regionFetcher";
import photoWebp from "../../../../resources/images/region/webp/vancouver.webp";
import photoReduced from "../../../../resources/images/region/reduced/vancouver.jpg";
import {Helmet} from "react-helmet";

export default class ShowVancouverPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      cityName: "Vancouver",
      photoWebp: photoWebp,
      photoReduced: photoReduced,
    }
  }

  render() {
    return <div className={`gallery-container`}>
      <Helmet>
        <title>Custom Cut Mat Boards • Order Online • Deliver to Vancouver</title>
        <meta name="description" content="We cut custom mat boards for your picture frames. We tailor mat boards and deliver to Vancouver. Explore ways save on picture framing. Explore ways save on picture framing."/>
      </Helmet>

      <RegionFetcher article={this.state}/>
    </div>
  }
}