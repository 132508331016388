import React from 'react';
import LazyLoad from 'react-lazyload';


import image1 from "../../../../resources/images/gallery/12-articleTwelve/photo1.jpg";
import image2 from "../../../../resources/images/gallery/12-articleTwelve/photo2.jpg";
import image3 from "../../../../resources/images/gallery/12-articleTwelve/photo3.jpg";
import image4 from "../../../../resources/images/gallery/12-articleTwelve/photo4.jpg";
import image5 from "../../../../resources/images/gallery/12-articleTwelve/photo5.png";
import image6 from "../../../../resources/images/gallery/12-articleTwelve/photo6.png";
import image7 from "../../../../resources/images/gallery/12-articleTwelve/photo7.png";
import image8 from "../../../../resources/images/gallery/12-articleTwelve/photo8.jpg";
import image9 from "../../../../resources/images/gallery/12-articleTwelve/photo9.jpg";
import image10 from "../../../../resources/images/gallery/12-articleTwelve/photo10.jpg";
import image11 from "../../../../resources/images/gallery/12-articleTwelve/photo11.jpg";
import {Helmet} from "react-helmet";



export default class BlogArticleEleven extends React.Component {

  constructor() {
    super();
    this.state = {
      penDrawing: false,
    };
  };

  render() {
    return <div className="blog-app-container">

      <Helmet>
        <title>CustomMat.ca - How to create family photo frames on a budget</title>
        <meta name="description" content="If you’re looking for an affordable and easy DIY, you’re in the right place. In this tutorial, not only am I going to show you how you can get oversized mat picture frames for a fraction of the store price, I’m going to give you THREE different templates to help you do it."/>
      </Helmet>

      <div className="carousel-subject-head">
        DIY OVERSIZED MAT & SQUARE FAMILY PHOTO FRAMES
      </div>

      <div className="carousel-subject-subhead-type2">GIFT IDEAS</div>

      <div className="carousel-subject-subhead-type3">Written by Kendra H<br/>
        Project Blogger
        <a
            href="https://www.kendrafoundit.com/blog/gallery-wall-template-oversized-mat-frames-with-ikea-ramsborg">@KendraFoundIt</a>
      </div>

      <LazyLoad>
        <picture>
          <source type="image/webp" srcSet={image1}/>
          <source type="image/png" srcSet={image1}/>
          <img className="blog-carousel-photo" src={image1}
               alt={"This is an illustrative diagram of how a ready made frame can look perfect with a custom cut mat boards."}/>
        </picture>
      </LazyLoad>

      <div className={"blog-article-content"}>
        If you’re looking for an affordable and easy DIY, you’re in the right place.  In this tutorial, not only am I going to show you how you can get oversized mat picture frames for a fraction of the store price, I’m going to give you THREE different templates to help you do it.
        <br/><br/>
        This DIY gift is incredibly easy, sentimental, and very affordable; I’m making them to use as Christmas gifts, but you could easily make these DIY photo frames for birthday gifts, anniversary gifts, wedding gifts…and for any other occasion.
        <br/><br/>
        Bonus: If you love the oversized mat look, be sure to check out my first DIY with Custom Mat, where I created an <a href={"/oversized-matboard-gallery-wall-grid"}>oversized mat gallery wall grid.</a>
        <br/><br/>
        <LazyLoad>
          <picture>
            <source type="image/webp" srcSet={image2}/>
            <source type="image/png" srcSet={image2}/>
            <img className="blog-content-photo" src={image2}
                 alt={"This is an illustrative diagram of how a ready made frame can look perfect with a custom cut mat boards."}/>
          </picture>
        </LazyLoad>
        <br/><br/>
        <b>STEP ONE: CHOOSE YOUR FRAMES</b>
        <br/><br/>
        I chose three different frame sizes and found them at my local Michaels:
        <br/><br/>
        <li><a href={"https://canada.michaels.com/en/basics-multi-purpose-frame-studio-decor/M10353844.html"}>
          <u>8.5x14 Black Thin Multipurpose Frame</u>
        </a></li>
        <li><a href={"https://canada.michaels.com/en/format-frame-basic-by-studio-decor/M10137858.html"}>
          <u>8x8 Basics By Studio Décor Frame</u>
        </a></li>
        <li><a href={"https://canada.michaels.com/en/greige-frame-with-mat-simply-essentials-by-studio-decor/M10413879.html"}>
          <u>8x10 Griege Simply Essentials Frame</u>
        </a></li>
        <br/><br/>
        <LazyLoad>
          <picture>
            <source type="image/webp" srcSet={image3}/>
            <source type="image/png" srcSet={image3}/>
            <img className="blog-content-photo" src={image3}
                 alt={"This is an illustrative diagram of how a ready made frame can look perfect with a custom cut mat boards."}/>
          </picture>
        </LazyLoad>
        <br/><br/>
        If you find frames elsewhere with the same measurements, you can absolutely use those too! If you have a frame that’s a different size, you can enter the dimensions on the <a href={"/customize-matboards"}>Custom Mat design tool</a>  and copy my designs or create your own.
        <br/><br/>
        <b>STEP TWO: ORDER YOUR OVERSIZED MATTING</b>
        <br/><br/>
        I created three different designs using standard picture frames purchased off the shelf:
        <br/><br/>
        1) An 8.5x14 tall, narrow picture frame with oversized matting and a 5x7 photo<br/>
        2) An 8x8 square picture frame with oversized matting and a 4x4 photo<br/>
        3) An 8x10 collage picture frame with two 4x4 square photos
        <br/><br/>

        <LazyLoad>
          <picture>
            <source type="image/webp" srcSet={image4}/>
            <source type="image/png" srcSet={image4}/>
            <img className="blog-content-photo" src={image4}
                 alt={"This is an illustrative diagram of how a ready made frame can look perfect with a custom cut mat boards."}/>
          </picture>
        </LazyLoad>
        <div className={"blog-article-photo-description"}>
          Top: 8x8 Square Frame with Single Window for 4x4 photo.<br/>
          Bottom: 8x10 Frame with Double Window for 5x 7 photo.
        </div>
        <br/><br/>
        Below are the exact measurements and details I used to order the mat boards from Custom Mat. If you’re creating your own design, their easy design tool helps you bring your own ideas to life and does all the mathematics and calculations for you. You can also find a handy measuring guide <a href="https://www.custommat.ca/customize-matboards">here</a>.
        <br/><br/>
        <b>Shipping:</b> Custom Mat’s matting is shipped within 1-2 business days and is delivered to your door, so if you’re a last minute gift-giver like I am, there’s no need to stress.
        <br/><br/>
        <b>Price:</b> Each mat cost me less than $10, but Custom Mat also offers a quantity discount of up to 40% off.  So that means the more mats you purchase (they can be different sizes), the bigger the discount. The best part? They ship to Canada and the USA with free shipping over $49 - you know I love free shipping!
        <br/><br/>

        <LazyLoad>
          <picture>
            <source type="image/webp" srcSet={image5}/>
            <source type="image/png" srcSet={image5}/>
            <img className="blog-content-photo" src={image5}
                 alt={"This is an illustrative diagram of how a ready made frame can look perfect with a custom cut mat boards."}/>
          </picture>
        </LazyLoad>
        <div className={"blog-article-photo-description"}>
          8.5x14 Narrow Frame
        </div>

        <LazyLoad>
          <picture>
            <source type="image/webp" srcSet={image6}/>
            <source type="image/png" srcSet={image6}/>
            <img className="blog-content-photo" src={image6}
                 alt={"This is an illustrative diagram of how a ready made frame can look perfect with a custom cut mat boards."}/>
          </picture>
        </LazyLoad>
        <div className={"blog-article-photo-description"}>
          8x8 Square Frame
        </div>

        <LazyLoad>
          <picture>
            <source type="image/webp" srcSet={image7}/>
            <source type="image/png" srcSet={image7}/>
            <img className="blog-content-photo" src={image7}
                 alt={"This is an illustrative diagram of how a ready made frame can look perfect with a custom cut mat boards."}/>
          </picture>
        </LazyLoad>

        <div className={"blog-article-photo-description"}>
          8x10 Square Picture Collage
        </div>

        <br/><br/>
        <b>STEP THREE: ORDER YOUR PHOTOS & SWAP OUT THE MATS</b>
        <br/><br/>
        I used 4x4 (great for framing Instagram photos) and 5x7 photos that I printed at my local drugstore. My custom matting arrived within 1 business day of ordering it, so as soon as it arrived, I was ready to complete my project. All I had to do was swap out the matting to see the instant transformation that oversized matting can make.
        <br/><br/>

        <LazyLoad>
          <picture>
            <source type="image/webp" srcSet={image8}/>
            <source type="image/png" srcSet={image8}/>
            <img className="blog-content-photo" src={image8}
                 alt={"This is an illustrative diagram of how a ready made frame can look perfect with a custom cut mat boards."}/>
          </picture>
        </LazyLoad>

        <br/><br/>
        I am so happy with this DIY (can I even call it a DIY?!) family photo project.  The oversized, custom mats really elevate the simple and affordable frames to give them an elegant, classic look; I can’t wait to surprise my friends and family by giving them custom framed gifts.  Perhaps I’ll even find one addressed to me from Santa because I want one for myself now!
        <br/><br/>

        <a href="/customize-matboards">
          <div className="product-listing-action-button">Start Designing</div>
          <br/><br/>
        </a>

        <div className="carousel-subject-subhead-type2">Photo Gallery</div>

        <LazyLoad>
          <picture>
            <source type="image/webp" srcSet={image9}/>
            <source type="image/png" srcSet={image9}/>
            <img className="blog-content-photo" src={image9}
                 alt={"This is an illustrative diagram of how a ready made frame can look perfect with a custom cut mat boards."}/>
          </picture>
          <picture>
            <source type="image/webp" srcSet={image10}/>
            <source type="image/png" srcSet={image10}/>
            <img className="blog-content-photo" src={image10}
                 alt={"This is an illustrative diagram of how a ready made frame can look perfect with a custom cut mat boards."}/>
          </picture>
          <picture>
            <source type="image/webp" srcSet={image11}/>
            <source type="image/png" srcSet={image11}/>
            <img className="blog-content-photo" src={image11}
                 alt={"This is an illustrative diagram of how a ready made frame can look perfect with a custom cut mat boards."}/>
          </picture>
          <picture>
            <source type="image/webp" srcSet={image11}/>
            <source type="image/png" srcSet={image11}/>
            <img className="blog-content-photo" src={image11}
                 alt={"This is an illustrative diagram of how a ready made frame can look perfect with a custom cut mat boards."}/>
          </picture>
        </LazyLoad>

        <a href="/customize-matboards">
          <div className="product-listing-action-button">Start Designing</div>
          <br/><br/>
        </a>






      </div>




    </div>
  }
}