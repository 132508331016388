import React from "react";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import singleMat from "../../../../resources/images/diplomaMats/1.SingleMat.png";

import singleMatPlain from "../../../../resources/images/diplomaMats/Single Matting.png";
import singleMatText from "../../../../resources/images/diplomaMats/Single Matting Text.png";
import singleMatLogo from "../../../../resources/images/diplomaMats/Single Matting with Logo.png";
import singleMatLogoAndText from "../../../../resources/images/diplomaMats/Single Matting Text and Logo.png";

import singleMatVerticalPlain from "../../../../resources/images/diplomaMats/Single Matting Verticial.png";
import singleMatVerticalText from "../../../../resources/images/diplomaMats/Single Matting Vertical Text.png";
import singleMatVerticalLogo from "../../../../resources/images/diplomaMats/Single Matting Vertical Logo.png";
import singleMatVerticalLogoAndText from "../../../../resources/images/diplomaMats/Single Matting Vertical Text and Logo.png";

import doubleMatPlain from "../../../../resources/images/diplomaMats/Double Matting.png";
import doubleMatText from "../../../../resources/images/diplomaMats/Double Matting Text.png";
import doubleMatLogo from "../../../../resources/images/diplomaMats/Double Matting Logo Only.png";
import doubleMatLogoAndText from "../../../../resources/images/diplomaMats/Double Matting Logo and Text.png";

import doubleMatVerticalPlain from "../../../../resources/images/diplomaMats/Double Matting Vertical.png";
import doubleMatVerticalText from "../../../../resources/images/diplomaMats/Double Matting Vertical Text.png";
import doubleMatVerticalLogo from "../../../../resources/images/diplomaMats/Double Matting Logo Only.png";
import doubleMatVerticalLogoAndText from "../../../../resources/images/diplomaMats/Double Matting Logo and Text.png";


import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import {MenuItem} from "@material-ui/core";
import displayInputFields from "../../app-matsize-rendering/components/displayInputFields";
import arrowUp from "../../../../resources/svgIcons/select-mat-arrow-up.svg";
import arrowDown from "../../../../resources/svgIcons/select-mat-arrow-down.svg";
import localStorageFunctions from "../../comp-localStorage/localStroage";
import {Helmet} from "react-helmet";
import {productDB} from "../../comp-pricing/productDB";
import Tooltip from "@material-ui/core/Tooltip";
import warningIcon from "../../../../resources/svgIcons/warning-icon.svg";
import TextField from "@material-ui/core/TextField";
import frameCssRendering from "../../app-matsize-rendering/components/artWindowOpening/frameCssRendering";
import ShowMattingWindows from "../../app-matsize-rendering/components/artWindowOpening/showWindows";
import walnutFrame from "../../../../resources/images/frameDetailedPhotos/frameCategoryColours/walnut.png";
import updateWindowFunctions from "../../app-matsize-rendering/components/artWindowOpening/updateWindowFunctions";

export default class SingleDiplomaMat extends React.Component {

    constructor() {
        super();
        this.state = {
            singleMat: {
                img: singleMat,
                img2: singleMat,
                default: "4x6",
                width: 5,
                height: 7,
                desc: 'Single Mat',
                descDetail: '5x7" Matboards for 5x7" Frames with choice of interior dimensions',
                priceCAD: 7.95,
                href: "/single-mat-diploma-frame-matboard",
            },
            frameSizeAvailable: {
                "8x10": {w:8, h:10},
                "10x8": {w:10, h:8},
                "11x14": {w:11, h:14},
                "12x16": {w:12, h:16},
                "14x11": {w:14, h:11},
                "14x18": {w:14, h:18},
                "16x20": {w:16, h:20},
                "18x14": {w:18, h:14},
                "18x24": {w:18, h:24},
                "19.75x27.5": {w:19.5, h:27.5, note: "(Available @ Amazon & IKEA Only)"},
                "20x16": {w:20, h:16},
                "20x28": {w:20, h:28},
                "24x18": {w:24, h:18},
                "24x35.75": {w:24, h:35.75, note: "(Available @ Amazon & IKEA Only)"},
                "24x36": {w:24, h:36},
                "27.5x19.75": {w:27.5, h:19.75, note: "(Available @ Amazon & IKEA Only)"},
                "28x20": {w:28, h:20},
                "35.75x24": {w:35.75, h:24, note: "(Available @ Amazon & IKEA Only)"},
                "36x24": {w:36, h:24},
            },
            frameSizeSelected: null,
            selectedQuantity: 1,
            fetchDiscountDetail: false,
            fetchShippingInfo: false,
            frameWidth: 18,
            frameHeight: 14,
            renderingStandardLength: 18,
            windowWidth: 14,
            windowHeight: 11,
            renderingToolWidth: 400, //400px
            renderingToolHeight: 400, // 400px
            selectedFrame: "",
            deviceType: "web",
            diplomaWidth: 10,
            diplomaHeight: 8,
            openDiplomaSizeModal: true,
            knowFrameSize: null,
            openFrameSizeModal: true,
            openFrameOptionsModal: true,
            colorModalOpen: true,
            matColour: "navyBlue",
            openSecondMattingModal: false,
            secondMatColour: "none",
            secondMatWidth: 0,
            secondMatPrice: 4.95,
            textAdditionPrice: 9.95,
            logoAdditionPrice: 14.95,
            textAddition: false,
            logoAdd: false,
            french: false,
            showAddNoteModal: false,
            additionalNoteContent: "",
            showRenderingTool: false,
            highlightPhoto: singleMatPlain,
        };
    };

    componentDidMount() {
        // windowaddEventListener("scroll", this.setState({showNotificationBanner: false})
        window.scrollTo(0, 0);
        this.updateWindowDimensions();
        this.setState({
            secondMatWidth: this.props.doubleMatting ? 0.25 : 0,
            secondMatColour: this.props.doubleMatting ? "gold" : "none",
            openSecondMattingModal: this.props.doubleMatting,
            textAddition: this.props.text,
            logoAdd: this.props.logo,
            showAddNoteModal: (this.props.text || this.props.logo) ? true : false,
        }, () => { this.selectHighlightedPhoto()})

    };

    selectHighlightedPhoto() {
        var highlightedPhoto;
        if (this.state.secondMatColour !== "none") {
            if (this.state.textAddition == true && this.state.logoAdd == true) {
                highlightedPhoto = doubleMatLogoAndText;
                this.setState({highlightPhoto: highlightedPhoto});
            } else if (this.state.textAddition == true) {
                highlightedPhoto = doubleMatText;
                this.setState({highlightPhoto: highlightedPhoto});
            } else if (this.state.logoAdd == true) {
                highlightedPhoto = doubleMatLogo;
                this.setState({highlightPhoto: highlightedPhoto});
            } else {
                highlightedPhoto = doubleMatPlain;
                this.setState({highlightPhoto: highlightedPhoto});
            }
        } else {
            if (this.state.textAddition == true && this.state.logoAdd == true) {
                highlightedPhoto = singleMatLogoAndText;
                this.setState({highlightPhoto: highlightedPhoto});
            } else if (this.state.textAddition == true) {
                highlightedPhoto = singleMatText;
                this.setState({highlightPhoto: highlightedPhoto});
            } else if (this.state.logoAdd == true) {
                highlightedPhoto = singleMatLogo;
                this.setState({highlightPhoto: highlightedPhoto});
            } else {
                highlightedPhoto = singleMatPlain;
                this.setState({highlightPhoto: highlightedPhoto});
            }
        }
    }

    updateRenderingStandardLength() {
        var stdLength = this.state.frameWidth > this.state.frameHeight ? this.state.frameWidth : this.state.frameHeight;
        this.setState({renderingStandardLength: stdLength, showRenderingTool: true})
    };

    updateWindowModalState(modalType, windowKey) {
        var stateToBeUpdated = {...this.state.window};
        var windowId = `window${windowKey + 1}`;
        stateToBeUpdated[windowId][modalType] = !this.state.window[windowId][modalType];
        this.updateComponentStateCallBack(window, stateToBeUpdated);
    };

    updateComponentStateCallBack(dictKey, dictValue) {
        this.setState({[dictKey]: dictValue});
    };

    moveWindowCallBack(direction, windowId, windowCssRenderingValues, changedMattingNewValue, frame) {
        var stateToBeUpdated = {...this.state.window};
        windowId = `window${windowId + 1}`;
        this.updateComponentStateCallBack(window, updateWindowFunctions(stateToBeUpdated, this.state.window, direction, windowId, changedMattingNewValue, windowCssRenderingValues, frame));
    };

    fetchMatboardColours(primaryOrSecondaryMat) {
        var matboardList = Object.keys(productDB.matboards);

        return matboardList.map((mat, key) => {
            return <div className={productDB.matboards[mat].active ? "matboard-selected-colour-container" : "no-display"} key={`matboard-selected-colour-container-${key}`}>
                <Tooltip title={productDB.matboards[mat].color} placement={"top"}>
                    <img alt={`Selected matboard colour is -- ${productDB.matboards[mat].color}`}
                         className={ mat === this.state[primaryOrSecondaryMat] ? "matboard-colour matboard-colour-selected" : "matboard-colour"}
                         src={productDB.matboards[mat].img}
                         onClick={() => {this.setState({[primaryOrSecondaryMat]: mat, showRenderingTool: true})}}/>
                </Tooltip>
                {/*<div className={mat === this.state[primaryOrSecondaryMat] ? "matboard-selector-checkmark-container" : "no-display"}>*/}
                {/*  <img src={checkMark} alt={`This is a checkmark indicating ${productDB.matboards[mat].color} has been selected.`}/>*/}

            </div>
        })
    };

    updateWindowDimensions() {
        if (window.innerWidth >= 767 && window.innerWidth <= 1039) {
            //if tablet
            this.setState({
                renderingToolWidth: 480, //60vw
                deviceType: "tablet",
            })
        } else if (window.innerWidth <= 767) {
            //if mobile
            this.setState({
                renderingToolWidth: 480,
                deviceType: "mobile",
            })
        } else {
            this.setState({
                renderingToolWidth: 480, //480px
                deviceType: "web",
            })
        }
    };

    fetchSecondMatColourDesc() {
        if (productDB.matboards[this.state.secondMatColour]) {
            return `${this.props.french ? productDB.matboards[this.state.secondMatColour].colorFr: productDB.matboards[this.state.secondMatColour].color}: ${this.state.french ? productDB.matboards[this.state.secondMatColour].descFr: productDB.matboards[this.state.secondMatColour].desc}`

        }
    };

    showSelectedSecondMatColour() {
        if (productDB.matboards[this.state.secondMatColour]) {
            return this.props.french ? productDB.matboards[this.state.secondMatColour].colorFr : productDB.matboards[this.state.secondMatColour].color;
        } else {
            return "";
        }

    };

    matboardOnlyPricingCalculator(frameWidth, frameHeight, priceOrShippingCost, secondMatPrice) {
        frameWidth = parseFloat(frameWidth);
        frameHeight = parseFloat(frameHeight);

        if (frameWidth * frameHeight <= (10 * 10)) {
            //small enough parcel that fits into Canadian legal mailing
            return priceOrShippingCost === "price" ? productDB.matboardOnlyPricing.xSmall.price : productDB.matboardOnlyPricing.xSmall.shippingCosts;
        } else if (frameWidth * frameHeight <= (13 * 10)) {
            //canadapost or UPS
            return priceOrShippingCost === "price" ? productDB.matboardOnlyPricing.small.price : productDB.matboardOnlyPricing.small.shippingCosts;
        } else if (frameWidth * frameHeight <= (14.1 * 11.1)) {
            //canadapost or UPS
            return priceOrShippingCost === "price" ? productDB.matboardOnlyPricing.small1.price : productDB.matboardOnlyPricing.small1.shippingCosts;
        } else if (frameWidth * frameHeight <= (16.1 * 12.1)) {
            //canadapost or UPS
            return priceOrShippingCost === "price" ? productDB.matboardOnlyPricing.small2.price : productDB.matboardOnlyPricing.small2.shippingCosts;
        } else if (frameWidth * frameHeight <= (14.1 * 18.1)) {
            //canadapost or UPS
            return priceOrShippingCost === "price" ? productDB.matboardOnlyPricing.medium1.price : productDB.matboardOnlyPricing.medium1.shippingCosts;
        } else if (frameWidth * frameHeight <= (16.1 * 16.1)) {
            //canadapost or UPS
            return priceOrShippingCost === "price" ? productDB.matboardOnlyPricing.medium2.price : productDB.matboardOnlyPricing.medium2.shippingCosts;
        } else if (frameWidth * frameHeight <= (16.1 * 20.1)) {
            //canadapost or UPS
            return priceOrShippingCost === "price" ? productDB.matboardOnlyPricing.medium3.price : productDB.matboardOnlyPricing.medium3.shippingCosts;
        } else if (frameWidth * frameHeight <= (20.1 * 20.1)) {
            //canadapost or UPS
            return priceOrShippingCost === "price" ? productDB.matboardOnlyPricing.large1.price : productDB.matboardOnlyPricing.large1.shippingCosts;
        } else if (frameWidth * frameHeight <= (20.1 * 28.1)) {
            //canadapost or UPS
            return priceOrShippingCost === "price" ? productDB.matboardOnlyPricing.large2.price : productDB.matboardOnlyPricing.large2.shippingCosts;
        } else if (frameWidth * frameHeight <= (24.1 * 30.1)) {
            //canadapost or UPS
            return priceOrShippingCost === "price" ? productDB.matboardOnlyPricing.xlarge1.price : productDB.matboardOnlyPricing.xlarge1.shippingCosts;
        } else if (frameWidth * frameHeight <= (24.1 * 36.1)) {
            //canadapost or UPS
            return priceOrShippingCost === "price" ? productDB.matboardOnlyPricing.xlarge2.price : productDB.matboardOnlyPricing.xlarge2.shippingCosts;
        } else {
            //canadapost or UPS
            return priceOrShippingCost === "price" ? productDB.matboardOnlyPricing.xlarge3.price : productDB.matboardOnlyPricing.xlarge3.shippingCosts;
        }
    };

    clickAddToCartButton() {
        var certMatPremium = 2;
        //charging extra two dollars for certificate matborad

        var price = this.matboardOnlyPricingCalculator(this.state.frameWidth, this.state.frameHeight, "price");

        price = price + certMatPremium;

        var item = {
            prodType: "certMat",
            frameType: "Not Applicable -- Matboard Only",
            frameSize: "Not Applicable -- Matboard Only",
            frameWidth: this.state.frameWidth,
            frameHeight: this.state.frameHeight,
            matColour: this.state.matColour,
            window: {
                rowCount: 1,
                columnCount: 1,
                window1: {
                    width: this.state.diplomaWidth - 0.25,
                    height: this.state.diplomaHeight - 0.25,
                    marginTop: (this.state.frameHeight - this.state.diplomaHeight)/2,
                    marginBottom: (this.state.frameHeight - this.state.diplomaHeight)/2,
                    marginLeft: (this.state.frameWidth - this.state.diplomaWidth - 0.25)/2,
                    marginRight: (this.state.frameWidth - this.state.diplomaWidth - 0.25)/2,
                    showBackgroundImageModal: false,
                    showModal: false,
                },
            },
            secondMatPrice: this.getSecondMatPricing() ? this.getSecondMatPricing() : 0,
            textAdditionPrice: this.state.textAddition ? this.state.textAdditionPrice : 0,
            logoAdditionPrice: this.state.logoAdd ? this.state.logoAdditionPrice : 0,

            secondMatColour: this.state.secondMatColour,
            secondMatWidth: this.state.secondMatWidth,
            orderQuantity: parseFloat(this.state.selectedQuantity),
            // //for matboard price, we will have to run a pricing mechanism for matboard pricing. We might borrow this from our legacy system.
            price: price,
            shipping: [9.95, 9.95],
            canvasDrawing: null,
            additionalNoteContent: this.state.additionalNoteContent,
            backingMatboard: false,
            isWindowOval: false,
            textAddition: this.state.textAddition,
            logoAdd: this.state.logoAdd,
        };

        this.addProductToLocalStorageCart(item);
        this.props.showShoppingCartModal("open");
    };

    addProductToLocalStorageCart(item) {
        //check if a shopper has already shopped something on before this item
        var shoppingCart = [];

        if (localStorageFunctions("CJ92A38CCJXAE8C379C6F79758BQJXB88D14FEJKB", null) !== null ) {
            //this part will have to be parse json

            shoppingCart = JSON.parse(localStorageFunctions("CJ92A38CCJXAE8C379C6F79758BQJXB88D14FEJKB", null));

            shoppingCart.push(item);
        } else {
            shoppingCart.push(item);
        };

        localStorageFunctions("CJ92A38CCJXAE8C379C6F79758BQJXB88D14FEJKB", JSON.stringify(shoppingCart));
    };

    toggleModal(stateToToggle) {
        this.setState({[stateToToggle]: !this.state[stateToToggle]})
    };

    clickPrecutModal(sortSize) {
        this.setState({selectedFrameSize: sortSize})
    };

    showSelectedSecondMatColourPicture() {
        return <picture>
            <source type="image/webp" srcSet={productDB.matboards[this.state.secondMatColour] ? productDB.matboards[this.state.secondMatColour].webp : ""}/>
            <source type="image/png" srcSet={productDB.matboards[this.state.secondMatColour] ? productDB.matboards[this.state.secondMatColour].img : ""}/>
            <Tooltip title={productDB.matboards[this.state.secondMatColour] ? productDB.matboards[this.state.secondMatColour].color : ""} placement={"top"}>
                <img alt={"Click to add window."} className={"matboard-selected-colour"} src={productDB.matboards[this.state.secondMatColour] ? productDB.matboards[this.state.secondMatColour].webp : ""} />
            </Tooltip>
        </picture>

    };

    activateOrDeactivateSecondMatting() {
        if (this.state.secondMatWidth > 0) {
            this.setState({secondMatWidth: 0});
            this.setState({secondMatColour: "none"}, () => {this.selectHighlightedPhoto()});

        } else {
            this.setState({secondMatWidth: 0.25});
            this.setState({secondMatColour: "smoothWhite"}, () => {this.selectHighlightedPhoto()})
            this.selectHighlightedPhoto()
        }

    };

    fetchWindowSizeDropdown(dropDownList, title, stateToUpdate) {

        var list = Object.keys(this.state.frameSizeAvailable);
        var dict = this.state.frameSizeAvailable;

        return <FormControl
            variant="outlined" className={"precut-window-selector"}>
            <div className={"add-text-fields-text-field-title"}>
                {this.props.french ? "Taille de Cadre Standard Disponible" : "Standard Frame Size Available"}
            </div>
            <Select value={this.state.frameSizeSelected}
                    onChange={(evt) => {
                        this.setState({frameSizeSelected: evt.target.value, frameWidth: dict[evt.target.value].w, frameHeight: dict[evt.target.value].h}, () => {this.updateRenderingStandardLength()})
                    }}
            >
                <MenuItem value={"Select"}>
                    Select
                </MenuItem>
                {
                    list.map((item, key) => {
                        if (dict[item].w > parseFloat(this.state.diplomaWidth) + 1.9 && dict[item].h > parseFloat(this.state.diplomaHeight) + 2) {

                                // console.log(parseFloat(dict[oneSide]))
                                return <MenuItem value={item} key={`diploma-frame-size-${key}`} >
                                    {item} inch {dict[item].note ? dict[item].note : ""}
                                </MenuItem>
                        }
                    })
                }
            </Select>
        </FormControl>
    };

    fetchListDropDown(dropDownList, title, stateToUpdate) {
        var list = dropDownList;

        return <FormControl
            variant="outlined" className={"precut-window-selector"}>
            <div className={"add-text-fields-text-field-title"}>
                {title}
            </div>
            <Select value={this.state[stateToUpdate]}
                    onChange={(evt) => {
                        this.setState({[stateToUpdate]: evt.target.value})
                    }}
            >
                <MenuItem value={"Select"}>
                    Select
                </MenuItem>
                {
                    list.map((item, key) => {
                        return <MenuItem value={item} key={`select-province-state-${key}`} >
                            {this.state.matboardColourOptions[item].colourName}
                        </MenuItem>
                    })
                }
            </Select>
        </FormControl>
    };

    reduceQuantity() {
        if (this.state.selectedQuantity > 1) {
            this.setState({selectedQuantity: this.state.selectedQuantity - 1})
        } else {
            alert("Order quantity cannot be less than 1.")
        }

    };

    fetchAddToBagButton() {
        return <div className="add-to-bag-button" style={{width: "auto"}} onClick={() => {this.clickAddToCartButton()}}>
            {this.props.french ? "Ajouter au sac" : "Add to bag"}
        </div>
    };

    fetchSelectedMatColor() {
        if (productDB.matboards[this.state.matColour]) {
            return this.props.french ? productDB.matboards[this.state.matColour].colorFr : productDB.matboards[this.state.matColour].color;
        } else {
            return "";
        }
    };

    showAddNoteModal() {
        return <div className={(this.state.knowFrameSize !== null) ? "add-matboard-note-section " : "no-display"}>

            <div className={"select-mat-row"}>
                <img className={"select-matboard-window-caption-arrow"}
                     onClick={() => this.setState({showAddNoteModal: !this.state.showAddNoteModal})}
                     src={this.state.showAddNoteModal ? arrowUp : arrowDown}
                     alt={this.state.french ? "Cliquez pour ajouter une note." : "Click to add a note."}
                />

                <div className="select-matboard-window-caption-text">
                    {this.props.french ? "NOTES SUPPLÉMENTAIRES" : "Additional Notes"}
                </div>
            </div>


            <div className={this.state.showAddNoteModal ? "select-mat-row-divider add-border-top" : "no-display"}>
                <div className={"backing-mat-instructions additional-notes-add-margin"}>
                    {this.props.french ? "Nous les lisons attentivement avant la production." : "We read these carefully before production."}
                </div>

                <div className="select-mat-input-field-direction">
                    Notes
                </div>
                <TextField
                    className="add-matboard-note-section-input-field"
                    id="outlined-multiline-static"
                    label=""
                    multiline
                    // rows="10"
                    variant="outlined"
                    placeholder={this.props.french ? "Veuillez nous faire part de vos instructions." : "Please let us know."}
                    value={this.state.additionalNoteContent}
                    onChange={(evt) => this.setState({additionalNoteContent: evt.target.value})}
                />

            </div>

            {/*<div className={"drawing-app-saving-status"}>{this.state.additionalNoteContent !== "" ? "Saved" : null}</div>*/}
        </div>
    };

    fetchProductPriceTab() {
        return <div>
            <div style={{width: "auto", overflow: "auto", paddingBottom: "16px"}}>

                {this.fetchAddToBagButton()}

                <div className="select-mat-container-row" style={{width: "auto", cursor: "pointer"}}>
                    {this.props.french ? "Devise:" : "Currency:"}
                    <span className={this.props.currency === "CA$" ? "bold" : ""} onClick={() => {this.props.changeCurrency("CA$")}}> CAD </span>
                    |
                    <span className={this.props.currency === "US$" ? "bold" : ""} onClick={() => {this.props.changeCurrency("US$")}}> USD </span>
                    <br/>
                    {this.props.french ? "Prix Unitaire: " : "Unit Price: "}<b>$<span>{this.props.fxConversion(this.calculateItemPrice().toFixed(2)).toFixed(2)}</span></b>
                </div>

            </div>

            <div className={"precut-size-header"}>
                {this.props.french ? "Passe-partout pour Diplômes et Certificats" : "Diploma & Certificate Matboard"}
            </div>

        </div>
    };

    fetchDiplomaFrameOptions() {
        return  <div className={"multiple-window-artwork-size-selection-panel"} >

            <div className="select-mat-sub-row">

                <img className={"select-matboard-window-caption-arrow add-margin-buffer-5px"}
                     id={"window-options-container-mobile"}
                     onClick={() => {this.setState({openFrameSizeModal: !this.state.openFrameSizeModal})}}
                     alt="Expand for more options."
                     src={this.state.openFrameSizeModal ? arrowUp : arrowDown}/>

                <div>
                    <div className="select-matboard-window-caption-text matboard-colour-name-desc" id={"select-matboard-window-sizing-mobile"}>
                        {this.props.french ? "Options de Cadre" : "Frame Options"}
                    </div>

                    <div className={this.state.knowFrameSize ? "select-matboard-window-caption-selected-colour-text" : "no-display"}>
                        <div className={"matboard-colour-name matboard-colour-name-desc"} id={"matboard-window-size-mobile"}>
                            {/*/!*<span className={this.state.openBackingMatModal || (this.props.backingMatboard) ? "no-display" : "display"}>+${this.props.backingMatboardPrice}</span>*!/*/}
                            {/*{(this.props.windowCount > 1) ? `${this.props.windowCount} Windows` : null}*/}
                            {this.state.frameWidth} <span> x </span> {this.state.frameHeight} {this.props.french ? "po" : "in"}
                        </div>
                    </div>
                </div>

                <div className={this.state.openFrameSizeModal ? "select-mat-row-divider" : "no-display"}>

                    <div className="select-mat-sub-row diploma-mat" style={{marginBottom: "12px"}}>
                        {this.props.french ? "Avez-vous un cadre pour votre diplôme?" : "Do you have a frame for your diploma?"}
                    </div>

                    <div style={{width: "100%", overflow: "auto"}} className={"diploma-checkbox"}>

                        <div style={{width: "auto", overflow: "auto", float: "left"}}>
                            <div className={"select-matboard-window-caption-arrow add-margin-buffer-5px select-box-outside"}
                                 onClick={() => this.setState({knowFrameSize: true}, () => { window.scrollTo(0, 0)})}>
                                <div className={this.state.knowFrameSize == true ? "box-selected" : "box-unselected"}></div>
                            </div>
                            <div className="select-matboard-window-caption-text matboard-colour-name-desc" id={"backing-mat-price-and-colour-container-2"}>
                                {this.props.french ? "Oui" : "Yes"}
                            </div>

                        </div>


                        <div style={{width: "auto", overflow: "auto", float: "left", marginLeft: "20px"}}>
                            <div className={"select-matboard-window-caption-arrow add-margin-buffer-5px select-box-outside"}
                                 onClick={() => this.setState({knowFrameSize: false}, () => { window.scrollTo(0, 0)})}>
                                <div className={this.state.knowFrameSize == false ? "box-selected" : "box-unselected"}></div>

                            </div>

                            <div className="select-matboard-window-caption-text matboard-colour-name-desc" id={"backing-mat-price-and-colour-container-2"}>
                                {this.props.french ? "Non" : "No"}
                            </div>
                        </div>
                    </div>

                    <div className={this.state.knowFrameSize ? "display" : "no-display"}>
                        <div className="select-mat-sub-row" style={{margin: "12px 0"}}>
                            {this.props.french ? "Quelle est la taille de votre cadre?" : "What is your frame size?"}
                            What is your frame size?
                        </div>

                        <div className={"select-mat-sub-row"}>

                            <div className="select-window-multiple-window-dimensions" style={{width: "45%"}}>
                                <div className="select-mat-input-field-direction">
                                    {this.props.french ? "Largeur" : "Width"}
                                </div>
                                {displayInputFields("", this.state.frameWidth, (event) => this.setState({frameWidth: event.target.value}, () => this.updateRenderingStandardLength()), "outlined")}
                            </div>

                            <div className={"select-mat-window-quantity-row-column-transition"} style={{width: "10%", padding: "25px 0 0 0"}}>
                                x
                            </div>

                            <div className="select-window-multiple-window-dimensions" style={{width: "45%"}}>
                                <div className="select-mat-input-field-direction">
                                    <div className="select-mat-input-field-direction">
                                        {this.props.french ? "Hauteur" : "Height"}
                                    </div>
                                </div>
                                {displayInputFields("", this.state.frameHeight, (event) => this.setState({frameHeight: event.target.value},() => this.updateRenderingStandardLength()) , "outlined")}
                            </div>
                            <div className="select-mat-input-field-direction-long">
                                {this.props.french ? "Mesures partielles utilisant des décimales (par exemple : 19,75)." : "Partial measurements using decimals (ie: 19.75)."}
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    };

    fetchDiplomaSizeWindowOptions() {
        return <div className={this.state.knowFrameSize !== null ? "multiple-window-artwork-size-selection-panel" : "no-display"}>

            <div className="select-mat-sub-row">

                <img className={"select-matboard-window-caption-arrow add-margin-buffer-5px"}
                     id={"window-options-container-mobile"}
                     onClick={() => {this.setState({openDiplomaSizeModal: !this.state.openDiplomaSizeModal})}}
                     alt="Expand for more options."
                     src={this.state.openDiplomaSizeModal ? arrowUp : arrowDown}/>

                <div className="select-matboard-window-caption-text matboard-colour-name-desc" id={"select-matboard-window-sizing-mobile"}>
                    {this.props.french ? "Taille du Document" : "Document Size"}
                </div>

                <div className="select-matboard-window-caption-selected-colour-text">
                    <div className={"matboard-colour-name matboard-colour-name-desc diploma-mat"} id={"matboard-window-size-mobile"}>
                        {/*/!*<span className={this.state.openBackingMatModal || (this.props.backingMatboard) ? "no-display" : "display"}>+${this.props.backingMatboardPrice}</span>*!/*/}
                        {/*{(this.props.windowCount > 1) ? `${this.props.windowCount} Windows` : null}*/}
                        {this.state.diplomaWidth} <span> x </span> {this.state.diplomaHeight} {this.props.french ? "po" : "in"}
                    </div>
                </div>

                <div className={"select-mat-row-divider"}>


                    <div className={this.state.openDiplomaSizeModal ? "select-mat-sub-row" : "no-display"}>

                        <div className="select-mat-sub-row" style={{margin: "12px 0"}}>
                            {this.props.french ? "Quelle est la taille de votre document? (en pouces)" : "What is the size of your document? (in inches)"}
                        </div>


                        <div className="select-window-multiple-window-dimensions" style={{width: "45%"}}>
                            <div className="select-mat-input-field-direction">
                                {this.props.french ? "Largeur" : "Width"}
                            </div>
                            {displayInputFields("", this.state.diplomaWidth, (event) => this.setState({diplomaWidth: event.target.value}), "outlined")}
                        </div>

                        <div className={"select-mat-window-quantity-row-column-transition"} style={{width: "10%", padding: "25px 0 0 0"}}>
                            x
                        </div>

                        <div className="select-window-multiple-window-dimensions" style={{width: "45%"}}>
                            <div className="select-mat-input-field-direction">
                                <div className="select-mat-input-field-direction">
                                    {this.props.french ? "Hauteur" : "Height"}

                                </div>
                            </div>
                            {displayInputFields("", this.state.diplomaHeight, (event) => this.setState({diplomaHeight: event.target.value}), "outlined")}
                        </div>
                        <div className="select-mat-input-field-direction-long">
                            {this.props.french ? "Mesures partielles utilisant des décimales (par exemple : 19,75)." : "Partial measurements using decimals (ie: 19.75)."}
                        </div>
                    </div>
                </div>






            </div>
        </div>
    };

    fetchFrameSizeOptions() {
        if (this.props.french) {
            return <div className={(this.state.knowFrameSize === false) ? "multiple-window-artwork-size-selection-panel" : "no-display"}>

                <div className="select-mat-sub-row">

                    <img className={"select-matboard-window-caption-arrow add-margin-buffer-5px"}
                         id={"window-options-container-mobile"}
                         onClick={() => {this.setState({openFrameOptionsModal: !this.state.openFrameOptionsModal})}}
                         alt="Expand for more options."
                         src={this.state.openFrameOptionsModal ? arrowUp : arrowDown}/>

                    <div className="select-matboard-window-caption-text matboard-colour-name-desc" id={"select-matboard-window-sizing-mobile"}>
                        Taille du Cadre
                    </div>


                    <div className={"select-mat-row-divider"}>
                        <div className={this.state.openFrameOptionsModal ? "display" : "no-display"}>
                            <div className="select-mat-sub-row" style={{margin: "12px 0"}}>
                                Veuillez choisir une taille de cadre dans la liste ci-dessous.
                            </div>

                            <div className="select-mat-input-field-direction-long" style={{margin: "0 0 12px 0"}}>
                                Veuillez noter que <u>NOUS N'EXPÉDIONS PAS</u> de cadres. Ce sont des cadres de taille standard recommandés qui s'adaptent bien à la taille de votre document. Vous pouvez obtenir ces cadres facilement en ligne ou dans des magasins physiques.
                            </div>

                            <div className={"select-mat-sub-row"}>
                                {this.fetchWindowSizeDropdown()}
                            </div>
                        </div>

                    </div>

                </div>

            </div>
        } else {
            return <div className={(this.state.knowFrameSize === false) ? "multiple-window-artwork-size-selection-panel" : "no-display"}>

                <div className="select-mat-sub-row">

                    <img className={"select-matboard-window-caption-arrow add-margin-buffer-5px"}
                         id={"window-options-container-mobile"}
                         onClick={() => {this.setState({openFrameOptionsModal: !this.state.openFrameOptionsModal})}}
                         alt="Expand for more options."
                         src={this.state.openFrameOptionsModal ? arrowUp : arrowDown}/>

                    <div className="select-matboard-window-caption-text matboard-colour-name-desc" id={"select-matboard-window-sizing-mobile"}>
                        Frame Size
                    </div>


                    <div className={"select-mat-row-divider"}>
                        <div className={this.state.openFrameOptionsModal ? "display" : "no-display"}>
                            <div className="select-mat-sub-row" style={{margin: "12px 0"}}>
                                {this.props.french ? "Veuillez choisir une taille de cadre dans la liste ci-dessous" : "Please choose a frame size from the list below."}
                            </div>

                            <div className="select-mat-input-field-direction-long" style={{margin: "0 0 12px 0"}}>
                                {this.props.french ? "Veuillez noter que nous ne livrons PAS de cadres. Ce sont des cadres de taille standard recommandés qui s'adaptent bien à la taille de votre document. Vous pouvez vous procurer ces cadres facilement en ligne ou en magasin." : "Please note we DO NOT ship frames. These are recommended standard-sized frames that go well with your document size. You can get these frames easily from online or offline stores.\n"}
                            </div>

                            <div className={"select-mat-sub-row"}>
                                {this.fetchWindowSizeDropdown()}
                            </div>
                        </div>

                    </div>

                </div>

            </div>
        }

    };

    fetchPrimaryColorOptions() {
        return <div className={this.state.knowFrameSize !== null ? "select-mat-row" : "no-display"}>

            <div>
                <img className={"select-matboard-window-caption-arrow add-margin-buffer-5px"}
                     onClick={() => this.setState({colorModalOpen: !this.state.colorModalOpen})}
                     src={this.state.colorModalOpen ? arrowUp : arrowDown}
                     alt={this.props.french ? "Développez pour plus d'options." : "Expand for more options."}
                />

                <div style={{width:"auto", overflow: "auto", paddingBottom: "12px"}}>
                    <div className="select-matboard-window-caption-text matboard-colour-name-desc" id={"select-matboard-window-caption-text-double-matting"}>
                        {this.props.french ? "Couleur" : `Matboard ${this.props.location === "us" ? "Color" : "Colour"}`}
                    </div>
                    <div className="select-matboard-window-caption-selected-colour-text" id={"select-matboard-window-captiaon-selected-colour-text-mobile"}>
                        <div className={"matboard-colour-name matboard-colour-name-desc"}>
                            {this.fetchSelectedMatColor()}
                        </div>
                        <picture>
                            <source type="image/webp" srcSet={productDB.matboards[this.state.matColour] ? productDB.matboards[this.state.matColour].webp : ""}/>
                            <source type="image/png" srcSet={productDB.matboards[this.state.matColour] ? productDB.matboards[this.state.matColour].img : ""}/>
                            <Tooltip title={productDB.matboards[this.state.matColour] ? productDB.matboards[this.state.matColour].color : ""} placement={"top"}>
                                <img alt={"Click to add window."} className={"matboard-selected-colour"} src={productDB.matboards[this.state.matColour] ? productDB.matboards[this.state.matColour].webp : ""}
                                     alt={this.props.french ? "Développez pour plus d'options." : "Expand for more options."}
                                />
                            </Tooltip>
                        </picture>
                    </div>

                </div>

            </div>


            <div className={this.state.colorModalOpen ? "select-mat-size-option-toggle" : "no-display"} style={{marginBottom: "12px"}}>
                <div className={"select-mat-sub-row"}>
                    <img className={"select-mat-guidance-icon"} src={warningIcon}
                         alt={this.props.french ? "Veuillez consulter le guide pour plus de détails sur les couleurs" : "Please see guide for colour details."}
                    />
                    <div className={"select-mat-guidance-desc"}>
                        {this.props.french ? "Veuillez consulter le guide pour plus de détails sur les couleurs" : "Please see guide for colour details."}
                    </div>
                    <div className={"select-mat-guidance-button"}>
                        <a href="/colour-guide">
                            Guide
                        </a>
                    </div>

                    <div className={"change-matboard-colour-layout"}>
                        {this.fetchMatboardColours("matColour")}
                    </div>

                    <div className="select-mat-input-field-direction-long" id={"colour-description-container"}>
                        {`${this.props.french ? productDB.matboards[this.state.matColour].colorFr: productDB.matboards[this.state.matColour].color}: ${this.props.french ? productDB.matboards[this.state.matColour].descFr: productDB.matboards[this.state.matColour].desc}`}
                    </div>

                </div>
            </div>

        </div>
    };

    fetchDoubleMattingOptions() {

        return <div className={this.state.knowFrameSize !== null ? "display" : "no-display"}>
            <div className={"select-mat-row"} id={"select-mat-row-mobile-margin-left-2"}>

                <div className={"select-matboard-window-caption-arrow add-margin-buffer-5px select-box-outside"}
                     onClick={() => this.setState({openSecondMattingModal: !this.state.openSecondMattingModal}, () => {this.activateOrDeactivateSecondMatting()})}>
                    <div className={this.state.secondMatWidth > 0 ? "box-selected" : "box-unselected"}></div>
                </div>

                <div className={"select-mat-row-divider"} id={"double-mat-mobile-float-right"}>

                    <div className="select-matboard-window-caption-text matboard-colour-name-desc" id={"select-matboard-window-caption-text-double-matting"}>
                            <span>
                              {this.props.french ? "Double Passe-partout" : "Double Matting"}
                            </span>
                        <span> </span>
                        {this.state.openSecondMattingModal ? `+$${(this.props.fxConversion(this.getSecondMatPricing() * this.props.getDiscountMultiplier())).toFixed(2)}` : ""}
                    </div>

                    <div className="select-matboard-window-caption-selected-colour-text" id={"double-mat-mobile-float-right-price"}>
                        <div className={"matboard-colour-name matboard-colour-name-desc"} id={"double-mat-mobile-float-right-price-2"}>
                            {(this.state.secondMatColour !== "none" && this.state.secondMatColour !== "not selected") ? this.showSelectedSecondMatColour() : null}
                            <span className={this.state.openSecondMattingModal ? "no-display" : "mobile-no-display"}> +${(this.props.fxConversion(this.getSecondMatPricing() * this.props.getDiscountMultiplier())).toFixed(2)}</span>
                        </div>
                        {(this.state.secondMatColour !== "none" && this.state.secondMatColour !== "not selected") ? this.showSelectedSecondMatColourPicture() : null}
                    </div>

                </div>

            </div>

            <div className={"select-mat-sub-row"}>

                <div className={this.state.openSecondMattingModal ? "select-mat-size-option-toggle" : "no-display"} id={"choose-double-matting-option"}>
                    <div className={"show-secondary-mat-colour-container"}>
                        {this.fetchMatboardColours("secondMatColour")}
                    </div>

                    <div className="select-mat-input-field-direction-long" id={"colour-description-container"}>
                        {this.fetchSecondMatColourDesc()}
                    </div>

                    <div className={this.state.openSecondMattingModal ? "select-mat-product-order-quantity" : "no-display"} style={{marginTop: "12px"}}>
                        <div>
                            <div className={"select-mat-input-field-direction"} >
                                {this.props.french ? "révéler" : "reveal (matting width)"}
                            </div>
                            {displayInputFields("", this.state.secondMatWidth, (event) => this.setState({secondMatWidth: event.target.value}), "outlined")}
                        </div>

                    </div>
                </div>

            </div>
        </div>
    };

    fetchTextOptions() {
        var discountRate = this.props.getDiscountMultiplier() ? this.props.getDiscountMultiplier() : 1;
        var textOptionPrice = this.props.fxConversion(this.state.textAdditionPrice * discountRate);
        var textOptionPriceClean = textOptionPrice.toFixed(2);

        if (this.props.french) {
            return <div className={this.state.knowFrameSize !== null ? "multiple-window-artwork-size-selection-panel" : "no-display"} id={"select-mat-row-mobile-margin-left-2"} style={{marginTop: "12px", padding: "12px 0"}}>

                <div className={"select-matboard-window-caption-arrow add-margin-buffer-5px select-box-outside"}
                     onClick={() => this.setState({textAddition: !this.state.textAddition, showAddNoteModal: true, textAdditionPrice: textOptionPrice, showRenderingTool: false}, () => {this.selectHighlightedPhoto()})}>
                    <div className={this.state.textAddition ? "box-selected" : "box-unselected"}></div>
                </div>

                <div className={"select-mat-row-divider"} id={"double-mat-mobile-float-right"}>

                    <div className="select-matboard-window-caption-text matboard-colour-name-desc" id={"select-matboard-window-caption-text-double-matting"}>
                        <span>AJOUT DE TEXTE <span className={"mobile-no-display tablet-no-display"}> / NOM DE L'ÉCOLE</span></span>
                        <span> </span>
                        {this.state.textAddition ? `+${textOptionPriceClean}$` : ""}
                    </div>

                    <div className="select-matboard-window-caption-selected-colour-text" id={"double-mat-mobile-float-right-price"}>
                        <div className={"matboard-colour-name matboard-colour-name-desc"} id={"double-mat-mobile-float-right-price-2"}>
                            <span className={this.state.textAddition ? "no-display" : "mobile-no-display"}> +${textOptionPriceClean}$</span>
                        </div>
                        <div className={"matboard-colour-name"} id={"double-mat-mobile-float-right-price-2"}>
                            {(this.state.textAddition !== false) ? "Ajouté" : null}
                        </div>

                    </div>

                </div>

                <div className={this.state.textAddition ? "select-mat-input-field-direction-long" : "no-display"} style={{width: "calc(100% - 36px)", overflow: "auto", marginLeft: "36px", paddingTop: "12px", lineHeight: 1.5}}>
                    Nous pouvons créer des passe-partout spéciaux pour vous. Nous appliquerons des mots sur le dessus de votre passe-partout.<br/><br/>

                    Dans la zone "Notes supplémentaires" ci-dessous, veuillez nous indiquer :
                    <ol>
                        <li>Texte / Mots -- par exemple : Nom de l'école</li>
                        <li>Couleur -- La couleur que vous souhaitez pour vos mots. Par défaut, c'est l'or.</li>
                        <li>Police -- Le type de police que vous souhaitez. Notre police par défaut est Cambria, mais vous pouvez choisir n'importe quelle police que vous aimez à partir de Microsoft Word.</li>
                        <li>Emplacement -- Où vous souhaitez que les mots soient sur le cadre. Normalement, nous les plaçons sous la partie en verre.</li>
                    </ol>
                    Si vous avez une conception que vous aimeriez que nous reproduisions, veuillez nous envoyer un e-mail à hello@custommat.ca
                </div>


            </div>
        } else {
            return <div className={this.state.knowFrameSize !== null ? "multiple-window-artwork-size-selection-panel" : "no-display"} id={"select-mat-row-mobile-margin-left-2"} style={{marginTop: "12px", padding: "12px 0"}}>

                <div className={"select-matboard-window-caption-arrow add-margin-buffer-5px select-box-outside"}
                     onClick={() => this.setState({textAddition: !this.state.textAddition, showAddNoteModal: true, textAdditionPrice: textOptionPrice, showRenderingTool: false}, () => {this.selectHighlightedPhoto()})}>
                    <div className={this.state.textAddition ? "box-selected" : "box-unselected"}></div>
                </div>

                <div className={"select-mat-row-divider"} id={"double-mat-mobile-float-right"}>

                    <div className="select-matboard-window-caption-text matboard-colour-name-desc" id={"select-matboard-window-caption-text-double-matting"}>
                        <span>Add Text <span className={"mobile-no-display tablet-no-display"}> / School Name</span></span>
                        <span> </span>
                        {this.state.textAddition ? `+$${textOptionPriceClean}` : ""}
                    </div>

                    <div className="select-matboard-window-caption-selected-colour-text" id={"double-mat-mobile-float-right-price"}>
                        <div className={"matboard-colour-name matboard-colour-name-desc"} id={"double-mat-mobile-float-right-price-2"}>
                            <span className={this.state.textAddition ? "no-display" : "mobile-no-display"}> +${textOptionPriceClean}</span>
                        </div>
                        <div className={"matboard-colour-name"} id={"double-mat-mobile-float-right-price-2"}>
                            {(this.state.textAddition !== false) ? "Added" : null}
                        </div>

                    </div>

                </div>

                <div className={this.state.textAddition ? "select-mat-input-field-direction-long" : "no-display"} style={{width: "calc(100% - 36px)", overflow: "auto", marginLeft: "36px", paddingTop: "12px", lineHeight: 1.5}}>
                    We can make a special matboards for you. We will apply words on top of your matboard. <br/><br/>

                    In the "Additional Notes" box below, please tell us:
                    <ol>
                        <li>Text / Words -- i.e. School Name</li>
                        <li>Color -- What color you want your words to be. Default is Gold.</li>
                        <li>Font -- What kind of font you want. Our default is Cambria, but you can choose any font you like from Microsoft Word.</li>
                        <li>Location -- Where you want the words to be on the frame. Normally, we put them under the glass part.</li>
                    </ol>
                    If you have any design that you would like us to replicate, please send us an email to hello@custommat.ca
                </div>


            </div>

        }

    };

    fetchLogoOptions() {
        var discountRate = this.props.getDiscountMultiplier() ? this.props.getDiscountMultiplier() : 1;
        var logoOptionPrice = this.props.fxConversion(this.state.logoAdditionPrice * discountRate);
        var logoOptionPriceClean =  logoOptionPrice.toFixed(2);

        if (this.props.french) {
            return <div className={this.state.knowFrameSize !== null ? "select-mat-row" : "no-display"} id={"select-mat-row-mobile-margin-left-2"} style={{paddingBottom: "12px"}}>

                <div className={"select-matboard-window-caption-arrow add-margin-buffer-5px select-box-outside"}
                     onClick={() => this.setState({logoAdd: !this.state.logoAdd, showAddNoteModal: true, logoAdditionPrice: logoOptionPrice, showRenderingTool: false}, () => {this.selectHighlightedPhoto()})}>
                    <div className={this.state.logoAdd ? "box-selected" : "box-unselected"}></div>
                </div>

                <div className={"select-mat-row-divider"} id={"double-mat-mobile-float-right"}>

                    <div className="select-matboard-window-caption-text matboard-colour-name-desc" id={"select-matboard-window-caption-text-double-matting"}>
                            <span>
                              AJOUT DE LOGO
                            </span>
                        <span> </span>
                        {this.state.logoAdd ? `+${logoOptionPriceClean}$` : ""}
                    </div>

                    <div className="select-matboard-window-caption-selected-colour-text" id={"double-mat-mobile-float-right-price"}>
                        <div className={"matboard-colour-name matboard-colour-name-desc"} id={"double-mat-mobile-float-right-price-2"}>
                            <span className={this.state.logoAdd ? "no-display" : "mobile-no-display"}> +${logoOptionPriceClean}</span>
                        </div>
                        <div className={"matboard-colour-name"} id={"double-mat-mobile-float-right-price-2"}>
                            {(this.state.logoAdd !== false) ? "Ajouté" : null}
                        </div>
                    </div>

                </div>

                <div className={this.state.logoAdd ? "select-mat-input-field-direction-long" : "no-display"} style={{width: "calc(100% - 36px)", overflow: "auto", marginLeft: "36px", paddingTop: "12px", lineHeight: "1.5"}}>
                    Nous pouvons personnaliser votre logo et l'appliquer sur votre passe-partout. Il ne sera qu'en une seule couleur et nous utilisons généralement l'or.<br/><br/>
                    Dans la zone "Notes supplémentaires" ci-dessous, veuillez nous indiquer :
                    <ol>
                        <li>Fichier de votre logo -- Le lien vers votre logo sur le site web ou envoyez-le par e-mail à hello@custommat.ca avec votre numéro de commande.</li>
                        <li>Couleur du logo -- Indiquez-nous la couleur que vous souhaitez pour votre logo. Par défaut, nous utilisons l'or.</li>
                        <li>Emplacement du logo (par défaut sous la fenêtre).</li>
                        <li>Si votre logo comporte des écritures ou le nom de votre école, vous devez choisir l'option "Ajouter du texte/Nom de l'école" ainsi que cette option ("Ajouter un logo").</li>
                    </ol>
                </div>
            </div>

        } else {
            return <div className={this.state.knowFrameSize !== null ? "select-mat-row" : "no-display"} id={"select-mat-row-mobile-margin-left-2"} style={{paddingBottom: "12px"}}>

                <div className={"select-matboard-window-caption-arrow add-margin-buffer-5px select-box-outside"}
                     onClick={() => this.setState({logoAdd: !this.state.logoAdd, showAddNoteModal: true, logoAdditionPrice: logoOptionPrice, showRenderingTool: false}, () => {this.selectHighlightedPhoto()})}>
                    <div className={this.state.logoAdd ? "box-selected" : "box-unselected"}></div>
                </div>

                <div className={"select-mat-row-divider"} id={"double-mat-mobile-float-right"}>

                    <div className="select-matboard-window-caption-text matboard-colour-name-desc" id={"select-matboard-window-caption-text-double-matting"}>
                            <span>
                              Add Logo
                            </span>
                        <span> </span>
                        {this.state.logoAdd ? `+$${logoOptionPriceClean}` : ""}
                    </div>

                    <div className="select-matboard-window-caption-selected-colour-text" id={"double-mat-mobile-float-right-price"}>
                        <div className={"matboard-colour-name matboard-colour-name-desc"} id={"double-mat-mobile-float-right-price-2"}>
                            <span className={this.state.logoAdd ? "no-display" : "mobile-no-display"}> +${logoOptionPriceClean}</span>
                        </div>
                        <div className={"matboard-colour-name"} id={"double-mat-mobile-float-right-price-2"}>
                            {(this.state.logoAdd !== false) ? "Added" : null}
                        </div>
                    </div>

                </div>

                <div className={this.state.logoAdd ? "select-mat-input-field-direction-long" : "no-display"} style={{width: "calc(100% - 36px)", overflow: "auto", marginLeft: "36px", paddingTop: "12px", lineHeight: "1.5"}}>
                    We can customize your logo and apply on your matboard. It will only be one color and we usually use gold.<br/><br/>
                    In the "Additional Notes" box below, please tell us:
                    <ol>
                        <li>Your Logo File -- The website link to your logo or email it to us at hello@custommat.ca along with your order number.</li>
                        <li>Logo Color -- Tell us what color you want your logo to be. We use gold by default.</li>
                        <li>Location of Logo (Default is under the window).</li>
                        <li>If your logo has writing or your school name, you need to choose the "Add Text/School Name" option along with this (“Add Logo”) option.</li>
                    </ol>
                </div>
            </div>

        }


    };

    fetchQuantityOptions() {
        return <div>
            <div className="select-mat-container-row" style={{width: "100%"}}>
                <div className={"add-text-fields-text-field-title"} style={{color: "#3b3b3b"}}>
                    Quantity
                </div>
            </div>

            <div className={"cart-slider-shopped-item-quantity"} style={{margin: 0, paddingBottom: "12px", width: "100%", borderBottom: "solid 1px #828282"}}>
                <div className={"cart-slider-shopped-item-quantity-button"} style={{marginTop: "10px"}} onClick={() => {this.setState({selectedQuantity: this.state.selectedQuantity + 1})}}>+</div>
                <div className={"select-window-multiple-window-dimensions"}>
                    {/*{this.state.selectedQuantity}*/}
                    {displayInputFields("", this.state.selectedQuantity, (event) => this.setState({selectedQuantity: event.target.value}))}
                </div>
                <div className={"cart-slider-shopped-item-quantity-button"} style={{marginTop: "10px", marginLeft: "10px"}} onClick={() => {this.reduceQuantity()}}>-</div>
            </div>
        </div>
    };

    getSecondMatPricing() {
        var frameSize = this.state.frameWidth * this.state.frameHeight;
        var secondMatPricing = 0.00;
        // var discountFactor = this.props.getDiscountMultiplier() ? this.props.getDiscountMultiplier() : 1;

        if (frameSize <= productDB.matboardOnlyPricing.xSmall.width * productDB.matboardOnlyPricing.xSmall.height) {
            secondMatPricing = secondMatPricing + productDB.matboardOnlyPricing.xSmall.secondaryMat;
        } else if (frameSize <= productDB.matboardOnlyPricing.small1.width * productDB.matboardOnlyPricing.small1.height) {
            secondMatPricing = secondMatPricing + productDB.matboardOnlyPricing.small1.secondaryMat;
        } else if (frameSize <= productDB.matboardOnlyPricing.small2.width * productDB.matboardOnlyPricing.small2.height) {
            secondMatPricing = secondMatPricing + productDB.matboardOnlyPricing.small2.secondaryMat;
        } else if (frameSize <= productDB.matboardOnlyPricing.medium1.width * productDB.matboardOnlyPricing.medium1.height) {
            secondMatPricing = secondMatPricing + productDB.matboardOnlyPricing.medium1.secondaryMat;
        } else if (frameSize <= productDB.matboardOnlyPricing.medium2.width * productDB.matboardOnlyPricing.medium2.height) {
            secondMatPricing = secondMatPricing + productDB.matboardOnlyPricing.medium2.secondaryMat;
        } else if (frameSize <= productDB.matboardOnlyPricing.medium3.width * productDB.matboardOnlyPricing.medium3.height) {
            secondMatPricing = secondMatPricing + productDB.matboardOnlyPricing.medium3.secondaryMat;
        } else if (frameSize <= productDB.matboardOnlyPricing.large1.width * productDB.matboardOnlyPricing.large1.height) {
            secondMatPricing = secondMatPricing + productDB.matboardOnlyPricing.large1.secondaryMat;
        } else if (frameSize <= productDB.matboardOnlyPricing.large2.width * productDB.matboardOnlyPricing.large2.height) {
            secondMatPricing = secondMatPricing + productDB.matboardOnlyPricing.large2.secondaryMat;
        } else if (frameSize <= productDB.matboardOnlyPricing.xlarge1.width * productDB.matboardOnlyPricing.xlarge1.height) {
            secondMatPricing = secondMatPricing + productDB.matboardOnlyPricing.xlarge1.secondaryMat;
        } else if (frameSize <= productDB.matboardOnlyPricing.xlarge2.width * productDB.matboardOnlyPricing.xlarge2.height) {
            secondMatPricing = secondMatPricing + productDB.matboardOnlyPricing.xlarge2.secondaryMat;
        } else if (frameSize <= productDB.matboardOnlyPricing.xlarge3.width * productDB.matboardOnlyPricing.xlarge3.height) {
            secondMatPricing = secondMatPricing + productDB.matboardOnlyPricing.xlarge3.secondaryMat;
        }
        // return secondMatPricing = secondMatPricing * discountFactor;
        return secondMatPricing = secondMatPricing;
    };

    calculateItemPrice() {
        var price = parseFloat(this.matboardOnlyPricingCalculator(this.state.frameWidth, this.state.frameHeight, "price"));

        var certMatPriceAddition = 2.95;
        var secondMatPrice = this.state.secondMatWidth > 0 ? this.getSecondMatPricing() : 0;
        var textPrice = this.state.textAddition ? this.state.textAdditionPrice : 0;
        var logoPrice = this.state.logoAdd ? this.state.logoAdditionPrice : 0;

        var discountMultiplier = this.props.getDiscountMultiplier() ? this.props.getDiscountMultiplier() : 1;

        price = (price + certMatPriceAddition + secondMatPrice + textPrice + logoPrice) * discountMultiplier;

        return price;
    }

    showSubtotalPrice() {
        return <span>{this.singleItemSubtotalCalculator(this.matboardOnlyPricingCalculator(this.state.frameWidth, this.state.frameHeight, "price"), this.state.secondMatPrice, this.state.secondMatColour, this.state.backingMatboard)}</span>
    }

    fetchAddToCartMenu() {
        return <div className={this.state.knowFrameSize !== null ? "display" : "no-display"} style={{width: "auto", overflow: "auto", padding: "12px 0", marginTop: "12px"}}>
            {this.fetchAddToBagButton()}
            <div className="select-mat-container-row" style={{width: "auto", cursor: "pointer"}}>
                {this.props.french ? "Devise: " : "Currency: "}
                <span className={this.props.currency === "CA$" ? "bold" : ""} onClick={() => {this.props.changeCurrency("CA$")}}> CAD </span>
                |
                <span className={this.props.currency === "US$" ? "bold" : ""} onClick={() => {this.props.changeCurrency("US$")}}> USD </span>
                <br/>
                {this.props.french ? "Prix Unitaire: " : "Unit Price: "}<b>$<span>{this.props.fxConversion(this.calculateItemPrice().toFixed(2)).toFixed(2)}</span></b>
            </div>
        </div>
    };

    fetchCarousel() {
        return <div className={this.state.showRenderingTool ? "no-display" : `select-mat-frame diploma-mat-rendering`}>
            <Carousel>
                <div className={"carousel-image-item"}>
                    <img src={this.state.highlightPhoto} className={"precut-container-left-photo"}/>
                </div>
                <div className={"carousel-image-item"}>
                    <img src={doubleMatLogoAndText} className={"precut-container-left-photo"}/>
                </div>
                <div className={"carousel-image-item"}>
                    <img src={doubleMatLogo} className={"precut-container-left-photo"}/>
                </div>
                <div className={"carousel-image-item"}>
                    <img src={doubleMatText}/>
                </div>
                <div className={"carousel-image-item"}>
                    <img src={singleMatLogoAndText}/>
                </div>
                <div className={"carousel-image-item"}>
                    <img src={doubleMatLogo}/>
                </div>
                <div className={"carousel-image-item"}>
                    <img src={doubleMatText}/>
                </div>
                <div className={"carousel-image-item"}>
                    <img src={doubleMatPlain}/>
                </div>
                <div className={"carousel-image-item"}>
                    <img src={singleMat}/>
                </div>
            </Carousel>
        </div>
    }

    fetchPreCutMatDetails() {

        var frame = {
            width: this.state.frameWidth,
            height: this.state.frameHeight,
            renderingStandardLength: this.state.renderingStandardLength,
            //rendering standard length refers to longer of the width and height
            type: "black",
            unit: "in",

            fractionWidthValueWholeNumber: this.state.frame,
            fractionHeightValueWholeNumber: this.state.frameHeight,
            fractionWidthValueSelected: "1/4",
            fractionHeightValueSelected: "1/4",
        }

        var window = {
            rowCount: 1,
            columnCount: 1,
            window1: {
                width: this.state.diplomaWidth,
                height: this.state.diplomaHeight,
                marginTop: (this.state.frameHeight - this.state.diplomaHeight)/2,
                marginBottom: (this.state.frameHeight - this.state.diplomaHeight)/2,
                marginLeft: (this.state.frameWidth - this.state.diplomaWidth)/2,
                marginRight: (this.state.frameWidth - this.state.diplomaWidth) / 2,
                showBackgroundImageModal: false,
                showModal: false,
            },
        }

        return <div className={"select-mat-container select-mat-container-narrow"} id={"diploma-mat-container"}>

            <div className={"select-mat-rendering"}>

                <div className={this.state.showRenderingTool ? `select-mat-frame diploma-mat-rendering` : "no-display" }
                     style={frameCssRendering(this.state.frameWidth, this.state.frameHeight, this.state.renderingStandardLength, this.state.renderingToolWidth, this.state.renderingToolHeight, walnutFrame, this.state.deviceType)}>
                    <div className={`select-matboard-section`} style={{backgroundImage: `url(${productDB.matboards[this.state.matColour] ? productDB.matboards[this.state.matColour].img : ""})`}}>
                        <ShowMattingWindows
                            frame={frame}
                            window={window}
                            selectedMatColour={this.state.matColour}
                            renderingToolWidth={this.state.renderingToolWidth}
                            renderingToolHeight={this.state.renderingToolHeight}
                            updateWindowSizeCallBack={this.updateWindowRendering}
                            moveWindowCallBack={this.moveWindowCallBack}
                            updateWindowModalState={this.updateWindowModalState}
                            totalWindowCount={1}
                            deviceType={this.state.deviceType}
                            secondMatWidth={this.state.secondMatWidth}
                            secondMatColour={this.state.secondMatColour}
                            isWindowOval={false}
                            // fetchLanguage={this.fetchLanguage()}
                            addWriting={this.state.addWriting}
                            guideDark={productDB.matboards[this.state.matColour].guideDark}
                        />
                    </div>
                </div>

                {this.fetchCarousel()}



                <div style={{width: "100%", overflow: "auto"}}>
                    <div className={"diploma-tool-selection"} style={this.state.showRenderingTool ? {} : {textDecoration: "underline"}} onClick={() => this.setState({showRenderingTool: false})}>
                        Product Photos
                    </div>

                    <div className={"diploma-tool-selection"} style={this.state.showRenderingTool ? {textDecoration: "underline"} : {}} onClick={() => this.setState({showRenderingTool: true})}>
                        Rendering Tool
                    </div>
                </div>

            </div>

            <div id={"select-matboard-description-container"} className={"select-matboard-description-container"}>

                {this.fetchProductPriceTab()}

                {this.fetchDiplomaFrameOptions()}

                {this.fetchDiplomaSizeWindowOptions()}

                {this.fetchFrameSizeOptions()}

                {this.fetchPrimaryColorOptions()}

                {this.fetchDoubleMattingOptions()}

                {this.fetchTextOptions()}

                {this.fetchLogoOptions()}

                {this.showAddNoteModal()}

                {this.fetchAddToCartMenu()}
            </div>

        </div>
    };

    render() {
        return <div style={{width: "auto", overflow: "auto"}}>

            <Helmet>
                <title>
                    Custom diploma and certificate matboards for the standard sized frames.
                </title>
                <meta name="description" content={`Certificate & diploma matboards delivered to your door anywhere in Canada and the United States.`} />
            </Helmet>

            {this.fetchPreCutMatDetails()}

        </div>
    }
}