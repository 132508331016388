export default function emailEnc (email) {
  var inputEmail = email;
  var outputEmail = inputEmail.split(".").join("7");
  outputEmail = outputEmail.split("@").join("8");
  outputEmail = outputEmail.split("a").join("1");
  outputEmail = outputEmail.split("e").join("2");
  outputEmail = outputEmail.split("i").join("3");
  outputEmail = outputEmail.split("o").join("4");
  outputEmail = outputEmail.split("u").join("5");
  outputEmail = outputEmail.split("s").join("6");
  return outputEmail;
}