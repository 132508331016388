import React from "react";
import {Helmet} from "react-helmet";

export default class GuideToFindARightSizedFrame extends React.Component {

    constructor() {
        super();
        this.state = {
        };
    };
    componentDidMount() {
        this.setState({
            french: window.location.pathname.slice(-2) === "fr" ? true : false
        })
    };

    toggleModal(stateToToggle) {
        this.setState({[stateToToggle]: !this.state[stateToToggle]})
    }

    fetchResponse(fr, en) {
        return this.state.french ? `${fr}` : `${en}`
    }

    render() {
        return <div className={`blog-article-content`}>

            <Helmet>
                <title>CustomMat - Guide to Find a Right Standard Sized Picture Frame</title>
                <meta name="description" content="We are one of the biggest art suppliers specializing in matboards and picture framing supplies. We specialize in picture frame matboards."/>
            </Helmet>

            <div className={"faq-question"}>
                <div className={"faq-question-right"}>
                    Guide to Finding a Right (Standard Sized) Picture Frame
                </div>
            </div>

            <div className={"faq-answer"}>
                <div className={"faq-answer-paragraph"}>
                    Are you searching for the perfect picture frame or photo frame for your artwork? Do you want to create a gallery wall with different-sized art prints? Are your print sizes unusual or custom sizes?
                </div>

                <div className={"faq-answer-paragraph"}>
                    Here's a guide to help you find the right frames or poster frames for your needs. Whether you are framing a fine art piece or standard sized prints, this guide will assist you in framing and matting your prints — not just for standard photo sizes but any sizes of artwork.
                </div>

                <div className={"faq-answer-paragraph"}>
                    If you already have a frame for your prints, that's fantastic! But if you haven't chosen a frame yet, here are a couple of tips to help you make the right choice:
                </div>

                {/*<div className={"visit-custom-mat-button"}>*/}
                {/*    <a href={"/customize-matboards"} className={"carousel-read-more-button"}>*/}
                {/*        Customize Matboards*/}
                {/*    </a>*/}
                {/*</div>*/}
            </div>

            <div className={"faq-question"}>
                <div className={"faq-question-right"}>
                    How big should your picture frame be (compared to the size of artwork)?
                </div>
            </div>


            <div className={"faq-answer"}>
                <div className={"faq-answer-paragraph"}>
                    Typically, your frame should be at least 1.5 times larger than your artwork on each side.
                </div>

                <div className={"faq-answer-paragraph"}>
                    For example, if you have an 8 x 10 inch print, your picture frame should be at least 12 inches on one side (8 x 1.5 = 12 inches) and 15 inches on the other side (10 x 1.5 = 15 inches). So, your frame should be at least 12 x 15 inches in size.
                </div>

                <div className={"faq-answer-paragraph"}>
                    A good choice for a standard-sized frame for your 8 x 10 print would be a 12 x 16 inch frame. It's slightly bigger than the minimum size requirement. If you want to go a bit larger, you can opt for a 14 x 18 or 16 x 20 inch frame.
                </div>

                <div className={"faq-answer-paragraph"}>
                    To fill the gap between your 12 x 16 inch frame and the 8 x 10 inch print, you can consider getting a custom-cut matboard from us. This will help create a balanced and aesthetically pleasing look for your framed artwork.
                </div>

                {/*<div className={"visit-custom-mat-button"}>*/}
                {/*    <a href={"/customize-matboards"} className={"carousel-read-more-button"}>*/}
                {/*        Customize Matboards*/}
                {/*    </a>*/}
                {/*</div>*/}
            </div>

        </div>
    }
}