import React from "react";

export default class FetchPrices extends React.Component {

    constructor() {
        super();
        this.state = {
            penDrawing: false,
            french: false,
        };
    };

    componentDidMount() {
        this.setState({
            french: (window.location.pathname.slice(-2) === "fr" || this.props.language === "fr") ? true : false
        })
    }


    render() {
        return <div className={"fair-pricing-discount-section-container"}>
            <div className="product-listing-header">
                {this.state.french ? "PRIX DU MANUFACTURIER DIRECT" : "FACTORY DIRECT PRICING"}
            </div>

            <div className="product-listing-desc">
                {this.state.french ? "Achetez directement sans sacrifier la qualité. Économisez jusqu’à 60 % sur le prix de détail." : "Buy direct without sacrificing quality. Save up to 60% off retail."}
                <br/>
                {this.state.french ? "Remises substantielles sur les achats en gros." : "Substantial discounts on bulk purchases."}
            </div>

            <table className="pricing-table">
                <tbody>
                <tr>
                    <th className="faq-table-row faq-table-row-size">
                        {this.state.french ? "Taille" : "Size"}
                        <br/>
                        <span className={"faq-table-row-subtext"}>
                            {this.state.french ? "(ou inférieure)" : "(or smaller)"}
                        </span>

                    </th>
                    <th className="faq-table-row">
                        <b>
                            {this.state.french ? "Notre Prix Régulier" : "Our Full Price"}
                            <br/>
                            <span className={"faq-table-row-subtext"}>
                                {this.state.french ? "(Achat 1 Feuille)" : "(Buying 1 Sheet)"}
                            </span>
                        </b>
                    </th>
                    <th className="faq-table-row mobile-no-display">
                        <b>
                            {this.state.french ? "Notre prix réduit" : "Our Discounted Price"}
                            <br/>
                            <span className={"faq-table-row-subtext"}>
                                {this.state.french ? "(Achat de 4 Feuilles)" : "(Buying 4 Sheets)"}
                            </span>
                        </b>
                    </th>
                    <th className="faq-table-row">
                        {this.state.french ? "Prix de détail estimé*" : "Estimated Retail Price*"}
                    </th>
                </tr>
                </tbody>

                <tbody>
                <tr>
                    <td className="faq-table-row-number">7 x 5 {this.state.french ? "pouces" : "inch"}</td>
                    <td className="faq-table-row"><b>{this.state.french ? "9,95$" : "$9.95"}</b></td>
                    <td className="faq-table-row mobile-no-display"><b>{this.state.french ? "6,97$" : "$6.97"}</b></td>
                    <td className="faq-table-row">{this.state.french ? "25$" : "$25"}</td>
                </tr>
                </tbody>




                <tbody>
                <tr>
                    <td className="faq-table-row-number">14 x 11 {this.state.french ? "pouces" : "inch"}</td>
                    <td className="faq-table-row"><b>{this.state.french ? "11,95$" : "$11.95"}</b></td>
                    <td className="faq-table-row mobile-no-display"><b>{this.state.french ? "8,37$" : "$8.37"}</b></td>
                    <td className="faq-table-row">{this.state.french ? "30$" : "$30"}</td>
                </tr>
                </tbody>

                <tbody>
                <tr>
                    <td className="faq-table-row-number">16 x 12 {this.state.french ? "pouces" : "inch"}</td>
                    <td className="faq-table-row"><b>{this.state.french ? "14,95$" : "$14.95"}</b></td>
                    <td className="faq-table-row mobile-no-display"><b>{this.state.french ? "10,47$" : "$10.47"}</b></td>
                    <td className="faq-table-row">{this.state.french ? "40$" : "$40"}</td>
                </tr>
                </tbody>

                <tbody>
                <tr>
                    <td className="faq-table-row-number">20 x 16 {this.state.french ? "pouces" : "inch"}</td>
                    <td className="faq-table-row"><b>{this.state.french ? "19,95$" : "19.95"}</b></td>
                    <td className="faq-table-row mobile-no-display"><b>{this.state.french ? "13,97$" : "$13.97"}</b></td>
                    <td className="faq-table-row">{this.state.french ? "60$" : "$60"}</td>
                </tr>
                </tbody>

                <tbody>
                <tr>
                    <td className="faq-table-row-number">20 x 20 {this.state.french ? "pouces" : "inch"}</td>
                    <td className="faq-table-row"><b>{this.state.french ? "29,95$" : "$29.95"}</b></td>
                    <td className="faq-table-row mobile-no-display"><b>{this.state.french ? "20,97$" : "$20.97"}</b></td>
                    <td className="faq-table-row">{this.state.french ? "70$" : "$70"}</td>
                </tr>
                </tbody>

                <tbody>
                <tr>
                    <td className="faq-table-row-number">28 x 20 {this.state.french ? "pouces" : "inch"}</td>
                    <td className="faq-table-row"><b>{this.state.french ? "31,95$" : "$31.95"}</b></td>
                    <td className="faq-table-row mobile-no-display"><b>{this.state.french ? "22,37$" : "$22.37"}</b></td>
                    <td className="faq-table-row">{this.state.french ? "120$" : "$120"}</td>
                </tr>
                </tbody>

                <tbody>
                <tr>
                    <td className="faq-table-row-number">36 x 24 {this.state.french ? "pouces" : "inch"}</td>
                    <td className="faq-table-row"><b>{this.state.french ? "39,95$" : "$39.95"}</b></td>
                    <td className="faq-table-row mobile-no-display"><b>{this.state.french ? "27,97$" : "$27.97"}</b></td>
                    <td className="faq-table-row">{this.state.french ? "140$" : "$140"}</td>
                </tr>
                </tbody>

            </table>

            <div className="product-listing-desc">
                {this.state.french ? "*Les prix sont basés sur des demandes faites auprès de plusieurs magasins d’art et d’encadrements." : "*Prices based on inquiries made at multiple art and framing shops."}

            </div>
        </div>

    }
}