import React from 'react';
import ArticleFetcher from "../articleFetcher";
import {galleryArticles} from "../articles";
import {Helmet} from "react-helmet";
import analyticsDataToDB from "../../comp-analyticsLog/dbLogging";

export default class BlogArticleFive extends React.Component {

  constructor(props) {
    super(props);
    var article = galleryArticles.article5;
    this.state = {
      category: article.category,
      hashTags: article.hashTags,
      title: article.title,
      subtitle: article.subtitle,
      photos: article.photos,
      contents: article.contents,
      thumbnail: article.thumbnail,
    }
  }

  componentDidMount(){
    this.logToDbUserRecord();
  }

  logToDbUserRecord() {
    analyticsDataToDB("fetch-article", "article-5-ordering-custom-passe-partout-in-us-and-canada");
  }


  render() {
    return <div className={`gallery-container`}>

      <Helmet>
        <title>How to order a custom passepartuout (mat board) in US & Canada online? </title>
        <meta name="description" content="We have a large variety of passe-partout (mat board). We cut them for your dimensions with our advanced devices. Typically, we cut our mats on the same day and ship them on the next day. Our mat boards will reach your door in days. Anywhere in USA & Canada."/>
      </Helmet>

      <ArticleFetcher article={this.state}/>
    </div>
  }
}