import React from "react";
import "@babel/polyfill";
import TextField from "@material-ui/core/TextField";

export default class PromoCodeValidation extends React.Component {

  constructor() {
    super();
    this.state = {
      // promoCodeModal: false,
    // Black Friday Promo Code
    promoCodeModal: false,
    };
  };

  fetchPromoCodeBox() {
      if (this.props.onGoingPromotion) {
        return <div className={"promo-code-selector"} onClick={() => {this.setState({promoCodeModal: !this.state.promoCodeModal})}}>
          {/*Black Friday Promo Code*/}
          {/*{this.props.french ? `Votre commande sera expédiée le 31 Décembre` : `Your order will ship on Dec 31`}*/}
          <span> // </span>
          {this.props.french ? `Code promotionnel -- Entrez ${this.props.promoCode} ici :)` : `Promo Code -- Enter ${this.props.promoCode} Here :)`}

        </div>
      } else {
      return <div className={"promo-code-selector"} onClick={() => {this.setState({promoCodeModal: !this.state.promoCodeModal})}}>
        {this.props.french ? "Code promotionnel" : "Promo Code"}
      </div>
    }
  }

  render() {
    return <div className={this.props.onGoingPromotion ? `promo-code-section-container` : `no-display`}>

      {this.fetchPromoCodeBox()}

      <div className={this.state.promoCodeModal || this.props.onGoingPromotion ? "promo-code-container" : "no-display"} >

        <TextField
            className={"discount-input-summary"}
            placeholder={this.props.french ? "Code Promotionnel" : "Promo Code"}
            value={this.props.promoCodeInfo.promoCodeEntered}
            onChange={(evt) => this.props.evaluatePromoCode(evt.target.value)}
        />


        <div className={this.props.promoCodeInfo.promoCodeEntered === "" ? "no-display" : "display promo-application-view"}>
          <span className={this.props.promoCodeInfo.promoCodeApplied ? "no-display" : "display error"}>
            {this.props.french ? "Code Promotionnel Invalide" : "Hmm... Invalid Code"}
          </span>
          <span className={this.props.promoCodeInfo.promoCodeApplied ? "display success" : "no-display"}>
            {this.props.french ? "Remise appliquée" : "Bingo! Discount applied."}
          </span>
        </div>

      </div>


    </div>
  }
}