import React from "react";

export default function renderImg (regImg, webpImg, className, alt) {
  return <picture>
    <source type="image/webp" srcSet={`${webpImg}`}/>
    <source type="image/png" srcSet={`${regImg}`}/>
    <img className={className} src={regImg}
         alt={alt}/>
  </picture>
}
//add if total product price goes over 50 waive the shipping cost