import React from 'react';
import photo1 from "../../../../resources/images/blog/diploma/Background Photo.png"
import photo2 from "../../../../resources/images/blog/diploma/Diploma mat Single.jpg";
import photo4 from "../../../../resources/images/blog/diploma/univ-edited.jpg";
import photo5 from "../../../../resources/images/blog/diploma/Single Mat with Logo.png";
import photo6 from "../../../../resources/images/blog/diploma/Double Matting No Writing.png";
import photo7 from "../../../../resources/images/blog/diploma/Diploma Mat Double.jpg";


import LazyLoad from "react-lazyload";
import {Helmet} from "react-helmet";
import blogArticle1Regular32 from "../../../../resources/images/gallery/1-articleOne/reduced/3-2.png";
import {TikTok} from "react-tiktok";



export default class Highschool extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            schoolName: "highschool",
            schoolColor: "black and gold",
            diplomaSize: "8x6",
            openingSize: "7.75x5.75",
            frameRec1: "16x12",
            frameRec2: "18x14",
            frameRec3: "20x16",
        };
    };

    componentDidMount() {

    }

    render() {
        return <div className={"blog-app-container"}>

            <Helmet>
                <title>How to Frame Your {this.state.schoolName} Diploma on a Budget: Clever Tips and Tricks</title>
                <meta name="description" content="Instead of spending over $200 dollars for a custom diploma frame or official diploma frames, you can do it for about $30-60 dollars with us at Custom Mat. You can customize your own design, and get the high quality, 100% custom designed frames and matboards delivered to your door. "/>
            </Helmet>

            <div className={"carousel-subject-head"} style={{textAlign: "center"}}>
                How to Frame Your <br/> {this.state.schoolName} Diploma <br/> on a Budget: Clever Tips and Tricks
            </div>

            <div className={"carousel-subject-subhead-type2"}>
                Diplomas & Certificates
            </div>

            <div className={"carousel-subject-subhead-type3"}>
                Written by Daniel S<br/>Custom Mat Project Blogger
            </div>

            <LazyLoad>
                <img src={photo1} className={"blog-carousel-photo"}/>
            </LazyLoad>

            <div className={"blog-article-content"}>

                Congratulations <b>{this.state.schoolName}</b> Alums! You worked hard for your degree. Did you have fun at the college graduation? Your will be getting your diploma in your inbox soon. Display it with pride at your home or office. It's not the same without a graduation frame.

                <br/><br/>

                Instead of spending over $200 dollars for a custom diploma frame or official diploma frames, you can do it for about $30-60 dollars with us at Custom Mat. You can customize your own design, and get the high quality, 100% custom designed frames and matboards delivered to your door.

                <br/><br/>


                <TikTok url="https://www.tiktok.com/@custommat/video/7106680001117916422?is_from_webapp=1&sender_device=pc&web_id=7101339422562797062" />

                <br/><br/>

                <b>
                    How does this work?
                </b>

                <br/><br/>

                How do you frame your diploma within budget? Here’s how we can save money on your bachelor’s, master's, or doctoral degree certificate frames:

                <br/><br/>

                <b>1. Get a standard-sized picture frame.</b>

                <br/><br/>

                You can get picture frames or document frames easily from Amazon or retail stores — like Costco, IKEA, Target, Walmart, or Homesense. They come in many different sizes and designs. We have recommended you sizes for your {this.state.schoolName} diplomas below.

                <br/><br/>

                <b>2. Get a custom matboard.</b>

                <br/><br/>

                Customize your matboard with us. It can be combination of your school color. You can add your school logo and name. Or, maybe you can even go for your name and sorority name. We got a lot of options and we deliver to your door anywhere in Canada and the United States.

                <LazyLoad>
                    <video className={"what-is-mat-image-v2"} loop="true" autoPlay="autoplay" controls muted
                           src="https://s3.ca-central-1.amazonaws.com/custommat.landing.v2/0_What-is-a-matboard_1920x1290_v1+(1).mp4"></video>
                </LazyLoad>

                <b>3. Put them together at home.</b>

                <br/><br/>

                It takes only 5 minutes. Much easier than you think. That’s it.

                <br/><br/>

                <b>Let's get this started.</b>

                <div className={"visit-custom-mat-button"}>
                    <a href={"/diploma-frame-matboards"} className={"carousel-read-more-button"}>
                        Visit Custom Mat
                    </a>
                </div>

                <LazyLoad>
                    <img src={blogArticle1Regular32} className={"blog-carousel-photo"}/>
                </LazyLoad>


                <br/><br/>

                <b>1. Frame</b>

                <br/><br/>

                {this.state.schoolName} diplomas are sized at {this.state.diplomaSize} inches. We have listed the recommended document frames for your diploma.

                <br/><br/>

                {this.state.frameRec1} inch frame
                <ul>
                    <li>IKEA {this.state.frameRec1} inch frame series  - <a href={"https://www.ikea.com/ca/en/cat/picture-photo-frames-18746/?filters=f-typed-reference-measurement%3A16%20%C2%BCx20%20%22_frames%7C16x20%20%22_frames"}>link</a></li>
                    <li>Amazon's Opposite Wall Frame Series - <a href={"https://www.amazon.ca/stores/OppositeWall/page/5F8F0336-ACA0-41D9-AA96-073D690CE213?ref_=ast_bln"}>link</a></li>
                </ul>

                {this.state.frameRec2} inch frame
                <ul>
                    <li>Amazon's Opposite Wall Frame Series - <a href={"https://www.amazon.ca/stores/OppositeWall/page/5F8F0336-ACA0-41D9-AA96-073D690CE213?ref_=ast_bln"}>link</a></li>

                </ul>

                {this.state.frameRec3} inch frame
                <ul>
                    <li>IKEA {this.state.frameRec1} inch frame series  - <a href={"https://www.ikea.com/ca/en/cat/picture-photo-frames-18746/?filters=f-typed-reference-measurement%3A16%20%C2%BCx20%20%22_frames%7C16x20%20%22_frames"}>link</a></li>
                    <li>Amazon's Opposite Wall Frame Series - <a href={"https://www.amazon.ca/stores/OppositeWall/page/5F8F0336-ACA0-41D9-AA96-073D690CE213?ref_=ast_bln"}>link</a></li>
                </ul>

                <br/>

                These frames come in various sizes, colors, frame mouldings (metal / wood frames), and glass options (including glass and plexiglass acrylic), you can find your favorite design based on your home decor. They can be placed on table top or wall mounted.

                <br/><br/>

                <b>2. Matboard</b>

                <br/><br/>

                Based on the frame size above, you will decide the matboard size. If you chose IKEA's {this.state.frameRec1} inch frame, you can go for {this.state.frameRec1} inch matboard with {this.state.openingSize} inch opening.

                <br/><br/>

                Please note that your opening should be a quarter inch smaller than your diploma size as your matboard and your diploma needs an overlap. For instance, as {this.state.schoolName} diplomas are {this.state.diplomaSize} inches, you can enter {this.state.openingSize} as your opening.

                <br/><br/>

                <LazyLoad>
                    <img src={photo4} className={"blog-content-photo"}/>
                </LazyLoad>

                <br/><br/>

                Colors:

                <br/><br/>

                {this.state.schoolName} clors are {this.state.schoolColor}. We can do the primary mat in our "Smooth White" or off-white "China White" and secondary mat in gold to give some lift on your diploma.

                <br/><br/>

                Alternatively, we can also do "Smooth White" or "China White" as primary mat and have {this.state.schoolColor} as your secondary mat to have your school pride.


                <br/><br/>

                <LazyLoad>
                    <img src={photo5} className={"blog-content-photo"}/>
                </LazyLoad>

                <br/><br/>

                Text & Logo:

                <br/><br/>

                If you would like to your school name, logo, or your name plus degree designations (like BA, BS, RN, NP, MD, PharmD, DDS, JD, LLM etc), we recommend you to go with gold, silver, or same color as secondary matting as your text color.

                <br/><br/>


                <LazyLoad>
                    <img src={photo6} className={"blog-content-photo"}/>
                </LazyLoad>

                <br/><br/>


                More Options:

                <br/><br/>

                Going further, you can add more things to your frame. For instance, you can add tassel, graduation photos, medallion, other certificate of honors to your college degree frames. You just have to get a bigger frame and add additional openings like below. Instead of having a separate photo frame for your graduation photo, you can display them alongside your tassel and degree. It will be a memorable graduation gift to yourself.

                <br/><br/>

                If you are have masters, you can actually frame your bachelor’s and master’s degrees al together. You can create a double diploma frame. Most of the college diploma frames are mass produced for single diploma needs. With 100% custom design and truly highest quality materials, you can get yourself a graduation gifts at a bargain.

                <br/><br/>

                <LazyLoad>
                    <img src={photo7} className={"blog-content-photo"}/>
                </LazyLoad>

                <br/><br/>

                <b>3. Putting them together</b>

                <br/><br/>

                In a couple of days, you will get your frames (that you ordered separately) and your matboard (from us). Once your frames and matboards arrive, you can put them together easily.

                <br/><br/>

                Most of the time, you don't need to use the tape or glue to put them in to the frame. You can just use the pressure between your glass and your backing board of your frame to put them there.

                <br/><br/>

                If the pressure is not adequate, you can get a easily removable artist tapes to adhere them to the back of the matboard. You want to make your that your tapes can be removed easily, just in case you might need to take the diploma out in the future for other purposes.

                <br/><br/>

                **Just a quick note -- it is a good practice to take a picture or scan your diploma as you might need to send the file when you change your jobs verify your qualifications. :)

                <br/><br/>

                That's it. You now know how to frame your diploma without spending too much money!

                <br/><br/>

                If you have any questions, please feel free to reach out us at hello@custommat.ca. We would be more than happy to help you with your custom framing needs! :)

                <br/><br/>

                CustomMat runs with 100% Happiness Guaranteed Policy – if you are not happy with your order, let us know and we will make it right. We have a very lenient exchanges and return policy. If there are any issues with your matboard, we got you covered.



                <div className={"visit-custom-mat-button"}>
                    <a href={"/diploma-frame-matboards"} className={"carousel-read-more-button"}>
                        Visit Custom Mat
                    </a>
                </div>

                <div className={"carousel-subject-subhead-type2"}>
                    Photo Gallery
                </div>

                <LazyLoad>
                    <img src={photo1} className={"blog-content-photo"}/>
                    <img src={photo2} className={"blog-content-photo"}/>
                    <img src={photo4} className={"blog-content-photo"}/>
                    <img src={photo5} className={"blog-content-photo"}/>
                    <img src={photo6} className={"blog-content-photo"}/>
                    <img src={photo7} className={"blog-content-photo"}/>
                </LazyLoad>

                <div className={"visit-custom-mat-button"}>
                    <a href={"/"} className={"carousel-read-more-button"}>
                        Visit Custom Mat
                    </a>
                </div>



            </div>

            <div>
                <div className="elfsight-app-7688ab8e-8d1b-4130-9172-7c7f3e0d45b4"></div>
            </div>



        </div>
    }
}