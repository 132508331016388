import React from 'react';
import backgorundPhoto from "../../../resources/images/blog/blackAndWhitePhotoFraming/kayejcatapang.jpg";

import photo1 from "../../../resources/images/blog/blackAndWhitePhotoFraming/kayejcatapang.jpg";
import photo2 from "../../../resources/images/blog/blackAndWhitePhotoFraming/kayejcatapang-2.jpg";
import photo3 from "../../../resources/images/blog/blackAndWhitePhotoFraming/ourhomeinthewoodstock.jpg";
import photo4 from "../../../resources/images/blog/blackAndWhitePhotoFraming/thegrguide.jpg";
import photo5 from "../../../resources/images/blog/blackAndWhitePhotoFraming/laurenminointeriors - 1.jpg";
import photo6 from "../../../resources/images/blog/blackAndWhitePhotoFraming/laurenminointeriors -2.jpg";
import photo7 from "../../../resources/images/blog/blackAndWhitePhotoFraming/laurenminointeriors - 3.jpg";
import photo8 from "../../../resources/images/blog/blackAndWhitePhotoFraming/laurenminointeriors - 4.jpg";
import photo9 from "../../../resources/images/blog/blackAndWhitePhotoFraming/mama.norwood - 1.jpg";
import video1 from "../../../resources/images/blog/blackAndWhitePhotoFraming/jessyywongg.mp4";

import {Helmet} from "react-helmet";
import LazyLoad from 'react-lazyload';

export default class BlackAndWhitePhotos extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        };
    };

    componentDidMount() {

    }

    render() {
        return <div className={"blog-app-container"}>

            <Helmet>
                <title>Black and White Photography & Framing</title>
                <meta name="description" content="We're all about that black and white! One of our favorite things to do lately is take photos in shades like these, which create such an elegant look for your gallery wall. "/>
            </Helmet>

            <div className={"carousel-subject-head"} style={{textAlign: "center"}}>
                Black and White Photography & Framing
            </div>

            <div className={"carousel-subject-subhead-type2"}>
                Photography Framing
            </div>
            <div className={"carousel-subject-subhead-type3"}>
                Project created by Paris K - Project Blogger @ Custom Mat
            </div>

            <LazyLoad>
                <img src={backgorundPhoto} className={"blog-carousel-photo"}/>
            </LazyLoad>
            <div className={"blog-article-photo-description"}>
                Customer photo from @kayejcatapang
            </div>

            <div className={"blog-article-content"}>

                <b>Quick Intro on Black and White Photography</b>

                <br/><br/>

                We're all about that black and white! One of our favorite things to do lately is take photos in shades like these, which create such an elegant look for your gallery wall.

                <br/><br/>

                You can put together a spectacular display by using color pictures as well but there's just something about having every inch covered with stark contrast between light parts (the sky) versus dark areas such as the shadows underneath some furniture. The contrast creates a dynamic display.

                <br/><br/>

                <iframe className={"design-guide-illustration-video"} src={video1} frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen></iframe>

                <br/><br/>

                Gallery walls are an easy and affordable way to display all your favorite family photos, travel memories or even just art you've collected over the years. There is no rule book when it comes down how we want our homes decorated so sometimes they change from one trend-to another, but classic is classic. And the black and white photo gallery wall trends are in now. They are not going anywhere anytime soon.

                <br/><br/>

                <LazyLoad>
                    <img src={photo2} className={"blog-content-photo"}/>
                </LazyLoad>
                <div className={"blog-article-photo-description"}>
                    Customer photo from @kayejcatapang
                </div>

                <br/>

                Vintage is back and this time it's not going anywhere! If you're feeling the same way, then read on for our tutorial about how to get an eclectic gallery wall without breaking your budget. We'll take some thrifted picture frames from around town (or maybe even yours) elevate them with custom matting designed just for us by Custom Mat--and voila: A unique piece of artwork that will fit right into any decorating style imaginable while still remaining true vintage at heart

                <br/><br/>

                <b>What Frames Should I Get? Black or White?</b>

                <br/><br/>

                <b>Black Frames</b>

                <br/><br/>

                The black frame is the perfect way to keep your photos clean and organized, while still making them stand out. These frames won’t distract from what really matters here - you memories- but instead just add onto it! A large white matboard will give a modern look that's guaranteed not be forgettable or gone anytime soon with its simple style.

                <br/><br/>

                <LazyLoad>
                    <img src={photo3} className={"blog-content-photo"}/>
                    <div className={"blog-article-photo-description"}>
                        Customer photo from @ourhomeinthewoodstock
                    </div>
                    <img src={photo4} className={"blog-content-photo"}/>
                    <div className={"blog-article-photo-description"}>
                        Customer photo from @ourhomeinthewoodstock
                    </div>
                </LazyLoad>


                <br/><br/>

                <b>White Frames</b>

                <br/><br/>

                Why not go with a classic look? A white frame is always appropriate for displaying family photos. The grid-style layout will make sure that your wall remains neat and organized, no matter what you display in it!

                <br/><br/>

                <LazyLoad>
                    <img src={photo5} className={"blog-content-photo"}/>
                    <img src={photo6} className={"blog-content-photo"}/>
                    <img src={photo7} className={"blog-content-photo"}/>
                    <img src={photo8} className={"blog-content-photo"}/>

                    <div className={"blog-article-photo-description"}>
                        Customer photo from @laurenminointeriors
                    </div>
                </LazyLoad>

                <br/><br/>

                <b>Wood Frames</b>

                <br/><br/>

                The gallery wall is a great way to show off your favorite family photos in an elegant display. The wood frames tie into the monochromatic color scheme well and create something that will catch any guest’s attention! You can never go wrong with framing pictures for this type of eye-catching project, so be sure choose wisely from among all their smiling faces—you won't regret it because these are some cute kids (and adults)!

                <br/><br/>

                <LazyLoad>
                    <img src={photo9} className={"blog-content-photo"}/>

                    <div className={"blog-article-photo-description"}>
                        Customer photo from @mama.norwood
                    </div>
                </LazyLoad>


                <br/><br/>

                <b>Gold Frames</b>

                <br/><br/>

                Imagine the look on your face when you walk into a home that's been beautifully decorated with black and white photos. Metallic gold frames will give any wall of pictures an elegant, luxurious touch without making it seem too cluttered! For something extra special we recommend mixing up how many frames are displayed by laying one across several others for an asymmetrical effect (the photo featured above shows this). If elegance isn't really what catches somebody’s eye, then maybe creativity could – think about creating layer after beautiful layer.

                <br/><br/>

                <b>Go for a Large Matting</b>

                <br/><br/>

                When you have a lot of photos, it can be difficult to find space for them all. Large matboards are an excellent way not only make your wall look more spacious but also give each photo that cohesive look, so they stand out from one another!

                <br/><br/>

                <LazyLoad>
                    <img src={photo5} className={"blog-content-photo"}/>
                    <img src={photo6} className={"blog-content-photo"}/>
                    <div className={"blog-article-photo-description"}>
                        Customer photo from @laurenminointeriors
                    </div>
                </LazyLoad>

                <b>Summary</b>

                <br/><br/>

                Classic is classic. You can't go wrong with black and white photos. Black and white photos will look great against any decor style. Whether you want to display them in a colorful room or keep things simple, these classic images are an excellent addition for anyone who needs some extra flair without taking away from their other decorative pieces!

                <br/><br/>

                <div className={"visit-custom-mat-button"}>
                    <a href={"/"} className={"carousel-read-more-button"}>
                        Visit Custom Mat
                    </a>
                </div>

                <div className={"carousel-subject-subhead-type2"}>
                    Photo Gallery
                </div>

                <LazyLoad>
                    <img src={backgorundPhoto} className={"blog-content-photo"}/>
                    <img src={photo1} className={"blog-content-photo"}/>
                    <img src={photo2} className={"blog-content-photo"}/>
                    <img src={photo3} className={"blog-content-photo"}/>
                    <img src={photo4} className={"blog-content-photo"}/>
                    <img src={photo5} className={"blog-content-photo"}/>
                    <img src={photo6} className={"blog-content-photo"}/>
                    <img src={photo7} className={"blog-content-photo"}/>
                    <img src={photo8} className={"blog-content-photo"}/>
                    <img src={photo9} className={"blog-content-photo"}/>
                </LazyLoad>

                <div className={"visit-custom-mat-button"}>
                    <a href={"/"} className={"carousel-read-more-button"}>
                        Visit Custom Mat
                    </a>
                </div>


            </div>



            <div>
                <div className="elfsight-app-7688ab8e-8d1b-4130-9172-7c7f3e0d45b4"></div>
            </div>



        </div>
    }
}