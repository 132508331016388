export default function checkMatAndWindowSize (matWidth, matHeight, windowWidth, windowHeight) {


  var returnVal = [];
  // all returns in true and false
  // [is mat width valid?, is matHeightValid?, is windowWidthvalid?, is Window height valid?, is Mat longer than window height?, is mat Height longer than window height?

  if (matWidth !== undefined && matWidth !== 0 && isNaN(matWidth) === false) {
    returnVal[0] = true;
  } else {
    returnVal[0] = false;
  }

  if (matHeight !== undefined && matHeight !== 0 && isNaN(matHeight) === false) {
    returnVal[1] = true;
  } else {
    returnVal[1] = false;
  }

  if (windowWidth !== undefined && windowWidth !== 0 && isNaN(windowWidth) === false) {
    returnVal[2] = true;
  } else {
    returnVal[2] = false;
  }

  if (windowHeight !== undefined && windowHeight !== 0 && isNaN(windowHeight) === false) {
    returnVal[3] = true;
  } else {
    returnVal[3] = false;
  }

  if (matWidth > windowWidth) {
    returnVal[4] = true;
  } else {
    returnVal[4] = false;
  }

  if (matHeight > windowHeight) {
    returnVal[5] = true;
  } else {
    returnVal[5] = false;
  }

  return returnVal
}
//add if total product price goes over 50 waive the shipping cost