import firebase from 'firebase';

const config = {
  apiKey: "AIzaSyCybKO0BgI8LG_NAC242CbzInTDdPY-dA0",
  authDomain: "custom-mat-canada-prod.firebaseapp.com",
  databaseURL: "https://custom-mat-canada-prod.firebaseio.com",
  projectId: "custom-mat-canada-prod",
  storageBucket: "custom-mat-canada-prod.appspot.com",
  messagingSenderId: "980134802940"
};

firebase.initializeApp(config);
export default firebase;