import React from "react";
import CanvasApp from "../app-canvas/canvasApp";
import {Helmet} from "react-helmet";

export default class StartDesigningPage extends React.Component {
  render() {
    return <div className={`start-designing-page-container`}>
      <Helmet>
        <title>Custom Mat Boards • Design & Order Online • Deliver to Canada</title>
        <meta name="description" content="We customize your mat boards. Design and order online with us. We deliver to anywhere in US & Canada. No minimum order quantity. No or very low shipping costs."/>
      </Helmet>

      <h1 className={`landing-page-banner`}>
        Custom Mat Boards • Design & Order Online • Free or Low Cost Shipping • Ship to US & Canada
      </h1>
      <CanvasApp/>
    </div>
  }
}