import React from 'react';
import photo1 from "../../../resources/images/galleryWall/coverPhoto2.jpg";
import signaturePiece from "../../../resources/images/galleryWall/signaturePiece.jpg";
import photo3 from "../../../resources/images/galleryWall/photo1.jpg";
import photo4 from "../../../resources/images/galleryWall/photo2.jpg";
import squareFrame from "../../../resources/images/galleryWall/SquareGalleryWall.jpg";
import kendraGalleryWall from "../../../resources/images/galleryWall/KendraGalleryWall1.jpg";
import staircaseGalleryWall from "../../../resources/images/galleryWall/staircase.jpg";
import LazyLoad from "react-lazyload";
import {Helmet} from "react-helmet";
import IGLogo from "../../../resources/logo/IG-Logo.svg";

export default class GalleryWall extends React.Component {

    constructor(props) {
        super(props);
    };

    componentDidMount() {

    }


    fetchInspirationPhotos() {

        return this.state.photos.map((item, key) => {

            if (key < this.state.fetchIndex) {
                return <div className="inspiration-posts-col">
                    <img className={"inspiration-post-col-photo"} id={"inspiration-post-col-photo-main-page"} alt={
                        this.props.french ? "Photos réelles de nos clients." : "Actual photos from our customers."
                    } src={item.path}/>
                    <div className={"inspiration-post"}>
                        <div className={"inspiration-logo-name"}>
                            @{item.source.igHandle}
                        </div>
                        <div className={"inspiration-logo-IG-POST-container"}>
                            <a href={item.source.igPath}>
                                <img className={"inspiration-logo-IG-POST"} src={IGLogo} alt={this.props.french ? "Cliquez ici pour visiter la page Instagram de la publication.\n" : "Click here to visit the Instagram page for the post."}/>
                            </a>

                        </div>
                    </div>
                </div>
            }

        })
    }

    render() {
        return <div className={"blog-app-container"}>

            <Helmet>
                <title>
                    Mastering the Art of Affordable Gallery Wall Creation: Elevate Your Space Without Breaking the Bank
                </title>
                <meta name="description" content="Learn how to create an impressive gallery wall without breaking the bank. Discover the magic of well-designed gallery walls that transform your space, banish awkward blank walls, and create a sense of unity with cherished family photos."/>
            </Helmet>

            <h1 className={"carousel-subject-head"} style={{textAlign: "center"}}>
                How to Create A Gallery Wall (without breaking a bank)
            </h1>

            <div className={"carousel-subject-subhead-type2"}>
                Ideas
            </div>

            <LazyLoad>
                <img src={photo1} className={"blog-carousel-photo"}/>
            </LazyLoad>

            <div className={"blog-article-content"}>

                So, you've probably noticed how trendy gallery walls are right now, right? Whether you're jazzing up your entryway, adding pizzazz to the living room, or giving the dining room a makeover, the home decor and interior design scene is all about these awesome gallery walls.

                <br/><br/>

                And guess what? They're not just about looking cool – there's some real magic going on. See, a well-put-together gallery wall (1) banishes those awkward blank wall moments, (2) actually tricks the eye into thinking your small space is much bigger, and (3) when you throw in those cherished family photos, it totally brings in that warm and fuzzy feeling of unity and togetherness. Cool, huh?

                <br/><br/>


                <LazyLoad>
                    <img src={"https://images.squarespace-cdn.com/content/v1/55c41255e4b0700d65dd29ca/1603730687441-BZC4WQYGXFLT1SMRJ1SS/oversized+mat+grid+gallery+wall+6+copy.jpg?format=1500w"} className={"blog-content-photo"}/>
                </LazyLoad>

                <h2>
                    Gallery Wall? What's that all about?
                </h2>

                So, picture this: a gallery wall is like this awesome arrangement of artworks and photos that you hang up together using a bunch of cool picture frames. But here's the fun part – it's not just limited to those fancy wall hangings or art prints.

                <br/><br/>

                Nope, you can mix it up big time. We're talking canvas prints, snapshots from family photos, those cute polaroids you've been collecting, and basically any kind of wall art you're into. Frames? Oh yeah, you can go matchy-matchy or mix them up – totally your call.

                <br/><br/>

                But wait, there's more! Instead of staring at a bare wall, this gallery wall thing becomes the star of the show — it becomes a focal point. So, if you're decking out your home office, go for stuff that'll get your creative juices flowing, like wall art prints or motivational quotes.

                <br/><br/>

                Now, if it's your living room or dining room, slap on some family pics or shots from your big day – it's all about that warm and fuzzy vibe. And for your entryway? Well, pick something that screams "Welcome to my awesome place!"

                <br/><br/>

                See? Gallery walls aren't just walls anymore – they're your space's best buddies.

                <br/><br/>

                <LazyLoad>
                    <img src={"https://images.squarespace-cdn.com/content/v1/55c41255e4b0700d65dd29ca/1631567989109-8XV6HFILACGW8H71OACD/Eclectic+Gallery+Wall+DIY+Tutorial_0015.jpg?format=1500w"} className={"blog-content-photo"}/>
                </LazyLoad>

                <h2>
                    How to Create a Gallery Wall?
                </h2>

                There are couple ways to go about this.

                <br/>

                <ol>
                    <li>
                        <b>First, you can go for a same sized gallery wall art and photo frames.  </b> You can either display in a hotel hall way style, set of large signature pieces, or you can go in a smaller, grid style wall frame sets.
                    </li>

                    <br/>

                    <li>
                        <b>Second, you can go for a different size of picture frames. </b> Normally with a picture frame with a largest piece of artwork in the center being a focal point surrounded by the smaller pieces of arts in a small set of picture frames.
                    </li>
                </ol>

                So, let's chat about whipping up your very own photo wall. No worries, there's a couple of cool ways to tackle this.

                <br/><br/>

                <b>First off, </b>you could keep it super sleek with a bunch of the <b> same-sized artworks and picture frames. </b> It’s almost like a template. You can’t go wrong with these.

                <br/><br/>

                Imagine giving off that chic hotel hallway vibe, where you've got a bunch of pieces that are all rocking the same size. It would be a classic picture wall — it works every time.

                <br/><br/>

                <b>Or, </b> if you're feeling it, you can <b> mix things up with a set of larger signature art pieces. </b> Oh, but don't stop there – you can also go for a grid-style arrangement with smaller frames. You know, mix and match, keep it fresh.

                <br/><br/>

                Now, here's option number two: you can get all artsy with varying picture frame sizes. Picture this – your main star, the focal point, is this big pieces of art in the middle. And it's like a magnet, pulling in all these smaller artworks that are rocking smaller frames. It's like a crew gathering around their superstar friend, but in black frame form.

                <br/><br/>

                So, there you have it, two cool ways to dive into the gallery wall game. Just pick the wall space design that tickles your fancy the most!

                <br/><br/>

                <h2>
                    How much would it cost?
                    <br/>
                    So, let's dive into the dollars and cents, shall we?
                </h2>

                There's like three routes you can take with this:

                <ol>
                    <li>
                        <b>The Fancy Route: Custom Framing</b>

                        <br/>

                        Going all-in with custom picture framing is pretty swanky. But heads up, it's not exactly penny-pinching territory. We're talking several hundred dollars for each frame.

                        <br/><br/>

                        And if you've got this massive centerpiece that's screaming for attention, that could even run you over 500 bucks per frame. Smaller pieces, though, they might hover around 200 to 500 dollars each, if you're getting them done at a custom picture framing spot.
                    </li>

                    <br/>

                    <li>
                        <b>The Clever Mix: Standard Frames + Custom Matboards</b>

                        <br/>

                        Now, if you're thinking budget vibes, here's a slick move. Combine regular-sized picture frames with custom-sized matboards. You can snag those standard frames online and then get crafty with matboards that fit your style.

                        <br/><br/>

                        Boom, custom feels without the splurge. And the price tag? Way more wallet-friendly – think 20 to 100 bucks per frame.
                    </li>
                </ol>


                <LazyLoad>
                    <video className={"what-is-mat-image-v2"} loop="true" autoPlay="autoplay" alt={"Exploring Matboards: An Informative Illustrative Video. Discover the mechanics behind matboards in this engaging video. Learn about an innovative approach to custom framing—pairing a standard-sized frame from online or retail stores with our bespoke matboards."} controls muted
                           src="https://s3.ca-central-1.amazonaws.com/custommat.landing.v2/0_What-is-a-matboard_1920x1290_v1+(1).mp4"></video>
                </LazyLoad>

                <br/>

                <h2>Which approach to Gallery wall would be better?</h2>

                There’s no better approach. Both are popular. however, the first approach with uniform size of artwork and picture frames would be easier — as they are more standardized. Unless you have a specific look that you are looking for (or have a model interior design vibe that you want to replicate), we would recommend you with the first approach, as it’s much easier to shop.

                <br/><br/>

                <h2>How to design a Gallery Wall.</h2>

                So, you're all about nailing that gallery wall look, huh? Well, guess what – there's a bunch of paths you can stroll down.

                <br/><br/>

                <h2>1. Inspiration Hunting and Home Decor Imitation</h2>

                Now, let's start with the smoothest move. Take a little gallery wall tour right here, checking out what our awesome customers have pulled off.

                <br/><br/>

                Find that style and vibe that totally vibes with you, you know what I mean? Once you've got that feeling, it's all about making your space sing the same tune.

                <br/><br/>

                Hey, a lot of the folks showing off their skills are photographers and interior design whizzes, so you're basically snagging inspiration from the pros.

                <br/><br/>

                Alright, time for a little secret-sharing. We spill the beans on the frames and custom matboard sizes that brought those jaw-dropping projects to life. It's like a behind-the-scenes peek at the magic ingredients. And guess what? You can grab those frames and matboards online – easy peasy.

                <br/><br/>

                Oh, and no worries if your art prints or photos aren't ready yet, you can order those online too (unless you've got 'em already stashed away). And there you go – the simplest route to getting that oh-so-cool gallery wall happening in your world.

                <br/><br/>

                So, pick a vibe, snag those frame secrets, and let your walls do the talking. It's like magic, seriously!

                <br/><br/>

                <LazyLoad>
                    <img className={"blog-article-photo-two-image"} src={photo3}/>
                    <img className={"blog-article-photo-two-image"} src={photo4}/>
                </LazyLoad>

                <h2>2. Or Plan your Layout</h2>

                Now, if you're all about adding that personal touch, check this out. Imagine this: you grab some art paper or even artist tape – cool, right? Then, roll up those sleeves and start your gallery wall masterplan.

                <br/><br/>

                Here's the deal – you take the size of each frame and map it out. Just like, picture it on the wall. You're like an art detective, figuring out where everything fits best.

                <br/><br/>

                Step two: grab this big piece of Kraft paper – you know, that brown stuff that looks kinda rustic? Trace all your frames on it, then grab your trusty scissors and cut those shapes out.

                <br/><br/>

                Now, this is the fun part. Slap 'em on your wall, like a sneak peek of the future gallery. And don't worry, you're not using super glue or anything – just some tape to keep things in place.

                <br/><br/>

                Ta-da! It's like you're test-driving your gallery wall, but without any nail holes. So, if you're all about that custom vibe, this hands-on approach is your ticket to gallery wall greatness.

                <br/><br/>

                <h2>What kind of Frames I should go for? </h2>


                <LazyLoad>
                    <img className={"blog-content-photo"} src={"https://www.custommat.ca/static/media/jules_interiors-IG-5.22b7ddd8.PNG"} style={{width: "50%", marginLeft: "25%"}}/>
                </LazyLoad>

                <h2>1. Wooden Frames: Warm and Classic Vibe</h2>

                Okay, so wooden frames are like the rockstars of the frame world. They're classic, cozy, and they get along famously with wooden furniture. Plus, they're the chameleons of the frame world – tons of colors, styles, and sizes to pick from. Basically, you've got options galore, my friend.

                <br/><br/>

                <LazyLoad>
                    <img className={"blog-content-photo"} src={"https://www.custommat.ca/static/media/nestingdesign-IG.ed9a31f6.PNG"} style={{width: "50%", marginLeft: "25%"}}/>
                </LazyLoad>

                <h2>2. Metal Frames: Sleek and Minimalist Vibes</h2>

                Now, if you're all about that sleek and minimalist style – listen up. Metal frames are totally having a moment, especially with that IKEA-esque vibe that's spreading like wildfire.

                <br/><br/>

                These frames are all about slim and lightweight. Perfect if you're rocking a cozy space. And yeah, you'll spot them in classic shades like black, gold, and silver.

                <br/><br/>

                Now, don't think "limited" is a bad thing – it's like having a capsule wardrobe for your frames. They're simple, chic, and blend like a dream with any home decor or prints you throw their way.

                <br/><br/>

                So there you have it, wooden or metal – whichever floats your frame-loving boat!

                <br/><br/>

                <h2>Where Do I get the Frames From?</h2>

                <b>1. Online Stores</b>

                <ul>
                    <li>
                        Amazon.com / Amazon.ca (Canada)
                    </li>
                    <li>
                        IKEA.com / IKEA.ca (Canada)
                    </li>
                    <li>
                        PosterStore
                    </li>
                </ul>

                <b>2. Offline Stores</b>

                <ul>
                    <li>
                        IKEA
                    </li>
                    <li>
                        Michael’s
                    </li>
                    <li>
                        Hobby Lobby
                    </li>
                    <li>
                        Blick
                    </li>
                    <li>
                        Costco
                    </li>
                    <li>
                        Target
                    </li>
                    <li>
                        Walmart
                    </li>
                    <li>
                        Homesense
                    </li>
                </ul>

                <br/>

                <h2>What kind of artwork do you use for Gallery Wall?</h2>

                Here are some of the artwork for your gallery wall ideas:

                <ol>
                    <li>
                        Family Photos
                    </li>
                    <li>
                        Wedding Photos
                    </li>
                    <li>
                        Old Black and White Photos
                    </li>
                    <li>
                        Tour Posters of your favorite musicians
                    </li>
                    <li>
                        Magazine Covers
                    </li>
                    <li>
                        Favorite Vinyls / LPs
                    </li>
                    <li>
                        Photographs
                    </li>
                    <li>
                        Prints from the Museum
                    </li>
                    <li>
                        Photos and Maps of your Favorite City
                    </li>
                    <li>
                        Drawings
                    </li>
                    <li>
                        Collages
                    </li>
                    <li>
                        Typography
                    </li>
                    <li>
                        Textiles
                    </li>
                    <li>
                        Wall Decals
                    </li>
                    <li>
                        Maps
                    </li>
                    <li>
                        Nature
                    </li>
                    <li>
                        Postcards and Travel Memorabilia
                    </li>
                    <li>
                        Sports Jersey
                    </li>
                    <li>
                        Children’s Art
                    </li>
                    <li>
                        Digital Art and Graphic Illustration
                    </li>
                    <li>
                        Minimalist Art
                    </li>
                    <li>
                        Vintage
                    </li>
                    <li>
                        Color Themed
                    </li>
                </ol>

                <br/>

                <h2>
                    Shop by Looks<br/>
                    Wall Decor Ideas & Inspirations
                </h2>

                <br/><br/>

                Best Gallery Wall home decor among our Customers:

                <div className={"visit-custom-mat-button"}>
                    <a href={"https://www.custommat.ca/how-it-works"} className={"carousel-read-more-button"}>
                        Visit Custom Mat & Learn More
                    </a>
                </div>
            </div>

            <div className={"gallery-wall-content-container"}>
                <div className={"gallery-wall-content-left"}>
                    <div style={{fontSize: "26px", lineHeight: "1.5"}}>
                        1. Hotel Style
                        <br className={"mobile-no-display"}/>
                        Hallway Gallery Wall #1
                        <br className={"mobile-no-display"}/>
                        (Wooden Frame)

                    </div>

                    <br/><br/>
                    An adored, timeless layout that combines tradition and modernity. Make a row of hotel story gallery walls using black wooden frames.
                    <br/><br/>

                    <b>How to create this look?</b>
                    <ul>
                        <li>
                            <span>IKEA 24x35.75" </span>
                            <a target={"_blank"} href={"https://www.ikea.com/ca/en/p/ribba-frame-black-90301621"} style={{textDecoration: "underline"}}>
                            Ribba Frames
                            </a>
                        </li>
                        <li>
                            14x11" Black & White Photo Prints
                        </li>
                        <li>
                            <a target="_blank" href={"/customize-matboards?mWidth=24&mHeight=35.75&wWidth=13.75&wHeight=10.75&mTop=9&mLeft=5.125&mRight=5.125&mBottom=16&mColor=smoothWhite&dmColor=none&dmWidth=0&backing=false&oval=false&ctr=false"} style={{textDecoration: "underline"}}>24x35.75" Matboard</a>
                            <span> with opening for 14x11 inch prints</span>
                        </li>
                    </ul>

                    <br/>

                    <div className={"inspiration-post"} style={{background: "none"}}>
                        <div className={"inspiration-logo-name"} >
                            From our customer @inspiration.home.mtl
                        </div>
                        <div className={"inspiration-logo-IG-POST-container"}>
                            <a target={"_blank"} href={"https://www.instagram.com/p/CL4HLOuHbS6/"}>
                                <img className={"inspiration-logo-IG-POST"} src={IGLogo} alt={this.props.french ? "Cliquez ici pour visiter la page Instagram de la publication.\n" : "Click here to visit the Instagram page for the post."}/>
                            </a>

                        </div>
                    </div>

                </div>
                <div className={"gallery-wall-content-right"}>
                    <LazyLoad>
                        <img className={"gallery-wall-content-image"} src={"https://www.custommat.ca/static/media/inspiration.home.mtl-IG.a887c80d.PNG"}/>
                    </LazyLoad>

                </div>
            </div>

            <div className={"gallery-wall-content-container"}>
                <div className={"gallery-wall-content-left"}>
                    <LazyLoad>
                        <img className={"gallery-wall-content-image"} style={{float: "right"}} src={"https://www.custommat.ca/static/media/nestingdesign-IG.ed9a31f6.PNG"}/>
                    </LazyLoad>
                </div>
                <div className={"gallery-wall-content-right"}>
                    <div style={{fontSize: "26px", lineHeight: "1.5"}}>
                        2. Hotel Style <br className={"mobile-no-display"}/>
                        Hallway Gallery Wall #2 (Half Wall) <br className={"mobile-no-display"}/>
                    </div>

                    <br/><br/>
                    In rooms with classic moulding already adorning the bottom half of the walls, there's a simple yet effective way to elevate the aesthetic. Introduce thin, classic metal frames to fashion a gallery wall that effortlessly harmonizes with the room's overall ambiance.
                    <br/><br/>

                    <b>How to create this look?</b>
                    <ul>
                        <li>
                            <span>IKEA 19.75x27.5 inch </span>
                            <a target={"_blank"} href={"https://www.ikea.com/ca/en/p/lomviken-frame-gold-colour-80419602"} style={{textDecoration: "underline"}}>
                                Lomivken Frames
                            </a>
                        </li>
                        <li>
                            10x8 Inch Photo Prints
                        </li>
                        <li>
                            <a target="_blank" href={"/customize-matboards?mWidth=19.75&mHeight=27.5&wWidth=9.75&wHeight=7.75&mTop=5&mLeft=5&mRight=5&mBottom=14.75&mColor=smoothWhite&dmColor=none&dmWidth=0&backing=false&oval=false&ctr=false"} style={{textDecoration: "underline"}}>19.75 inch Matboards</a>
                            <span> with opening for 10x8 inch prints</span>
                        </li>
                    </ul>


                    <div className={"inspiration-post"} style={{background: "none"}}>
                        <div className={"inspiration-logo-name"} >
                            From our customer @nestingdesign
                        </div>
                        <div className={"inspiration-logo-IG-POST-container"}>
                            <a target={"_blank"} href={"https://www.instagram.com/p/CPV_VKAsOvC/"}>
                                <img className={"inspiration-logo-IG-POST"} src={IGLogo} alt={this.props.french ? "Cliquez ici pour visiter la page Instagram de la publication.\n" : "Click here to visit the Instagram page for the post."}/>
                            </a>

                        </div>
                    </div>
                </div>
            </div>

            <div className={"gallery-wall-content-container"}>
                <div className={"gallery-wall-content-left"}>
                    <div style={{fontSize: "26px", lineHeight: "1.5"}}>
                        3. Signature Piece
                        <br className={"mobile-no-display"}/>
                        Gallery Wall
                    </div>

                    <br/><br/>
                    Got a standout signature piece in mind? Let us craft a stunning gallery wall around it, complete with complementary artworks and frames. Elevate your room's ambiance with this stylish enhancement.

                    <br/><br/>

                    <b>How to create this look?</b>
                    <ul>
                        <li>
                            <span>Amazon's </span>
                            <a target={"_blank"} href={"https://www.amazon.ca/dp/B08264756M"} style={{textDecoration: "underline"}}>
                                20x20 inch Frame
                            </a>
                        </li>
                        <li>
                            10x10 inch Square Photo Prints
                        </li>
                        <li>
                            <a target="_blank" href={"/customize-matboards?mWidth=20&mHeight=20&wWidth=10&wHeight=10&mTop=5&mLeft=5&mRight=5&mBottom=5&mColor=smoothWhite&dmColor=none&dmWidth=0&backing=false&oval=false&ctr=true"} style={{textDecoration: "underline"}}>20x20 inch Matboards</a>
                            <span> with opening for 10x10 inch prints</span>
                        </li>
                    </ul>

                    <br/>

                    <div className={"inspiration-post"} style={{background: "none"}}>
                        <div className={"inspiration-logo-name"} >
                            From our customer @jules_interiors
                        </div>
                        <div className={"inspiration-logo-IG-POST-container"}>
                            <a target={"_blank"} href={"https://www.instagram.com/p/CYXFa0clrv7/"}>
                                <img className={"inspiration-logo-IG-POST"} src={IGLogo} alt={this.props.french ? "Cliquez ici pour visiter la page Instagram de la publication.\n" : "Click here to visit the Instagram page for the post."}/>
                            </a>

                        </div>
                    </div>

                </div>
                <div className={"gallery-wall-content-right"}>
                    <LazyLoad>
                        <img className={"gallery-wall-content-image"} src={signaturePiece} style={{width: "100%"}}/>
                    </LazyLoad>

                </div>
            </div>

            <div className={"gallery-wall-content-container"}>
                <div className={"gallery-wall-content-left"}>
                    <LazyLoad>
                        <img className={"gallery-wall-content-image"} style={{float: "right"}} src={"https://www.custommat.ca/static/media/helloimlaurie-IG-1.fd7d4b5a.PNG"}/>
                    </LazyLoad>
                </div>
                <div className={"gallery-wall-content-right"}>
                    <div style={{fontSize: "26px", lineHeight: "1.5"}}>
                       4. Mix & Match Artist Wall
                        <br className={"mobile-no-display"}/>
                    </div>

                    <br/><br/>
                    Elevate your workshop or home office with a dose of inspiration. Mix and match your favorite prints alongside your own artwork and photos, all beautifully displayed in a colorful array of frames.

                    <br/><br/>

                    <b>How to create this look?</b>
                    <ul>
                        <li>
                            <span>Various Frames from IKEA and Amazon</span>
                        </li>
                        <li>
                            Various Prints
                        </li>
                        <li>
                            <a target="_blank" href={"/customize-matboards"} style={{textDecoration: "underline"}}>Customized matboards</a>
                            <span> to find a perfect fit for all your artowrks.</span>
                        </li>
                    </ul>

                    <br/>

                    <div className={"inspiration-post"} style={{background: "none"}}>
                        <div className={"inspiration-logo-name"}>
                            From our customer @helloimlaurie
                        </div>
                        <div className={"inspiration-logo-IG-POST-container"}>
                            <a target={"_blank"} href={"https://www.instagram.com/p/CDmgfmkAaoH/"}>
                                <img className={"inspiration-logo-IG-POST"} src={IGLogo} alt={this.props.french ? "Cliquez ici pour visiter la page Instagram de la publication.\n" : "Click here to visit the Instagram page for the post."}/>
                            </a>

                        </div>
                    </div>
                </div>
            </div>

            <div className={"gallery-wall-content-container"}>
                <div className={"gallery-wall-content-left"}>
                    <div style={{fontSize: "26px", lineHeight: "1.5"}}>
                        5. Dining Room Gallery Wall
                    </div>

                    <br/><br/>
                    Turn your dining room into a cozy haven where cherished memories come to life. Create a timeless atmosphere by printing and displaying your favorite moments on a simple three-piece gallery wall.
                    <br/><br/>

                    <b>How to create this look?</b>
                    <ul>
                        <li>
                            <span>IKEA 24 x 35 3/4 inch </span>
                            <a target={"_blank"} href={"https://www.ikea.com/ca/en/p/ribba-frame-white-30301624"} style={{textDecoration: "underline"}}>
                                White Ribba Frames
                            </a>
                        </li>
                        <li>
                            12 x 12 Inch Square Photo Prints
                        </li>
                        <li>
                            <a target="_blank" href={"/customize-matboards?mWidth=24&mHeight=35.75&wWidth=11.75&wHeight=11.75&mTop=6.125&mLeft=6.125&mRight=6.125&mBottom=17.875&mColor=smoothWhite&dmColor=none&dmWidth=0&backing=false&oval=false&ctr=false"} style={{textDecoration: "underline"}}>24 x 35 3/4 Matboards</a>
                            <span> with opening for 12 x 12 inch prints</span>
                        </li>
                    </ul>

                    <br/>

                    <div className={"inspiration-post"} style={{background: "none"}}>
                        <div className={"inspiration-logo-name"}>
                            From our customer @the78boler
                        </div>
                        <div className={"inspiration-logo-IG-POST-container"}>
                            <a target={"_blank"} href={"https://www.instagram.com/p/CLVPkzohFdr/"}>
                                <img className={"inspiration-logo-IG-POST"} src={IGLogo} alt={this.props.french ? "Cliquez ici pour visiter la page Instagram de la publication.\n" : "Click here to visit the Instagram page for the post."}/>
                            </a>

                        </div>
                    </div>

                </div>
                <div className={"gallery-wall-content-right"}>
                    <LazyLoad>
                        <img className={"gallery-wall-content-image"} src={"https://www.custommat.ca/static/media/the78boler-IG-1.8c6636e9.PNG"}/>
                    </LazyLoad>

                </div>
            </div>

            <div className={"gallery-wall-content-container"}>
                <div className={"gallery-wall-content-left"}>
                    <LazyLoad>
                        <img className={"gallery-wall-content-image"} style={{float: "left", width: "80%", marginLeft: "10%"}} src={squareFrame}/>
                    </LazyLoad>
                </div>
                <div className={"gallery-wall-content-right"}>
                    <div style={{fontSize: "26px", lineHeight: "1.5"}}>
                        6. Grid Gallery Wall
                        <br className={"mobile-no-display"}/>
                        (Square Frames)
                    </div>

                    <br/><br/>
                    Transform Your Family Room with a Cozy Gallery Wall Grid! Capture precious family moments in square frames and create a warm and inviting atmosphere in your family room.
                    <br/><br/>

                    <b>How to create this look?</b>
                    <ul>
                        <li>
                            <span>Amazon's </span>
                            <a target={"_blank"} href={"https://www.amazon.ca/dp/B08264756M"} style={{textDecoration: "underline"}}>
                                20x20 inch Frame
                            </a>
                        </li>
                        <li>
                            10x10 inch Square Photo Prints
                        </li>
                        <li>
                            <a target="_blank" href={"/customize-matboards?mWidth=20&mHeight=20&wWidth=7.75&wHeight=7.75&mTop=6.125&mLeft=6.125&mRight=6.125&mBottom=6.125&mColor=smoothWhite&dmColor=none&dmWidth=0&backing=false&oval=false&ctr=true"} style={{textDecoration: "underline"}}>20x20 inch Matboards</a>
                            <span> with opening for 8x8 inch prints</span>
                        </li>
                    </ul>

                    <br/>

                    <div className={"inspiration-post"} style={{background: "none"}}>
                        <div className={"inspiration-logo-name"}>
                            From our customer @mama.norwood
                        </div>
                        <div className={"inspiration-logo-IG-POST-container"}>
                            <a target={"_blank"} href={"https://www.instagram.com/p/CWHiLgfrbdX/"}>
                                <img className={"inspiration-logo-IG-POST"} src={IGLogo} alt={this.props.french ? "Cliquez ici pour visiter la page Instagram de la publication.\n" : "Click here to visit the Instagram page for the post."}/>
                            </a>

                        </div>
                    </div>
                </div>
            </div>

            <div className={"gallery-wall-content-container"}>
                <div className={"gallery-wall-content-left"}>
                    <div style={{fontSize: "26px", lineHeight: "1.5"}}>
                        7. Grid Galley Wall <br className={"mobile-no-display"}/>
                        (Metal Frames)
                    </div>

                    <br/><br/>
                    In the quest for a chic and heartwarming home makeover, look no further than the transformative power of grid-style metallic frames. Picture your living space adorned with an array of cherished family photos, emanating a warm sense of belonging.

                    <br/><br/>

                    <b>How to create this look?</b>
                    <ul>
                        <li>
                            <span>Amazon's 16x20 inch </span>
                            <a target={"_blank"} href={"https://www.amazon.ca/dp/B082637W42"} style={{textDecoration: "underline"}}>
                                 Silver Metal Frames
                            </a>
                        </li>
                        <li>
                            6 x 4 inch photo Prints
                        </li>
                        <li>
                            <a target="_blank" href={"/16x20-inch-matboard"} style={{textDecoration: "underline"}}>16x20 inch Matboards</a>
                            <span> with opening for 6 x 4 inch prints</span>
                        </li>
                    </ul>

                    <br/>

                    <div className={"inspiration-post"} style={{background: "none"}}>
                        <div className={"inspiration-logo-name"}>
                            From our customer @kendrafoundit
                        </div>
                        <div className={"inspiration-logo-IG-POST-container"}>
                            <a target={"_blank"} href={"https://www.kendrafoundit.com/blog/how-to-make-an-oversized-mat-gallery-wall-grid"}>
                                <img className={"inspiration-logo-IG-POST"} src={IGLogo} alt={this.props.french ? "Cliquez ici pour visiter la page Instagram de la publication.\n" : "Click here to visit the Instagram page for the post."}/>
                            </a>

                        </div>
                    </div>

                </div>
                <div className={"gallery-wall-content-right"}>
                    <LazyLoad>
                        <img className={"gallery-wall-content-image"} src={kendraGalleryWall} style={{width: "70%", marginLeft: "15%"}}/>
                    </LazyLoad>

                </div>
            </div>

            <div className={"gallery-wall-content-container"}>
                <div className={"gallery-wall-content-left"}>
                    <LazyLoad>
                        <img className={"gallery-wall-content-image"} style={{float: "right"}} src={"https://www.custommat.ca/static/media/home.made.lovely-IG-4.ea49a1b2.PNG"}/>
                    </LazyLoad>
                </div>
                <div className={"gallery-wall-content-right"}>
                    <div style={{fontSize: "26px", lineHeight: "1.5"}}>
                        8. Library Style Gallery Wall <br className={"mobile-no-display"}/>
                        with Square Frames
                    </div>

                    <br/><br/>
                    The allure of a traditional library-style look is truly timeless. It's a design choice that effortlessly marries the classic with the contemporary, making it the perfect addition to any interior space.
                    <br/><br/>

                    <b>How to create this look?</b>
                    <ul>
                        <li>
                            <span>Amazon Home's </span>
                            <a target={"_blank"} href={"https://www.amazon.ca/dp/B08264756M"} style={{textDecoration: "underline"}}>
                                20x20 inch Frame
                            </a>
                        </li>
                        <li>
                            4x4 inch Square Photo Prints
                        </li>
                        <li>
                            <a target="_blank" href={"/20x20-inch-matboard-4x4-inch-opening"} style={{textDecoration: "underline"}}>20x20 inch Matboards</a>
                            <span> with opening for 4x4 inch Photo</span>
                        </li>
                    </ul>

                    <br/>

                    <div className={"inspiration-post"} style={{background: "none"}}>
                        <div className={"inspiration-logo-name"} >
                            From our customer @home.made.lovely
                        </div>
                        <div className={"inspiration-logo-IG-POST-container"}>
                            <a target={"_blank"} href={"https://www.instagram.com/p/CK7Sip6JMfv/"}>
                                <img className={"inspiration-logo-IG-POST"} src={IGLogo} alt={this.props.french ? "Cliquez ici pour visiter la page Instagram de la publication.\n" : "Click here to visit the Instagram page for the post."}/>
                            </a>

                        </div>
                    </div>
                </div>
            </div>

            <div className={"gallery-wall-content-container"}>
                <div className={"gallery-wall-content-left"}>
                    <div style={{fontSize: "26px", lineHeight: "1.5"}}>
                        9. Staircase Gallery Wall <br className={"mobile-no-display"}/>
                    </div>

                    <br/><br/>
                    When it comes to infusing your home with timeless elegance, few spaces are as captivating as the staircase walls. It's a canvas waiting to come to life, and with a touch of design finesse, you can achieve a look that harmonizes seamlessly with your staircase or rail color.
                    <br/><br/>

                    <b>How to create this look?</b>
                    <ul>
                        <li>
                            <span>IKEA 19 ¾x27 ½ inch</span>
                            <a target={"_blank"} href={"https://www.ikea.com/ca/en/p/ribba-frame-black-50268874"} style={{textDecoration: "underline"}}>
                                Black Ribba Frames
                            </a>
                        </li>
                        <li>
                            10 x 8 Inch Photo Prints
                        </li>
                        <li>
                            <a target="_blank" href={"/customize-matboards?mWidth=19.75&mHeight=27.5&wWidth=9.75&wHeight=7.75&mTop=5&mLeft=5&mRight=5&mBottom=14.75&mColor=smoothWhite&dmColor=none&dmWidth=0&backing=false&oval=false&ctr=false"} style={{textDecoration: "underline"}}>19 ¾x27 ½ inch Matboards</a>
                            <span> with opening for 10 x 8 inch prints</span>
                        </li>
                    </ul>

                    <br/>

                    <div className={"inspiration-post"} style={{background: "none"}}>
                        <div className={"inspiration-logo-name"}>
                            From our customer @ourhomeinthewoodstock
                        </div>
                        <div className={"inspiration-logo-IG-POST-container"}>
                            <a target={"_blank"} href={"https://www.instagram.com/p/CXNFu61rAdJ/"}>
                                <img className={"inspiration-logo-IG-POST"} src={IGLogo} alt={this.props.french ? "Cliquez ici pour visiter la page Instagram de la publication.\n" : "Click here to visit the Instagram page for the post."}/>
                            </a>

                        </div>
                    </div>

                </div>
                <div className={"gallery-wall-content-right"}>
                    <LazyLoad>
                        <img className={"gallery-wall-content-image"} src={staircaseGalleryWall} style={{width: "75%", marginLeft: "12.5%"}}/>
                    </LazyLoad>

                </div>
            </div>

            <div className={"gallery-wall-content-container"}>
                <div className={"gallery-wall-content-left"}>
                    <LazyLoad>
                        <img className={"gallery-wall-content-image"} style={{width: "100%"}} src={"https://www.custommat.ca/static/media/3.efd79706.jpeg"}/>
                    </LazyLoad>
                </div>
                <div className={"gallery-wall-content-right"}>
                    <div style={{fontSize: "26px", lineHeight: "1.5"}}>
                        10. Sports Themed Gallery Wall
                    </div>
                    <br/>
                    Calling all sports enthusiasts! Transform your space into a captivating gallery showcasing the jerseys, photographs, and trading cards of your beloved athletes. If you have a dedicated game-watching area where you gather with family and friends, consider embracing a vibrant and spirited theme. Alternatively, for a subtler touch, opt for a white mat complemented by a double matting in your team's signature colors.
                    <br/><br/>

                    <b>How to create this look?</b>
                    <ul>
                        <li>
                            <span>Michael's 30x40"</span>
                            <a target={"_blank"} href={"https://canada.michaels.com/en/studio-decor-large-shadowbox-black/10174578.html"} style={{textDecoration: "underline"}}>
                                Shadow Box (+ Smaller Frames)
                            </a>
                        </li>
                        <li>
                            Large Size Jersey and 8x10" Photos
                        </li>
                        <li>
                            <a target="_blank" href={"/customize-matboards?mWidth=30&mHeight=40&wWidth=20&wHeight=30&mTop=5&mLeft=5&mRight=5&mBottom=5&mColor=smoothWhite&dmColor=none&dmWidth=0&backing=false&oval=false&ctr=true"} style={{textDecoration: "underline"}}>30x40" Custom Design Mats</a>
                            <span> (Email us for more info) </span>
                        </li>
                    </ul>

                    <br/>

                    <div className={"inspiration-post"} style={{background: "none"}}>
                        <div className={"inspiration-logo-name"}>
                            From our customer @jivan
                        </div>
                        <div className={"inspiration-logo-IG-POST-container"}>
                            <a target={"_blank"} href={"https://www.instagram.com/p/B6vCYiLF9-9/"}>
                                <img className={"inspiration-logo-IG-POST"} src={IGLogo} alt={this.props.french ? "Cliquez ici pour visiter la page Instagram de la publication.\n" : "Click here to visit the Instagram page for the post."}/>
                            </a>

                        </div>
                    </div>
                </div>
            </div>


        </div>
    }
}