export default function calculateTotalPrice (priceToReturn, shoppingItemList) {
  var totalItemPrice = 0;
  var maxShippingPrice = 0;
  var totalTaxes = 0;

  shoppingItemList.map((item, key) => {
    totalItemPrice = totalItemPrice + (item.kPrimaryMattingPrice + item.lMatCuttingPrice + item.oSecondMattingPrice + item.pSecondMatCuttingPrice + item.sTaxes) * item.qItemQuantity;
    totalTaxes = item.sTaxes * item.qItemQuantity;
    if (item.rShippingCost > maxShippingPrice) {

      return maxShippingPrice = item.rShippingCost;
    } else {
      return null;
    }
  });

  var totalPrice = (totalItemPrice + maxShippingPrice).toFixed(2);
  totalTaxes = totalTaxes.toFixed(2);
  if (totalPrice > 50) {
    maxShippingPrice = 0;
  }

  if (priceToReturn === "total") {
    return totalPrice;
  } else if (priceToReturn === "shipping") {
    return maxShippingPrice;
  } else if (priceToReturn === "taxes") {
    return totalTaxes;
  }

}
//add if total product price goes over 50 waive the shipping cost