export const marketList = {
    vancouver: {
        cityName: "Vancouver",
        neighbourList: [

          ],
    },
    edmonton: {
      cityName: "Edmonton",
      neighbourList: [
          "Starthcona", "Westmount", "Glenora", "Garneau", "Windermere", "Central McDougall", "Bonnie Doon", "Crestwood", "Downtown",
          "Summerside", "Alberta Avenue", "Inglewood", "Belgravia", "McCauley", "Terwillegar Towne", "Strathearn", "Griesbach", "Ottewell",
          "Queen Alexandra", "Royal Gardens", "Canora", "Windsor Park", "Laurier Heights", "Pleasantview", "Parkallen", "Cloverdale",
          "Boyle Street", "Hazeldean", "Oliver", "Beverly Heights", "Queen Mark Park", "Forest Heights", "Woodcroft", "Malmo Plains",
          "McKernan", "Rossdale", "Killarney", "Brintnell", "Dovercourt", "Ellerslie", "Balwin", "West Jasper Place", "Allendale", "Beacon Heights",
          "Delwood", "Grovenor", "Lendrum Place", "Rutherford", "Tamarack", "Rundle Heights"
      ],
    },
    britishColumbia: {
        cityName: "British Columbia",
        neighbourList: [
            "Vancouver",
            "Surrey",
            "Burnaby",
            "Richmond",
            "Abbotsford",
            "Coquitlam",
            "Kelowna",
            "Delta",
            "Kamloops",
            "Nanaimo",
            "Victoria",
            "Chilliwack",
            "Maple Ridge",
            "Prince George",
            "New Westminster",
            "Port Coquitlam",
            "North Vancouver",
            "Vernon",
            "Port Moody",
            "Penticton",
            "Campbell River",
            "West Kelowna",
            "Langford",
            "Langley",
            "Courtenay",
            "White Rock",
            "Cranbrook",
            "Fort St. John",
            "Port Alberni",
            "Pitt Meadows",
            "Salmon Arm",
            "Colwood",
            "Powell River",
            "Prince Rupert",
            "Parksville",
            "Dawson Creek",
            "Terrace",
            "Williams Lake",
            "Nelson",
            "Quesnel",
            "Castlegar",
            "Trail",
            "Revelstoke",
            "Merritt",
            "Kimberley",
            "Duncan",
            "Armstrong",
            "Fernie",
            "Grand Forks",
            "Rossland",
            "Enderby",
            "Greenwood",
        ],
    },
    alberta: {
        cityName: "Alberta",
        neighbourList: [
            "Calgary",
            "Edmonton",
            "Red Deer",
            "Lethbridge",
            "St. Albert",
            "Medicine Hat",
            "Grande Prairie",
            "Airdrie",
            "Spruce Grove",
            "Leduc",
            "Fort Saskatchewan",
            "Chestermere",
            "Lloydminster",
            "Camrose",
            "Beaumont",
            "Cold Lake",
            "Brooks",
            "Lacombe",
            "Wetaskiwin",
            "Starthcona", "Westmount", "Glenora", "Garneau", "Windermere", "Central McDougall", "Bonnie Doon", "Crestwood", "Downtown",
            "Summerside", "Alberta Avenue", "Inglewood", "Belgravia", "McCauley", "Terwillegar Towne", "Strathearn", "Griesbach", "Ottewell",
            "Queen Alexandra", "Royal Gardens", "Canora", "Windsor Park", "Laurier Heights", "Pleasantview", "Parkallen", "Cloverdale",
            "Boyle Street", "Hazeldean", "Oliver", "Beverly Heights", "Queen Mark Park", "Forest Heights", "Woodcroft", "Malmo Plains",
            "McKernan", "Rossdale", "Killarney", "Brintnell", "Dovercourt", "Ellerslie", "Balwin", "West Jasper Place", "Allendale", "Beacon Heights",
            "Delwood", "Grovenor", "Lendrum Place", "Rutherford", "Tamarack", "Rundle Heights"
        ],
    },
    saskatchewan: {
        cityName: "Saskatchewan",
        neighbourList: [
            "Saskatoon",
            "Regina",
            "Prince Albert",
            "Moose Jaw",
            "Swift Current",
            "Yorkton",
            "North Battleford",
            "Lloydminster",
            "Estevan",
            "Warman",
            "Weyburn",
            "Martensville",
            "Melfort",
            "Humboldt",
            "Meadow Lake",
            "Melville",
            "Flin Flon",
        ],
    },
    manitoba: {
        cityName: "Manitoba",
        neighbourList: [
            "Brandon",
            "Dauphin",
            "Flin Flon",
            "Morden",
            "Portage la Prairie",
            "Selkirk",
            "Steinbach",
            "Thompson",
            "Winkler",
            "Winnipeg",
        ],
    },
    michigan: {
        cityName: "Michigan",
        neighbourList: [
            'Detroit',
            'Grand Rapids',
            'Warren',
            'Sterling Heights',
            'Ann Arbor',
            'Dearborn',
            'Lansing',
            'Livonia',
            'Troy',
            'Westland',
            'Farmington Hills',
            'Southfield',
            'Rochester Hills',
            'Wyoming',
            'Flint',
            'Kalamazoo',
            'Novi',
            'Dearborn Heights',
            'Taylor',
            'Pontiac',
            'St. Clair Shores',
            'Royal Oak',
            'Kentwood',
            'Battle Creek',
            'Portage',
            'Roseville',
            'East Lansing',
            'Midland',
            'Saginaw',
            'Lincoln Park',
            'Muskegon',
            'Eastpointe',
            'Holland',
            'Bay City',
            'Jackson',
            'Hamtramck',
            'Southgate',
            'Allendale',
            'Oak Park',
            'Burton',
            'Forest Hills',
            'Allen Park',
            'Port Huron',
            'Madison Heights',
            'Garden City',
            'Inkster',
            'Okemos',
            'Holt',
            'Walker',
            'Romulus',
            'Norton Shores',
            'Auburn Hills',
            'Wyandotte',
            'Waverly',
            'Birmingham',
            'Ypsilanti',
            'Adrian',
            'Marquette',
            'Mount Pleasant',
            'Monroe',
            'Haslett',
            'Ferndale',
            'Cutlerville',
            'Trenton',
            'Wixom',
            'Wayne',
            'Jenison',
            'Grosse Pointe Woods',
            'Grandville',
            'Traverse City',
            'Harper Woods',
            'Northview',
            'Mount Clemens',
            'Berkley',
            'Fraser',
            'Coldwater',
            'Owosso',
            'Hazel Park',
            'Ionia',
            'Melvindale',
            'Rochester',
            'Sault Ste. Marie',
            'Woodhaven',
            'Riverview',
            'Escanaba',
            'Fenton',
            'New Baltimore',
            'Niles',
            'Farmington',
            'South Lyon',
            'Grosse Pointe Park',
            'East Grand Rapids',
            'Clawson',
            'Grand Haven',
            'Sturgis',
            'Flat Rock',
            'Beverly Hills',
            'Comstock Park',
            'Lambertville',
            'Cadillac',
            'Grosse Pointe Farms',
            'Howell',
            'Alpena',
            'Marysville',
            'Westwood',
            'Muskegon Heights',
            'Alma',
            'Plymouth',
            'Temperance',
            'Charlotte',
            'Ecorse',
            'Lapeer',
            'Saline',
            'Greenville',
            'Benton Harbor',
            'Tecumseh',
            'Center Line',
            'Houghton',
            'Menominee',
            'Beecher',
            'Flushing',
            'Mason',
            'Highland Park',
            'Grand Blanc',
            'Three Rivers',
            'Hillsdale',
            'Whitmore Lake',
            'Byron Center',
            'Freeland',
            'Grand Ledge',
            'Hudsonville',
            'St. Joseph',
            'St. Johns',
            'Hastings',
            'Ludington',
            'Iron Mountain',
            'Brighton',
            'Albion',
            'Fair Plain',
            'Walled Lake',
            'Shields',
            'River Rouge',
            'St. Louis',
            'Big Rapids',
            'Marshall',
            'Milford',
            'New Haven',
            'South Monroe',
            'Bridgeport',
            'Huntington Woods',
            'Eastwood',
            'Manistee',
            'Rockford',
            'Northville',
            'Milan',
            'Ishpeming',
            'Lake Fenton',
            'Belding',
            'Holly',
            'Petoskey',
            'Swartz Creek',
            'Richmond',
            'Houghton Lake',
            'Zeeland',
            'Grosse Pointe',
            'Dundee',
            'Dowagiac',
            'Chelsea',
            'Comstock Northwest',
            'Buena Vista',
            'St. Clair',
            'Utica',
            'Gladstone',
            'Wolf Lake',
            'Springfield',
            'Allegan',
            'Pearl Beach',
            'Eaton Rapids',
            'Kingsford',
            'Davison',
            'Gibraltar',
            'Frankenmuth',
            'Coopersville',
            'Ironwood',
            'DeWitt',
            'Cheboygan',
            'Fremont',
            'Negaunee',
            'Bloomfield Hills',
            'Wolverine Lake',
            'Michigan Center',
            'Middleville',
            'Wayland',
            'Vandercook Lake',
            'Gaylord',
            'Hancock',
            'Roosevelt Park',
            'Sparta',
            'Buchanan',
            'Lowell',
            'Algonac',
            'Linden',
            'North Muskegon',
            'Otsego',
            'Lathrup Village',
            'Marine City',
            'Belleville',
            'Vicksburg',
            'Boyne City',
            'South Haven',
            'Romeo',
            'Williamston',
            'Harvey',
            'Plainwell',
            'Portland',
            'Imlay City',
            'Cedar Springs',
            'Durand',
            'Benton Heights',
            'Oxford',
            'Essexville',
            'Canadian Lakes',
            'Paw Paw',
            'Clare',
            'Paw Paw Lake',
            'Blissfield',
            'Rockwood',
            'Level Park-Oak Park',
            'K. I. Sawyer',
            'Mount Morris',
            'Potterville',
            'Beechwood',
            'West Monroe',
            'Franklin',
            'Gladwin',
            'Bath',
            'Iron River',
            'Skidway Lake',
            'Bad Axe',
            'Ferrysburg',
            'Fowlerville',
            'Whitehall',
            'Spring Arbor',
            'Corunna',
            'Almont',
            'Rogers City',
            'Lake Orion',
            'Norway',
            'Ithaca',
            'St. Helen',
            'Manistique',
            'Vassar',
            'Argentine',
            'Sandusky',
            'Mattawan',
            'Keego Harbor',
            'Pleasant Ridge',
            'East Tawas',
            'Newaygo',
            'Clinton',
            'Spring Lake',
            'Village of Grosse Pointe Shores',
            'West Ishpeming',
            'Cass City',
            'Reed City',
            'Clio',
            'Hartford',
            'Hudson',
            'Chesaning',
            'Montague',
            'West Branch',
            'Pinckney',
            'Carleton',
            'Trowbridge Park',
            'Edgemont Park',
            'Charlevoix',
            'Bronson',
            'Croswell',
            'Morenci',
            'St. Ignace',
            'East Jordan',
            'Orchard Lake Village',
            'Kalkaska',
            'Harrison',
            'Twin Lake',
            'Goodrich',
            'Auburn',
            'Galesburg',
            'Perry',
            'Bangor',
            'Bridgman',
            'Manitou Beach-Devils Lake',
            'Hart',
            'Manchester',
            'Capac',
            'Brownlee Park',
            'Lake Odessa',
            'St. Charles',
            'Parchment',
            'Leslie',
            'Indian River',
            'Berrien Springs',
            'Shelby',
            'Detroit Beach',
            'Constantine',
            'Yale',
            'Munising',
            'Lake Isabella',
            'Grayling',
            'Woodland Beach',
            'Fennville',
            'Marlette',
            'Howard City',
            'Tawas City',
            'Lawton',
            'Baraga',
            'Laurium',
            'Stony Point',
            'White Pigeon',
            'Bessemer',
            'Montrose',
            'Union City',
            'Gwinn',
            'Sylvan Lake',
            'Sebewaing',
            'Cassopolis',
            'Evart',
            'Armada',
            'Greilickville',
            'Wakefield',
            'Caledonia',
            'New Buffalo',
            'Mio',
            'Watervliet',
            'Crystal Falls',
            'Prudenville',
            'Decatur',
            'Harbor Beach',
            'South Rockwood',
            'Wacousta',
            'Homer',
            'Quincy',
            'Birch Run',
            'Nashville',
            'White Cloud',
            'Zilwaukee',
            'Elk Rapids',
            'Olivet',
            'Au Sable',
            'Laingsburg',
            'Coloma',
            'Shepherd',
            'Schoolcraft',
            'Standish',
            'Newberry',
            'Douglas',
            'Sand Lake',
            'Kingsley',
            'Litchfield',
            'Scottville',
            'Saranac',
            'Hemlock',
            'Luna Pier',
            'Rapid City',
            'Lakewood Club',
            'Ortonville',
            'Brooklyn',
            'Ravenna',
            'Mancelona',
            'Stanton',
            'Kent City',
            'Bellevue',
            'Edwardsburg',
            'Harbor Springs',
            'Brown City',
            'Three Oaks',
            'Webberville',
            'Centreville',
            'Coleman',
            'Stockbridge',
            'Manton',
            'Frankfort',
            'Fowler',
            'Pigeon',
            'Ontonagon',
            'Shorewood-Tower Hills-Harbert',
            'Edmore',
            'Breckenridge',
            'Colon',
            'Reese',
            'Burt',
            'South Gull Lake',
            'Petersburg',
            'Pinconning',
            'Beaverton',
            'Stevensville',
            'Carson City',
            'Bingham Farms',
            'North Branch',
            'Fruitport',
            'Dimondale',
            'Chums Corner',
            'Reading',
            'Concord',
            'Napoleon',
            'Grass Lake',
            'Lake Michigan Beach',
            'Hesperia',
            'Dollar Bay',
            'Memphis',
            'Quinnesec',
            'Bellaire',
            'Dryden',
            'Marcellus',
            'Lakeview',
            'Lake Linden',
            'Millington',
            'Onsted',
            'Richland',
            'Lake Victoria',
            'Vineyard Lake',
            'Grant',
            'Au Gres',
            'Central Lake',
            'Lawrence',
            'Morrice',
            'Roscommon',
            'Attica',
            'Village of Clarkston',
            'Ossineke',
            'Westphalia',
            'Oscoda',
            'Elsie',
            'Mayville',
            'Athens',
            'Weidman',
            'Deerfield',
            'Pentwater',
            'Hubbell',
            'Onaway',
            'Deckerville',
            'Mendon',
            'Farwell',
            'Lewiston',
            'Baroda',
            'Lexington',
            'Augusta',
            'Mackinaw City',
            'Gobles',
            'Delton',
            'Saugatuck',
            'Shoreham',
            'Ubly',
            'Grawn',
            'Lake City',
            'Hubbard Lake',
            'Baldwin',
            'Otisville',
            'Middletown',
            'Stephenson',
            'Buckley',
            'Sanford',
            'Elkton',
            'Alanson',
            'Parma',
            'Caspian',
            'Springport',
            'Marion',
            'Pellston',
            'Lyons',
            'South Range',
            'Burr Oak',
            'Interlochen',
            'Vernon',
            'Sheridan',
            'Vermontville',
            'Climax',
            'Atlanta',
            'Columbiaville',
            'Robin Glen-Indiantown',
            'Muir',
            'Fostoria',
            'Presque Isle Harbor',
            'Tekonsha',
            'McBain',
            'Merrill',
            'Hopkins',
            'Suttons Bay',
            'Mackinac Island',
            'Port Austin',
            'Metamora',
            'Calumet',
            'Peck',
            'Parkdale',
            'Hillman',
            'Benzonia',
            'Addison',
            'Freeport',
            'New Lothrop',
        ],
    },

    ontario: {
        cityName: "Ontario",
        neighbourList: [
            "Barrie",
            "Belleville",
            "Brampton",
            "Brant",
            "Brantford",
            "Brockville",
            "Burlington",
            "Cambridge",
            "Clarence-Rockland",
            "Cornwall",
            "Dryden",
            "Elliot Lake",
            "Sudbury",
            "Guelph",
            "Haldmand",
            "Hamilton",
            "Kawartha Lakes",
            "Kenora",
            "Kingston",
            "Kitchener",
            "London",
            "Markham",
            "Mississauga",
            "Niagara Falls",
            "Norfolk County",
            "North Bay",
            "Orillia",
            "Oshawa",
            "Ottawa",
            "Owen Sound",
            "Pembroke",
            "Peterborough",
            "Pickering",
            "Port Colborne",
            "Prince Edward County",
            "Quinte West",
            "Richmond Hill",
            "Sarnia",
            "Saulte Ste. Marie",
            "St. Catharines",
            "St. Thomas",
            "Startford",
            "Temiskaming Shores",
            "Thorold",
            "Thunder Bay",
            "Timmins",
            "Toronto",
            "Vaughan",
            "Waterloo",
            "Welland",
            "Windsor",
            "Woodstock",
            "Toronto",
            "Downtown Toronto",
            "East End Toronto",
            "North End Toronto",
            "West End Toronto",
            "East York",
            "Etobicoke",
            "North York",
            "ScarBorough",
            "York",

        ],
    },
    quebec: {
        cityName: "Quebec",
        neighbourList: [
            "Action Vale",
            "Alma",
            "Amos",
            "Amqui",
            "Asbestos",
            "Baie-Comeau",
            "Baie d'Urfé",
            "Baie-Saint-Paul",
            "Barkmere",
            "Beaconsfield",
            "Beauceville",
            "Beauharnois",
            "Bowsprit",
            "Becancour",
            "Bedford",
            "Belleterre",
            "Beloeil",
            "Berthierville",
            "Blainville",
            "Boisbriand",
            "Bois-des-Filion",
            "Bonaventure",
            "Boucherville",
            "Brome Lake",
            "Bromont",
            "Brossard",
            "Brownsburg-Chatham",
            "Candiac",
            "Cap-Chat",
            "Cape Health",
            "Carignan",
            "Carleton-sur-Mer",
            "Causapscal",
            "Chambly",
            "Chandler",
            "Chapais",
            "Charlemagne",
            "Châteauguay",
            "Château-Richer",
            "Chibougamau",
            "Clermont",
            "Coaticook",
            "Contrecoeur",
            "Cookshire-Eaton",
            "Côte Saint-Luc",
            "Coteau-du-Lac",
            "Cowansville",
            "Danville",
            "Daveluyville",
            "Dégelis",
            "Delson",
            "Goods",
            "Two Mountains",
            "Disraeli",
            "Dolbeau-Mistassini",
            "Dollard abalone",
            "Donnacona",
            "Dorval",
            "Drummondville",
            "Dunham",
            "Parquet",
            "East Angus",
            "Esterel",
            "Farnham",
            "Fermont",
            "Forestville",
            "Fossambault-sur-le-Lac",
            "Gaspé",
            "Gatineau",
            "Gracefield",
            "Granby",
            "Great River",
            "Hampstead",
            "Hudson",
            "Huntingdon",
            "Joliette",
            "Kingsey Falls",
            "Kirkland",
            "Malbaie",
            "La Pocatière",
            "La Prairie",
            "Saarland",
            "The Tuque",
            "Lac-Delage",
            "The Fall",
            "Megantic Lake",
            "Lac-Saint-Joseph",
            "Lac-Sergent",
            "L'Ancienne-Lorette",
            "The Assumption",
            "Laval",
            "Lavaltrie",
            "Lebel-sur-Quévillon",
            "Epiphany",
            "Lery",
            "Levi's",
            "L'Île-Cadieux",
            "Dorval Island",
            "L'Île-Perrot",
            "Longueuil",
            "Lorraine",
            "Louiseville",
            "Macamic",
            "Magog",
            "Malartic",
            "Maniwaki",
            "Marieville",
            "Mascouche",
            "Matagami",
            "Matane",
            "Haberdasher",
            "Métabetchouan-Lac-à-la-Croix",
            "Métis-sur-Mer",
            "Mirabel",
            "Mont-Joli",
            "Mont-Laurier",
            "Montmagny",
            "Montreal",
            "Montreal West",
            "Montreal East",
            "Mont-Saint-Hilaire",
            "Mont Tremblant",
            "Mount Royal",
            "Murdochville",
            "Neuville",
            "New Richmond",
            "Nicolet",
            "Normandin",
            "Notre-Dame-de-l'Île-Perrot",
            "Notre-Dame-des-Prairies",
            "Otterburn Park",
            "Paspébiac",
            "Perforated",
            "Pincourt",
            "Plessisville",
            "Pohénégamook",
            "Pointe-Claire",
            "Pont-Rouge",
            "Port-Cartier",
            "Portneuf",
            "Prevost",
            "Princeville",
            "Quebec",
            "Repentigny",
            "Richelieu",
            "Richmond",
            "Rimouski",
            "Wolf River",
            "Red River",
            "Roberval",
            "Rosemère",
            "Rouyn-Noranda",
            "Saguenay",
            "Saint-Augustin-de-Desmaures",
            "St. Basil",
            "Saint-Basile-le-Grand",
            "Saint-Bruno-de-Montarville",
            "Saint-Césaire",
            "Saint-Colomban",
            "Saint-Constant",
            "Sainte-Adèle",
            "Sainte-Agathe-des-Monts",
            "Sainte-Anne-de-Beaupré",
            "Sainte-Anne-de-Bellevue",
            "Sainte-Anne-des-Monts",
            "Sainte-Anne-des-Plaines",
            "Saint Catherine",
            "Sainte-Catherine-de-la-Jacques-Cartier",
            "Sainte-Julie",
            "Sainte-Marguerite-du-Lac-Masson",
            "Sainte Marie",
            "Sainte-Marthe-sur-le-Lac",
            "St. Therese",
            "Saint-Eustache",
            "Saint-Félicien",
            "St. Gabriel",
            "Saint Georges",
            "Saint-Hyacinthe",
            "Saint-Jean-sur-Richelieu",
            "St. Jerome",
            "Saint-Joseph-de-Beauce",
            "Saint-Joseph-de-Sorel",
            "Saint-Lambert",
            "Saint Lazare",
            "Saint-Lin-Laurentides",
            "Saint-Marc-des-Carrières",
            "Saint-Ours",
            "Saint-Pamphile",
            "Saint-Pascal",
            "St. Pius",
            "Saint-Raymond",
            "Saint-Rémi",
            "St. Saviour",
            "Saint-Tite",
            "Salaberry-de-Valleyfield",
            "Schefferville",
            "Scotstown",
            "Senneterre",
            "Sept-Îles",
            "Shawinigan",
            "Sherbrooke",
            "Sorel-Tracy",
            "Stanstead",
            "Sutton",
            "Temiscaming",
            "Témiscouata-sur-le-Lac",
            "Terrebonne",
            "Thetford Mines",
            "Thurso",
            "Three pistols",
            "Trois-Rivières",
            "Valcourt",
            "Val-d'Or",
            "Varennes",
            "Vaudreuil-Dorion",
            "Victoriaville",
            "Ville-Marie",
            "Warwick",
            "Waterloo",
            "Waterville",
            "Westmount",
            "Windsor",
        ],
    },
};

export const schoolName = [
    "Concordia University",
    "Université Laval",
    "Carleton University",
    "University of Saskatchewan",
    "Université du Québec à Montréal",
    "Wilfrid Laurier University",
    "Brock University",
    "University of Victoria",
    "Dalhousie University",
    "Memorial University of Newfoundland",
    "Simon Fraser University",
    "MacEwan University",
    "Université de Sherbrooke",
    "University of Windsor",
    "Mount Royal University",
    "Trent University",
    "University of Regina",
    "Thompson Rivers University",
    "Université du Québec à Trois-Rivières",
    "University of Lethbridge",
    "The University of Winnipeg",
    "Acadia University",
    "Algoma University",
    "Athabasca University",
    "Bishop's University",
    "Brandon University",
    "Brescia University College",
    "Campion College",
    "Canadian Mennonite University",
    "Cape Breton University",
    "Capilano University",
    "Concordia University of Edmonton",
    "Emily Carr University of Art and Design",
    "First Nations University of Canada",
    "Huron University College",
    "Institut national de la recherche scientifique",
    "King's University College at Western University",
    "Kwantlen Polytechnic University",
    "Lakehead University",
    "Laurentian University",
    "Luther College",
    "Mount Allison University",
    "Mount Saint Vincent University",
    "Nipissing University",
    "NSCAD University",
    "OCAD University",
    "Ontario Tech University",
    "Polytechnique Montréal",
    "Redeemer University College",
    "Royal Military College of Canada",
    "Royal Roads University",
    "Saint Mary's University",
    "St. Francis Xavier University",
    "St. Jerome's University",
    "St. Paul's College",
    "St. Thomas More College",
    "St. Thomas University",
    "The King's University",
    "Trinity Western University",
    "University of King's College",
    "University of New Brunswick",
    "University of Northern British Columbia",
    "University of Prince Edward Island",
    "University of St. Michael's College",
    "University of Sudbury",
    "University of the Fraser Valley",
    "University of Trinity College",
    "Université de Moncton",
    "Université de Saint-Boniface",
    "Université du Québec en Abitibi-Témiscamingue",
    "Université du Québec en Outaouais",
    "Université du Québec à Chicoutimi",
    "Université du Québec à Rimouski",
    "Université Sainte-Anne",
    "Université TÉLUQ",
    "Vancouver Island University",
    "Victoria University",
    "École de technologie supérieure",
    "École des Hautes Etudes Commerciale",
    "École nationale d'administration publique",
    "British Columbia Institute of Technology",
    "Justice Institute of British Columbia",
    "University of British Columbia",
    "Bow Valley College",
    "Grande Prairie Regional College",
    "Keyano College",
    "Lakeland College",
    "Lethbridge College",
    "Medicine Hat College",
    "NorQuest College",
    "Northern Lakes College",
    "Olds College",
    "Portage College",
    "Red Deer College",
    "Algonquin College",
    "Collège Boréal",
    "Cambrian College",
    "Canadore College",
    "Centennial College",
    "Conestoga College",
    "Confederation College",
    "Durham College",
    "Fanshawe College",
    "Fleming College",
    "George Brown College",
    "Georgian College",
    "Humber College",
    "La Cité Collégiale",
    "Lambton College",
    "Loyalist College",
    "Mohawk College",
    "Niagara College",
    "Northern College",
    "St. Clair College",
    "St. Lawrence College",
    "Sault College",
    "Seneca College",
    "Sheridan College",

    "Albertus Magnus College",
    "Asnuntuck Community College",
    "Capital Community College",
    "Central Connecticut State University",
    "Charter Oak State College",
    "Connecticut College",
    "Eastern Connecticut State University",
    "Fairfield University",
    "Gateway Community College",
    "Goodwin University",
    "Hartford International University for Religion and Peace",
    "Holy Apostles College and Seminary",
    "Housatonic Community College",
    "Manchester Community College",
    "Middlesex Community College",
    "Mitchell College",
    "Naugatuck Valley Community College",
    "Northwestern Connecticut Community College",
    "Norwalk Community College",
    "Paier College of Art",
    "Post University",
    "Quinebaug Valley Community College",
    "Quinnipiac University",
    "Sacred Heart University",
    "Southern Connecticut State University",
    "Three Rivers Community College",
    "Trinity College",
    "Tunxis Community College",
    "United States Coast Guard Academy",
    "University of Bridgeport",
    "University of Connecticut",
    "University of Hartford",
    "University of New Haven",
    "University of Saint Joseph",
    "Wesleyan University",
    "Western Connecticut State University",
    "Yale University",
    "Bates College",
    "Beal University",
    "Bowdoin College",
    "Central Maine Community College",
    "Colby College",
    "College of the Atlantic",
    "Eastern Maine Community College",
    "Husson University",
    "Institute for Doctoral Studies in the Visual Arts",
    "Kennebec Valley Community College",
    "The Landing School",
    "Maine College of Art",
    "Maine Maritime Academy",
    "Maine College of Health Professions",
    "Northern Maine Community College",
    "Saint Joseph's College of Maine",
    "Southern Maine Community College",
    "Thomas College",
    "Unity College",
    "University of Maine",
    "University of Maine at Augusta",
    "University of Maine at Farmington",
    "University of Maine at Fort Kent",
    "University of Maine at Machias",
    "University of Maine at Presque Isle",
    "University of New England",
    "University of Southern Maine",
    "Washington County Community College",
    "York County Community College",
    "American International College",
    "Amherst College",
    "Anna Maria College",
    "Assumption University",
    "Babson College",
    "Bard College at Simon's Rock",
    "Bay Path University",
    "Bay State College",
    "Benjamin Franklin Institute of Technology",
    "Bentley University",
    "Berklee College of Music",
    "Berkshire Community College",
    "Pope St. John XXIII National Seminary",
    "Boston Architectural College",
    "Boston Baptist College",
    "Boston College",
    "Boston Graduate School of Psychoanalysis",
    "Boston University",
    "Brandeis University",
    "Bridgewater State University",
    "Bristol Community College",
    "Bunker Hill Community College",
    "Cambridge College",
    "Cape Cod Community College",
    "Clark University",
    "College of the Holy Cross",
    "Conway School of Landscape Design",
    "Curry College",
    "Dean College",
    "Eastern Nazarene College",
    "Elms College",
    "Emerson College",
    "Emmanuel College",
    "Endicott College",
    "Fisher College",
    "Fitchburg State University",
    "Framingham State University",
    "Gordon College",
    "Gordon–Conwell Theological Seminary",
    "Greenfield Community College",
    "Hampshire College",
    "Harvard University",
    "Hebrew College",
    "Hellenic College Holy Cross Greek Orthodox School of Theology",
    "Holyoke Community College",
    "Hult International Business School",
    "Labouré College",
    "Lasell University",
    "Lesley University",
    "Longy School of Music of Bard College",
    "Massachusetts Bay Community College",
    "Massachusetts College of Art and Design",
    "Massachusetts College of Liberal Arts",
    "Massachusetts Institute of Technology",
    "Massachusetts Maritime Academy",
    "Massachusetts School of Law",
    "Massasoit Community College",
    "Massachusetts College of Pharmacy and Health Sciences",
    "Merrimack College",
    "MGH Institute of Health Professions",
    "Middlesex Community College",
    "Montserrat College of Art",
    "Mount Holyoke College",
    "Mount Wachusett Community College",
    "New England College of Optometry",
    "New England Conservatory of Music",
    "New England Law Boston",
    "Nichols College",
    "North Shore Community College",
    "Northeastern University",
    "Northern Essex Community College",
    "Northpoint Bible College",
    "Olin College",
    "Quincy College",
    "Quinsigamond Community College",
    "Regis College",
    "Roxbury Community College",
    "Saint John's Seminary",
    "Salem State University",
    "Simmons University",
    "Smith College",
    "Springfield College",
    "Springfield Technical Community College",
    "Stonehill College",
    "Suffolk University",
    "Tufts University",
    "University of Massachusetts Amherst",
    "University of Massachusetts Boston",
    "University of Massachusetts Dartmouth",
    "University of Massachusetts Lowell",
    "University of Massachusetts Medical School",
    "Urban College of Boston",
    "Wellesley College",
    "Wentworth Institute of Technology",
    "Western New England University",
    "Westfield State University",
    "Wheaton College",
    "William James College",
    "Williams College",
    "Worcester Polytechnic Institute",
    "Worcester State University",
    "Great Bay Community College",
    "Lakes Region Community College",
    "Manchester Community College",
    "Nashua Community College",
    "NHTI Concord's Community College",
    "River Valley Community College",
    "White Mountains Community College",
    "Granite State College",
    "Keene State College",
    "Plymouth State University",
    "University of New Hampshire",
    "University of New Hampshire at Manchester",
    "University of New Hampshire School of Law",
    "Antioch University New England",
    "ColbySawyer College",
    "Dartmouth College",
    "Franklin Pierce University",
    "Hellenic American University",
    "Magdalen College of the Liberal Arts",
    "New England College",
    "Rivier University",
    "Saint Anselm College",
    "Southern New Hampshire University",
    "Thomas More College of Liberal Arts",
    "The College of New Jersey",
    "Kean University",
    "Montclair State University",
    "New Jersey City University",
    "New Jersey Institute of Technology",
    "Ramapo College",
    "Rowan University",
    "Rutgers University[a]",
    "Stockton University",
    "Thomas Edison State University",
    "William Paterson University",
    "Bloomfield College",
    "Caldwell University",
    "Centenary University",
    "Drew University",
    "Fairleigh Dickinson University",
    "Felician University",
    "Georgian Court University",
    "Monmouth University",
    "Princeton University",
    "Rider University",
    "Saint Elizabeth University",
    "St. Peter's University",
    "Seton Hall University",
    "Stevens Institute of Technology",
    "Binghamton University",
    "Stony Brook University",
    "University at Albany",
    "University at Buffalo",
    "State University of New York Upstate Medical University",
    "State University of New York Downstate Health Sciences University",
    "State University of New York College of Environmental Science and Forestry",
    "State University of New York State College of Optometry",
    "Alfred State College",
    "State University of New York at Canton",
    "State University of New York at Cobleskill",
    "State University of New York at Farmingdale",
    "State University of New York at Morrisville",
    "State University of New York Maritime College",
    "Buffalo State College",
    "State University of New York at Brockport",
    "State University of New York at Cortland",
    "State University of New York at Fredonia",
    "State University of New York at Geneseo",
    "State University of New York at New Paltz",
    "State University of New York at Old Westbury",
    "State University of New York at Oneonta",
    "State University of New York at Oswego",
    "State University of New York at Plattsburgh",
    "State University of New York at Potsdam",
    "State University of New York at Purchase",
    "Adirondack Community College",
    "Broome Community College",
    "Cayuga County Community College",
    "Clinton Community College",
    "ColumbiaGreene Community College",
    "Corning Community College",
    "Dutchess Community College",
    "Erie Community College",
    "Finger Lakes Community College",
    "FultonMontgomery Community College",
    "Genesee Community College",
    "Herkimer County Community College",
    "Hudson Valley Community College",
    "Jamestown Community College",
    "Jefferson Community College",
    "Mohawk Valley Community College",
    "Monroe Community College",
    "Nassau Community College",
    "Niagara County Community College",
    "North Country Community College",
    "Onondaga Community College",
    "Orange County Community College",
    "Rockland Community College",
    "Schenectady County Community College",
    "Suffolk County Community College",
    "Sullivan County Community College",
    "Tompkins Cortland Community College",
    "Ulster County Community College",
    "Westchester Community College",
    "Empire State College",
    "SUNY Learning Network",
    "Brooklyn College",
    "College of Staten Island",
    "CUNY School of Medicine",
    "CUNY School of Professional Studies",
    "CUNY School of Public Health",
    "Borough of Manhattan Community College",
    "Bronx Community College",
    "Guttman Community College",
    "Hostos Community College",
    "Kingsborough Community College",
    "LaGuardia Community College",
    "Queensborough Community College",
    "Hunter College",
    "Baruch College",
    "Brooklyn College",
    "City College of New York",
    "College of Staten Island",
    "John Jay College of Criminal Justice",
    "Lehman College",
    "Medgar Evers College",
    "New York City College of Technology",
    "Queens College",
    "York College",
    "CUNY William E. Macaulay Honors College",
    "CUNY Graduate Center",
    "CUNY Graduate School of Journalism",
    "CUNY School of Law",
    "CUNY School of Medicine",
    "CUNY School of Professional Studies",
    "CUNY School of Public Health",
    "Fashion Institute of Technology",
    "SUNY College of Optometry",
    "SUNY Downstate Medical Center",
    "SUNY Empire State College (The Harry Van Arsdale Jr. Center for Labor Studies)",
    "SUNY Maritime College",
    "Albert Einstein College of Medicine",
    "American Academy of Dramatic Arts",
    "American Academy McAllister Institute",
    "American Musical and Dramatic Academy",
    "ASA College",
    "Bank Street College of Education",
    "Barnard College",
    "Berkeley College",
    "Boricua College",
    "Brooklyn Law School",
    "Christie's Education",
    "College of Mount Saint Vincent",
    "Cooper Union",
    "Cornell University",
    "DeVry University",
    "Fordham University",
    "General Theological Seminary",
    "Gerstner Sloan Kettering Graduate School of Biomedical Science",
    "Hebrew Union College",
    "Helene Fuld College of Nursing",
    "Icahn School of Medicine at Mount Sinai",
    "Jewish Theological Seminary of America",
    "The Juilliard School",
    "The King's College",
    "Laboratory Institute of Merchandising",
    "Long Island Business Institute  Flushing",
    "Long Island University",
    "Mandl College of Allied Health",
    "Manhattan College",
    "Manhattan School of Music",
    "Marymount Manhattan College",
    "Mercy College",
    "Metropolitan College of New York",
    "Monroe College",
    "The New School",
    "New York Academy of Art",
    "New York Conservatory for Dramatic Arts",
    "New York Film Academy",
    "New York Graduate School of Psychoanalysis",
    "New York Institute of Technology",
    "New York Law School",
    "New York School of Interior Design",
    "New York Theological Seminary",
    "New York University",
    "Nyack College",
    "Pace University",
    "Pacific College of Oriental Medicine",
    "Phillips Beth Israel School of Nursing",
    "Plaza College",
    "Pratt Institute",
    "Rabbi Isaac Elchanon Theological Seminary",
    "Rockefeller University",
    "School of American Ballet",
    "School of Visual Arts",
    "St. Francis College",
    "St. Joseph's University",
    "Sotheby's Institute of Art",
    "Swedish Institute of Massage Therapy",
    "Touro University",
    "Vaughn College of Aeronautics & Technology",
    "Wagner College",
    "Yeshiva University",
    "Adelphi University",
    "DeVry University",
    "Five Towns College",
    "Farmingdale State College",
    "Hofstra University",
    "Molloy College",
    "New York Institute of Technology",
    "State University of New York at Old Westbury",
    "Stony Brook University",
    "Touro Law Center",
    "Lincoln University",
    "University of Pittsburgh",
    "Temple University",
    "Penn State Abington",
    "Penn State Altoona",
    "Penn State Beaver",
    "Penn State Berks",
    "Penn State Brandywine",
    "Penn State University College of Medicine",
    "Penn State Dickinson Law",
    "Penn State DuBois",
    "Penn State Erie The Behrend College",
    "Penn State Fayette The Eberly Campus",
    "Penn State Great Valley School of Graduate Professional Studies",
    "Penn State Greater Allegheny",
    "Penn State Harrisburg",
    "Penn State Hazleton",
    "Penn State Lehigh Valley",
    "Penn State Mont Alto",
    "Penn State New Kensington",
    "Penn State Schuylkill",
    "Penn State Shenango",
    "Penn State WilkesBarre",
    "Penn State Scranton",
    "Penn State York",
    "Pennsylvania College of Technology",
    "Bloomsburg University of Pennsylvania",
    "California University of Pennsylvania",
    "Cheyney University of Pennsylvania",
    "Clarion University of Pennsylvania",
    "East Stroudsburg University of Pennsylvania",
    "Edinboro University of Pennsylvania",
    "Indiana University of Pennsylvania",
    "Kutztown University of Pennsylvania",
    "Lock Haven University of Pennsylvania",
    "Mansfield University of Pennsylvania",
    "Millersville University of Pennsylvania",
    "Shippensburg University of Pennsylvania",
    "Slippery Rock University of Pennsylvania",
    "West Chester University",

    "Community College of Beaver County",
    "Butler County Community College",
    "Harrisburg Area Community College Central Pennsylvania's Community College (Harrisburg campus)",
    "Harrisburg Area Community College Central Pennsylvania's Community College (Lancaster campus)",
    "Harrisburg Area Community College Central Pennsylvania's Community College (York campus)",
    "Harrisburg Area Community College Central Pennsylvania's Community College (Gettysburg campus)",
    "Harrisburg Area Community College Central Pennsylvania's Community College (Lebanon campus)",
    "Pennsylvania Highlands Community College",
    "Community College of Philadelphia",
    "Reading Area Community College",
    "Thaddeus Stevens College of Technology",
    "Westmoreland County Community College",
    "Pittsburgh Technical College",
    "University of Pittsburgh at Bradford",
    "University of Pittsburgh at Greensburg",
    "University of Pittsburgh at Johnstown",
    "Temple University Ambler",
    "Alvernia University",
    "Arcadia University",
    "Bryn Mawr College",
    "Carlow University",
    "Carnegie Mellon University",
    "Chestnut Hill College",
    "DeSales University",
    "Drexel University",
    "Eastern University",
    "Gannon University",
    "Gratz College",
    "Holy Family University",
    "Immaculata University",
    "La Salle University",
    "Lehigh University",
    "Marywood University",
    "Misericordia University",
    "Neumann University",
    "University of Pennsylvania",
    "Robert Morris University",
    "Saint Francis University",
    "Saint Joseph's University",
    "University of Scranton",
    "University of the Sciences",
    "Thomas Jefferson University",
    "Villanova University",
    "Waynesburg University",
    "Widener University",
    "Wilkes University",
    "Missio Seminary",
    "Byzantine Catholic Seminary of Saints Cyril and Methodius",
    "Calvary Baptist Theological Seminary",
    "Geisinger Commonwealth School of Medicine",
    "Evangelical Seminary",
    "Lancaster Theological Seminary",
    "Lake Erie College of Osteopathic Medicine",
    "United Lutheran Seminary",
    "United Lutheran Seminary",
    "Palmer Theological Seminary",
    "Pittsburgh Theological Seminary",
    "Philadelphia College of Osteopathic Medicine",
    "Reformed Episcopal Seminary",
    "Reformed Presbyterian Theological Seminary",
    "Reconstructionist Rabbinical College",
    "Respect Graduate School",
    "Saint Charles Borromeo Seminary",
    "Saint Tikhon's Orthodox Theological Seminary",
    "Salus University",
    "Trinity School for Ministry",
    "United States Army War College",
    "Westminster Theological Seminary",
    "Won Institute of Graduate Studies",
    "Albright College",
    "Allegheny College",
    "The American College of Financial Services",
    "Bucknell University",
    "Cabrini University",
    "Cairn University",
    "Cedar Crest College",
    "Central Penn College",
    "Chatham University",
    "Delaware Valley University",
    "Dickinson College",
    "Elizabethtown College",
    "Franklin and Marshall College",
    "Geneva College",
    "Gettysburg College",
    "Grove City College",
    "Gwynedd Mercy University",
    "Harrisburg University of Science and Technology",
    "Haverford College",
    "Juniata College",
    "Keystone College",
    "La Roche University",
    "Lafayette College",
    "Lebanon Valley College",
    "Lycoming College",
    "Mercyhurst University",
    "Messiah University",
    "Moravian University",
    "Mount Aloysius College",
    "Muhlenberg College",
    "Peirce College",
    "Point Park University",
    "Rosemont College",
    "Saint Vincent College",
    "Saint Vincent Seminary",
    "Seton Hill University",
    "Susquehanna University",
    "Swarthmore College",
    "Thiel College",
    "Ursinus College",
    "Washington & Jefferson College",
    "Westminster College",
    "Wilson College",
    "York College of Pennsylvania",
    "The University of the Arts",
    "Curtis Institute of Music",
    "Pennsylvania College of Health Sciences",
    "Moore College of Art and Design",
    "Pennsylvania Academy of the Fine Arts",
    "Pennsylvania College of Art and Design",
    "Harcum College",
    "Hussian School of Art",
    "Johnson College",
    "Lackawanna College",
    "Lackawanna College",
    "Lackawanna College",
    "Lackawanna College",
    "Lackawanna College",
    "Lackawanna College",
    "Manor College",
    "Mercyhurst North East",
    "Pennsylvania Institute of Technology",
    "Pittsburgh Institute of Aeronautics",
    "Pittsburgh Institute of Mortuary Science",
    "Valley Forge Military Academy and College",
    "University of Valley Forge",
    "Clarks Summit University",
    "Lancaster Bible College",
    "Talmudical Yeshiva of Philadelphia",
    "Antonelli College",
    "Schuylkill Institute of Business and Technology",
    "Bradford School",
    "Brightwood College",
    "CambriaRowe Business College",
    "Career Training Academy",
    "Consolidated School of Business",
    "Rosedale Technical College",
    "Delaware Valley Academy of Medical and Dental Assistants",
    "Douglas Education Center",
    "DeVry University",
    "Erie Institute of Technology",
    "JNA Institute of Culinary Arts",
    "Keystone Technical Institute",
    "Lansdale School of Business",
    "Laurel Business Institute",
    "Laurel Technical Institute",
    "Laurel Technical Institute",
    "McCann School of Business and Technology",
    "McCann School of Business and Technology",
    "New Castle School of Trades",
    "Orleans Technical College",
    "Pennco Tech",
    "Penn Commercial Business and Technical School",
    "Special Focus TwoYear: Health Professions",
    "University of Phoenix",
    "Precision Manufacturing Institute",
    "Prism Career Institute",
    "The Restaurant School at Walnut Hill College",
    "South Hills School of Business and Technology",
    "Star Technical Institute",
    "Vet Tech Institute",
    "Academy of Vocal Arts",
    "AllState Career School",
    "AllState Career School",
    "International Institute for Restorative Practices",
    "Commonwealth Technical Institute",
    "Central Pennsylvania Institute of Science and Technology",
    "ASPIRA City College",
    "Williamson College of the Trades",
    "United Career Institute",
    "Pittsburgh Career Institute",
    "Princeton Information Technology Center Institute",
    "Northern Pennsylvania Regional College",
    "Berks Technical Institute",
    "Lancaster County Career and Technology Center",
    "Institute of Medical and Business Careers",
    "Great Lakes Institute of Technology",
    "Brown University",
    "Bryant University",
    "Community College of Rhode Island",
    "Johnson & Wales University",
    "Naval War College",
    "New England Institute of Technology",
    "Providence College",
    "Rhode Island College",
    "Rhode Island School of Design",
    "Roger Williams University",
    "Salve Regina University",
    "University of Rhode Island",
    "Bennington College",
    "Castleton University",
    "Champlain College",
    "Community College of Vermont",
    "Goddard College",
    "Landmark College",
    "Middlebury College",
    "Northern Vermont University",
    "Norwich University",
    "Saint Michael's College",
    "SIT Graduate Institute",
    "Sterling College",
    "University of Vermont",
    "Vermont College of Fine Arts",
    "Vermont Law School",
    "Vermont Technical College",



]