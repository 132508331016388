import React from "react";

import diplomaPostFramed from "../../../../resources/images/whatWeDo/reduced/1-2-diploma-postframed.png";
import diplomaPreFramed from "../../../../resources/images/whatWeDo/reduced/1-1-diploma-preframed.png";
import canadaPostFramed from "../../../../resources/images/whatWeDo/reduced/2-2-canada-postframed.png"
import canadaPreFramed from "../../../../resources/images/whatWeDo/reduced/2-1-canada-preframed-poster.png";

import canadaPostFramedWebp from "../../../../resources/images/whatWeDo/webp/2-2-canada-postframed.webp";
import canadaPreFramedWebp from "../../../../resources/images/whatWeDo/webp/2-1-canada-preframed-poster.webp";
import diplomaPostFramedWebp from "../../../../resources/images/whatWeDo/webp/1-2-diploma-postframed.webp";
import diplomaPreFramedWebp from "../../../../resources/images/whatWeDo/webp/1-1-diploma-preframed.webp";

import renderImg from "../../comp-renderImg/renderImg";

import analyticsDataToDB from "../../comp-analyticsLog/dbLogging";

export default class WhatWeDoSection extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showFirstBeforePhoto: false,
      showSecondBeforePhoto: false,
    }
  }

  componentDidMount(){
    this.logToDBIfUserClickedNavBar();
  }

  logToDBIfUserClickedNavBar() {
    if (window.location.pathname === "/what-we-do") {
      analyticsDataToDB("navbar-button", "what-we-do-button-clicked");
    }
  }

  render() {
    return <div className={`how-it-works-container`} id={"what-we-do-container"}
                onMouseEnter={(evt) => {this.props.sectionHoveredCallbackFunction("what-we-do-container-entered")}}
    >
      <div className={`what-we-do-container-first-container`}>
        <div className={`what-we-do-first-left`}>
          <div className={`section-topic-header`}>
            Why a mat board?
          </div>
          <div>Custom cut mat boards allow your art to fit in any size frame.</div>
          <div className={`what-we-do-subheader`}>The added bonus? Your art will look amazing.</div>
        </div>
        <div className={`what-we-do-first-right`}>
          <div className={`what-we-do-see-before-button` }
            onMouseEnter={() => {this.setState({showFirstBeforePhoto: true})}}
            onMouseLeave={() => {this.setState({showFirstBeforePhoto: false})}}
          >Without Custom Mat</div>
          {renderImg(canadaPreFramed, canadaPreFramedWebp, this.state.showFirstBeforePhoto ? "what-we-do-photo-before" : "no-display")}
          {renderImg(canadaPostFramed, canadaPostFramedWebp, this.state.showFirstBeforePhoto ? "no-display" : "what-we-do-photos")}
      </div>
        <a href={"/custom-picture-mats-make-difference"}>
          <div className={`go-to-success-stories`}>
            *see the difference a mat board can make*
          </div>
        </a>
      </div>
      <div className={`what-we-do-container-second-container`}>
        <div className={`what-we-do-second-left`}>
          <div className={`float-left what-we-do-see-before-button`}
               onMouseEnter={() => {this.setState({showSecondBeforePhoto: true})}}
               onMouseLeave={() => {this.setState({showSecondBeforePhoto: false})}}
          >Without Custom Mat</div>
          {renderImg(diplomaPreFramed, diplomaPreFramedWebp, this.state.showSecondBeforePhoto ? "what-we-do-photo-before" : "no-display")}
          {renderImg(diplomaPostFramed, diplomaPostFramedWebp, this.state.showSecondBeforePhoto ? "no-display" : "what-we-do-photos")}
        </div>
        <div className={`what-we-do-second-right`}>
          <div className={`section-topic-header`}>
            You need great art
          </div>
          <div>Let us cut you a great mat board.</div>
          <div className={`what-we-do-subheader`}>Trust us, your art will thank you.</div>
        </div>
        <a href={"/custom-picture-mats-make-difference"} target={"_blank"} rel="noopener noreferrer">
          <div className={`go-to-success-stories`}>
            *read our success stories*
          </div>
        </a>
      </div>
    </div>
  }
}