import React from 'react';
import {cadUsdRate} from "./forExRate";

export default function PriceRendering({primaryMattingPrice, matCuttingPrice, secondaryMattingPrice, secondMatCuttingPrice, shipping, taxes, shippingDate, eta, currencySelected}) {

  var forExRate = 1;

  if (currencySelected === "USD") {
    forExRate = cadUsdRate;
  }

  return <div>
    <div className={"canvas-app-pricing-container canvas-app-rendering-pricing-section"}>
      <div className={"canvas-app-rendering-pricing-section-header pricing-section-line-item"}>Price Summary</div>

      <div className={"pricing-summary-details canvas-app-rendering-pricing-section-options pricing-section-line-item"}>
        <div className={"pricing-summary-details-item"}>
          Primary Matting
          {/*<span*/}
          {/*className={this.state.selectedMatBoardSizeWidth === null || this.state.selectedMatBoardSizeHeight === null ? "no-display" : "display"}>({this.state.selectedMatBoardSizeWidth} {this.state.selectedMeasurementUnits} x {this.state.selectedMatBoardSizeHeight} {this.state.selectedMeasurementUnits})</span>*/}
        </div>
        <div className={"pricing-summary-details-price"}>
          {currencySelected} {(primaryMattingPrice * forExRate).toFixed(2)}
        </div>
      </div>
      <div className={"pricing-summary-details canvas-app-rendering-pricing-section-options pricing-section-line-item"}>
        <div className={"pricing-summary-details-item"}>
          Mat Window Cutting
          {/*<span*/}
          {/*className={this.state.selectedMatWindowWidth === null || this.state.selectedMeasurementUnits === null ? "no-display" : "display"}>({this.state.selectedMatWindowWidth} {this.state.selectedMeasurementUnits} x {this.state.selectedMatWindowHeight} {this.state.selectedMeasurementUnits})</span>*/}
        </div>
        <div className={"pricing-summary-details-price"}>
          {currencySelected} {(matCuttingPrice * forExRate).toFixed(2)}
        </div>
      </div>
      <div className={secondMatCuttingPrice > 0 ? "display" : "no-display"}>
        <div className={"pricing-summary-details canvas-app-rendering-pricing-section-options pricing-section-line-item"}>
          <div className={"pricing-summary-details-item"}>
            Double Matting
            {/*<span*/}
            {/*className={this.state.selectedMatBoardSizeWidth === null || this.state.selectedMatBoardSizeHeight === null ? "no-display" : "display"}>({this.state.selectedMatBoardSizeWidth} {this.state.selectedMeasurementUnits} x {this.state.selectedMatBoardSizeHeight} {this.state.selectedMeasurementUnits})</span>*/}
          </div>
          <div className={"pricing-summary-details-price"}>
            {currencySelected} {(secondaryMattingPrice * forExRate).toFixed(2)}
          </div>
        </div>
        <div className={"pricing-summary-details canvas-app-rendering-pricing-section-options pricing-section-line-item"}>
          <div className={"pricing-summary-details-item"}>
            Second Mat Cutting
            {/*<span*/}
            {/*className={this.state.selectedMatWindowWidth === null || this.state.selectedMeasurementUnits === null ? "no-display" : "display"}>({this.state.selectedMatWindowWidth} {this.state.selectedMeasurementUnits} x {this.state.selectedMatWindowHeight} {this.state.selectedMeasurementUnits})</span>*/}
          </div>
          <div className={"pricing-summary-details-price"}>
            {currencySelected} {(secondMatCuttingPrice * forExRate).toFixed(2)}
          </div>
        </div>
      </div>
      <div className={"pricing-summary-shipping-details canvas-app-rendering-pricing-section-options"}>
        <div className={"pricing-summary-shipping-line-item pricing-section-line-item"}>
          <div className={"pricing-summary-details-shipping-charges"}>
            Shipping Fees
          </div>
          <div className={"pricing-summary-details-price"}>
            {(primaryMattingPrice + matCuttingPrice + secondaryMattingPrice + secondMatCuttingPrice >= 50) ? "No Shipping Cost" : `${currencySelected} ${(shipping * forExRate).toFixed(2)}` }
          </div>
        </div>

        <div className={"pricing-summary-shipping-line-item pricing-section-line-item"}>
          <div className={"pricing-summary-details-shipping-charges"}>
            GST/HST/PST Taxes
          </div>
          <div className={"pricing-summary-details-price"}>
            {currencySelected} {(taxes).toFixed(2)}
          </div>
        </div>

      </div>
      <div
        className={"pricing-summary-details pricing-summary-total-amount canvas-app-rendering-pricing-section-options pricing-section-line-item"}>
        <div className={"pricing-summary-details-item"}>
          Total
        </div>
        <div className={"pricing-summary-details-price"}>
          {(primaryMattingPrice + matCuttingPrice + secondaryMattingPrice + secondMatCuttingPrice >= 50) ?
            `${currencySelected} ${((primaryMattingPrice + matCuttingPrice + secondaryMattingPrice + secondMatCuttingPrice + taxes)*forExRate).toFixed(2)}` :
            `${currencySelected} ${((primaryMattingPrice + matCuttingPrice + secondaryMattingPrice + secondMatCuttingPrice + shipping + taxes)*forExRate).toFixed(2)}`}
        </div>
      </div>
    </div>

  </div>


}