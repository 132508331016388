import React from "react";
import {Helmet} from "react-helmet";
import LazyLoad from "react-lazyload";
import photo1 from "../../../../resources/images/gallery/floatMounting/floatMountingReddit.png"

export default class FloatMounting extends React.Component {

    constructor() {
        super();
        this.state = {
        };
    };
    componentDidMount() {
        this.setState({
            french: window.location.pathname.slice(-2) === "fr" ? true : false
        })
    };

    render() {
        return <div className={`blog-article-content`}>

            <Helmet>
                <title>CustomMat - Float Mounting Matboards / How to Flaot Mount an Artwork</title>
                <meta name="description" content="We are one of the biggest art suppliers specializing in matboards and picture framing supplies. We specialize in picture frame matboards."/>
            </Helmet>

            <div className={"carousel-subject-head"} style={{textAlign: "center"}}>
                Float Mounting / Mounting / Float Mounting Art<br/>
                (in the world of picture framing)
            </div>

            <div className={"carousel-subject-subhead-type2"}>
                Design Inspirations
            </div>
            <div className={"carousel-subject-subhead-type3"}>
                Written by Paris <br/>Custom Mat Project Blogger
            </div>

            <LazyLoad>
                <iframe className={"design-guide-illustration-video"} width="560" height="315" src="https://www.youtube.com/embed/nI1_AlavLV0"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen></iframe>
            </LazyLoad>

            <div className="blog-article-photo-description">
                Video Tutorial from Youtube Channel The Savvy Heart
            </div>

            <div className={"blog-article-content"}>

                Float mounting or mounting artwork may initially seem like a daunting task that requires custom framing expertise and expert mounting methods.

                <br/><br/>

                However, it's much simpler than you might expect. With the right materials and guidance, you can easily accomplish it at home. Let's dive into this exciting DIY project!

                <br/><br/>

                <b>In this article, we will address the following questions:</b>
                <ol>
                    <li>What is the definition of float mounting?</li>
                    <li>What are the differences between regular float mounting and elevated float mounting?</li>
                    <li>How can you float mount an artwork at home? What materials do you need for a DIY float mounting project?</li>
                </ol>

                What does float mounting artwork or mounting mean in the world of custom framing? Let's find out together!

                <br/><br/>

                <div className={"visit-custom-mat-button"}>
                    <a href={"/"} className={"carousel-read-more-button"}>
                        Visit Custom Mat
                    </a>
                </div>


                <br/><br/>

                <b>
                    What does float mounting artwork or mounting mean in the world of custom framing? And what exactly does it mean to float mount an artwork?
                </b>

                <br/><br/>

                Float mounting, sometimes referred to as "floating," is a technique employed in custom framing to showcase artwork like art prints, paintings, prints, piece of art, or photos in a manner that gives the illusion of the artwork floating within the frame.


                <br/><br/>

                The process involves mounting the artwork on a matboard or foamboard, such as foam core boards. This technique is beautifully demonstrated in the photo below.

                <br/><br/>


                <LazyLoad>
                    <img src={photo1} className={"blog-carousel-photo"}/>
                </LazyLoad>

                <div className="blog-article-photo-description">
                    <a href={"https://www.reddit.com/r/framing/comments/enjeav/just_had_these_framed_float_mounted_is_the/"}>Photo Credit -- Framing Gallery</a>
                </div>

                <br/><br/>

                When you float mount your artwork, also referred to as simply "floating," it is positioned on top of the matboard rather than being concealed by the traditional style of matting.

                <br/><br/>

                Float mounting presents a unique opportunity to display all four edges of your print, setting it apart from traditional matting and framing methods that cover the edges. This approach offers a distinctive aesthetic that diverges from the conventional matting and framing techniques.

                <br/><br/>

                The result is an artwork that appears suspended within the frame, allowing the edges of the artwork to be visible and enhancing its presentation while creating a sense of depth. This technique is often employed when the artwork possesses interesting or decorative edges that the artist or curator intends to showcase.

                <br/><br/>

                In the case of prints with rough or uneven edges, particularly on specialty art paper like Rice Paper, float mounting can effectively accentuate the texture, shape, and unique attributes of the paper. This approach helps the artwork stand out and capture attention.

                <br/><br/>

                Floating mounting offers an elegant and contemporary method of displaying artwork, delivering a distinct and visually striking presentation. It is a common choice in galleries, museums, exhibitions, and private art collections.


                <br/><br/>

                <b>
                    Float mounting pieces can be categorized into two types: elevated and non-elevated. But what exactly are the differences between these two? What are the differences between the ones that are elevated and ones that are not?
                </b>

                <br/><br/>

                <b>Elevated Floating</b>

                <br/><br/>

                <LazyLoad>
                    <iframe className={"design-guide-illustration-video"} width="560" height="315" src="https://www.youtube.com/embed/nI1_AlavLV0"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen></iframe>
                </LazyLoad>

                <div className="blog-article-photo-description">
                    Example of Elevated Floating -- Video Tutorial from Youtube Channel The Savvy Heart
                </div>

                <br/><br/>

                Elevated floating is one of the many methods used to frame and display artwork. It offers a distinct option for showcasing your artwork. In the case of elevated float mounting, the artwork is raised or elevated within the frame, creating a visually dynamic effect. On the other hand, non-elevated float mounting involves mounting the artwork without any additional elevation.

                <br/><br/>


                To illustrate this, let's consider two examples. The first piece showcases elevated float mounting, where the artwork is intentionally raised within the frame, adding an extra dimension to its presentation. Conversely, the next piece demonstrates float mounting without elevation, where the artwork is mounted directly onto the matboard or backing without any raised elements.

                <br/><br/>

                To achieve the floating effect, a spacer is inserted between your artwork and the backing matboard, positioned at the back of the frame. This mounting technique involves adhering the spacer to the backing matboard, after which your artwork is mounted on top of the spacer.

                <br/><br/>

                It's important to note that the spacer mat should be slightly smaller than your art print. This ensures that the spacer remains hidden and does not become visible or create unwanted shadows.

                <br/><br/>

                Please note that elevated float mounting can only be applied to certain types of art papers that have a certain level of sturdiness.

                <br/><br/>

                If your art print materials are too fragile, such as rice paper or fine art paper, there is a risk of the paper edges bending or distorting over time. In such situations, we recommend opting for regular matting or regular floating without elevating your artwork.

                <br/><br/>

                <b>
                    How to Float Mount an Artwork? Can it be done as a DIY project at home? What materials do you need for Float Mounting?
                </b>

                <br/><br/>

                Absolutely! Float mounting can be easily done at home as a DIY project, provided you have all the necessary materials. With the right materials in hand, you can proceed with the float mounting process.

                <br/><br/>

                <b>1. Picture Frames</b>

                <br/><br/>

                When it comes to regular floating (or float mounting without elevation), you can utilize any frames available in stores.

                <br/><br/>

                However, for elevated float mounting, you will need a specific type of picture frame  that features a small gap between the glass/acrylic/glazing and the backing board.

                <br/><br/>

                These frames are commonly referred to as shadow boxes or float mounting frames (or siply floating frames), and they are readily accessible

                <br/><br/>

                <a href={"/ikea-frame-matboard-sizing-guide"}>Here is a list of some of the frames you can consider</a>

                <br/><br/>

                Please note that for elevated float mounting, you specifically require shadow box or float mounting frames. However, these frames can also be used for regular floating.

                <br/><br/>

                It's important to keep in mind that while these frames can accommodate both types of floating, regular frames are only suitable for regular float mounting and cannot achieve the elevated effect.

                <br/><br/>

                <b>
                    2. Backing Matboard
                </b>

                <br/><br/>

                You will require a backing matboard (also known as an uncut mat, mounting mat, or matboard without an opening or cutout) for float mounting. Most frames or shadow boxes come with a thin sheet of paper or a plain backing board in a wooden color.

                <br/><br/>

                Therefore, it is essential to obtain a sturdy backing matboard. Float mounting matboards are typically available in Smooth White or China White. If you want to add a touch of creativity, you can opt for different colors to make it stand out from the rest of the wall.

                <br/><br/>

                Ensure that the backing matboard you select matches the same size as your frame. For example, if your frame measures 16 x 20 inches, you should obtain a backing matboard of the same size. If you are uncertain about the dimensions, you can flip your frame over and measure the width and height of the backing board.

                <br/><br/>

                <b>
                    Where to get the mounting matboard / backing matboard?
                </b>

                <br/><br/>

                You can find them right here at CustomMat. We offer a wide range of mounting matboards available in standard sizes for the most popular frames on the market.

                <br/><br/>

                If your frame happens to be in an unusual size, worry not — we can always custom cut them to fit your specific dimensions, shape, and color preferences.

                <br/><br/>

                Rest assured, all our backing matboards and mounting matboards undergo a meticulous process to eliminate any acidic components and are buffered to meet the acid-free surface requirements necessary for preserving and archival needs.

                <br/><br/>

                <b>
                    3. Spacers (Optional — only needed for elevated float mounting)
                </b>


                <LazyLoad>
                    <iframe className={"design-guide-illustration-video"} width="560" height="315" src="https://www.youtube.com/embed/nI1_AlavLV0?start=69"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen></iframe>
                </LazyLoad>

                <div className="blog-article-photo-description">
                    Example of Spacer -- Video Tutorial from Youtube Channel The Savvy Heart<br/>
                    Here's a good tutorial that shows how to float mount an artwork.
                </div>


                <br/><br/>

                Spacers are small and discreet attachments placed between your artwork and the backing matboard. They create a slight gap that allows your artwork to appear as if it's floating within the frame.

                <br/><br/>

                Please note that spacers are not required for regular float mounting using #1 and #2 methods. However, if you wish to elevate your float and create a more pronounced effect, then spacers are necessary.


                <br/><br/>

                <b>
                    How to Float Mount a Flag? How to Float Mount a T-Shirt? How to Float Mount a Fabric?
                </b>

                <br/><br/>

                T-Shirts, Flags, or any Fabric created materials are frequent and popular items for float mounting. Float mounting can be a great way to display flags, T-shirts, or fabrics while preserving it’s shape and integrity. Float mounting those items is generally similar to the method above.

                <br/><br/>

                <ol>
                    <li>
                        Prepare the backing matboard (or also known as mounting matboard): Cut a piece of acid-free mat board to the desired size and shape for the backing. Ensure that it fits comfortably within the frame.
                    </li>
                    <li>
                        Position the flag: Lay the flag on a clean, flat surface and carefully arrange it on top of the backing board. Smooth out any wrinkles or folds.
                    </li>
                    <li>
                        Attach the fabric to the backing: Use small pins, clips, hinging tapes, or linen tapes to secure the flag to the backing board. Place them discreetly along the top edge of the fabric, ensuring they are hidden from view.
                    </li>
                    <li>
                        Add tissue paper support (optional): Cut strips of acid-free tissue paper and fold them into small accordion-like shapes. These will provide gentle support to the fabric and prevent it from sagging.
                    </li>
                    <li>
                        Position the tissue paper (optional): Place the folded tissue paper strips behind the fabric, distributing them evenly to provide support across the entire surface. The tissue paper should be hidden from view when looking at the front of the fabric.
                    </li>
                    <li>
                        Frame the Fabric: Carefully place the mounted flag, T-Shirt, or Fabric into a frame with sufficient depth to accommodate the flag's thickness, the backing board, and any additional matting if desired.
                    </li>
                    <li>
                        Seal the frame: Secure the back of the frame to ensure that the fabric and mounting materials remain in place. Use appropriate framing hardware or techniques to protect the flag from dust, moisture, and damage.
                    </li>
                </ol>

                <br/><br/>

                When executed properly, floating mounting allows the fabric to be displayed in a way that emphasizes its design and allows it to be viewed without any obstructions. It creates a visually appealing presentation that highlights the flag's unique features and preserves its condition over time.

                <br/><br/>

                <div className={"visit-custom-mat-button"}>
                    <a href={"/"} className={"carousel-read-more-button"}>
                        Visit Custom Mat
                    </a>
                </div>













            </div>


        </div>
    }
}