import React from "react";

export default function fetchBanner(line1, line2, line3) {
  return <h1 className="landing-page-banner">
    <span>
      {line1}
    </span>
    <span className="mobile-no-display">
      {line2}
    </span>
    <span className="mobile-no-display">
      {line3}
    </span>
  </h1>
}