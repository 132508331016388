import localStorageFunctions from "../../comp-localStorage/localStroage";

export default function checkLatestVersion (versionCode) {
    var versionNumber = "version5.05";
    if (localStorageFunctions("B50A23F6186020353E50882873C6CD2EDD1401B1", null) !== versionNumber) {
        localStorage.clear();
        localStorageFunctions("B50A23F6186020353E50882873C6CD2EDD1401B1", versionNumber);
    } else {
        //no action needed.
    }
}