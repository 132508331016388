import React from 'react';
import {keywordList} from "./articleKeywords";

export default class SiteMapPage extends React.Component {

    fetchAHrefs() {
        return Object.keys(keywordList).map((keyword) => {
            return <div>
                <a href={`/get-${keywordList[keyword].replace(/\s+/g, '-')}`}>
                    Page for {keywordList[keyword]}
                </a>
            </div>
        })
    }

    render() {
        return <div>
            Hello, here's the list of our pages:
            {this.fetchAHrefs()}
        </div>
    }
}