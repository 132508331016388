import React from "react";
import ImageGallery from "react-image-gallery";
import ReactHtmlParser from "react-html-parser";
import ArticleIndexFetcher from "./articleIndexFetcher";

export default class ArticleFetcher extends React.Component {

  fetchImages(imageArrayInput) {
    var images = [];
    imageArrayInput.map((image, key) => {
      return images.push({
        original: image,
        thumbnail: image,
      })
    });
    return images;
  }

  fetchArticle(content) {
    var articleInVanillaHtml = "";
    var htmlParagraph;
    content.map((paragraph, key) => {
      if (paragraph.substr(0,6) === "images") {
        return articleInVanillaHtml = articleInVanillaHtml + htmlParagraph;
      } else if (paragraph.substr(0,14) === "imageSubscript") {
        if (paragraph.substr(15,5) === "type2") {
          htmlParagraph = `<div class="image-subscript">
                ${paragraph.substr(21,1000)}
            </div>`
          return articleInVanillaHtml = articleInVanillaHtml + htmlParagraph;
        } else {
          htmlParagraph = `<div class="image-subscript-type1">
                ${paragraph.substr(21,1000)}
            </div>`
          return articleInVanillaHtml = articleInVanillaHtml + htmlParagraph;
        }
      } else if (paragraph.length < 100) {
        //this is a paragraph leader
        htmlParagraph = `<div class="gallery-section-content-highlights">
                ${paragraph}
            </div>`
        return articleInVanillaHtml = articleInVanillaHtml + htmlParagraph;
      } else {
        //this is regular paragraph
        htmlParagraph = `<div class="content-paragraph">
           ${paragraph}
          </div>`
        return articleInVanillaHtml = articleInVanillaHtml + htmlParagraph;
      }
    });
    return articleInVanillaHtml;
  }

  render() {
    var article = this.props.article;
    const images = this.fetchImages(article.photos);

    return <div>
      <div className={`gallery-container-topic`}>
        Home > Gallery > {article.title}
        <h1>{article.title}</h1>
        <h3>{article.hashTags[0]}</h3>
        <h3>{article.hashTags[1]}</h3>
      </div>

      <div className={`overview-section-container`} style={{width: "80%", margin: "0 10%"}}>
        <div className={`overview-section-left`}>
          <div className={`gallery-left-item-container`}>
            <ImageGallery items={images}/>
          </div>

          <div className={`gallery-left-item-container`}>
            <div className={`gallery-section-content`}>
              {ReactHtmlParser(this.fetchArticle(article.contents))}
            </div>
          </div>
        </div>

        <ArticleIndexFetcher/>
      </div>
    </div>
  }
}