import React from 'react';
import RegionFetcher from "../regionFetcher";
import photoWebp from "../../../../resources/images/region/webp/philly.webp";
import photoReduced from "../../../../resources/images/region/reduced/philly.jpg";
import {Helmet} from "react-helmet";

export default class ShowPhillyPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      cityName: "Philadelphia",
      photoWebp: photoWebp,
      photoReduced: photoReduced,
    }
  }

  render() {
    return <div className={"blog-app-container"}>
      <Helmet>
        <title>Custom Cut Mat Boards • Order Online • Deliver to Philadelphia</title>
        <meta name="description" content="We cut custom mat boards for your picture frames. We tailor mat boards and deliver to Philadelphia. Explore ways save on picture framing."/>
      </Helmet>

      <RegionFetcher article={this.state}/>
    </div>
  }
}