import React from 'react';
import {productDB} from "../comp-pricing/productDB";
import SelectFrameFramework from "./selectFrameFramework";


export default class SelectPolaroidFrame extends React.Component {
  render() {
    return <SelectFrameFramework
        productDBFrameCategory={productDB.frameCategories}
        productDBFrame={productDB.polaroidFrame}
        productType={"polaroidFrame"}
    />
  }
}