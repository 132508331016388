import fbdb from '../database/cre';

export default function analyticsDataToDB(destDirectory, value) {
  var datetime = new Date();
  var date = `${datetime.getFullYear()}-${datetime.getMonth()+1}-${datetime.getDate()}`;
  var time = `${datetime.getHours()}-${datetime.getMinutes()}-${datetime.getSeconds()}`;
  datetime = date + "-" + time;
  var destination = `prod/analytics/${destDirectory}/${datetime}`;
  fbdb.database().ref(destination).set(value);
}

