import f16x20o12x16no1 from "../../../resources/precut-mat-options/16x20/12x16no1.jpg";
import f16x20o12x16no2 from "../../../resources/precut-mat-options/16x20/12x16no2.jpg";
import f16x20o12x16no3 from "../../../resources/precut-mat-options/16x20/12x16no3.jpg";

export const preCutMatOpeningSizes = {
    "5x7": {
        default: "4x6",
        width: 5,
        height: 7,
        desc: '5x7" Matboard',
        descDetail: '5x7" Matboards for 5x7" Frames with choice of interior dimensions',
        priceCAD: 5.95,
        openSize: {
            '4x6': {
                width: 3.75,
                height: 5.75,
                desc: '4x6 standard prints',
            },
        },
    },
    "9x9": {
        width: 9,
        height: 9,
        desc: '8x10" Matboard',
        descDetail: '8x10" Matboards for 8x10" Frames with choice of interior dimensions',
        priceCAD: 6.95,
        default: "4x4",
        openSize: {
            '4x4': {
                width: 3.75,
                height: 3.75,
                desc: '4x4 standard prints',
            },
            '5x5': {
                width: 4.75,
                height: 4.75,
                desc: '5x5 standard prints',
            },
        },
    },
    "8x10": {
        width: 8,
        height: 10,
        desc: '8x10" Matboard',
        descDetail: '8x10" Matboards for 8x10" Frames with choice of interior dimensions',
        priceCAD: 7.95,
        default: "4x6",
        openSize: {
            '4x6': {
                width: 3.75,
                height: 5.75,
                desc: '4x6 standard prints',
            },
            '5x7': {
                width: 4.75,
                height: 6.75,
                desc: '5x7 standard prints',
            },
        },
    },
    "11x14": {
        width: 11,
        height: 14,
        default: "4x6",
        desc: '8x10" Matboard',
        descDetail: '8x10" Matboards for 8x10" Frames with choice of interior dimensions',
        priceCAD: 7.95,
        openSize: {
            '4x6': {
                width: 3.75,
                height: 5.75,
                desc: '4x6 standard prints',
            },
            '5x7': {
                width: 4.75,
                height: 6.75,
                desc: '5x7 standard prints',
            },
            '8x10': {
                width: 7.75,
                height: 9.75,
                desc: '8x10 standard prints',
            },
            '8.5x11': {
                width: 8.25,
                height: 10.75,
                desc: "Letter, ANSI-A",
            },
            '9x12': {
                width: 8.75,
                height: 11.75,
                desc: 'ARCH-A prints',
            },
        },
    },
    "12x16": {
        width: 12,
        height: 16,
        desc: '8x10" Matboard',
        descDetail: '8x10" Matboards for 8x10" Frames with choice of interior dimensions',
        priceCAD: 10.95,
        default: "4x6",
        openSize: {
            '4x6': {
                width: 3.75,
                height: 5.75,
                desc: '4x6 standard prints',
            },
            '5x7': {
                width: 4.75,
                height: 6.75,
                desc: '5x7 standard prints',
            },
            '8x10': {
                width: 7.75,
                height: 9.75,
                desc: '8x10 standard prints',
            },
            '8.5x11': {
                width: 8.25,
                height: 10.75,
                desc: "Letter and ANSI-A prints",
            },
            '9x12': {
                width: 8.75,
                height: 11.75,
                desc: 'ARCH-A prints',
            },
        },
    },
    "14x18": {
        width: 14,
        height: 18,
        desc: '8x10" Matboard',
        descDetail: '8x10" Matboards for 8x10" Frames with choice of interior dimensions',
        priceCAD: 15.95,
        default: "4x6",
        openSize: {
            '4x6': {
                width: 3.75,
                height: 5.75,
                desc: '4x6 standard prints',
            },
            '5x7': {
                width: 4.75,
                height: 6.75,
                desc: '5x7 standard prints',
            },
            '8x10': {
                width: 7.75,
                height: 9.75,
                desc: '8x10 standard prints',
            },
            '8.27x11.69': {
                width: 8,
                height: 11.5,
                desc: 'A4 prints',
            },
            '8.5x11': {
                width: 8.25,
                height: 10.75,
                desc: "Letter and ANSI-A prints",
            },
            '9x12': {
                width: 8.75,
                height: 11.75,
                desc: 'ARCH-A prints',
            },
        },
    },
    "16x20": {
        width: 16,
        height: 20,
        desc: '8x10" Matboard',
        descDetail: '8x10" Matboards for 8x10" Frames with choice of interior dimensions',
        priceCAD: 17.95,
        default: "4x6",
        openSize: {
            '4x6': {
                width: 3.75,
                height: 5.75,
                desc: '4x6 standard prints',
            },
            '5x7': {
                width: 4.75,
                height: 6.75,
                desc: '5x7 standard prints',
            },
            '8x10': {
                width: 7.75,
                height: 9.75,
                desc: '8x10 standard prints',

            },
            '8.27x11.69': {
                width: 8,
                height: 11.5,
                desc: 'A4 prints',
            },
            '8.5x11': {
                width: 8.25,
                height: 10.75,
                desc: "Letter and ANSI-A prints",
            },
            '9x12': {
                width: 8.75,
                height: 11.75,
                desc: 'ARCH-A prints',
            },
            '11x14': {
                width: 10.75,
                height: 13.75,
                desc: '11x14 standard prints',
            },
            '11x17': {
                width: 11.5,
                height: 16.25,
                desc: 'A3 prints',
            },
            '11.69x16.54': {
                width: 11.5,
                height: 16.25,
                desc: 'A3 prints',
            },
            '12x18': {
                width: 11.75,
                height: 17.75,
                desc: 'Mini-Poster prints',
            },
        },
    },
    "18x24": {
        width: 18,
        height: 24,
        desc: '8x10" Matboard',
        descDetail: '8x10" Matboards for 8x10" Frames with choice of interior dimensions',
        priceCAD: 19.95,
        default: "4x6",
        openSize: {
            '4x6': {
                width: 3.75,
                height: 5.75,
                desc: '4x6 standard prints',
            },
            '5x7': {
                width: 4.75,
                height: 6.75,
                desc: '5x7 standard prints',
            },
            '8x10': {
                width: 7.75,
                height: 9.75,
                desc: '8x10 standard prints',
            },
            '8.27x11.69': {
                width: 8,
                height: 11.5,
                desc: 'A4 prints',
            },
            '8.5x11': {
                width: 8.25,
                height: 10.75,
                desc: "Letter and ANSI-A prints",
            },
            '9x12': {
                width: 8.75,
                height: 11.75,
                desc: 'ARCH-A prints',
            },
            '11x14': {
                width: 10.75,
                height: 13.75,
                desc: '11x14 standard prints',
            },
            '11x17': {
                width: 10.75,
                height: 16.75,
                desc: 'ANSI-B, Tabloid, and Ledger prints',
            },
            '11.69x16.54': {
                width: 11.5,
                height: 16.25,
                desc: 'A3 prints',
            },
            '12x18': {
                width: 11.75,
                height: 17.75,
                desc: 'Mini-Poster prints',
            },
            '16x20': {
                width: 15.75,
                height: 19.75,
                desc: 'Portrait Pictures (digital camera picture) prints',

            },
        },
    },
    "19.75x19.75": {
        width: 19.75,
        height: 19.75,
        desc: '19.75x19.75" Matboard',
        descDetail: '19.75x19.75" Matboards for 8x10" Frames with choice of interior dimensions',
        priceCAD: 21.95,
        default: "4x4",
        openSize: {
            '4x4': {
                width: 3.75,
                height: 3.75,
                desc: '4x4 standard prints',
            },
            '5x5': {
                width: 4.75,
                height: 4.75,
                desc: '5x5 standard prints',
            },
            '8x8': {
                width: 7.75,
                height: 7.75,
                desc: '8x8 standard prints',
            },
            '10x10': {
                width: 9.75,
                height: 9.75,
                desc: '10x10 standard prints',
            },
            '12x12': {
                width: 11.75,
                height: 11.75,
                desc: '12x12 standard prints',
            },
            '16x16': {
                width: 15.75,
                height: 15.75,
                desc: '12x12 standard prints',
            },
        },
    },
    "20x20": {
        width: 20,
        height: 20,
        desc: '20x20" Matboard',
        descDetail: '20x20" Matboards for 8x10" Frames with choice of interior dimensions',
        priceCAD: 21.95,
        default: "4x4",
        openSize: {
            '4x4': {
                width: 3.75,
                height: 3.75,
                desc: '4x4 standard prints',
            },
            '5x5': {
                width: 4.75,
                height: 4.75,
                desc: '5x5 standard prints',
            },
            '8x8': {
                width: 7.75,
                height: 7.75,
                desc: '8x8 standard prints',
            },
            '10x10': {
                width: 9.75,
                height: 9.75,
                desc: '10x10 standard prints',
            },
            '12x12': {
                width: 11.75,
                height: 11.75,
                desc: '12x12 standard prints',
            },
            '16x16': {
                width: 15.75,
                height: 15.75,
                desc: '12x12 standard prints',
            },
        },
    },
    "19.75x27.5": {
        width: 19.75,
        height: 27.5,
        desc: '19.75x27.5" Matboard',
        descDetail: '19.75x27.5" Matboards for 8x10" Frames with choice of interior dimensions',
        priceCAD: 22.95,
        default: "4x6",
        openSize: {
            '4x6': {
                width: 3.75,
                height: 5.75,
                desc: '4x6 standard prints',
            },
            '5x7': {
                width: 4.75,
                height: 6.75,
                desc: '5x7 standard prints',
            },
            '8x10': {
                width: 7.75,
                height: 9.75,
                desc: '8x10 standard prints',
            },
            '8.27x11.69': {
                width: 8,
                height: 11.5,
                desc: 'A4 prints',
            },
            '8.5x11': {
                width: 8.25,
                height: 10.75,
                desc: "Letter and ANSI-A prints",
            },
            '9x12': {
                width: 8.75,
                height: 11.75,
                desc: 'ARCH-A prints',
            },
            '11x14': {
                width: 10.75,
                height: 13.75,
                desc: '11x14 standard prints',
            },
            '11x17': {
                width: 10.75,
                height: 16.75,
                desc: 'ANSI-B, Tabloid, and Ledger prints',
            },
            '11.69x16.54': {
                width: 11.5,
                height: 16.25,
                desc: 'A3 prints',
            },
            '12x18': {
                width: 11.75,
                height: 17.75,
                desc: 'Mini-Poster prints',
            },
            '14x18': {
                width: 13.75,
                height: 17.75,
                desc: 'Mini-Poster prints',
            },
            '16x20': {
                width: 15.75,
                height: 19.75,
                desc: 'Portrait Pictures (digital camera picture) prints',
            },

        },
    },
    "20x28": {
        width: 20,
        height: 28,
        desc: '20x28" Matboard',
        descDetail: '20x28" Matboards for 8x10" Frames with choice of interior dimensions',
        priceCAD: 22.95,
        default: "4x6",
        openSize: {
            '4x6': {
                width: 3.75,
                height: 5.75,
                desc: '4x6 standard prints',
            },
            '5x7': {
                width: 4.75,
                height: 6.75,
                desc: '5x7 standard prints',
            },
            '8x10': {
                width: 7.75,
                height: 9.75,
                desc: '8x10 standard prints',
            },
            '8.27x11.69': {
                width: 8,
                height: 11.5,
                desc: 'A4 prints',
            },
            '8.5x11': {
                width: 8.25,
                height: 10.75,
                desc: "Letter and ANSI-A prints",
            },
            '9x12': {
                width: 8.75,
                height: 11.75,
                desc: 'ARCH-A prints',
            },
            '11x14': {
                width: 10.75,
                height: 13.75,
                desc: '11x14 standard prints',
            },
            '11x17': {
                width: 10.75,
                height: 16.75,
                desc: 'ANSI-B, Tabloid, and Ledger prints',
            },
            '11.69x16.54': {
                width: 11.5,
                height: 16.25,
                desc: 'A3 prints prints',
            },
            '12x18': {
                width: 11.75,
                height: 17.75,
                desc: 'Mini-Poster prints',
            },
            '14x18': {
                width: 13.75,
                height: 17.75,
                desc: 'Mini-Poster prints',
            },
            '16x20': {
                width: 15.75,
                height: 19.75,
                desc: 'Portrait Pictures (digital camera picture) prints',
            },

        },
    },
    "24x35.75": {
        width: 24,
        height: 35.75,
        desc: '24x35.75" Matboard',
        descDetail: '24x35.75" Matboards for 8x10" Frames with choice of interior dimensions',
        priceCAD: 29.95,
        default: "4x6",
        openSize: {
            '4x6': {
                width: 3.75,
                height: 5.75,
                desc: '4x6 standard prints',
            },
            '5x7': {
                width: 4.75,
                height: 6.75,
                desc: '5x7 standard prints',
            },
            '8x10': {
                width: 7.75,
                height: 9.75,
                desc: '8x10 standard prints',
            },
            '8.27x11.69': {
                width: 8,
                height: 11.5,
                desc: 'A4 prints',
            },
            '8.5x11': {
                width: 8.25,
                height: 10.75,
                desc: "Letter and ANSI-A prints",
            },
            '11x14': {
                width: 10.75,
                height: 13.75,
                desc: '11x14 standard prints',
            },
            '11x17': {
                width: 10.75,
                height: 16.75,
                desc: 'ANSI-B, Tabloid, and Ledger prints',
            },
            '11.69x16.54': {
                width: 11.5,
                height: 16.25,
                desc: 'A3 prints',
            },
            '12x18': {
                width: 11.75,
                height: 17.75,
                desc: 'Mini-Poster prints',
            },
            '16x20': {
                width: 15.75,
                height: 19.75,
                desc: 'Portrait Pictures (digital camera picture) prints',
            },
            '16x24': {
                width: 15.75,
                height: 23.75,
                desc: '16x24 standard prints',
            },
            '18x24': {
                width: 17.75,
                height: 23.75,
                desc: 'Architectural C, Medium Poster, and ½ size prints',
            },
            '20x24': {
                width: 19.75,
                height: 23.75,
                desc: '20x24 standard prints',
            },
            '20x28': {
                width: 19.75,
                height: 27.75,
                desc: '20x28 standard prints',
            },
            '20x30': {
                width: 19.75,
                height: 29.75,
                desc: '20x30 standard prints.',
            }
        },
    },
    "24x36": {
        width: 24,
        height: 36,
        desc: '8x10" Matboard',
        descDetail: '8x10" Matboards for 8x10" Frames with choice of interior dimensions',
        priceCAD: 29.95,
        default: "4x6",
        openSize: {
            '4x6': {
                width: 3.75,
                height: 5.75,
                desc: '4x6 standard prints',
            },
            '5x7': {
                width: 4.75,
                height: 6.75,
                desc: '5x7 standard prints',
            },
            '8x10': {
                width: 7.75,
                height: 9.75,
                desc: '8x10 standard prints',
            },
            '8.27x11.69': {
                width: 8,
                height: 11.5,
                desc: 'A4 prints',
            },
            '8.5x11': {
                width: 8.25,
                height: 10.75,
                desc: "Letter and ANSI-A prints",
            },
            '11x14': {
                width: 10.75,
                height: 13.75,
                desc: '11x14 standard prints',
            },
            '11x17': {
                width: 10.75,
                height: 16.75,
                desc: 'ANSI-B, Tabloid, and Ledger prints',
            },
            '11.69x16.54': {
                width: 11.5,
                height: 16.25,
                desc: 'A3 prints',
            },
            '12x18': {
                width: 11.75,
                height: 17.75,
                desc: 'Mini-Poster prints',
            },
            '16x20': {
                width: 15.75,
                height: 19.75,
                desc: 'Portrait Pictures (digital camera picture) prints',
            },
            '16x24': {
                width: 15.75,
                height: 23.75,
                desc: '16x24 standard prints',
            },
            '18x24': {
                width: 17.75,
                height: 23.75,
                desc: 'Architectural C, Medium Poster, and ½ size prints',
            },
            '20x24': {
                width: 19.75,
                height: 23.75,
                desc: '20x24 standard prints',
            },
            '20x28': {
                width: 19.75,
                height: 27.75,
                desc: '20x28 standard prints',
            },
            '20x30': {
                width: 19.75,
                height: 29.75,
                desc: '20x30 standard prints.',
            }
        },
    },

}