import React from 'react';

import image1 from "../../../../resources/images/gallery/11-articleEleven/11-image1.jpg";
import image2 from "../../../../resources/images/gallery/11-articleEleven/11-image2.jpg";
import image3 from "../../../../resources/images/gallery/11-articleEleven/11-image3.jpg";
import image4 from "../../../../resources/images/gallery/11-articleEleven/11-image4.jpg";
import image5 from "../../../../resources/images/gallery/11-articleEleven/11-image5.jpg";
import image6 from "../../../../resources/images/gallery/11-articleEleven/11-image6.jpg";
import image7 from "../../../../resources/images/gallery/11-articleEleven/11-image7.jpg";
import image8 from "../../../../resources/images/gallery/11-articleEleven/11-image8.jpg";
import image9 from "../../../../resources/images/gallery/11-articleEleven/11-image9.jpg";
import image10 from "../../../../resources/images/gallery/11-articleEleven/11-image10.jpg";
import image11 from "../../../../resources/images/gallery/11-articleEleven/11-image11.jpg";
import image12 from "../../../../resources/images/gallery/11-articleEleven/11-image12.jpg";
import image13 from "../../../../resources/images/gallery/11-articleEleven/11-image13.jpg";
import image14 from "../../../../resources/images/gallery/11-articleEleven/11-image14.jpg";
import image15 from "../../../../resources/images/gallery/11-articleEleven/11-image15.jpg";
import image16 from "../../../../resources/images/gallery/11-articleEleven/11-image16.jpg";
import image17 from "../../../../resources/images/gallery/11-articleEleven/11-image17.jpg";
import photo1 from "../../../../resources/images/blog/photoset2/photo1.jpg";
import LazyLoad from 'react-lazyload';
import photo2 from "../../../../resources/images/blog/photoset2/photo2.jpg";
import photo7 from "../../../../resources/images/blog/photoset2/photo7.jpg";
import photo23 from "../../../../resources/images/blog/photoset2/23.k.brooke.home.PNG";
import photo3 from "../../../../resources/images/blog/photoset2/photo3.jpg";
import photo4 from "../../../../resources/images/blog/photoset2/photo4.jpg";
import photo5 from "../../../../resources/images/blog/photoset2/photo5.jpg";
import photo9 from "../../../../resources/images/blog/photoset2/photo9.jpg";


export default class BlogArticleTen extends React.Component {

  constructor() {
    super();
    this.state = {
      penDrawing: false,
    };
  };

  fetchImage(img) {
    return <LazyLoad>
      <img src={img} className={"blog-content-photo"}/>
    </LazyLoad>
  }

  render() {
    return <div className="blog-app-container">

      <div className="carousel-subject-head">HOW TO MAKE AN OVERSIZED <br/> MAT GALLERY WALL GRID</div>

      <div className="carousel-subject-subhead-type2">
        GALLERY WALL | GRID WALL
      </div>

      <div className={"carousel-subject-subhead-type3"}>
          Written by Kendra Hangerman  @ <a href={"https://www.kendrafoundit.com/blog/how-to-make-an-oversized-mat-gallery-wall-grid"}>kendrafoundit.com</a>
        <br/><br/>
      </div>

      <LazyLoad>
        <img src={photo1} className={"blog-carousel-photo"}/>
      </LazyLoad>

      <div className={"blog-article-content"}>
        Oversized mat gallery walls are a thing of beauty; they are sophisticated, yet personal and can be spotted all over Pinterest, Instagram, and design blogs. Unfortunately, at around $55 for a 16x20 frame, they can get very pricey very quickly.
        <br/><br/>
        But don’t let that discourage you! I’m going to let you in on a little hack and a big secret: did you know that you can buy custom oversized matting online to fit any frame? I hit up my local IKEA and with the help of <a href={"www.custommat.ca"}>Custom Mat</a>, I was able to create the best IKEA hack I’ve ever created.
        <br/><br/>
      </div>

      {this.fetchImage(photo2)}

      <div className={"blog-article-content"}>
        <b>STEP ONE: PLAN YOUR GRID & LAYOUT</b>
        <br/><br/>
        Figure out how many columns and rows you want by using painter’s tape and approximate measurements of the frames you want to use; you can also complete this step by cutting out craft paper and using it as a template on your wall. I originally tried to just eyeball it, but had trouble deciding, so this method really helped me choose the proper arrangement.
        <br/><br/>
        Don’t worry about measurements between the “frames” too much or if it’s exact (you can see that mine isn’t even close to exact and I ran out of painter’s tape halfway through!). This is just to give you an idea of the scale of your project and prevent you from purchasing too many or too few frames.
        <br/><br/>
      </div>

      {this.fetchImage(photo7)}

      <div className={"blog-article-content"}>
        <b>STEP TWO: PURCHASE AFFORDABLE FRAMES</b>
        <br/><br/>
        I purchased <a href={"https://www.ikea.com/ca/en/p/lomviken-frame-aluminum-80314308/"}>12 Lomviken 16¼x20" frames from IKEA for $16.99 each.</a> Note that this is Canadian dollars, and they are even less in the United States. They have a plexiglass topper instead of glass, which keeps the cost down (and from them breaking when my toddler gets too rambunctious).
        <br/><br/>
      </div>

      {this.fetchImage(photo23)}

      <div className={"blog-article-content"}>
        <b>STEP THREE: PURCHASE CUSTOM MATTING TO ELEVATE YOUR FRAME</b>
        <br/><br/>
        IKEA frames come with their own basic mats, but I wanted to swap them out for something with a little more character and style.
        <br/><br/>
        Below are the exact mats that I ordered from Custom Mat to fit the 16¼x20" silver Lomviken frames (these particular frames also come in gold if that’s more your style). I designed the mats to fit a horizontal 5x7" photo and to look like the large-mat offset frames from the Pinterest photo.
        <br/><br/>
      </div>

      {this.fetchImage(photo3)}

      <div className={"blog-article-content"}>
        <b>Mat colour:</b> Smooth White<br/>
        <b>Opening for a 5x7" photo:</b> 4.75x6.75" (the photo window size needs to be a bit smaller than your photo)<br/>
        <b>Size of overall matting:</b> 16x20"*<br/>
        <br/><br/>
        *Note: IKEA says that their frames are 16¼x20" but they are actually 16x20". I know it’s scary, but trust me on this one. Custom Mat makes mat boards for these frames all the time; if you still feel unsure, send them an email. I actually learned this lesson the hard way with a previous project when my mats were too big for my IKEA frames. The measurements above fit these frames perfectly.
        <br/><br/>
        Custom Mat offers a quantity discount of up to 40% off, so the more mats you purchase, the bigger the discount. I ordered 12, so my mats came to $14 each. Bonus: they ship to Canada and the USA with free shipping over $49.
        <br/><br/>
      </div>

      {this.fetchImage(photo4)}

      <div className={"blog-article-content"}>
        <b>STEP FOUR: SWAP OUT THE MATTING & ADD THE PHOTOS</b>
        <br/><br/>
        This step speaks for itself, but this is where the magic happens. Swap out the IKEA mats for the custom mats and watch your frames instantly transform before your eyes.        <br/><br/>
        <br/><br/>
      </div>

      {this.fetchImage(photo9)}
      <div className={"blog-article-photo-description"}>
        Left: Custom Cut matboard | Right: IKEA's default matobard
      </div>


      <div className={"blog-lead-photo"}>
        <picture>
          <source type="image/webp" srcSet={image10}/>
          <source type="image/png" srcSet={image10}/>
          <img className="blog-type-2-image-file" src={image10}
               alt={"This is an illustrative diagram of how a ready made frame can look perfect with a custom cut mat boards."}/>
        </picture>
      </div>

      <div className={"blog-article-content"}>
        <b>STEP FIVE: HANG YOUR GALLERY WALL</b>
        <br/><br/>
        When hanging a grid gallery wall, spacing is everything. I chose to space my pictures 1.75 inches apart (top to bottom and side to side).
        <br/><br/>
        My new gallery wall is in my home office, and it really elevates the entire room. The best part is that I’m now surrounded by my family photos everyday and I did it on a budget (would you expect anything else of me?).
        <br/><br/>
      </div>

      <a href="/customize-matboards">
        <div className="product-listing-action-button">Start Designing</div>
        <br/><br/>
      </a>

      <div className={"blog-lead-photo"}>
        <picture>
          <source type="image/webp" srcSet={image11}/>
          <source type="image/png" srcSet={image11}/>
          <img className="blog-type-2-image-file" src={image11}
               alt={"This is an illustrative diagram of how a ready made frame can look perfect with a custom cut mat boards."}/>
        </picture>
      </div>

      <div className={"blog-lead-photo"}>
        <picture>
          <source type="image/webp" srcSet={image12}/>
          <source type="image/png" srcSet={image12}/>
          <img className="blog-type-2-image-file" src={image12}
               alt={"This is an illustrative diagram of how a ready made frame can look perfect with a custom cut mat boards."}/>
        </picture>
      </div>

      <div className={"blog-lead-photo"}>
        <picture>
          <source type="image/webp" srcSet={image13}/>
          <source type="image/png" srcSet={image13}/>
          <img className="blog-type-2-image-file" src={image13}
               alt={"This is an illustrative diagram of how a ready made frame can look perfect with a custom cut mat boards."}/>
        </picture>
      </div>

      <div className={"blog-lead-photo"}>
        <picture>
          <source type="image/webp" srcSet={image14}/>
          <source type="image/png" srcSet={image14}/>
          <img className="blog-type-2-image-file" src={image14}
               alt={"This is an illustrative diagram of how a ready made frame can look perfect with a custom cut mat boards."}/>
        </picture>
      </div>

      <div className={"blog-lead-photo"}>
        <picture>
          <source type="image/webp" srcSet={image15}/>
          <source type="image/png" srcSet={image15}/>
          <img className="blog-type-2-image-file" src={image15}
               alt={"This is an illustrative diagram of how a ready made frame can look perfect with a custom cut mat boards."}/>
        </picture>
      </div>

      <div className={"blog-lead-photo"}>
        <picture>
          <source type="image/webp" srcSet={image16}/>
          <source type="image/png" srcSet={image16}/>
          <img className="blog-type-2-image-file" src={image16}
               alt={"This is an illustrative diagram of how a ready made frame can look perfect with a custom cut mat boards."}/>
        </picture>
      </div>

      <div className={"blog-lead-photo"}>
        <picture>
          <source type="image/webp" srcSet={image17}/>
          <source type="image/png" srcSet={image17}/>
          <img className="blog-type-2-image-file" src={image17}
               alt={"This is an illustrative diagram of how a ready made frame can look perfect with a custom cut mat boards."}/>
        </picture>
        <br/><br/>
      </div>

      <a href="/customize-matboards">
        <div className="product-listing-action-button">Start Designing</div>
        <br/><br/>
      </a>

    </div>
  }
}