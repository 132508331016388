import React from 'react';
import LazyLoad from "react-lazyload";
import {Helmet} from "react-helmet";

import photo1 from "../../../resources/images/gallery/13-chooseRightMatboard/IGPhoto1.jpg";
import photo2 from "../../../resources/images/gallery/13-chooseRightMatboard/IGPhoto2.jpg";
import photo3 from "../../../resources/images/gallery/13-chooseRightMatboard/IGPhoto3.jpg";
import photo4 from "../../../resources/images/gallery/13-chooseRightMatboard/IgPhoto4.webp";
import photo5 from "../../../resources/images/gallery/13-chooseRightMatboard/IGPhoto5.webp";
import photo6 from "../../../resources/images/gallery/13-chooseRightMatboard/Photo6.jpg";
import photo7 from "../../../resources/images/gallery/13-chooseRightMatboard/Photo7.jpg";
import photo8 from "../../../resources/images/gallery/matboards/Shade6.jpg";


export default class HowToChooseRightMatboard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        };
    };

    componentDidMount() {

    }

    render() {
        return <div className={"blog-app-container"}>

            <Helmet>
                <title>How To Choose The Right Mat Board For Your Picture, Image or Artwork.</title>
                <meta name="description" content="Matboards, also known as matting or mounting boards, are commonly used in the picture framing industry to enhance and protect artworks, photographs, or other items. Matboards are made through a manufacturing process that involves several steps. Here's a general overview of how matboards are typically produced."/>
            </Helmet>

            <div className={"carousel-subject-head"} style={{textAlign: "center"}}>
                How To Choose The Right Mat Board For Your Picture, Image or Artwork.
            </div>

            <div className={"carousel-subject-subhead-type2"}>
                Matboard
            </div>

            <div className={"carousel-subject-subhead-type3"}>
                Written by Meghan L.<br/>Custom Mat Project Blogger
            </div>

            <LazyLoad>
                <img className="blog-article-photo-two-image" src={photo1}/>
                <img className="blog-article-photo-two-image" src={photo2}/>
            </LazyLoad>

            <br/>

            <div className="blog-article-photo-description">
                Customer Photo<br/>
                <a href={"https://www.instagram.com/p/CpX-HBZJGGp/"}>
                    Photo Credit @madisonmariephoto
                </a>
            </div>

            <div className={"blog-article-content"}>



                <b>
                    How To Choose The Right Mat Board For Your Picture, Image or Artwork.
                </b>

                <br/>

                <b>Which Picture Mat Is Right For Your Project? </b>

                <br/><br/>

                Choosing the right picture mat (also called photo mats or mat board) for your project is key as it can greatly impact the overall presentation and aesthetic appeal of your project.

                Here are 5 simple steps to help you decide which picture mat is perfect for your project.

                <br/><br/>

                <LazyLoad>
                    <img src={photo4} className={"blog-content-photo"}/>
                </LazyLoad>

                <LazyLoad>
                    <img src={photo6} className={"blog-content-photo"}/>
                </LazyLoad>


                <div className="blog-article-photo-description">
                    Circular / Oval Matboard has become increasingly popular | Customer Photos<br/>
                    <a href={"https://www.instagram.com/p/CiSggAwOvjv/"}>
                        Top: Photo Credit @frau.jelena.art
                    </a>
                    <span> | </span>
                    <a href={"https://www.instagram.com/p/Coij5vZOsdg/"}>
                        Bottom: Photo Credit @absolem.ink
                    </a>
                </div>


                <b>
                    1) Consider the artwork or picture that you want to display. Then consider the context.
                </b>

                <br/><br/>

                Analyze the colours, subject matter, and style of your picture. Determine whether it is a vibrant, colourful piece or a black-and-white photograph. This will guide you in choosing a colour for your picture mat that complements or enhances the wall art.

                <br/><br/>

                Questions to consider:

                <br/><br/>

                <b>
                    2) Assess the size of your picture or image.
                </b>

                <br/><br/>

                Measure the dimensions of your picture. The mat board should have an appropriate border width to provide visual breathing space and balance. A general guideline is to leave a border of 2-3 inches around the artwork, but you can adjust it based on personal preference and the size of the piece.

                <br/><br/>

                The picture frame mat should also have a window that is slightly smaller than your picture, for the best fit. We recommend an opening size of 0.25” smaller in both length and width.

                <br/><br/>

                You should also consider the frame size. Custom picture framing can be very expensive, and time consuming.

                <br/><br/>

                To determine the overall mat board size for your picture, follow these steps:

                <br/><br/>

                <ol>
                    <li>
                        <b>Measure the artwork: </b>
                        Measure the width and height of your picture, including any borders or white space you want to include within the mat.
                    </li>

                    <br/>

                    <li>
                        <b>Determine the desired mat border size: </b>
                        Decide how much of a border you want around your picture. This is a personal preference and can vary depending on the size of the artwork and the desired visual impact. Common mat border widths range from 1 inch to 3 inches, but you can choose any size you prefer.
                    </li>

                    <br/>


                    <li>
                        <b>Calculate the overall mat board size: </b>
                        Add the dimensions of the artwork to twice the desired mat border width. For example, if your artwork measures 8 inches by 10 inches and you want a 2-inch border, your overall mat board size would be:
                        <br/><br/>

                        Width: 8 inches + (2 inches x 2) = 8 inches + 4 inches = 12 inches Height: 10 inches + (2 inches x 2) = 10 inches + 4 inches = 14 inches

                        <br/><br/>
                        Therefore, your overall mat board size would be 12 inches by 14 inches.
                    </li>

                    <br/>


                    <li>
                        <b>Adjust for the frame: </b>

                        If you plan to place the matted picture inside a frame, consider the frame's dimensions. The overall mat board size should be slightly smaller than the frame opening to ensure a proper fit. Measure the inside dimensions of the frame and subtract a small margin, typically around 1/8 to 1/4 inch, from each side of the overall mat board size.
                    </li>

                    <br/>


                    <li>
                        <b>Finalize the mat board size & color: </b>

                        After adjusting for the frame, you will have the final overall mat board size that fits both your picture and frame properly.

                        <br/><br/>

                        In terms of colors, normally, Smooth White picture mat boards are our best sellers. After that, off-white China White comes in second for popularity.
                    </li>

                    <br/>

                    <LazyLoad>
                        <img src={photo7} className={"blog-content-photo"}/>
                    </LazyLoad>


                    <div className="blog-article-photo-description">
                        Example of Double Matting | Customer Photos<br/>
                        <a href={"https://www.instagram.com/p/CQwA3tqJpbT/"}>
                            Photo Credit @watercolorsmakemesmile
                        </a>
                    </div>


                    <li>
                        <b>Check if you need additional options  </b>— like double matting or backing boards:

                        See if you need the accented double matting or backing board.

                        <br/><br/>

                        Double matting enhances overall presentation of your artwork by highlighting the artwork with an accented color as shown by the photo below. Also, if your artwork needs to be mounted (or attached to a board to ensure there will be no wrinkles or bubbles in the future), you might want to consider the uncut backing matboard for mounting purposes. They are great replacements for the foam boards.
                    </li>

                    <br/>


                    <li>
                        <b>Show Kits: </b>
                        See if you want to add sleeves / clear bags to make it as a show kit. Showkits are great for the art fairs, or if you want to send your customers your artworks in a nicer way (easier for them to frame).

                        <br/><br/>

                        For instance, if your artwork is drawn on a 11 x 14 inch drawing paper, you can send your customers in your 14 x 18 inch or 16 x 20 inch artwork showkit — along with (1) bevel cut matboard, backing board, and sleeves, so that they can frame it easily.

                        <br/><br/>

                        Your customers can order 14 x 18 inch or 16 x 20 inch picture frames online or offline retail stores easily, and create a “custom framed” look on any standard sized photo frames. It will make your customers appreciate your work more and cherish the piece for a longer time.
                    </li>

                </ol>

                <br/>

                <LazyLoad>
                    <img src={photo5} className={"blog-content-photo"}/>
                </LazyLoad>


                <div className="blog-article-photo-description">
                    Example of the Showkit | Customer Photo<br/>
                    <a href={"https://www.instagram.com/p/Cb036v9LB_f/"}>
                        Photo Credit @wendygielis.art
                    </a>
                </div>

                <br/>

                Remember to double-check your measurements and consider any specific preferences or requirements you may have for the mat board, such as a reveal or overlap of the picture within the mat opening. It's always a good idea to consult with a professional framer if you need assistance or have any doubts about determining the correct mat size.

                <br/><br/>

                <div className={"visit-custom-mat-button"}>
                    <a href={"/"} className={"carousel-read-more-button"}>
                        Visit Custom Mat
                    </a>
                </div>

                <b>Questions to consider:</b>

                <br/><br/>

                <b>
                    Q: How wide of a margin or border mat do I want around my image?
                </b>

                <br/><br/>

                A: 1 or 2” is most common.

                <br/><br/>

                <b>
                    Q: What are the most common picture frame sizes and which picture frame size is closest to my image size? Do I need to go up one frame size?
                </b>

                <br/><br/>

                A: The most common photo frame sizes are:

                <br/><br/>

                5ʺ x 7ʺ<br/>
                8ʺ x 10ʺ<br/>
                9ʺ x 12ʺ<br/>
                11ʺ x 14ʺ<br/>
                12ʺ x 12ʺ<br/>
                12ʺ x 16ʺ<br/>
                16ʺ x 20ʺ<br/>
                18" x 24ʺ<br/>
                20ʺ x 24ʺ<br/>
                24ʺ x 36ʺ<br/>
                30ʺ x 40ʺ<br/>

                <br/>

                <b>
                    Q:  Should I choose pre-cut sizes?
                </b>
                <br/><br/>

                <a href={"https://www.custommat.ca/precut-mats"}>Link to Order Standard Sized, Precut Mats</a><br/>
                <a href={"https://www.custommat.ca/customize-matboards"}>Link to Order Custom Sized, Custom Cut mats</a>

                <br/><br/>

                A:  If you are working with a standard print size and a standard frame size, you can easily find and order a ready-made white mat board in the above pre-cut link. These are collection of all the matboard and cutout / openings for the prints and posters that are best sellers.

                <br/><br/>

                <b>3) Select the colour of the picture mat.</b>

                <br/><br/>

                Look for colors that complement the artwork, photo, or art print. This will enhance its visual impact. You can choose a colour for your picture mat that matches a dominant colour in your picture or opt for a neutral shade to create a classic and timeless look. Experiment with different picture mat colors to see how they affect the overall presentation. You may request samples of our mat board colours. Simply email us at hello@custommat.ca and let us know. Please be sure to include your name and address.

                <br/><br/>

                <b>
                    Questions to consider:
                </b>

                <br/><br/>

                <b>Will my mat board colour clash with the other things in the room?</b>

                <br/>

                <b>Will my mat board colour go with my frame?</b>

                <br/><br/>

                <ol>
                    <li>
                        <b>Test different combinations: </b>
                        If you're unsure about the best mat board choice, consider obtaining samples or swatches from your framing supplier. Place the samples against the artwork/photo and observe how different colors, textures, and thicknesses interact. This will help you visualize the result and make an informed decision.
                    </li>
                    <li>
                        <b>Best Sellers: </b>
                        In terms of colors, normally, Smooth White picture mat boards are our best sellers. After that, off-white China White comes in second for popularity.
                    </li>
                </ol>

                <br/><br/>

                <b>4) Consider the thickness of the picture mat.</b>

                <br/><br/>

                Mat boards come in different thicknesses, typically ranging from 2-ply to 8-ply. Thicker mat boards can add depth and dimension to the artwork, but be mindful of the frame depth and how it will accommodate the combined thickness of the mat, artwork, and any other elements like spacers or glazing.

                <br/><br/>

                Things to consider:<br/>
                — Do I want single matting or double matting? ( See our article on Single Matting VS Double Matting for more details)

                <br/><br/>

                Note: Our picture mats only come in 1 thickness. They are 4 - ply thick which translates to 1/16”

                <br/><br/>

                <b>5) Seek another opinion: </b>

                <br/><br/>

                If you are uncertain about the aesthetic direction you want to go, try consulting with a trusted friend/ colleague or family member for an additional set of eyes. Having another opinion can offer guidance as well as help you better understand your own preference on the matter.

                <br/><br/>

                Remember, selecting the right mat board is a subjective process that depends on personal taste, the artwork itself, and the desired aesthetic. Take your time to explore different options and consider how each choice enhances the visual impact and presentation of your picture.

                <br/><br/>

                <b>Does my picture need a photo mat?</b>

                <br/><br/>

                The decision to use a picture mat is subjective and depends on several factors. Here are some considerations to help you determine if your picture needs a mat:

                <ol>
                    <li>
                        <b>Protection: </b>
                        If your picture is valuable, delicate, or requires extra protection, a mat can create a buffer between the artwork and the glass or acrylic glazing. This helps prevent direct contact, minimizing the risk of damage, sticking, or smudging.
                    </li>

                    <br/>

                    <li>
                        <b>Aesthetic Enhancement: </b>
                        A well-chosen mat can enhance the overall presentation of your picture. It can provide a visual separation between the artwork and the frame, adding depth and creating a professional and finished look. Additionally, a mat can complement the colors, style, or theme of the picture, accentuating its visual impact.
                    </li>

                    <br/>


                    <li>
                        <b>Size Adjustment: </b>
                        If your picture is smaller than the frame you intend to use, a mat can help fill the space and create a balanced composition. By extending the dimensions of the artwork, the mat can make it more visually appealing within a larger frame.
                    </li>

                    <br/>


                    <li>
                        <b>Personal Preference: </b>
                        Ultimately, the decision to use a mat comes down to personal taste and the desired aesthetic. Some individuals prefer the clean and minimalist look of a frame without a mat, while others appreciate the traditional and classic appearance that a mat provides.
                    </li>
                </ol>

                <br/>

                Consider these factors and evaluate how they align with your preferences and the specific characteristics of your picture. If you're uncertain, you can consult a professional framer who can offer guidance based on their expertise and experience. They can assess the artwork, consider your preferences, and recommend whether a mat would be beneficial for yours picture.

                <br/><br/>

                <LazyLoad>
                    <video className={"what-is-mat-image-v2"} loop="true" autoPlay="autoplay" controls muted
                           src="https://s3.ca-central-1.amazonaws.com/custommat.landing.v2/0_What-is-a-matboard_1920x1290_v1+(1).mp4"></video>
                </LazyLoad>

                <b>
                    What is a picture mat?
                </b>

                <br/><br/>

                A picture mat, also known as a mat board or matting, is a flat material typically made of paper or cardboard that is placed between the artwork or photograph and the frame. It serves several purposes in the framing process:

                <br/><br/>

                <ol>
                    <li>
                        <b>Aesthetic Enhancement: </b>
                        A mat can enhance the overall appearance of the artwork or photograph by providing a visual separation between the image and the frame. It creates a border around the picture, giving it a clean and finished look.
                    </li>

                    <br/>

                    <li>
                        <b>Focus and Emphasis: </b>
                        A mat helps direct the viewer's attention to the artwork or photograph by providing a neutral background that prevents distractions. It can also create a sense of space and depth, allowing the image to stand out more prominently.
                    </li>

                    <br/>

                    <li>
                        <b>Protection: </b>
                        Mat boards can serve as a protective barrier between the artwork/photo and the glass or acrylic glazing in the frame. This helps prevent direct contact, reducing the risk of damage, sticking, or smudging.
                    </li>

                    <br/>

                    <li>
                        <b>Size Adjustment: </b>
                        Mats are useful when the artwork or photograph is smaller than the frame. They can be cut to fit the image, effectively extending its dimensions and filling the space within the frame.
                    </li>

                    <br/>

                    <li>
                        <b>Preservation: </b>
                        Mat boards can be made from acid-free and archival-quality materials. These materials are specifically designed to be pH-neutral and free from harmful substances that could degrade the artwork or photograph over time. Using archival mats helps ensure the long-term preservation of the image.
                    </li>
                </ol>

                <br/>

                The size of the mat border can be adjusted according to personal preference and the specific requirements of the artwork or photograph.

                <br/><br/>

                Overall, picture mats play a significant role in framing, providing both aesthetic and protective benefits while enhancing the presentation of the image.

                <br/><br/>

                Any other questions: Please check our <a href={"https://www.custommat.ca/faq"}>FAQ page</a>

                <br/><br/>

                <b>About Our Mat Boards / Photo Mats</b>

                <br/><br/>

                <ul>
                    <li>
                        All of our picture mat boards have a pure white core.
                    </li>
                    <li>
                        All of our picture mat boards are 4 ply (1/16”) in thickness
                    </li>
                    <li>
                        All of our picture mat boards are cut to the exact dimensions using our high tech mat board specific cutting machine.
                    </li>
                    <li>
                        Each of the interior cutouts are cut on an exact 45º angle and will showcase the white interior core of the picture mat board.
                    </li>
                    <li>
                        All of our picture mat boards are acid-neutralized. Please note that all our matboards are treated to remove all acidic components to achieve acid-neutralized, buffered surface for your preservation needs.
                    </li>
                    <li>
                        Over 99% of our picture mat boards are sourced and produced in Canada and the USA. The remainder comes from the UK.
                    </li>
                </ul>

                <br/>

                Please feel free to reach out to us at hello@custommat.ca if you have any questions. We are here to help.


                <div className={"visit-custom-mat-button"}>
                    <a href={"/"} className={"carousel-read-more-button"}>
                        Visit Custom Mat
                    </a>
                </div>

                <div className={"carousel-subject-subhead-type2"}>
                    Photo Gallery
                </div>

                <LazyLoad>
                    <img src={photo1} className={"blog-content-photo"}/>
                    <img src={photo2} className={"blog-content-photo"}/>
                    <img src={photo3} className={"blog-content-photo"}/>
                    <img src={photo4} className={"blog-content-photo"}/>
                    <img src={photo5} className={"blog-content-photo"}/>
                    <img src={photo6} className={"blog-content-photo"}/>
                    <img src={photo7} className={"blog-content-photo"}/>
                    <img src={photo8} className={"blog-content-photo"}/>
                </LazyLoad>

                <div className={"visit-custom-mat-button"}>
                    <a href={"/"} className={"carousel-read-more-button"}>
                        Visit Custom Mat
                    </a>
                </div>



            </div>

            <div>
                <div className="elfsight-app-7688ab8e-8d1b-4130-9172-7c7f3e0d45b4"></div>
            </div>



        </div>
    }
}