import React from 'react';
import ArticleFetcher from "../articleFetcher";
import {galleryArticles} from "../articles";
import {Helmet} from "react-helmet";
import analyticsDataToDB from "../../comp-analyticsLog/dbLogging";

export default class BlogArticleSeven extends React.Component {

  constructor(props) {
    super(props);
    var article = galleryArticles.article7;
    this.state = {
      category: article.category,
      hashTags: article.hashTags,
      title: article.title,
      subtitle: article.subtitle,
      photos: article.photos,
      contents: article.contents,
      thumbnail: article.thumbnail,
    }
  }

  componentDidMount(){
    this.logToDbUserRecord();
  }

  logToDbUserRecord() {
    analyticsDataToDB("fetch-article", "article-7-rescuing-thrift-shop-frames");
  }


  render() {
    return <div className={`gallery-container`}>

      <Helmet>
        <title>Rescuing Thrift Shop Picture Frames • Fitting Border for Art Frames • Custom Mat Board </title>
        <meta name="description" content="Learn more about how much difference can be made by getting a fitting border (mat board or pasee-partuout) for picture frames. Order online deliver to anywhere in US & Canada."/>
      </Helmet>

      <ArticleFetcher article={this.state}/>
    </div>
  }
}