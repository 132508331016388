import React from 'react';
import localStorageFunctions from "../comp-localStorage/localStroage";
import connectToDB from "../database/connectDB";
import EncDec from "../app-enc/encryption";
import emailEnc from "../app-enc/emailEnc";
import {Helmet} from "react-helmet";
import ReactPixel from 'react-facebook-pixel';
import leafVector from "../../../resources/logo/leaf-vector.svg";
import FetchItemList from "../comp-cart/fetchItemList";
import {productDB} from "../comp-pricing/productDB";
import landingPageArrowLeft from "../../../resources/logo/landing-page-arrow-left.svg";

export default class ConfirmationApp extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      currencySelected: localStorageFunctions("qHysYpo5yeED836ap5UzjYIkIAKO147dX1CXetXw", null),
      savedToDB: false,
      showShoppingCartItems: false,
      french: false,
    };
    this.fetchPaymentSectionHeader = this.fetchPaymentSectionHeader.bind(this);
    this.dispatchSectionHeader = this.dispatchSectionHeader.bind(this);
    this.fetchLanguage = this.fetchLanguage.bind(this);
  };

  componentDidMount() {
    // this.loadLocalStorage();
    this.getOrderConfirmTimestamp();

    if (this.state.savedToDB === false) {
      this.saveDataToDB().then(this.handleSuccess(), this.handleFailure());
    }

    var valueAmount = parseFloat(localStorageFunctions("CU4B798322B2F5626DD285BC60CCA0CA2785643BCD", null));

    if (typeof window.rdt === "function") {
      window.rdt("track", "Purchase");
    }

    if (typeof window.pintrk === "function") {
      window.pintrk('track', 'checkout', {
        order_id: `${new Date()}`,
        value: valueAmount,
        order_quantity: 1,
        currency: 'CAD'
      });;
    }


    if (typeof window.ttq.track === "function") {
      window.ttq.track("CompletePayment", {
        content_id: '301',
        content_type: "product",
        quantity: 1,
        price: parseFloat(this.props.calculateItemSubtotal()),
        value: parseFloat(this.props.calculateItemSubtotal()),
        currency: 'CAD',
      });
    }

    this.setState({
      french: (window.location.pathname.slice(-2) === "fr" || this.props.language === "fr") ? true : false
    })

    ReactPixel.track('Purchase', {currency: "CAD", value: valueAmount});
  }

  fetchLanguage(fr, en) {
    return this.state.french ? `${fr}` : `${en}`;
  }

  updateLocalState(data) {
    this.setState({
      addressCity: data.MJqZAX6AVWn4sIXbGOUCDL0OUwgni95nOx8Lyn6qdZk,
      postalCode: data.ziDZfAze4WuKoqGIu2xxzor0XLkV8rLXaNSdU78,
      province: data.ziDZfAze4WuKoqGIu2xyRRJlIXMAHwrIjworrSZE,
      name: data.Gn2OgGjCiGWGOKNk5wphSOeKpfDjvbRn7cb6yfofK4,
      streetAddress: data.yofarEil3YNaQAINbRgdiYy6vebJKr5G8fZXPCIxc,
      suiteAddress: data.yofarEil3YNaQAINbRgTGwbfxIIhBDXDjRhwh3Dpc,
      country: data.MJqZAX6AVWn4sIXbGOUCDPGCFU2WpgFGnoK8uagWmYw,
      orderConfirmationTime: data.orderConfirmationTime,
      email: data.eApOtsVDv3CBJxgQ074pEw,
      phoneNumber: data.yofareqwrEewqrgTGwbfxIIhBDewqrwekrjkjlkr,
      shoppingCart: data.shoppingCart,
      discountApplied: data.discountApplied,
      promoCodeUsed: data.promoCodeUsed,
      promoRateApplied: data.promoRateApplied,
      pickUpOrShipping: data.pickUpOrShipping,
      paymentCurrency: data.paymentCurrency,
      shoppingSubtotal: data.shoppingSubtotal,
      shippingCost: data.shippingCost,
      taxes: data.taxes,
      grandTotal: data.grandTotal,
    });
  };

  saveDataToDB() {
    var userEmail, objToUploadToDB, objToUploadToLocalState;
    var updatedTODB = false;
    var promiseObject = new Promise((resolve, reject) => {
      userEmail = localStorageFunctions("eApOtsVDv3CBJxgQ074pEw", null);
      objToUploadToLocalState = {
        // orderedItems: localStorage.getItem("storedOrders"),
        MJqZAX6AVWn4sIXbGOUCDL0OUwgni95nOx8Lyn6qdZk: localStorageFunctions("MJqZAX6AVWn4sIXbGOUCDL0OUwgni95nOx8Lyn6qdZk", null),
        ziDZfAze4WuKoqGIu2xxzor0XLkV8rLXaNSdU78: localStorageFunctions("ziDZfAze4WuKoqGIu2xxzor0XLkV8rLXaNSdU78", null),
        ziDZfAze4WuKoqGIu2xyRRJlIXMAHwrIjworrSZE: localStorageFunctions("ziDZfAze4WuKoqGIu2xyRRJlIXMAHwrIjworrSZE", null),
        Gn2OgGjCiGWGOKNk5wphSOeKpfDjvbRn7cb6yfofK4: localStorageFunctions("Gn2OgGjCiGWGOKNk5wphSOeKpfDjvbRn7cb6yfofK4", null),
        yofarEil3YNaQAINbRgdiYy6vebJKr5G8fZXPCIxc: localStorageFunctions("yofarEil3YNaQAINbRgdiYy6vebJKr5G8fZXPCIxc", null),
        yofarEil3YNaQAINbRgTGwbfxIIhBDXDjRhwh3Dpc: localStorageFunctions("yofarEil3YNaQAINbRgTGwbfxIIhBDXDjRhwh3Dpc", null),
        MJqZAX6AVWn4sIXbGOUCDPGCFU2WpgFGnoK8uagWmYw: localStorageFunctions("MJqZAX6AVWn4sIXbGOUCDPGCFU2WpgFGnoK8uagWmYw", null),
        a1ysQM3xOEe9nGpQg5D5g: localStorage.getItem("a1ysQM3xOEe9nGpQg5D5g"),
        a7DqdNz46FaG4kHddQ11KNQ: localStorage.getItem("a7DqdNz46FaG4kHddQ11KNQ"),
        orderConfirmationTime: EncDec("enc", `${new Date().toString().substring(0,24)}`),
        jDm9LcBpCl2JWkDYHgwJEw: EncDec("enc", "Order in Process"),
        eApOtsVDv3CBJxgQ074pEw: localStorageFunctions("eApOtsVDv3CBJxgQ074pEw", null),
        qHysYpo5yeED836ap5UzjYIkIAKO147dX1CXetXw: localStorage.getItem("qHysYpo5yeED836ap5UzjYIkIAKO147dX1CXetXw"),
        eApOtsVDv3CBJxgQ074pDisc: parseFloat(localStorageFunctions("eApOtsVDv3CBJxgQ074pDisc", null)),
        yofareqwrEewqrgTGwbfxIIhBDewqrwekrjkjlkr: localStorageFunctions("yofareqwrEewqrgTGwbfxIIhBDewqrwekrjkjlkr", null),
        shoppingCart: JSON.parse(localStorageFunctions("CJ92A38CCJXAE8C379C6F79758BQJXB88D14FEJKB", null)),
        userSelectedFrameType: localStorageFunctions("KU872B0FD81727FD1A4452C2811Q1FB071154793D7", null),
        userSelectedFrameSize: localStorageFunctions("JX91B0V6K10168431BCS189C79759EA3DJTB61D944", null),
        selectedFilmSize: localStorageFunctions("CD7BCA387F92B43781990FDQX50BA04082795J83", null),
        discountApplied: parseFloat(localStorageFunctions("eApOtsVDv3CBJxgQ074pDisc", null)),
        promoCodeUsed: localStorageFunctions("promoCodeUsed", null),
        promoRateApplied: parseFloat(localStorageFunctions("JUX7AA083A5BLB26E8BC965A30JX6398E7BA3DE6", null)),
        pickUpOrShipping: localStorageFunctions("F5299BDA449B72EJJ8526ECS93579EB5C4CE14E2J7", null),
        paymentCurrency: localStorageFunctions("XD671979A1B043B8FKJLS41D8ECA38124521C95F416", null),
        shoppingSubtotal: parseFloat(localStorageFunctions("JX8A56C8KC922DAFECKKD1E8019730DF6EE3233CF29", null)),
        shippingCost: parseFloat(localStorageFunctions("A1B5E2CDA16E8BCD313BB213K10ACF2B11CDF2D746", null)),
        taxes: parseFloat(localStorageFunctions("XT1B211887KJ1C7BA4A955318DB3CAA2001C5954064", null)),
        grandTotal: parseFloat(localStorageFunctions("CU4B798322B2F5626DD285BC60CCA0CA2785643BCD", null)),
      };
      this.updateLocalState(objToUploadToLocalState);
      objToUploadToDB = {
        // orderedItems: localStorage.getItem("storedOrders"),
        MJqZAX6AVWn4sIXbGOUCDL0OUwgni95nOx8Lyn6qdZk: localStorage.getItem("MJqZAX6AVWn4sIXbGOUCDL0OUwgni95nOx8Lyn6qdZk"),
        ziDZfAze4WuKoqGIu2xxzor0XLkV8rLXaNSdU78: localStorage.getItem("ziDZfAze4WuKoqGIu2xxzor0XLkV8rLXaNSdU78"),
        ziDZfAze4WuKoqGIu2xyRRJlIXMAHwrIjworrSZE: localStorage.getItem("ziDZfAze4WuKoqGIu2xyRRJlIXMAHwrIjworrSZE"),
        Gn2OgGjCiGWGOKNk5wphSOeKpfDjvbRn7cb6yfofK4: localStorage.getItem("Gn2OgGjCiGWGOKNk5wphSOeKpfDjvbRn7cb6yfofK4"),
        yofarEil3YNaQAINbRgdiYy6vebJKr5G8fZXPCIxc: localStorage.getItem("yofarEil3YNaQAINbRgdiYy6vebJKr5G8fZXPCIxc"),
        yofarEil3YNaQAINbRgTGwbfxIIhBDXDjRhwh3Dpc: localStorage.getItem("yofarEil3YNaQAINbRgTGwbfxIIhBDXDjRhwh3Dpc"),
        MJqZAX6AVWn4sIXbGOUCDPGCFU2WpgFGnoK8uagWmYw: localStorage.getItem("MJqZAX6AVWn4sIXbGOUCDPGCFU2WpgFGnoK8uagWmYw"),
        a1ysQM3xOEe9nGpQg5D5g: localStorage.getItem("a1ysQM3xOEe9nGpQg5D5g"),
        a7DqdNz46FaG4kHddQ11KNQ: localStorage.getItem("a7DqdNz46FaG4kHddQ11KNQ"),
        orderConfirmationTime: EncDec("enc", `${new Date().toString().substring(0,24)}`),
        jDm9LcBpCl2JWkDYHgwJEw: EncDec("enc", "Order in Process"),
        eApOtsVDv3CBJxgQ074pEw: localStorage.getItem("eApOtsVDv3CBJxgQ074pEw"),
        qHysYpo5yeED836ap5UzjYIkIAKO147dX1CXetXw: localStorage.getItem("qHysYpo5yeED836ap5UzjYIkIAKO147dX1CXetXw"),
        eApOtsVDv3CBJxgQ074pDisc: localStorage.getItem("eApOtsVDv3CBJxgQ074pDisc"),
        yofareqwrEewqrgTGwbfxIIhBDewqrwekrjkjlkr: localStorage.getItem("yofareqwrEewqrgTGwbfxIIhBDewqrwekrjkjlkr"),
        CJ92A38CCJXAE8C379C6F79758BQJXB88D14FEJKB: localStorage.getItem("CJ92A38CCJXAE8C379C6F79758BQJXB88D14FEJKB"),
        KU872B0FD81727FD1A4452C2811Q1FB071154793D7: localStorage.getItem("KU872B0FD81727FD1A4452C2811Q1FB071154793D7"),
        JX91B0V6K10168431BCS189C79759EA3DJTB61D944: localStorage.getItem("JX91B0V6K10168431BCS189C79759EA3DJTB61D944"),
        CD7BCA387F92B43781990FDQX50BA04082795J83: localStorage.getItem("CD7BCA387F92B43781990FDQX50BA04082795J83"),
        JKLCJ7D0D0A9E18871A032FA6A30JX9E901CBBDE8: localStorage.getItem("JKLCJ7D0D0A9E18871A032FA6A30JX9E901CBBDE8"),
        JUX7AA083A5BLB26E8BC965A30JX6398E7BA3DE6: localStorage.getItem("JUX7AA083A5BLB26E8BC965A30JX6398E7BA3DE6"),
        F5299BDA449B72EJJ8526ECS93579EB5C4CE14E2J7: localStorage.getItem("F5299BDA449B72EJJ8526ECS93579EB5C4CE14E2J7"),
        XD671979A1B043B8FKJLS41D8ECA38124521C95F416: localStorage.getItem("XD671979A1B043B8FKJLS41D8ECA38124521C95F416"),
        JX8A56C8KC922DAFECKKD1E8019730DF6EE3233CF29: localStorage.getItem("JX8A56C8KC922DAFECKKD1E8019730DF6EE3233CF29"),
        A1B5E2CDA16E8BCD313BB213K10ACF2B11CDF2D746: localStorage.getItem("A1B5E2CDA16E8BCD313BB213K10ACF2B11CDF2D746"),
        XT1B211887KJ1C7BA4A955318DB3CAA2001C5954064: localStorage.getItem("XT1B211887KJ1C7BA4A955318DB3CAA2001C5954064"),
        CU4B798322B2F5626DD285BC60CCA0CA2785643BCD: localStorage.getItem("CU4B798322B2F5626DD285BC60CCA0CA2785643BCD"),
        promoCodeUsed: localStorage.getItem("promoCodeUsed"),
      };
      updatedTODB = true;

      if (objToUploadToDB.a1ysQM3xOEe9nGpQg5D5g !== undefined) {
        userEmail = localStorageFunctions("eApOtsVDv3CBJxgQ074pEw", null);
        var encEmail = emailEnc(userEmail);

        //directory should be paid for the prod. make sure to change this before
        connectToDB("w","paid-prod", encEmail, objToUploadToDB);
        resolve(updatedTODB);
      } else {
        var reason = "Your entry has not been saved to DB.";
        reject(reason);
      }

    });
    return promiseObject
  };


  handleSuccess() {
    // window.location.href="/confirmation";
    // this.setState({savedToDB: true}, () => {localStorage.clear();});

      //submit the datag to gtag
      window.gtag('event', 'conversion', {
          'send_to': 'AW-732793253/RjgUCLrI2qcBEKWTtt0C',
          'value': parseFloat(localStorageFunctions("CU4B798322B2F5626DD285BC60CCA0CA2785643BCD", null)),
          'currency': this.props.currency === "US$" ? "USD" : "CAD",
          'transaction_id': ''
      })


  };

  handleFailure() {
    this.saveDataToDB();
  };

  fetchPaymentSectionHeader(text) {
    return <div className={"payment-section-container-header"}>
      {text}
    </div>
  };

  dispatchSectionHeader(text) {
    return <div className={`shipping-form-container-section-header`}>
      {text}
    </div>
  };

  getOrderConfirmTimestamp() {
    var timestamp = new Date();
    timestamp = timestamp.toString().substring(0,24);
    this.setState({orderConfirmationTime: timestamp});
  };

  calculateShippingCosts(subtotalAmount, shippingItems, country, shippingOrPickup) {
    if (subtotalAmount >= 49 || shippingOrPickup === "pickup") {
      return 0;
    } else {
      var maxShippingCost = 0;
      var countryCode = (country === "CA") ? 0 : 1;
      if (shippingItems) {
        var shippingItemList = Object.keys(shippingItems);
        shippingItemList.map((item, key) => {
          if (shippingItems[item].shipping[countryCode] > maxShippingCost) {
            maxShippingCost = shippingItems[item].shipping[countryCode];
          };
        });
        return maxShippingCost.toFixed(2)
      } else {
        return null;
      };
    };
  };

  taxesCalculator(amount, countryCode, provincialCode) {
    //if country code is US, there will be no taxes
    if (countryCode === "US") {
      return 0.00
    } else {
      if (provincialCode !== null && provincialCode !== undefined ) {
        var taxRate = productDB.taxRates.CA[provincialCode];
        return (amount * taxRate).toFixed(2);
      }
    }
    //if else (canada), the tax rates will depend on provincial code, which comes from pricingDB
  };

  fetchShippingChargesPrompt(shippingCost, shippingOrPickup, shoppingSubtotal) {
    if (shoppingSubtotal >= 49 && shippingOrPickup === "shipping") {
      return this.state.french ? "Livraison  Gratuite" : "Free Shipping";
    } else if (shippingCost === 0 && shippingOrPickup === "pickup") {
      return "Free Pickup";
    } else {
      return `$${shippingCost.toFixed(2)}`;
    }
  };




  render() {

    let discountAppliedRate = this.state.discountApplied ? this.state.discountApplied : 0;

    let subtotal = this.state.shoppingSubtotal * (1 - discountAppliedRate);

    let taxes = this.state.taxes * (1 - discountAppliedRate);

    let grandTotal = subtotal + this.state.shippingCost + taxes;

    grandTotal = this.props.fxConversion(grandTotal);


    return <div className={`payments-container`}>

      <Helmet>
        <title>
          {this.state.french ? "PERSONNALISEZ LE PASSE-PARTOUT • Livraison gratuite au Canada • Livraison gratuite au Québec •  Pas de quantité minimum de commande" :
              "Custom Matboards • Photo Mats • Picture Mats • Matboard Supplies • Free Shipping in Canada • No Minimum Order Quantity "}
        </title>
        <meta name="description" content={
          this.state.french ? "Personnalisez vos passe-partout en ligne. Nous vous les expédions sous 48h. Pas de quantité minimum de commande. Des dizaines d'options de couleurs. Livraison gratuite au Canada et aux États-Unis à partir de 49 $." :
              "Customize your matboards online. We ship them to you within 48 hours. No minimum order quantity. Dozens of colour options. Free shipping to Canada and the USA over $49."
        } />
      </Helmet>

      <div className={"checkout-progress-bar"}>

        <div className={"checkout-progress-option"}>
          {this.state.french ? "Adresse" : "Address"}
        </div>

        <div className={"checkout-progress-option"}>
          {this.state.french ? "Paiement + Révision" : "Payment + Review"}
        </div>

        <div className={"checkout-progress-option checkout-progress-option-selected"}>
          {this.state.french ? "Confirmation" : "Confirmation"}
        </div>

      </div>

      <div className={"order-confirmation-header"} style={{borderBottom: "none"}}>

        <div className={"delivery-info-header-emphasis"}>
          {this.state.french ? "Commande Passée" : "Order Placed!"}

        </div>

        <div className={"delivery-info-header-regular"}>
          {this.state.french ? "Commande# " : "Order# "}
           {localStorage.getItem("orderConfirmNumber")}
        </div>

        <div className={this.state.country === "CA" ? "delivery-info-header-regular-2" : "no-display"}>
          {this.state.french ? "MERCI DE SOUTENIR LES ENTREPRISES " : "Thank you for supporting "}

          <img className={"navbar-bottom-leaf-vector"} src={leafVector} alt={this.state.french ? "MERCI DE SOUTENIR LES ENTREPRISES CANADIENNES!" : "Thank you for supporting Canadian business!"}/>
          {this.state.french ? " CANADIENNES!" : " Canadian business!"}

        </div>

        <div className={"delivery-info-wrapper"}>
          <div>
            {this.state.french ? "Vous devriez recevoir un courriel de confirmation de commande de " : "You should receive an order confirmation email from "}

          </div>
          <div><b>
            <span className={"mobile-no-display"}>orderconfirmation@custommat.ca</span>
            <span className={"mobile-display-only"}>orderconfirmation @custommat.ca</span>
          </b></div>
          <div>
            {this.state.french ? "Veuillez cocher " : "Please check "}

            <i>
              {this.state.french ? "la case Spam ou promotion  " : "Spam or Promotion "}
            </i>
            {this.state.french ? "si vous ne le voyez pas." : "box if you do not see it."}

          </div>
        </div>

      </div>

      <div className={"display"} style={{marginTop: "32px"}}>

        <div className={"review-section-container-header"}>
          <div className={"summary-review-button-left"}>
            {this.state.french ? "Révision" : "Review"}
            <img
                className={this.state.showShoppingCartItems ? "support-arrow-arrow-v2-inverse" : "support-arrow-arrow-v2"}
                src={landingPageArrowLeft}
                onClick={() => this.setState({showShoppingCartItems: !this.state.showShoppingCartItems})}
                alt={this.state.french ? "Cliquez pour afficher les détails du panier." : "Click to view shopping cart details."}
            />
          </div>

          <div className={"summary-review-button-right"}>
            {this.props.getTotalItemCount()} {this.props.getTotalItemCount() > 1 ?
              this.state.french ? "Articles" : "Items" : this.state.french ? "Article" : "Item"}
          </div>

        </div>

        <div className={this.state.showShoppingCartItems ? "display" : "no-display"}>
          <FetchItemList
              shoppingCartItems={this.props.shoppingCartItems}
              updateItemList={this.props.updateItemList}
              getDiscountMultiplier={this.props.getDiscountMultiplier}
              fetchLanguage={this.fetchLanguage}
              fxConversion={this.props.fxConversion}
              currency={this.props.currency}
              location={this.props.location}
              french={this.state.french}
          />
        </div>


      </div>
      
      {/*<div className={"subtotal-calculator-container"}>*/}
      
        <div className={"shipping-cost-container"}>
          <div className={"shipping-summary-header"}>
            {this.state.french ? "Sous-total" : "Subtotal"}
          </div>
          <div className={this.state.discountApplied ? "shipping-summary-amount" : "no-display"} style={{textDecoration: "line-through"}}>
            ${parseFloat(this.state.shoppingSubtotal).toFixed(2)}
          </div>
          <div className={this.state.discountApplied ? "shipping-summary-header" : "no-display"} style={{color: "#F59276"}}>
            {this.state.discountApplied*100}% {this.state.french ? "de réduction" : "off"} :)
          </div>
          <div className={"shipping-summary-amount"} style={{float: "right"}}>
            ${this.props.fxConversion(subtotal).toFixed(2)}
          </div>
        </div>

      
        {/*<div className={ (this.props.focusedModal === "summary" || this.props.focusedModal === "payment") ? "shipping-and-taxes-container" : "no-display"}>*/}

          <div className={"shipping-cost-container"}>
            <div className={"shipping-summary-header"}>
              {this.state.french ? "Frais d’expédition" : "Shipping"}
            </div>
            <div className={"shipping-summary-amount"}>{this.fetchShippingChargesPrompt(this.props.fxConversion(this.state.shippingCost), this.state.pickUpOrShipping, this.state.shoppingSubtotal)}</div>
          </div>

          <div className={"shipping-cost-container shipping-summary-add-bottom-line"}>
            <div className={"shipping-summary-header"}>
              Taxes
            </div>
            <div className={"shipping-summary-amount"}>${taxes.toFixed(2)}</div>
          </div>

          <div className={"shipping-cost-container"}>
            <div className={"shipping-summary-header-total"}>
              Total
            </div>
            <div className={"shipping-summary-amount-total"}>{this.props.currency}{grandTotal.toFixed(2)}</div>
          </div>

        {/*</div>*/}
      
      {/*</div>*/}

      <div className={"delivery-info-wrapper"}>
        {this.state.french ? "Nos commandes sont produites et expédiées dans un délai de 2 jours ouvrables. Le calendrier de livraison estimé région par région peut être vu ici. Nous utilisons Postes Canada et Purolator / UPS." :
            "Shipped from our workshops within the next 2 business days with Canada Post, USPS, or UPS/Purolator. Delivery typically takes about 2-6 days."}
      </div>

      <div className={"delivery-info-wrapper-subheading"}>
        {this.state.french ? "Si le passe-partout ne fonctionne pas ou est endommagé, faites-nous le savoir et nous remplacerons votre commande gratuitement." : "If the mat does not work or is damaged, let us know! We will replace your order free of charge."}
      </div>

      <a href={this.state.french ? "/inspiration-fr" : "/inspiration"}>
        <div className={"future-inspirations-button"}>
          {this.state.french ? "Trouvez de futures inspirations" : "Find Future Inspirations"}
        </div>
      </a>


    </div>
  }
}