import React from "react";

export default class RefundPolicy extends React.Component {
  render() {
    return <div className={`general-static-page-container`}>
      <div className={`general-static-page-container-heading`}>
        {this.props.french ? "RETOURS" : "Returns"}
      </div>

      <div className={`general-static-page-container-text`}>
        {this.props.french ? "Chez Custom Mat, nous sommes très fiers de la qualité de notre marchandise et de l’excellent service à la clientèle. Veuillez communiquer avec notre service à la clientèle si vous avez des préoccupations concernant la marchandise défectueuse ou endommagée dès que possible. N’oubliez pas qu’une preuve d’achat est requise pour tous les retours. Veuillez consulter notre politique de retour complète avant de commencer le processus de retour." : "At Custom Mat, we take great pride in the quality of our merchandise and excellent customer care. Please contact our Customer Care Team with any concerns about defective or damaged merchandise as soon as possible. Please keep in mind that a proof of purchase is required for all returns. Please review our full return policy before starting the return process."}
      </div>

      <div className={`general-static-page-container-topic-heading`}>
        {this.props.french ? "NOTRE POLITIQUE DE BONHEUR GARANTI À 100 %" : "OUR 100% HAPPINESS GUARANTEED POLICY"}
      </div>

      <div className={`general-static-page-container-text`}>
        {this.props.french ? "Si vous n’êtes pas entièrement satisfait de la couleur, de la taille ou de la conception de votre passe-partout, ou si nous avons mal coupé votre passe-partout, veuillez envoyer un courriel à notre équipe du service à la clientèle à l’adresse hello@custommat.ca pour nous faire savoir comment nous pouvons travailler avec vous pour corriger la situation. Nous traitons gratuitement toutes les demandes raisonnables." : "If you’re not completely satisfied with the colour, size, or design of your matboard, or we’ve cut your matboard incorrectly, please email our Customer Care Team at hello@custommat.ca to let us know how we can work with you to make it right. We handle all reasonable requests free of charge."}

      </div>

      <div className={`general-static-page-container-topic-heading`}>
        {this.props.french ? "PREUVE D’ACHAT" : "PROOF OF PURCHASE"}

      </div>

      <div className={`general-static-page-container-text`}>
        {this.props.french ? "Une preuve d’achat valide est requise pour émettre un remboursement pour les articles éligibles. Nous accepterons tous les éléments suivants comme preuve d’achat." : "A valid proof of purchase is required to issue refunds for eligible items. We will accept all of the following as proof of purchase."}

        <div className={`general-static-page-container-text`}>
          <ul>
            <li>
              {this.props.french ? "Reçu électronique" : "Electronic receipt"}
            </li>
            <li>
              {this.props.french ? "Numéro de commande" : "Order number"}
            </li>
            <li>
              {this.props.french ? "Votre nom et votre adresse de livraison" : "Your name and shipping address"}
            </li>
          </ul>
        </div>
      </div>

      <div className={`general-static-page-container-topic-heading`}>
        {this.props.french ? "MODIFICATIONS OU ANNULATIONS DE COMMANDE" : "Order Changes or Cancellations"}
      </div>

      <div className={`general-static-page-container-text`}>
        {this.props.french ? "Modifications de commande : nous produisons et expédions votre commande dans les 48 heures. Si vous souhaitez modifier votre commande, veuillez nous en informer avant l’expédition. Si ce n’est pas possible, contactez notre service clientèle et nous ferons de notre mieux pour trouver une solution pour vous." : "Order Changes — We produce and ship your order within 48 hours. If you would like to change your order, please let us know before we ship. If this isn’t possible, contact our Customer Care Team and we will do our best to find a solution for you."}
      </div>

      <div className={`general-static-page-container-text`}>
        {this.props.french ? "Annulations de commande : si vous souhaitez annuler votre commande, veuillez envoyer un courriel à notre service clientèle au hello@custommat.ca dès que possible. Nous vous rembourserons votre carte de crédit dans un délai d’un jour ouvrable après réception de votre courriel d’annulation. Vous recevrez également une notification de remboursement par courriel pour confirmer que nous avons émis le crédit. Si vous n’avez pas de nouvelles de notre part dans quelques heures, veuillez contacter notre équipe du service à la clientèle au 1 226 828-1705. Si ce n’est pas possible, contactez notre service clientèle et nous ferons de notre mieux pour trouver une solution pour vous." : "Order Cancellations — If you would like to cancel your order, please email our Customer Care Team at hello@custommat.ca as soon as possible. We will issue a refund to your credit card within one business day of receiving your cancellation email. You will also receive an email refund notification to confirm we’ve issued the credit. If you don’t hear from us within a few hours, please contact our Customer Care Team at +1-226-828-1705.. If this isn’t possible, contact our Customer Care Team and we will do our best to find a solution for you."}
      </div>

      <div className={`general-static-page-container-topic-heading`}>
        {this.props.french ? "PRODUITS ENDOMMAGÉS" : "Damaged Products"}
      </div>

      <div className={`general-static-page-container-text`}>
        {this.props.french ? "Tout d’abord, nous vous prions de nous excuser pour tout inconvénient. Nous emballons nos produits avec soin pour éviter les dommages d’expédition, mais des dommages peuvent survenir dans de rares cas. Un petit pli au coin peut être caché par votre cadre. Si cela ne vous satisfait pas, veuillez contacter notre service à la clientèle dès que possible." : "Firstly, we apologize for any inconvenience. We package our products with care to prevent shipping damage, but damages may occur in rare instances. A small bend at the corner may be hidden by your frame, if this does not satisfy you, please contact our Customer Care Team as soon as possible."}
        <br/><br/>
        {this.props.french ? "Si le tableau est gravement endommagé et inutilisable, veuillez envoyer un courriel à notre service à la clientèle à l’adresse hello@custommat.ca. Nous couperons un nouveau passe-partout et expédierons le paquet à votre adresse." : "If the matboard is severely damaged and unusable, please email our Customer Care Team at hello@custommat.ca. We will cut a new matboard and express ship the package to your address."}
      </div>


    </div>
  }
}

