import React from 'react';
import samplePhoto1Reduced from "../../../resources/images/gallery/0-generalGallery/reduced/1-custom-cut-mat-board-for-ikea-SILVERHOJDEN-frame-(36in-x-27in-91cm-x-61cm).png";
import samplePhoto2Reduced from "../../../resources/images/gallery/0-generalGallery/reduced/7-custom-cut-passepartout-board-for-ikea -Ribba-frame-(36in-x-27in-91cm-x61cm).png";
import samplePhoto1Webp from "../../../resources/images/gallery/0-generalGallery/webp/1-custom-cut-mat-board-for-ikea-SILVERHOJDEN-frame-(36in-x-27in-91cm-x-6cm).webp";
import samplePhoto2Webp from "../../../resources/images/gallery/0-generalGallery/webp/7-custom-cut-passepartout-board-for-ikea -Ribba-frame-(36in-x-27in-91cm-x61cm).webp";
import LazyLoad from 'react-lazyload';

export default class RegionFetcher extends React.Component {

  fetchDeliveryTimeline(cityName) {
    if (cityName === "Vancouver") {
      return <div>
        {cityName} is where we are based. We can offer same day service. You can also pick up from our facility located in Vancouver. For mail delivery, we expect your package to arrive within 1-2 business days.
      </div>
    } else if (cityName === "Calgary" || cityName === "Edmonton" || cityName === "Seattle" || cityName === "Portland") {
      return <div>
        {cityName} is a key market that we focus. Typically your custom mat boards and frames are expected to arrive in {cityName} within 3 business days. In addition to {cityName}, we ship to anywhere in Canada and the United States.
      </div>
    } else {
      return <div>
        {cityName} is a key market that we offer fast delivery. Typically your custom mat boards and frames are expected to arrive in {cityName} within couple of days. In addition to {cityName}, we ship to anywhere in Canada and the United States.
      </div>
    }
  }

  fetchContent(cityName, photoWebp, photoReduced) {
    return <div className="blog-app-container">

      <div className={"carousel-subject-head"}>
        Custom Mat now comes to {cityName}
      </div>

      <LazyLoad>
        <picture>
          <source type="image/webp" srcSet={photoWebp}/>
          <source type="image/png" srcSet={photoReduced}/>
          <img className="blog-carousel-photo" src={photoReduced} alt={`This is an beautiful view of ${cityName}`}/>
        </picture>
      </LazyLoad>

      <div className={"blog-article-content"}>
        Hello {cityName}! We are thrilled to announce that we now serve {cityName}.
        <br/><br/>
        Here at <a href={"/"} rel="noopener noreferrer" target={"_blank"}>Custom Mat</a>, we cut custom mat boards based on your dimensions. They will be delivered to your door step anywhere in Canada and the United States.
        <br/><br/>
        <LazyLoad>
          <picture>
            <source type="image/webp" srcSet={samplePhoto1Webp}/>
            <source type="image/png" srcSet={samplePhoto1Reduced}/>
            <img className="blog-content-photo" src={samplePhoto1Reduced} alt={`This is an beautiful view of ${cityName}`}/>
          </picture>
          <picture>
            <source type="image/webp" srcSet={samplePhoto2Webp}/>
            <source type="image/png" srcSet={samplePhoto2Reduced}/>
            <img className="blog-content-photo" src={samplePhoto2Reduced} alt={`This is an beautiful view of ${cityName}`}/>
          </picture>
        </LazyLoad>
        <br/><br/>
        We believe beautiful picture frames should be budget friendly. We are introducing a new alternative way of "custom framing." Instead of spending hundreds, you can get a similar visual effect by combining any regular picture frame with custom mat boards.
        <br/><br/>
        <a href={"/custom-picture-mats-make-difference"} rel="noopener noreferrer" target="_blank">Check here</a>
        <span> if </span>you want to learn more about how to frame your artwork seamlessly for fractional cost of traditional framing.
        <br/><br/>
        {this.fetchDeliveryTimeline(cityName)}
        <br/><br/>
        We are very proud to serve our new customers in {cityName}. You can place new orders at <a href={"/"}>Custom Mat</a>. Please get in touch with us via hello@CustomMat.ca, if you have any questions.
        <br/><br/>
        We look forward to serving you.
      </div>
    </div>
  }

  render() {
    var cityName = this.props.article.cityName;
    var photoWebp = this.props.article.photoWebp;
    var photoReduced = this.props.article.photoReduced;

    return <div className={"blog-app-container"}>

      {this.fetchContent(cityName, photoWebp, photoReduced)}

    </div>
  }
}