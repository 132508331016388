import React from "react";
import connectToDB from "../../database/connectDB";
import Rating from '@material-ui/lab/Rating';

export default class CustomerReviewSection extends React.Component {

  constructor() {
    super();
    this.state = {
      allReviews: [],
      showReviewBegin: 1,
      reviewClickedNumber: 1,
      averageRating: 5,
    };
  }


  componentDidMount() {
    connectToDB("readReviews","", "", "", (data) => {this.setState({allReviews: data})});
  }

  fetchSectionDivider() {
    var numberOfDots = Object.keys(this.state.allReviews).length/ 3;
    var dotNumberList = [];
    for (let i = 1; i < numberOfDots; i++) {
      dotNumberList.push(i);
    }

    // return dotNumberList.map((reviewPageNumber, key) => {
    //   return <div className={`customer-review-section-divider`}>
    //     {reviewPageNumber}
    //   </div>
    // });

    return <div className={`customer-review-section-divider`}>

      <div className={`customer-review-section-divider`} onClick={() => this.setState({reviewClickedNumber: this.state.showReviewBegin})}>
        <div className={this.state.reviewClickedNumber === this.state.showReviewBegin ? "customer-review-dot-selected" : "customer-review-dot-not-selected"}>
          <i className="fa fa-circle" aria-hidden="true"></i>
        </div>
        <div className={"customer-review-number"}>{this.state.showReviewBegin}</div>
      </div>

      <div className={`customer-review-section-divider`} onClick={() => this.setState({reviewClickedNumber: this.state.showReviewBegin + 1})}>
        <div className={this.state.reviewClickedNumber === this.state.showReviewBegin + 1 ? "customer-review-dot-selected" : "customer-review-dot-not-selected"}>
          <i className="fa fa-circle" aria-hidden="true"></i>
        </div>
        <div className={"customer-review-number"}>{this.state.showReviewBegin + 1}</div>
      </div>

      <div className={`customer-review-section-divider`} onClick={() => this.setState({reviewClickedNumber: this.state.showReviewBegin + 2})}>
        <div className={this.state.reviewClickedNumber === this.state.showReviewBegin + 2 ? "customer-review-dot-selected" : "customer-review-dot-not-selected"}>
          <i className="fa fa-circle" aria-hidden="true"></i>
        </div>
        <div className={"customer-review-number"}>{this.state.showReviewBegin + 2}</div>
      </div>

      <div className={`customer-review-section-divider`} onClick={() => this.setState({showReviewBegin: this.state.showReviewBegin + 3, reviewClickedNumber: this.state.showReviewBegin + 3})}>
        <div className={"customer-review-dot-not-selected"}>
          <i className="fa fa-arrow-right" aria-hidden="true"></i>
        </div>
        <div className={"customer-review-number"}>Next</div>
      </div>

    </div>

    // return <div className={this.state.showReviewId === 3 ? "customer-review-selected" : "customer-review-not-selected"}
    //             id={`customer-review-section-divider-0`} onClick={() => this.setState({showReviewId: 3})}></div>
  };

  calcAverageRating() {
    var totalStarNumbers = 0;
    var reviewObjectKeys = Object.keys(this.state.allReviews);
    return reviewObjectKeys.reverse().map((item, key) => {
      var dictKey = Object.keys(this.state.allReviews[item]);
      var dictSubKey = dictKey[0];
      var reviewEachItem = this.state.allReviews[item][dictSubKey];
      totalStarNumbers = parseFloat(totalStarNumbers) + parseFloat(reviewEachItem.stars);
      return totalStarNumbers / Object.keys(this.state.allReviews).length;
    })

  }


  fetchCustomerReview() {

    var reviewObjectKeys = Object.keys(this.state.allReviews);

    return reviewObjectKeys.reverse().map((item, key) => {

      var dictKey = Object.keys(this.state.allReviews[item]);
      var dictSubKey = dictKey[0];


      var reviewEachItem = this.state.allReviews[item][dictSubKey];

      var approved = reviewEachItem.approved;
      var firstName = reviewEachItem.firstName;
      var review = reviewEachItem.review;
      var stars = reviewEachItem.stars;
      var reviewDate = reviewEachItem.reviewDate;

      var currentTime = new Date()
      var todayMonth = currentTime.getMonth() + 1
      var todayDay = currentTime.getDate()
      var todayYear = currentTime.getFullYear()

      var reviewHeadline = review.split(/([!.])/g);

      var oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
      var firstDate = new Date(reviewDate.year, reviewDate.month, reviewDate.date);
      var today = new Date(todayYear, todayMonth, todayDay);
      var diffDays = Math.round(Math.abs((firstDate - today) / oneDay));
      var showDayDifference;

      if (diffDays < 8 ) {
        showDayDifference = "This week"
      } else if (diffDays < 30) {
        showDayDifference = "This month"
      } else if (diffDays < 60) {
        showDayDifference =  "Last month"
      } else if (diffDays < 90) {
        showDayDifference =  "2 months ago"
      } else if (diffDays < 120) {
        showDayDifference =  "3 months ago"
      } else if ( diffDays < 150) {
        showDayDifference =  "4 months ago"
      } else if ( diffDays < 180) {
        showDayDifference =  "5 months ago"
      } else if ( diffDays < 210) {
        showDayDifference =  "6 months ago"
      } else if ( diffDays < 240) {
        showDayDifference =  "7 months ago"
      } else if ( diffDays < 270) {
        showDayDifference =  "8 months ago"
      } else if ( diffDays < 300) {
        showDayDifference =  "9 months ago"
      } else if ( diffDays < 330) {
        showDayDifference =  "10 months ago"
      } else if ( diffDays < 360) {
        showDayDifference =  "11 months ago"
      } else if ( diffDays >= 360) {
        showDayDifference =  "Over a year ago"
      }

      return <div
          // className={`customer-review-section-row ${(key < this.state.showReviewId && key >= this.state.showReviewId - 3 ) ? "display" : "no-display"}`}
          style={ (key + 1) % 3 === 0 ? {"borderBottom": "none"} : {}}
          className={(key + 1) <= (this.state.reviewClickedNumber * 3) && (key + 1) >= (this.state.reviewClickedNumber * 3 - 2) && approved === true ? "customer-review-section-row" : "no-display" }
          id={`customer-review-id-${key+1}`}
      >
        <div className={`customer-review-section-row-left`}>
          <div className={`customer-review-section-customer-name`}>
            {firstName}
          </div>
          <div className={`customer-review-section-customer-location`}>
            <Rating name="read-only" value={stars} readOnly />
          </div>
          <div className={reviewDate.year <= 2020 && reviewDate.month <= 7 && reviewDate.date <= 18 ? `customer-review-section-customer-location` : "no-display"} >
            <a href={"https://www.google.com/search?hl=en-CA&gl=ca&q=Custom+Mat+Boards+%26+Frames+(www.CustomMat.ca),+1147+Grant+St,+Vancouver,+BC+V6A+2J7&ludocid=3617233075037277389&lsig=AB86z5WOtXp1smdBMNQnhz-22b41"}>Posted on Google <i className="fab fa-google"></i></a>
          </div>
          <div className={`customer-review-section-customer-location`}>
            {showDayDifference}
          </div>
        </div>
        <div className={`customer-review-section-row-right`}>
          <div className={`customer-review-section-customer-review-header`}>
            {reviewHeadline[0] !== "" ? reviewHeadline[0] + "!" : ""}
          </div>
          <div className={`customer-review-section-customer-review-content`}>
            {review !== "" ? review : "Customer gave us 5 star rating. No detailed review was provided."}
          </div>
        </div>
      </div>

      if (approved === "true") {


      } else {
        // we don't show unapproved reviews
      }

    })

  };

  fetchOverallRating() {
    return <div className={"overall-review-stars"}>
      <Rating name="read-only" value={parseFloat(this.calcAverageRating().slice(-1)[0])} readOnly/>
      <div>{parseFloat(this.calcAverageRating().slice(-1)[0]).toFixed(1) - 0.1 } / 5.0 Stars | Based on {Object.keys(this.state.allReviews).length} reviews
      </div>
    </div>
  }

  render() {
    return <div className={`customer-review-container`}>

      <div className={`product-listing-header`}>
        {window.location.pathname === "fr" ? "Témoignages" : "REVIEWS"}
      </div>

      {/*{this.fetchOverallRating()}*/}
      {/*{this.fetchSectionDivider()}*/}
      {/*{this.fetchCustomerReview()}*/}

      <script src="https://static.elfsight.com/platform/platform.js" data-use-service-core defer></script>
      <div className="elfsight-app-041baf8e-080e-4f01-b6dc-8bf6f6499fd4" data-elfsight-app-lazy></div>

      {/*<a href="/review">*/}
      {/*  <div className="product-listing-action-button">Post Review</div>*/}
      {/*</a>*/}
    </div>
  }
}