import React from "react";
import FetchItemList from "./fetchItemList";
import ReactPixel from "react-facebook-pixel";

export default class CartSlider extends React.Component {

    constructor() {
        super();
        this.state = {
            authEmail: null,
            dataLoaded: false,
            subtotal: 0,
            french: false,
        };
        this.fetchLanguage = this.fetchLanguage.bind(this);
    };

    componentDidMount() {
        this.setState({
            french: (window.location.pathname.slice(-2) === "fr" || this.props.language === "fr") ? true : false
        })
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.calculateItemSubtotal() > 0) {
            if (typeof window.pintrk === "function") {

                window.pintrk('track', 'AddToCart', {
                    value: parseFloat(nextProps.calculateItemSubtotal()),
                    order_quantity: nextProps.totalItemCounter(nextProps.shoppingCartItems),
                    currency: 'CAD'
                });

                ReactPixel.track('AddToCart', {currency: "CAD", value: parseFloat(nextProps.calculateItemSubtotal())});
            }
        }
    }

    fetchLanguage(fr, en) {
        return this.props.french ? `${fr}` : `${en}`;
    }


    fetchDiscountTier() {
        if (this.props.getDiscountMultiplier() === 1) {
            return <div className={"discount-tier-info"}>
                {this.props.french ? "Ajouter 1 item supplémentaire. Obtenez 20 % de réduction." : "Add 1 more item. Get 20% off."}

            </div>
        } else if (this.props.getDiscountMultiplier() === 0.8) {
            return <div className={"discount-tier-info"}>
                {this.props.french ? "Achetez 4 articles. Obtenez 30 % de réduction." : "Get 4 items. Get 30% off."}

            </div>
        } else if (this.props.getDiscountMultiplier() === 0.7) {
            return <div className={"discount-tier-info"}>
                {this.props.french ? "Achetez 10 articles. Obtenez 40% de réduction." : "Get 10 items. Get 40% off."}

            </div>
        } else if (this.props.getDiscountMultiplier() === 0.6) {
            return <div className={"discount-tier-info"}>
                {this.props.french ? "Vous obtenez 40 % de réduction." : "You are getting 40% off."}

            </div>
        }
    }

    fetchFreeShippingInfo(totalAmount, totalItemCount) {
        if (parseFloat(totalAmount) >= 49.99 && parseFloat(totalAmount) < 79.99) {
            return <div>
                {this.props.french ? "Profitez de la livraison gratuite!" : "Enjoy free shipping."}
                <br/>
                {this.fetchDiscountTier()}
            </div>
        } else if (parseFloat(totalAmount) >= 79.99) {
            return <div>
                {this.props.french ? "Livraison accélérée gratuite!" : "Free expedited shipping!"}
                <br/>
                {this.fetchDiscountTier()}
            </div>
        } else {
            return <div>
                ${(49.99 - parseFloat(totalAmount)).toFixed(2) === "NaN" ? this.props.fxConversion(49).toFixed(0) : (this.props.fxConversion(49.99 - parseFloat(totalAmount))).toFixed(2)}
                <span> {this.props.french ? "JUSQU’À LA LIVRAISON GRATUITE" : "till free shipping."}</span>
                <br/>
                {this.fetchDiscountTier()}
            </div>
        }
    }

    fetchDiscountDesc() {
        if (this.props.getDiscountMultiplier() === 1) {
            return <div className={"discount-info-desc"}>

            </div>
        } else if (this.props.getDiscountMultiplier() === 0.8) {
            return <div className={"discount-info-desc"}>
                {this.fetchLanguage("Remise de 20 % sur la quantité appliquée.","20% Quantity Discount Applied")}
            </div>
        } else if (this.props.getDiscountMultiplier() === 0.7) {
            return <div className={"discount-info-desc"}>
                {this.fetchLanguage("Remise de 30 % sur la quantité appliquée.","30% Quantity Discount Applied")}
            </div>
        } else if (this.props.getDiscountMultiplier() === 0.6) {
            return <div className={"discount-info-desc"}>
                {this.fetchLanguage("Remise de 40 % sur la quantité appliquée.","40% Quantity Discount Applied")}

            </div>
        }
    }

    displayItemCount(number) {
         if (number === 0) {
             return <div className={"cart-item-count-display"}>
                 <div>{number} item</div>
                 <div className={"cart-item-count-display-empty-cart"}>
                     {this.props.french ? "49 $ JUSQU’À LA LIVRAISON GRATUITE" : "$49 TILL FREE SHIPPING"}
                 </div>
             </div>
         } else if (number === 1) {
             return <div className={"cart-item-count-display"}>
                 {number} {this.props.french ? "article" : "item"}
             </div>
        } else {
             return <div className={"cart-item-count-display"}>
                 {number} {this.props.french ? "articles" : "items"}
             </div>
         }

    }

    render() {


        return <div className={this.props.openShoppingCartModal ? `cart-slider-container` : "no-display"}>

            <div className={`cart-slider-content`}>
                <div className={`cart-slider-top-container`} >
                    <div className={`cart-slider-free-shipping-banner`}>
                        {this.displayItemCount(this.props.totalItemCounter(this.props.shoppingCartItems))}
                        {this.fetchDiscountTier()}
                    </div>
                </div>

                {/*<div className={"cart-slider-top-container-divider"}>*/}
                {/*    <div className={"cart-slider-top-container-divider-inner-gauge"}></div>*/}
                {/*</div>*/}

                {/*<div className={"customer-satisfaction-policy-banner"}>*/}
                {/*    Don't worry. If it doesn't work, let us know. We will replace your order free of charge - <a href={"/returns"}>Learn More</a>*/}
                {/*</div>*/}

                <div>

                    <a href={this.props.french ? "/customize-matboards-fr" : "/customize-matboards"}>
                        <div className={"cart-start-new-design-button"}>
                            {this.props.french ? "Continuer les achats" : "Start new design"}
                        </div>
                    </a>



                </div>

                <div className={this.props.totalItemCounter(this.props.shoppingCartItems) === 0 ? "no-display" : "cart-subtotal-section"}>
                    <a href={this.props.french ? "/payments-fr" : "/payments"}>
                        <div className={"shopping-cart-checkout-button"}>
                            {this.props.french ? "Caisse" : "Checkout"}
                        </div>
                    </a>

                    <div className={"shopping-cart-subtotal-container"}>
                        {this.fetchLanguage("Sous-total", "Subtotal")}
                        <span> </span>
                        <span className={(this.props.getDiscountMultiplier() === 1 || this.props.getDiscountMultiplier() === undefined) ? "no-display" : "cart-subtotal-strikethrough"}>
                            {this.props.currency}
                            {this.props.calculateItemSubtotal() ? (this.props.fxConversion(this.props.calculateItemSubtotal() / this.props.getDiscountMultiplier())).toFixed(2) : 0.00}
                        </span>
                        <span> </span>
                        <span className={"cart-subtotal-highlight"}>
                            {this.props.currency}
                            {this.props.calculateItemSubtotal() ? (this.props.fxConversion(this.props.calculateItemSubtotal())).toFixed(2): 0}</span>
                        {this.fetchDiscountDesc()}

                        {/*//Black Friday*/}
                        <div className={this.props.onGoingPromotion ? "promotion-messages" : "no-display"}>
                            {this.props.french ? `Réduction extra ${this.props.promotionRate} à la caisse` : `Additional ${this.props.promotionRate} off at checkout`}
                        </div>
                    </div>





                </div>


                <div className={"cart-divider-thick"}>

                </div>

                <div className={this.props.totalItemCounter(this.props.shoppingCartItems) === 0 ? "show-empty-cart" : "no-display"}>
                    {this.props.french ? "Votre panier est vide" : "Your Cart is Empty"}
                </div>

                <div className={this.props.totalItemCounter(this.props.shoppingCartItems) === 0 ? "cart-slider-start-designing-button" : "no-display"}>
                    <a href={this.props.french ? "/customize-matboards-fr" : "/customize-matboards"}>
                        <div className={"navbar-start-designing-button-v2"}>
                            {this.fetchLanguage("Je crée", "Start Designing")}
                        </div>
                    </a>
                </div>


                <div className={"cart-slider-item-container"}>
                    <FetchItemList
                        shoppingCartItems={this.props.shoppingCartItems}
                        updateItemList={this.props.updateItemList}
                        getDiscountMultiplier={this.props.getDiscountMultiplier}
                        fetchLanguage={this.fetchLanguage}
                        fxConversion={this.props.fxConversion}
                        location={this.props.location}
                        french={this.props.french}
                    />
                </div>

                <div className={this.props.totalItemCounter(this.props.shoppingCartItems) === 0 ? "no-display" : "cart-slider-shopped-item-summary"}>

                    <div className={"shopping-cart-subtotal-container-large"}>
                        {this.fetchLanguage("Sous-total", "Subtotal")}
                        <span> </span>
                        <span className={(this.props.getDiscountMultiplier() === 1 || this.props.getDiscountMultiplier() === undefined) ? "no-display" : "cart-subtotal-strikethrough-large"}>
                            {this.props.currency}
                            {(this.props.fxConversion(this.props.calculateItemSubtotal() / this.props.getDiscountMultiplier())).toFixed(2)}</span>
                        <span> </span>
                        <span className={"cart-subtotal-highlight-large"}>
                            <b>
                                {this.props.currency}
                                {this.props.calculateItemSubtotal() ? (this.props.fxConversion(this.props.calculateItemSubtotal())).toFixed(2) : 0.00}
                            </b>
                        </span>
                        {this.fetchDiscountDesc()}

                        {/*//Black Friday*/}
                        <div className={this.props.onGoingPromotion ? "promotion-messages" : "no-display"}>
                            {this.props.french ? `Réduction extra ${this.props.promotionRate} à la caisse` : `Additional ${this.props.promotionRate} off at checkout`}
                        </div>
                    </div>

                    <a href={this.props.french ? "/payments-fr" : "/payments"}>
                        <div className={"shopping-cart-checkout-button-thick"}>
                            {this.props.french ? "Caisse" : "Checkout"}
                        </div>
                    </a>

                </div>

                <div className={this.props.totalItemCounter(this.props.shoppingCartItems) === 0 ? "no-display" : "cart-slider-ending"}>
                    <div className={"cart-slider-ending-main"}>
                        {this.fetchLanguage("Votre commande ","Your order")}
                        <b>{this.fetchLanguage(" n’est pas "," does not ")}</b>
                        {this.fetchLanguage("pas livrée avec des cadres, des vitres ou des images. ","come with frames, glasses, or prints. ")}
                        <br className={this.props.french ? "no-display" : ""}/>
                        {this.fetchLanguage("Seulement vos passe-partout personnalisés.","Only your custom matboards.")}


                    </div>
                    <div className={"cart-slider-ending-subheading"}>
                        {this.fetchLanguage("Si le passe-partout ne fonctionne pas ou est endommagé, faites-nous le savoir et nous remplacerons votre commande gratuitement.", "If the mat does not work or is damaged, let us know!")}<br/>
                    </div>

                </div>


            </div>
        </div>
    };
}