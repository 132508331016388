import React from "react";
import homeinoak from "../../../resources/images/inspirationsPhoto/homeinoak-IG.PNG";
import IGLogo from "../../../resources/logo/IG-Logo.svg";
import kristinadapaah1 from "../../../resources/images/inspirationsPhoto/kristinadapaah-IG.jpg";
import julesInteriors5 from "../../../resources/images/inspirationsPhoto/jules_interiors-IG-5.PNG";
import emilyKLewis from "../../../resources/images/inspirationsPhoto/emily.k.lewis-IG.PNG";
import LazyLoad from 'react-lazyload';
import save from '../../../resources/svgIcons/money-bill-1-solid.svg';
import crop from '../../../resources/svgIcons/crop-simple-solid.svg';
import change from '../../../resources/svgIcons/arrow-right-arrow-left-solid.svg';
import online from '../../../resources/svgIcons/wifi-solid.svg';

export default class InstagramLoader extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      // testValue: 1,
      scrolled: false,
      currencySelected: "CAD",
      displayType: "type1",

    };

  };

  fetchSentence() {
    if (this.props.french) {
      return <span>
        Si vous n'êtes pas satisfait à 100 % de votre carton à biseaux, nous vous enverrons immédiatement <b>un carton de remplacement</b> ou vous <b>rembourserons</b>.
      </span>
    } else {
      return <span>
        If you are not 100% satisfied with your matboard, we will send you a <b>replacement matboard</b> or <b>refund</b> you right away.
      </span>
    }
  }

  render() {
    return <div className={"instagram-controller-container"}>


      <div className="instagram-scroller">
        <div className={"instagrom-scrollerpost-container"}>

          <LazyLoad>
            <img className={"inspiration-post-col-photo-landing-page"} src={kristinadapaah1}
                 alt={this.props.french ? "Image réelle de nos clients." : "Actual image from our customers."}
            />
          </LazyLoad>

          <div className={"inspiration-post"}>
            <div className={"inspiration-logo-name"}>
              {this.props.french ? "Blogueur d'Ottawa" : "Ottawa Blogger"}

            </div>
            <div className={"inspiration-logo-name-subscript"}>
              <a href={"https://www.instagram.com/p/CNVhgwDFuCf/"}>
                @kristina_pah
              </a>
            </div>
          </div>
        </div>

      </div>

      <div className="instagram-scroller-right">
        <div className="instagram-scroller-right-title">
          {this.props.french ? "Satisfaction à 100% garantie" : "100% Satisfaction Guaranteed"}

        </div>
        <div className="instagram-scroller-right-subtitle">
          {this.fetchSentence()}
        </div>
      </div>

    </div>
  }
}