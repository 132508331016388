import React from "react";
import LazyLoad from 'react-lazyload';
import whatIsAMat2 from '../../../resources/images/landingPage/whatIsAMatNew.png';
import whatIsAMatGif from '../../../resources/images/landingPage/whatIsMatGif.gif';


export default class WhatIsMatboard extends React.Component {

    constructor() {
        super();
        this.state = {
            french: false,
        }
    }

    componentDidMount() {
        this.setState({
            french: (window.location.pathname.slice(-2) === "fr" || this.props.language === "fr") ? true : false
        })
    }

    fetchBanner(french) {
        if (french) {
            return <div>
                Obtenez l'apparence d'un <b>passe-partout personnalisé</b> à une <b>fraction du coût</b> en combinant nos tapis sur mesure avec vos cadres.
                <br/><br/>
                Améliorez l'affichage de votre art de manière abordable en mariant nos tapis sur mesure avec des cadres de détaillants populaires tels qu'Amazon et IKEA.
            </div>
        } else {
            return <div>
                Create the <b>custom framing</b> look at a <b>fraction of cost</b> by combining our custom matboards with your frames.
                <div className={"what-is-mat-header-v2-transition"}>
                </div>
                Upgrade your art display affordably by pairing our custom matboards with frames from popular retailers like Amazon and IKEA.
            </div>
        }
    }


    render() {
        return <div className={"what-is-mat-page-v2"}>

            <div style={{width: "100%", overflow: "auto"}}>
                <div className={"landing-page-standard-left-v2"}>
                    <h1 className={"what-is-mat-header-v2"}>
                        {this.state.french ? "Qu’est-ce qu’un " : "What is a "}<br/>
                        {this.state.french ? "passe-partout?" : " matboard?"}
                    </h1>

                    <h2 className={"what-is-mat-desc-v2"}>
                        {this.state.french ? "Matboard – Une couche dense de papier d'art spécialisé qui se place entre le verre ou l'acrylique de votre cadre et votre œuvre d'art." :
                            "Matboard – A dense layer of specialty art paper that sits between your frame’s glass / acrylic and your artwork."}
                    </h2>

                    <div className={"what-is-mat-line-break-v2"}>
                        {this.state.french ? "✦ Cadres non inclus." : "✦ Frames not included."}
                    </div>
                </div>

                <div className={"landing-page-standard-right-v2"}>
                    <LazyLoad>
                        <img className={"what-is-mat-image-v2-mobile what-is-mat-image-v2"} src={whatIsAMatGif}></img>
                    </LazyLoad>
                </div>
            </div>

            <div style={{width: "100%", overflow: "auto"}}>
                <div className={"landing-page-standard-left-v2"}>
                    <LazyLoad>
                        <img className={"what-is-mat-image-v2"} src={whatIsAMat2}/>
                    </LazyLoad>
                </div>

                <div className={"landing-page-standard-right-v2"}>
                    <h1 className={"what-is-mat-header-v2"}>
                        {this.state.french ? "VOTRE CADRE" : "Your Frame +"} <br/>
                        {this.state.french ? "Nos Passe-Partout" : "Our Matboard"}
                    </h1>

                    <h2 className={"what-is-mat-desc-v2"}>
                        {this.fetchBanner(this.state.french)}
                    </h2>

                </div>
            </div>




        </div>
    }
}