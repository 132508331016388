export default function windowCountUpdate(dictValue, callbackFunction) {
  var rowValue, columnValue;

  if (Math.sqrt(dictValue) % 1 === 0) {
    rowValue = Math.sqrt(dictValue);
    columnValue = Math.sqrt(dictValue);
  } else {
    for (var i = 2; i < 20; i++) {
      if (dictValue % i === 0) {
        rowValue = i;
        columnValue = dictValue / i;
        break;
      }
    }
  }

  return [rowValue, columnValue];
  // this.addWindowMultiple("column", columnValue);
}
