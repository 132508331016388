import React from "react";
import landingPageArrowLeft from "../../../resources/logo/landing-page-arrow-left.svg";
import designGuidePhoto1 from "../../../resources/images/howItWorks/design-guide-photo-1.PNG";
import designGuidePhoto2 from "../../../resources/images/howItWorks/design-guide-photo-2.PNG";
import smoothWhiteFrame from "../../../resources/matColours/smoothWhiteFrame.jpg";
import chinaWhiteFrame from "../../../resources/matColours/chinaWhiteFrame.jpg";
import boneFrame from "../../../resources/matColours/boneFrame.jpg";
import ecruFrame from "../../../resources/matColours/ecruFrame.jpg";
import avillionFrame from "../../../resources/matColours/avillionWhiteFrame.jpg";
import seafoamFrame from "../../../resources/matColours/seafoamFrame.jpg";
import flagstoneFrame from "../../../resources/matColours/flagStoneFrame.jpg";
import strawFrame from "../../../resources/matColours/strawFrame.jpg";
import silverFrame from "../../../resources/matColours/silverFrame.jpg";
import goldFrame from "../../../resources/matColours/goldFrame.jpg";
import cricketGreen from "../../../resources/matColours/cricketGreenFrame.jpg";
import fire from "../../../resources/matColours/fireFrame.jpg";
import red from "../../../resources/matColours/redFrame.jpg";
import royalBlue from "../../../resources/matColours/royalBlueFrame.jpg";
import navyBlue from "../../../resources/matColours/navyBlueFrame.png";
import wine from "../../../resources/matColours/wineFrame.jpg";
import chocolate from "../../../resources/matColours/chocolateFrame.jpg";
import black from "../../../resources/matColours/blackFrame.jpg";
import icePink from "../../../resources/matColours/icepinkFrame.jpg";
import perisimmon from "../../../resources/matColours/perisimmonFrame.jpg";
import purple from "../../../resources/matColours/purpleFrame.jpg";
import cirrus from "../../../resources/matColours/cirrusFrame.jpg";
import flax from "../../../resources/matColours/flaxFrame.jpg";
import powderBlue from "../../../resources/matColours/powderBlueFrame.jpg";
import peach from "../../../resources/matColours/peachFrame.jpg";
import lavender from "../../../resources/matColours/lavenderFrame.jpg";
import customGuidePhoto1 from "../../../resources/images/howItWorks/customDesignPhoto1.png";
import customGuidePhoto2 from "../../../resources/images/howItWorks/customDesignPhoto2.PNG";
import customGuidePhoto3 from "../../../resources/images/howItWorks/customDesignPhoto3.png";
import customGuidePhoto4 from "../../../resources/images/howItWorks/customDesignPhoto4.png";
import customGuidePhoto5 from "../../../resources/images/howItWorks/customDesignPhoto5.png";
import customGuidePhoto6 from "../../../resources/images/howItWorks/customDesignPhoto6.png";
import customGuidePhoto7 from "../../../resources/images/howItWorks/customDesignPhoto7.png";
import LazyLoad from 'react-lazyload';

export default class DesignGuide extends React.Component {

    constructor() {
        super();
        this.state = {
            penDrawing: false,
            french: false,
            colourScheme: {
                smoothWhite: {
                    colour: smoothWhiteFrame,
                    name: "1. Smooth White",
                    nameFr: "1. Blanc Lisse"
                },

                chinaWhite: {
                    colour: chinaWhiteFrame,
                    name: "2. China White",
                    nameFr: "2. Blanc de Chine",
                },
                bone: {colour: boneFrame, name: "3. Bone", nameFr: "3. Os"},
                seafoam: {colour: seafoamFrame, name: "4. Seafoam", nameFr: "4. Écume de Mer"},
                ecru: {colour: ecruFrame, name: "5. Ecru", nameFr: "5. Écru"},

                flagstone: {colour: flagstoneFrame, name: "6. Flagstone (Matte)", nameFr: "6. Dalle (Mat)"},
                avillion: {colour: avillionFrame, name: "7. Avillion", nameFr: "7. Avillion"},
                royalBlue: {colour: royalBlue, name: "8. Royal Blue", nameFr: "8. Bleu Royal"},
                navyBlue: {colour: navyBlue, name: "9. Navy Blue", nameFr: "9. Bleu Marine"},
                cricketGreen: {colour: cricketGreen, name: "10. Cricket Green", nameFr: "10. Vert Grillon"},
                fire: {colour: fire, name: "11. Fire", nameFr: "11. Feu"},
                red: {colour: red, name: "12. Red", nameFr: "12. Rouge"},
                gold: {colour: goldFrame, name: "13. Gold (Shiny Foil)", nameFr: "13. Or (Feuille)"},
                chocolate: {colour: chocolate, name: "14. Chocolate", nameFr: "14. Chocolat"},
                black: {colour: black, name: "15. black", nameFr: "15. Noir"},
                wine: {colour: wine, name: "16. Wine", nameFr: "16. Vin"},
                straw: {colour: strawFrame, name: "17. Straw (Matte)", nameFr: "17. Paille (Mat)"},
                silver: {colour: silverFrame, name: "18. Silver (Shiny Foil)", nameFr: "18. Argent (Feuille)"},
                icePink: {colour: icePink, name: "19. Ice Pink", nameFr: "19. Rose Glacé"},
                perisimmon: {colour: perisimmon, name: "20. Persimmon", nameFr: "20. Persimmon"},
                purple: {colour: purple, name: "21. Purple", nameFr: "21. Violet"},
                cirrus: {colour: cirrus, name: "22. Cirrus", nameFr: "22. Cirrus"},
                flax: {colour: flax, name: "23. Flax", nameFr: "23. Lin"},
                powderBlue: {colour: powderBlue, name: "24. Powder Blue", nameFr: "24. Bleu Poudre"},
                peach: {colour: peach, name: "25. Peach", nameFr: "25. Pêche"},
                lavender: {colour: lavender, name: "26. Lavender", nameFr: "26. Lavande"},
            },
            fetchMobileDropdown: false,
        };
    };

    componentDidMount() {
        this.setState({
            french: (window.location.pathname.slice(-2) === "fr" || this.props.language === "fr") ? true : false
        })
    }

    fetchMatSizeTap() {
        if (this.props.tab === "matboardSize") {
            return <div className={"design-guide-content-container"}>
                <div className={"design-guide-content-container-topic"}>
                    {this.state.french ? "Comment mesurer la taille du passe-partout" : "How to measure the mat size"}
                </div>

                <div className={"design-guide-content"}>

                    <iframe className={"design-guide-illustration-video"} src="https://www.youtube.com/embed/t-YQxHYTkUQ" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen></iframe>

                    {/*<LazyLoad>*/}
                    {/*    <img*/}
                    {/*        className={"design-guide-image"}*/}
                    {/*        src={designGuidePhoto1}*/}
                    {/*        alt={this.state.french ? "Ceci est une image illustrative du contenu de cette page." : "This is an illustrative image for the content on this page."}*/}
                    {/*    />*/}
                    {/*</LazyLoad>*/}

                    {/*<div className={"design-guide-desc"}>*/}
                    {/*    {this.state.french ? "Si vous avez acheté un cadre au détail, il se peut que les mesures soient indiquées sur l’emballage." : "If you bought a retail frame, it may have the measurements you need on the packaging."}*/}
                    {/*    <br/><br/>*/}
                    {/*    {this.state.french ? "Si vous ne disposez pas d’informations ou si vous souhaitez confirmer vos mesures, retournez le cadre et mesurez la hauteur et la largeur du panneau arrière." : "If you don't have any information or you want to confirm your measurements, flip the frame over and measure the height and width of the backing board."}*/}
                    {/*    <br/><br/>*/}
                    {/*    {this.state.french ? "Il s’agit de la taille du passe-partout dont vous avez besoin." : "This is the size of the matboard you need."}*/}
                    {/*</div>*/}
                </div>
            </div>
        } else if (this.props.tab === "windowSize") {
            return <div className={"design-guide-content-container"}>
                <div className={"design-guide-content-container-topic"}>
                    {this.state.french ? "Comment mesurer vos fenêtres d’œuvres" : "How to measure your artwork windows"}

                </div>

                <div className={"design-guide-content"}>

                    <iframe className={"design-guide-illustration-video"} src="https://www.youtube.com/embed/t-YQxHYTkUQ?start=22" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                    {/*<img*/}
                    {/*    className={"design-guide-image"}*/}
                    {/*    src={designGuidePhoto2}*/}
                    {/*    alt={this.state.french ? "Ceci est une image illustrative du contenu de cette page." : "This is an illustrative image for the content on this page."}*/}

                    {/*/>*/}
                    {/*<div className={"design-guide-desc"}>*/}
                    {/*    {this.state.french ? "La fenêtre de l’œuvre est une ouverture coupée dans le passe-partout qui expose l’illustration en dessous." : "If artwork window is an opening cut in the mat that exposes the art below."}*/}
                    {/*    <br/><br/>*/}
                    {/*    {this.state.french ? "La fenêtre doit être plus petite que votre illustration, de sorte que l’ouverture masque les bordures de l’œuvre." : "The window must be smaller than your artwork, so the opening hides the edges of the work."}*/}
                    {/*    <br/><br/>*/}
                    {/*    {this.state.french ? "Pour mesurer la taille de la fenêtre de l’œuvre, mesurez la longueur et la largeur de votre illustration, puis soustrayez un quart de pouce de chaque mesure." : "To measure the artwork window size, measure the length and width of your art and substract a quarter of an inch (0.25 inch) from each measurement."}*/}
                    {/*    <br/><br/>*/}
                    {/*    {this.state.french ? "Exemple – image 5,0 x 7,0 moins 0,25 sur la longueur et la largeur = 4,75 x 6,75 pouces." : "Example -- 5.0 x 7.0 image minus 0.25 on the length and width = 4.75 x 6.75 inches."}*/}
                    {/*    <br/><br/>*/}
                    {/*    {this.state.french ? "Il s’agit de la taille de fenêtre de votre œuvre." : "This is the size of your artwork window."}*/}
                    {/*</div>*/}
                </div>
            </div>
        } else if (this.props.tab === "customDesign") {
            return <div className={"design-guide-content-instruction-container"}>
                <div className={"design-guide-content-instruction"}>
                    {this.state.french ? "Vous avez un design unique de passe-partout? Pas de problème!" : "Got a unique matboard design? No problem!"}
                    <br/>
                    {this.state.french ? "Nous pouvons créer n’importe quel design de passe-partout que vous recherchez!" : "We can create any matboard design you are looking for!"}
                </div>
                <div className={"design-guide-photo-container"}>
                    <LazyLoad>
                        <img src={customGuidePhoto1} className={"design-guide-photo-1"}
                             alt={this.state.french ? "Ceci est une image illustrative du contenu de cette page." : "This is an illustrative image for the content on this page."}

                        />
                    </LazyLoad>
                    <LazyLoad>
                        <img src={customGuidePhoto2} className={"design-guide-photo-2"}
                             alt={this.state.french ? "Ceci est une image illustrative du contenu de cette page." : "This is an illustrative image for the content on this page."}

                        />
                    </LazyLoad>
                </div>

                <div className={"design-guide-photo-container"}>
                    <LazyLoad>
                        <img src={customGuidePhoto3} className={"design-guide-photo-3"}
                             alt={this.state.french ? "Ceci est une image illustrative du contenu de cette page." : "This is an illustrative image for the content on this page."}

                        />
                    </LazyLoad>
                    <LazyLoad>
                        <img src={customGuidePhoto4} className={"design-guide-photo-4"}
                             alt={this.state.french ? "Ceci est une image illustrative du contenu de cette page." : "This is an illustrative image for the content on this page."}
                        />
                    </LazyLoad>
                </div>

                <div className={"design-guide-photo-container"}>
                    <div className={"design-guide-photo-container-title"}>
                        {this.state.french ? "ÉTAPE PAR ÉTAPE" : "Step by step"}
                    </div>
                    <div className={"design-guide-photo-container-subtitle"}>
                        {this.state.french ? "Créons LE cadre en vinyle unique vu ci-dessus. Nos prix sont basés sur la taille de votre passe-partout." :
                            "Let's create the unique vinyl frame seen above. Our prices are"}
                        {this.state.french ? " Aucun frais supplémentaires pour UN design personnalisé." :
                            " based on your matboard size. No extra charge for a custom design."}
                    </div>

                    <div className={"design-guide-step-by-step-container"}>
                        <div className={"design-guide-step-by-step-container-left"}>

                            <div className={"design-guide-step-by-step-container-left-topic"}>
                                {this.state.french ? "1. Esquissez-le sur l’outil de dessin" : "1. Sketch it out on the Drawing Tool"}
                            </div>

                            <div className={"design-guide-step-by-step-container-left-desc"}>
                                {this.state.french ? "Esquissons le design sur la planche à dessin. " : "Let's sketch out the design on the drawing pad. "}
                                {this.state.french ? "Vous pouvez inclure tous les détails que vous souhaitez!" : "You can include any details you'd like!"}
                            </div>

                        </div>
                        <img src={customGuidePhoto5} className={"design-guide-photo-5"}
                             alt={this.state.french ? "Ceci est une image illustrative du contenu de cette page." : "This is an illustrative image for the content on this page."}
                        />
                    </div>

                    <div className={"design-guide-step-by-step-container"}>

                        <div className={"design-guide-step-by-step-container-right"}>

                            <div className={"design-guide-step-by-step-container-left-topic"}>
                                {this.state.french ? "2. Saisir la taille et la couleur du passe-partout" : "2. Input Matboard Size and Colour"}
                                <br/>
                            </div>

                            <div className={"design-guide-step-by-step-container-left-desc"}>
                                {this.state.french ? "Il est important de saisir " : "It's important to enter right "}
                                <span><b>{this.state.french ? "la taille et la couleur de passe-partout appropriées" : `Matboard Size & Matboard ${this.props.location === "us" ? "Color" : "Colour"}`}</b></span>.
                                {this.state.french ? " Nous n’aurons pas à vous contacter pour confirmer votre commande, nous veillons à ce que tout se passe bien!" : " We won't have to follow up with you to confirm your order, make sure everything go smoothly!"}
                            </div>

                        </div>
                        <img src={customGuidePhoto6} className={"design-guide-photo-6"}
                             alt={this.state.french ? "Ceci est une image illustrative du contenu de cette page." : "This is an illustrative image for the content on this page."}
                        />


                    </div>

                    <div className={"design-guide-step-by-step-container"}>

                        <div className={"design-guide-step-by-step-container-left"}>
                            <div className={"design-guide-step-by-step-container-left-topic"}>
                                {this.state.french ? "3. Laissez-nous une note" : "3. Leave us a Note"}

                            </div>

                            <div className={"design-guide-step-by-step-container-left-desc"}>
                                {this.state.french ?
                                    "Écrivez une note rapide à l’intention de notre personnel de production dans " :
                                    "Let's write a quick note for our production staff on "}
                                <b>{this.state.french ? "la section Note supplémentaire. " : "the Additional Notes section. "}</b>
                                {this.state.french ?
                                    "Donnez le plus de détails que possible notre équipe de production lira attentivement votre note avant de produire votre commande." :
                                    "Give as much detail as possible. Our production team will carefully read your note before producing your order. "}
                                —<br/>
                                {this.state.french ? "Ajoutez ensuite au sac, et votre esquisse et les instructions détaillées seront enregistrées." : "Then Add to Bag, and your sketch and detailed instructions will be saved."}
                            </div>
                        </div>

                        <img src={customGuidePhoto7} className={"design-guide-photo-7"}
                             alt={this.state.french ? "Ceci est une image illustrative du contenu de cette page." : "This is an illustrative image for the content on this page."}
                        />

                    </div>





                </div>

            </div>
        }
    }

    fetchColourListing() {
        if (this.props.tab === "matboardColour") {
            var colourArray = Object.keys(this.state.colourScheme);

            return colourArray.map((colourID, key) => {
                return <div className={"design-guide-colour-box"}>
                    <img style={{width: "100%"}} src={this.state.colourScheme[colourID].colour}
                         alt={this.state.french ? "Ceci est une image illustrative du contenu de cette page." : "This is an illustrative image for the content on this page."}
                    />
                    <div>
                        {this.state.french ? this.state.colourScheme[colourID].nameFr : this.state.colourScheme[colourID].name}
                    </div>
                </div>
            })
        }
    }

    fetchTopicHeader() {
        if (this.props.tab === "matboardSize") {
            return <div className={"design-guide-button"}>
                {this.state.french ? "GUIDE DE CONCEPTION" : "Design Guide"}
            </div>
        } else if (this.props.tab === "matboardColour") {
            return <div className={"design-guide-button"}>
                {this.state.french ? "GUIDE DE Couleur" : "Colour Guide"}
            </div>
        } else if (this.props.tab === "windowSize") {
            return <div className={"design-guide-button"}>
                {this.state.french ? "GUIDE DE CONCEPTION" : "Design Guide"}
            </div>
        } else if (this.props.tab === "customDesign") {
            return <div className={"design-guide-button"}>
                {this.state.french ? "GUIDE DE CONCEPTION" : "Design Guide"}
            </div>
        }

    }

    render() {
        return <div className={"design-guide-container"}>
            <a href={this.state.french ? "customize-matboards-fr" : "customize-matboards"}>
                <div className={"back-to-my-design-button"}>
                    <img className={"back-to-my-design-button-icon"} src={landingPageArrowLeft}
                         alt={this.state.french ? "Ceci est une image illustrative du contenu de cette page." : "This is an illustrative image for the content on this page."}
                    />
                    {this.state.french ? "retour à ma conception" : "BACK TO MY DESIGN"}
                </div>
            </a>


            {this.fetchTopicHeader()}

            <div className={"mobile-no-display"}>
                <a className={"design-guide-section-button"}
                   href={this.state.french ? "design-guide-fr" : "design-guide"}
                >
                    <div className={this.props.tab === "matboardSize" ? "design-guide-section-button-selected" : "design-guide-section-unselected"}>
                        {this.state.french ? "PASSE-PARTOUT" : "Matbaord Size"}
                    </div>
                </a>

                <a className={"design-guide-section-button"}
                   href={this.state.french ? "colour-guide-fr" : "colour-guide"}
                >
                    <div className={this.props.tab === "matboardColour" ? "design-guide-section-button-selected" : "design-guide-section-unselected"}>
                        {this.state.french ? "COULEUR" : `Matboard ${this.props.location === "us" ? "Color" : "Colour"}`}

                    </div>
                </a>
                <a className={"design-guide-section-button"}
                   href={this.state.french ? "window-size-fr" : "window-size"}
                >
                    <div className={this.props.tab === "windowSize" ? "design-guide-section-button-selected" : "design-guide-section-unselected"}>
                        {this.state.french ? "TAILLE DE LA FENÊTRE" : "Window Size"}

                    </div>
                </a>
                <a className={"design-guide-section-button"}
                   href={this.state.french ? "custom-design-guide-fr" : "custom-design-guide"}
                >
                    <div className={this.props.tab === "customDesign" ? "design-guide-section-button-selected" : "design-guide-section-unselected"}>
                        {this.state.french ? "PERSONNALISÉE" : "Custom Design"}

                    </div>
                </a>
            </div>

            <div className={this.state.fetchMobileDropdown ? "design-guide-section-containe-type-2" : "no-display"}>

                <a
                   href={this.state.french ? "design-guide-fr" : "design-guide"}
                >
                    <div className={"design-guide-section-button-type-2"}>
                        {this.state.french ? "PASSE-PARTOUT" : "Matboard Size"}
                    </div>

                </a>





                <a
                   href={this.state.french ? "colour-guide-fr" : "colour-guide"}
                >
                    <div className={"design-guide-section-button-type-2"}>
                        {this.state.french ? "COULEUR DU PASSE-PARTOUT" : `Matboard ${this.props.location === "us" ? "Color" : "Colour"}`}
                    </div>
                </a>


                <a
                   href={this.state.french ? "window-size-fr" : "window-size"}
                >
                    <div className={"design-guide-section-button-type-2"}>
                        {this.state.french ? "TAILLE DE LA FENÊTRE" : "Window Size"}
                    </div>
                </a>


                <a
                   href={this.state.french ? "custom-design-guide-fr" : "custom-design-guide"}
                >
                    <div className={"design-guide-section-button-type-2"}>
                        {this.state.french ? "CONCEPTION PERSONNALISÉE" : "Custom Design"}
                    </div>
                </a>











            </div>

            <div className={this.state.fetchMobileDropdown ?  "no-display" : "mobile-display-only design-guide-selection-button-container"} onClick={() => {this.setState({fetchMobileDropdown: !this.state.fetchMobileDropdown})}}>
                <div className={this.props.tab === "matboardSize" ? "design-guide-section-button-selected" : "no-display"}>
                    <div className={"design-guide-section-button"}
                    >
                    {this.state.french ? "PASSE-PARTOUT" : "Matboard Size"}
                    </div>
                    <img className={"design-guide-mobile-dropdown-icon"} src={landingPageArrowLeft} style={{transform: "rotate(270deg)", marginLeft: "5px"}}
                         alt={this.state.french ? "Ceci est une image illustrative du contenu de cette page." : "This is an illustrative image for the content on this page."}

                    />
                </div>

                <div className={this.props.tab === "matboardColour" ? "design-guide-section-button-selected" : "no-display"}>

                    <div className={"design-guide-section-button"}
                    >
                    {this.state.french ? "COULEUR DU PASSE-PARTOUT" : `Matboard ${this.props.location === "us" ? "Color" : "Colour"}`}
                    </div>
                    <img className={"design-guide-mobile-dropdown-icon"}
                         src={landingPageArrowLeft} style={{transform: "rotate(270deg)", marginLeft: "5px"}}
                         onClick={() => {this.setState({fetchMobileDropdown: !this.state.fetchMobileDropdown})}}
                         alt={this.state.french ? "Ceci est une image illustrative du contenu de cette page." : "This is an illustrative image for the content on this page."}
                    />

                </div>



                <div className={this.props.tab === "windowSize" ? "design-guide-section-button-selected" : "no-display"}>
                    <div className={"design-guide-section-button"}
                    >
                    {this.state.french ? "TAILLE DE LA FENÊTRE" : "Window Size"}
                    </div>
                    <img className={"design-guide-mobile-dropdown-icon"}
                         src={landingPageArrowLeft} style={{transform: "rotate(270deg)", marginLeft: "5px"}}
                         onClick={() => {this.setState({fetchMobileDropdown: !this.state.fetchMobileDropdown})}}
                         alt={this.state.french ? "Ceci est une image illustrative du contenu de cette page." : "This is an illustrative image for the content on this page."}
                    />

                </div>

                <div className={this.props.tab === "customDesign" ? "design-guide-section-button-selected" : "no-display"}>
                    <div className={"design-guide-section-button"}
                    >
                    {this.state.french ? "CONCEPTION PERSONNALISÉE" : "Custom Design"}
                    </div>
                    <img className={"design-guide-mobile-dropdown-icon"}
                         src={landingPageArrowLeft} style={{transform: "rotate(270deg)", marginLeft: "5px"}}
                         onClick={() => {this.setState({fetchMobileDropdown: !this.state.fetchMobileDropdown})}}
                         alt={this.state.french ? "Ceci est une image illustrative du contenu de cette page." : "This is an illustrative image for the content on this page."}

                    />

                </div>

            </div>

            {this.fetchMatSizeTap()}

            <div className={this.props.tab === "matboardColour" ? "design-guide-colour-container" : "no-display"}>
                {this.fetchColourListing()}
            </div>

            <a href={"https://www.youtube.com/watch?v=t-YQxHYTkUQ"}>
                <div className={"design-guide-content-container-walkthrough-button"}>
                    {this.state.french ? "Cliquez ici pour regarder la vidéo pour une présentation complète." : "Click to watch the video for full walkthrough."}
                </div>
            </a>



            <div className={"design-guide-inquiry"}>
                {this.state.french ? "" : "If you have any questions, please don't hesiate to contact us at"}
                <br/>
                <span className={"design-guide-inquiry-underline"}>
                    {this.state.french ? "" : "hello@custommat.ca "}
                </span>
                <span className={"design-guide-inquiry-underline"}>
                    {this.state.french ? "" : "| 226-828-1705"}
                </span>
                <br/>
                {this.state.french ? "" : "We typically respond within an hour."}

            </div>

        </div>


    }
}