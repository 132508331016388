import React from "react";
import LazyLoad from 'react-lazyload';
import explainerVideo1 from "../../../resources/video/explainerVideo1.mp4";
import explainerVideo2 from "../../../resources/video/explainerVideo2.mp4";
import explainerGif1 from "../../../resources/video/explainerGif1.gif";
import explainerGif2 from "../../../resources/video/explainerGif2.gif";



export default class Explainer extends React.Component {

    constructor() {
        super();
        this.state = {
            french: false,
        }
    }

    componentDidMount() {
        this.setState({
            french: (window.location.pathname.slice(-2) === "fr" || this.props.language === "fr") ? true : false
        })
    }


    render() {
        return <div className={"what-is-mat-page-v2"}>
            <div className={this.props.titleNoDisplay ? "no-display" : "what-is-mat-header-v2 how-it-works-page-leader"}
                 id={"what-is-mat-header-v2-moblet"}
            >
                {this.state.french ? "Comment ça Marche" : "HOW IT WORKS"}
            </div>

              <div className={"how-it-works-steps-container-v2"}
                 id={"how-it-works-steps-container-v2-moblet"}>
                  <div className={"landing-page-standard-right-v2 mobile-and-tablet-display-only"}
                       id={"how-it-works-video1"}>
                      <LazyLoad>
                          <img className={"what-is-mat-image-v2"} id={"how-it-works-image1"} src={explainerGif1}></img>
                      </LazyLoad>
                  </div>

                <div className={"landing-page-standard-left-v2"}>
                    <div className={"how-it-works-header-left"}>
                        {this.state.french ? "Pour commander " : "Ordering yours "} <br/>
                        {this.state.french ? "votre passe-partout" : "in 3 simple steps"}
                    </div>

                    <div className={"how-it-works-subheader-left"} id={"how-it-works-substeps-1"}>
                        {this.state.french ? "1. Indiquez la taille totale de votre passe-partout." : "1. Enter the overall matboard size you want."} <br/> <br className={"mobile-and-tablet-display-only"}/>
                        {this.state.french ? "2. Précisez les dimensions de la découpe intérieure." : "2. Specify the interior cutout dimensions."} <br/> <br className={"mobile-and-tablet-display-only"}/>
                        {this.state.french ? "3. Choisissez la couleur de votre passe-partout." : `3. Pick  your matboard ${this.props.location === "us" ? "color" : "colour"}`} <br/>
                    </div>

                    <a href={this.state.french ? "/customize-matboards-fr" : "/customize-matboards"} id={"how-it-works-page-button"}>
                        <div className={"landing-page-button-standard-v2"}
                             id={"explainer-start-designing-button"}>
                            {this.state.french ? "Je crée " : "START DESIGNING"}
                        </div>
                    </a>
                </div>

                <div className={"landing-page-standard-right-v2 mobile-no-display tablet-no-display"}>
                    <LazyLoad>
                        <video className={"what-is-mat-image-v2"} loop="true" autoPlay="autoplay" controls muted
                               src={explainerVideo1}></video>
                    </LazyLoad>
                </div>
            </div>

            <div className={"how-it-works-steps-container-v2"}>

                <div className={"landing-page-standard-right-v2 mobile-and-tablet-display-only"}
                     id={"how-it-works-video2"}>
                    <LazyLoad>
                        <img className={"what-is-mat-image-v2"} id={"how-it-works-image2"} src={explainerGif2}></img>
                    </LazyLoad>
                </div>

                <div className={"how-it-works-step2-desc-v2 mobile-and-tablet-display-only"}>
                    <div className={"how-it-works-header-right"} id={"how-it-works-substeps-3"}>
                        {this.state.french ? "Expédié " : "Cut and shipped "}
                        <br/>
                        {this.state.french ? "dans les 48 heures" : "within 48 hours"}

                    </div>

                    <div className={"how-it-works-subheader-right"}>
                        {this.state.french ? "Après avoir coupé avec précision votre passe-partout personnalisé, nous l’emballons avec soin et nous vous l’expédions dans les 48 heures. " : "After we precision cut your custom mat, we package it with care and ship it to you within 48 hours."}
                        <br/><br/>
                        <u>
                            {this.state.french ? "✦Bonheur garanti à 100 %" : "✦100% Happiness Guaranteed."}
                        </u>
                        <br/><br/>
                    </div>

                    <div className={"read-our-review-section"}>
                        <a href={"/customer-reviews"}>
                            {this.state.french ? "Lisez nos avis clients élogieux" : "Read Our Glowing Reviews"}
                        </a>
                    </div>
                </div>



                <div className={"landing-page-standard-left-v2 mobile-no-display tablet-no-display"}>
                    <LazyLoad>
                        <video className={"what-is-mat-image-v2"} loop="true" autoPlay="autoplay" controls muted
                               src={explainerVideo2}></video>
                    </LazyLoad>
                </div>


                <div className={"how-it-works-step2-desc-v2 mobile-no-display tablet-no-display"}>
                    <div className={"how-it-works-header-right"}>
                        {this.state.french ? "Expédié dans" : "Produced and shipped "}
                        <br/>
                        {this.state.french ? "les 48 heures" : "within 48 hours."}
                    </div>

                    <div className={"how-it-works-subheader-right"}>
                        {this.state.french ? "Votre passe-partout personnalisé sera découpé avec précision, emballé avec soin, et expédié vers vous en seulement 48 heures." : "Your custom matboard will be precisely cut and shipped within 48 hours for quick delivery."}
                        <br/>
                        <br/>

                        {this.state.french ? "✦ Bonheur garanti à 100 %" : "✦ 100% Satisfaction Guaranteed."}

                        <br/><br/>

                        <a href={"/customer-reviews"}>
                            {this.state.french ? "Lisez nos avis clients élogieux" : "Read Our Glowing Customer Reviews"}
                        </a>


                    </div>
                </div>
            </div>

        </div>


    }
}