import React from 'react';
import photo1 from "../../../resources/images/backingMatBlogPage/backingMat.png";
import photo2 from "../../../resources/images/backingMats/BackingRectangle2.jpg";
import photo3 from "../../../resources/images/backingMatBlogPage/backingMatDirections.jpg";
import photo4 from "../../../resources/images/backingMatBlogPage/showKit.jpg";
import photo5 from "../../../resources/images/backingMats/BackingRectangle1.jpg";
import photo6 from "../../../resources/images/backingMats/BackingRectangle3.jpg";

import LazyLoad from "react-lazyload";
import {Helmet} from "react-helmet";

export default class BackingMat extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        };
    };

    componentDidMount() {

    }

    render() {
        return <div className={"blog-app-container"}>

            <Helmet>
                <title>
                    Unveiling the Role of Backing Boards in Art: A Comprehensive Guide
                </title>
                <meta name="description" content="Discover the significance of backing boards in the world of art. Explore how these sturdy craft paper layers support and present your artwork, ensuring a safe journey for your masterpieces. Learn about types, composition, and where to find quality backing matboards."/>
            </Helmet>

            <h1 className={"carousel-subject-head"} style={{textAlign: "center"}}>
                Unveiling the Role of Backing Boards in Art: A Comprehensive Guide
            </h1>

            <div className={"carousel-subject-subhead-type2"}>
                Ideas
            </div>

            <LazyLoad>
                <img className="blog-content-photo" src={photo1} alt={"Assortment of backing matboards in various sizes available at CustomMat.ca."}/>
            </LazyLoad>

            <br/>

            <div className={"blog-article-content"}>

                <h2>
                    Step into the World of Art and Discover the Hidden Champion: Backing Board
                </h2>

                In the enchanting realm of art, a term often overlooked yet indispensable is the backing board. Known by various monikers – backing matboard, mounting board, backer board, and more – this unassuming element plays a pivotal role in safeguarding and enhancing your artistic treasures.

                <br/><br/>

                Let's embark on a journey to unravel the mysteries and significance of the backing board.

                <br/><br/>


                {/*<div className={"visit-custom-mat-button"}>*/}
                {/*    <a href={"/"} className={"carousel-read-more-button"}>*/}
                {/*        Start Customization*/}
                {/*    </a>*/}
                {/*</div>*/}

                <LazyLoad>
                    <img className="blog-article-photo-two-image" src={photo2} alt={"Illustrations showcasing the side view of a backing matboard."}/>
                    <img className="blog-article-photo-two-image" src={photo3} alt={"Product description for our mounting mats and backing matboards available at CustomMat. These consist of matboards with overall size and no opening. Customize outer measurements and color. Quantity discounts offered."}/>
                </LazyLoad>


                <br/><br/>

                <h2>Defining the Backing Board</h2>

                <br/>

                In essence, a backing board serves as a steadfast foundation for your artwork. It's a dense layer of craft paper that elevates the presentation and preservation of your creative endeavors.

                <br/><br/>

                The role it undertakes is not just functional; it's a silent partner in showcasing the true essence of your artwork.

                <br/><br/>

                <div className={"visit-custom-mat-button"}>
                    <a href={"http://www.custommat.ca/backing-mat-boards"} className={"carousel-read-more-button"}>
                        Order Backing Matboards
                    </a>
                </div>

                <h2>The Multifaceted Identity of Backing Matboards</h2>

                <br/>


                Backing matboards, an extension of regular matboards, come in two variations: pre-cut sizes for immediate use and custom cut dimensions tailored to your needs.

                <br/><br/>
                These unadorned champions differ from regular matboards due to their absence of window cutouts, allowing them to be the perfect canvas for mounting your fine art or art print.

                <br/><br/>

                {/*<LazyLoad>*/}
                {/*    <img className="blog-carousel-photo" src={"https://www.custommat.ca/static/media/1.2608568c.png"}/>*/}
                {/*</LazyLoad>*/}


                <LazyLoad>
                    <img className="blog-content-photo" src={photo4} alt={"Description of Matboard Show Kit / Presentation Kit. This kit includes a custom-sized matboard with a personalized opening cutout in any size or shape, backing matboard for mounting, and a show bag with adhesive sleeves. Designed for artists and photographers, this kit ensures that their creations captivate and withstand their journey to the intended audience unscathed."}/>
                </LazyLoad>

                <h2>Crafting the Ultimate Presentation Kit</h2>

                <br/>

                When combined with custom matboards and clear bag sleeves, backing matboards morph into a powerful presentation kit, often referred to as a show kit.

                <br/><br/>

                Beyond being a mere display tool, this trio creates an immersive experience. For artists and photographers alike, this kit ensures that their masterpieces not only captivate but also endure their journey to their intended audience unscathed.

                <br/><br/>

                <div className={"visit-custom-mat-button"}>
                    <a href={"http://www.custommat.ca/show-presentation-kit"} className={"carousel-read-more-button"}>
                        Order Show Kit
                    </a>
                </div>

                <LazyLoad>
                    <img className="blog-article-photo-two-image" src={photo5} alt={"Product description for our mounting mats and backing matboards available at CustomMat. These consist of matboard with an overall size and no opening. You can customize outer measurements and color. Quantity discounts are available. Front view photo of the backing matboard."}/>
                    <img className="blog-article-photo-two-image" src={photo6} alt={"Product description for our mounting mats and backing matboards available at CustomMat. These consist of matboard with an overall size and no opening. You can customize outer measurements and color. Quantity discounts are available. Photo of the front view of the backing matboard within a picture frame."}/>
                </LazyLoad>

                <br/><br/>

                <h2>The Role of Backing Boards: Unveiling Clarity</h2>

                <br/>

                Amidst the meticulous framing and delicate handling of artwork, backing boards emerge as unsung heroes. The question of whether one needs a backing board arises, particularly when dealing with delicate compositions like fabrics or thin layers.

                <br/><br/>

                While not a mandatory component, using a backing board is strongly recommended, especially for preserving the integrity of delicate artworks.

                <br/><br/>

                <h2>Deciphering the Backing Board's Composition</h2>

                <br/>

                Backing boards, often constructed from corrugated, acid-free 4-ply pre-cut boards, stand as a testament to their durability and archival quality.

                <br/><br/>

                Their composition ensures that your artwork remains free from wrinkles and bubbles, maintaining its pristine state even when framed.

                <br/><br/>

                <h2>Choosing the Right Backing Board</h2>

                <br/>

                When it comes to selecting backing boards, two primary options dominate the landscape: paper-based mat boards and foam core boards. Both offer sturdiness and are commonly used in art preservation.

                <br/><br/>

                While foam boards boast greater thickness, paper-based mat boards also hold their ground, often used for their acid-neutralized properties.

                <br/><br/>

                <h2>The Dimensions and Compatibility</h2>

                <br/>

                Backing boards, generally 4-ply thick or about 1/16th of an inch, combine with matboards to create a combined thickness of approximately 1/8th of an inch. This compatibility makes them suitable for most wooden frames. However, it's essential to consider the frame's dimensions; slim metal frames may necessitate using the matboard without the backing board.

                <br/><br/>

                <h2>Mounting Artwork with Backing Matboards</h2>

                <br/>

                The versatility of backing matboards shines through in their capacity to meet archival and preservation standards. Their matte surface is a perfect canvas for various mounting methods, including self-adhesive tapes and artist glues. From delicate prints to intricate artworks, backing matboards provide a secure and reliable platform.

                <br/><br/>

                <h2>Sourcing Your Backing Boards</h2>

                <br/>

                If you're on a quest for backing matboards — whehter you want a custom size or standard, pre-cut backing mats, your search finds a conclusion at CustomMat (internal link).

                <br/><br/>

                With an extensive spectrum of colors and customizable options, this is your haven for quality backing boards. The best part is, they will deliver to your door anywhere in Canada and the United States.

                <br/><br/>

                For orders over $49, the added bonus of free shipping sweetens the deal. Traditional enthusiasts can explore local picture framing stores and art supply outlets, where quality backing boards await discovery.

                <br/><br/>

                <h2>
                    In Conclusion
                </h2>

                <br/>

                While often concealed behind the glamour of artistic presentations, backing boards emerge as the unsung heroes of art preservation and presentation.

                <br/><br/>

                Their robust support, archival qualities, and versatility make them an essential component in the art world's intricate tapestry.

                <br/><br/>

                The next time you gaze upon a carefully framed artwork, remember the backing board that silently elevates its charm and ensures its endurance through time and space.

                <div className={"visit-custom-mat-button"}>
                    <a href={"https://www.custommat.ca/how-it-works"} className={"carousel-read-more-button"}>
                        Visit Custom Mat & Learn More
                    </a>
                </div>

            </div>



        </div>
    }
}