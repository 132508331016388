export const frameInventory = {
  black: {
    small: {
      cm: {
        width: 41,
        height: 31,
      },
      in: {
        width: 16,
        height: 12,
      },
      price: 9.99,
    },
    wide: {
      cm: {
        width: 50,
        height: 23,
      },
      in: {
        width: 19.75,
        height: 9,
      },
      price: 12.99,
    },
    medium: {
      cm: {
        width: 51,
        height: 41,
      },
      in: {
        width: 20,
        height: 16,
      },
      price: 14.99,
    },
    large: {
      cm: {
        width: 70,
        height: 50,
      },
      in: {
        width: 27.5,
        height: 19.75,
      },
      price: 19.99,
    },
    xLarge: {
      cm: {
        width: 91,
        height: 61,
      },
      in: {
        width: 35.75,
        height: 24,
      },
      price: 24.99,
    },
    panorama: {
      cm: {
        width: 88,
        height: 30,
      },
      in: {
        width: 34.75,
        height: 11.75,
      },
      price: 25.99,
    },
    desc: "Our elegant black frame with custom matboard will fit your artwork perfectly. The frame is composed of MDF composite and covered in acrylic glaze."
  },
};