export const keywordList = [
    "custom frames canada",
    "custom mat",
    "custom picture mats canada",
    "custom frame canada",
    "custom matting",
    "custom mat canada",
    "custom mat board free shipping",
    "custom mat for frame",
    "custom picture frames canada",
    "custom mats",
    "custom picture frames online canada",
    "mat board canada",
    "picture matting canada",
    "matboard canada",
    "custom picture mats",
    "custom mat board",
    "custom matte",
    "custom mats canada",
    "custom photo mats canada",
    "custom frames online canada",
    "custom matboard",
    "custom frame mat",
    "online framing canada",
    "custom matting for frames",
    "custom photo mats",
    "picture mats canada",
    "custom mats for frames",
    "photo mats canada",
    "custom framing online canada",
    "online custom framing canada",
    "custom frame mats",
    "custom framing canada",
    "custom frame mats canada",
    "online framing and matting",
    "custom frame and mat",
    "custom picture frame canada",
    "frame mats canada",
    "custom matting online",
    "custom picture mat",
    "art frames canada",
    "custom mat and frame",
    "online picture frames canada",
    "custom frame online canada",
    "custom frame matting",
    "custom matboard canada",
    "photo mats online",
    "custom mat board canada",
    "custom frames",
    "custom frames and mats",
    "custom mat boards",
    "custom mat frame",
    // "matboards",
    // "custom mattes",
    // "custom matte for frame",
    // "custom mat cutting",
    // "custom matted frames",
    // "picture mat",
    // "custom picture matting",
    // "custom photo mat",
    // "frame mat board",
    // "custom matte board",
    // "picture framing red deer",
    // "art frames online canada",
    // "picture framing nanaimo",
    // "picture mats online",
    // "custom matt",
    // "custom mat vancouver",
    // "custom matboards",
    // "order picture frames online canada",
    // "custom picture mats free shipping",
    // "custom cut picture mat",
    // "custom matt for frame",
    // "frame mat",
    // "mat board",
    // "mat boards",
    // "custom mats and frames",
    // "frame matting",
    // "custom framing online",
    // "cheap custom frames",
    // "custom polaroid frame",
    // "picture matting",
    // "frames canada",
    // "picture framing burnaby",
    // "picture frames online canada",
    // "framing and matting",
    // "mat boards and more",
    // "custom picture frame mats",
    // "framing canada",
    // "frames and matting",
    // "custom matting and framing",
    // "custom cut matboard",
    // "custom matts",
    // "mattes for frames",
    // "mats and frames",
    // "frame mats online",
    // "custom mat frames",
    // "art to frames canada",
    // "custom matboard cutting",
    // "canada custom frames",
    // "custom mat for picture frame",
    // "art supply store vancouver",
    // "custom picture matting online",
    // "custom frames online",
    // "custom cut mat board",
    // "custom frame",
    // "matboard",
    // "custom mats for framing",
    // "custom picture frames online",
    // "picture mats cut to size",
    // "custom matte frame",
    // "mat ca",
    // "mat frame",
    // "custom cut matte board",
    // "matting frame",
    // "frames and mats online",
    // "online framing",
    // "custom cut mats",
    // "picture framing mats",
    // "mat frames",
    // "custom frame with mat",
    // "customized frames",
    // "custom mats frames",
    // "picture framing regina",
    // "custom matte cutting",
    // "mat custom",
    // "mat shop canada",
    // "custom cut photo mats",
    // "matting and framing",
    // "custom framing mats",
    // "custom framing vancouver",
    // "custom photo frames canada",
    // "acid free mat board",
    // "picture frames canada",
    // "frames and mats",
    // "custom photo matting",
    // "custom polaroid frames",
    // "frame and matte",
    // "mat and frame",
    // "photo mats",
    // "picture frames kamloops",
    // "picture framing duncan bc",
    // "custom frame and matting",
    // "custom mattes for frames",
    // "custom mat board cutting",
    // "how to make a picture mat",
    // "custom frames mats",
    // "picture frame matte",
    // "custom size mats",
    // "where to buy matboard for framing",
    // "picture frames nanaimo",
    // "custom mat promo code",
    // "custom cut mats for frames",
    // "custommats",
    // "custom frames vancouver",
    // "custom online frames",
    // "custom matting and framing online",
    // "custom matting for picture frames",
    // "mattboard",
    // "order custom matting",
    // "custom cut matting for frames",
    // "mat board for picture framing",
    // "custom mat cutting online",
    // "picture framing near me",
    // "custom matte and frame",
    // "custom passepartout",
    // "custom size mat for frame",
    // "custom sized mats",
    // "custom framing",
    // "matt boards for framing",
    // "custom picture matte",
    // "cheap custom frames canada",
    // "custom matt and frame",
    // "custom matted picture frames",
    // "custom framing near me",
    // "frame ca",
    // "frame picture near me",
    // "custom size mats for frames",
    // "Matboard Shop",
    // "cheap framing vancouver",
    // "custom plexiglass vancouver",
    // "inexpensive custom framing",
    // "online matting and framing",
    // "photo frame",
    // "custom picture frame montreal",
    // "custom frame montreal",
    // "picture frame mats",
    // "frame mats",
    // "picture mats",
    // "picture framing calgary",
    // "custom cut mat",
    // "custom cut mats for framing",
    // "mat canada",
    // "picture mat cutting",
    // "picture matts",
    // "custom picture frames",
    // "custom frame and mat online",
    // "picture frame mat cutting",
    // "polaroid frames",
    // "custom mat and frame online",
    // "custom online framing",
    // "custom picture frames calgary",
    // "custom matting with text",
    // "customized frame",
    // "custom art frames online",
    // "custom framing calgary",
    // "frames montreal",
    // "picture framing costs",
    // "custom cut picture mats",
    // "custom picture framing calgary",
    // "custom frames online cheap",
    // "matboard cutting calgary",
    // "custom picture framing online",
    // "framing online",
    // "custom cut matting",
    // "custom made frames",
    // "custom frame online",
    // "matte board",
    // "custom cut mat boards",
    // "red picture frames",
    // "large mat board",
    // "frames custom",
    // "custom order frames",
    // "cutting mat board",
    // "online custom picture framing",
    // "polaroid frame online",
    // "custom polaroid film",
    // "order custom frames online",
    // "custom framing costs",
    // "custom diploma framing toronto",
    // "online art framing",
    // "mats for frames",
    // "where to buy custom frames",
    // "photo mat",
    // "custom mats for picture frames",
    // "custom size frames online",
    // "online custom frame",
    // "buy custom frames online",
    // "how to cut mat board",
    // "custome frames",
    // "custon frames",
    // "custom frams",
    // "on line framing",
    // "frame custom",
    // "frame matting custom",
    // "art framing calgary",
    // "cut matboards",
    // "custom frames cheap",
    // "order custom mat",
    // "make your own mat",
    // "custome frame",
    // "20 x 30 frame",
    // "picture framing brampton",
    // "picture framing online",
    // "custom order picture",
    // "calgary picture framing",
    // "customised mat",
    // "how much do custom frames cost",
    // "polaroid frame",
    // "picture framing vancouver",
    // "cheap custom framing",
    // "canada mat",
    // "picture framing edmonton",
    // "order frames online",
    // "custom photo mattes",
    // "custom cutting mats",
    // "a frame board",
    // "customized mats",
    // "design your own photo mat",
    // "cheap custom mats",
    // "custom cut mats for picture framing",
    // "mat online",
    // "photo mat board",
    // "framing mats",
    // "matte frame",
    // "custom size mat board",
    // "framing calgary",
    // "toronto picture frame",
    // "frame and mat",
    // "matting board",
    // "mat board for framing",
    // "mat cut to size",
    // "picture frame mat",
    // "20 x 30 picture frames",
    // "frames with mats",
    // "mat for frame",
    // "custom gallery frames",
    // "canada frames",
    // "framing mat",
    // "custom framing cost",
    // "picture frames vancouver",
    // "mat boards for framing pictures",
    // "custom mattes and frames",
    // "shopping mall mats vancouver",
    // "custom frame shop",
    // "buy art frames online",
    // "shopping mall matting vancouver",
    // "quality mat",
    // "picture frame matting",
    // "mats canada",
    // "custom size cutting mat",
    // "how much for custom framing",
    // "frame matte",
    // "picture mat board",
    // "picture frames edmonton",
    // "matte boards",
    // "custom picture frame",
    // "custom mat framing",
    // "discount custom frames",
    // "how to frame a poster with a mat",
    // "framing artwork cost",
    // "photo mat online",
    // "poster mat",
    // "custom matting for pictures",
    // "dunbar southlands",
    // "custom made frames online",
    // "framing mats online",
    // "matting for picture frames",
    // "custom frame prices",
    // "framing matte",
    // "quality picture frames online",
    // "cost of custom framing",
    // "ottawa framing",
    // "photo frames toronto",
    // "framing mat board",
    // "poster frames vancouver",
    // "how to cut a mat for a picture",
    // "picture framing markham",
    // "custom framing markham",
    // "picture framing ottawa",
    // "best online framing",
    // "picture framing cost",
    // "picture frames toronto",
    // "cost of picture framing",
    // "matting services",
    // "cost of framing art",
    // "shipping art from canada to the us",
    // "framing costs",
    // "custom photo matte",
    // "matt frame",
    // "order picture frames online",
    // "cost of framing a poster",
    // "picture frames montreal",
    // "custom frame design",
    // "the matboard",
    // "how much does custom framing cost",
    // "affordable frames",
    // "order frames",
    // "61cm x 91cm frame",
    // "matboard cutting",
    // "art frames online",
    // "frames online canada",
    // "on line frames",
    // "online framing store",
    // "picture of a mat",
    // "picture framing boards",
    // "picture frames calgary",
    // "custom photo frames online",
    // "picture frame mat board",
    // "affordable framing",
    // "photo frame order online",
];