import React from 'react';
import {Helmet} from "react-helmet";
import WhatIsMatboard from "../page-howItWorks/whatIsMatboard";
import BlogArticleNine from "../app-gallery/articleView/articleNine";
import DesignBlogMain from "../blog2.0/blog-article-template";
import LazyLoad from "react-lazyload";

export default class FetchSeoArticle extends React.Component {

    constructor() {
        super();
        this.state = {
            french: false,
        }
    };
    componentDidMount() {
        this.setState({
            french: window.location.pathname.slice(-2) === "fr" ? true : false
        })
    };

    render() {
        return <div className={`fetch-region-content`}>
            <Helmet>
                <title>
                    {this.state.french ? "Des passe-partout et des cadres photo personnalisés • Envoyez à " : "Custom matboards and picture frames • Ship to "}
                    {this.props.neighbourhood}, {this.props.city}
                </title>
                <meta name="description" content={
                    this.state.french ? "Personnalisez vos passe-partout en ligne. Nous vous les expédions sous 48h. Pas de quantité minimum de commande. Des dizaines d'options de couleurs. Livraison gratuite au Canada et aux États-Unis à partir de 49 $." :
                        "Customize your matboards online. Any size or colour. Custom dimensions. Your design. We ship them to you within 48 hours. No minimum order quantity. Free shipping to Canada and the USA over $49."
                } />
            </Helmet>

            <div className={"blog-article-content"}>

                <div className="carousel-subject-head">
                    {this.state.french ? "Salut " : "Hi "}
                    {this.props.neighbourhood}!
                </div>

                <br/>

                {this.state.french ?
                    `Salut ${this.props.neighbourhood}! Nous sommes ravis d'annoncer que nous expédions maintenant à ${this.props.neighbourhood}, ${this.props.city}.` :
                    `Hello ${this.props.neighbourhood}! We are excited to announce that we now serve ${this.props.neighbourhood}, ${this.props.city}.`}

                <br/><br/>

                {this.state.french ?
                    `Nous pensons que les beaux cadres photo doivent être économiques. Nous introduisons une nouvelle façon alternative d'encadrement personnalisé. Au lieu de dépenser des centaines, vous pouvez obtenir un effet visuel similaire en combinant n'importe quel cadre photo ordinaire avec un tableau de passe-partout personnalisé.` :
                    `We believe beautiful picture frames should be budget friendly. We are introducing a new alternative way of custom framing. Instead of spending hundreds, you can get a similar visual effect by combining any regular picture frame with custom mat board.`
                }
                <br/><br/>

                <b>How to frame your art in a budget?</b>

                <br/>

                <ol>
                    <li>Measure the size of your artwork.</li>
                    <li>Find a picture frame that is bigger than your artwork.</li>
                    <li>Get a custom mat board to find a perfect fit.</li>
                </ol>

                <br/><br/>

                <LazyLoad>
                    <video className={"what-is-mat-image-v2"} loop="true" autoPlay="autoplay" controls muted
                           src="https://s3.ca-central-1.amazonaws.com/custommat.landing.v2/0_What-is-a-matboard_1920x1290_v1+(1).mp4"></video>
                </LazyLoad>

                <br/><br/>

                {this.state.french ? `Ici à ` : `Here at `}
                <a href={"www.custommat.ca"} rel="noopener noreferrer" target={"_blank"}>Custom Mat</a>
                {this.state.french ? `, nous produisons des passe-partout personnalisés dans n'importe quelle taille, couleur et design pour combler l'écart entre votre cadre et votre œuvre d'art. La combinaison de n'importe quel cadre de taille standard et de nos passe-partout personnalisés adaptés à vos dimensions créera un mur de galerie avec un budget limité.` : `, we produce custom matboards in any size, colour, and design to bridge the gap between your frame and your artwork. Combination of any standard sized frames and our custom matboards that are tailored for your dimensions will create a gallery wall on a budget.`}
                <br/><br/>



            </div>

            <DesignBlogMain/>



        </div>
    }
}