import React from 'react';
import ArticleFetcher from "../articleFetcher";
import {galleryArticles} from "../articles";
import {Helmet} from "react-helmet";
import analyticsDataToDB from "../../comp-analyticsLog/dbLogging";

export default class BlogArticleOne extends React.Component {

  constructor(props) {
    super(props);
    var article = galleryArticles.article1;
    this.state = {
      category: article.category,
      hashTags: article.hashTags,
      title: article.title,
      subtitle: article.subtitle,
      photos: article.photos,
      contents: article.contents,
      thumbnail: article.thumbnail,
    }
  }

  componentDidMount(){
    this.logToDbUserRecord();
  }

  logToDbUserRecord() {
    analyticsDataToDB("fetch-article", "article-1-gallery-of-success-stories");
  }

  render() {
    return <div className={`gallery-container`}>
      <Helmet>
        <title>Custom Cut Mat Board for IKEA Frames • Custom Mat Board • US & Canada </title>
        <meta name="description" content="We cut custom mat boards for your Amazon & IKEA frames. Picture frames look professional with customized mat board."/>
      </Helmet>

      <ArticleFetcher article={this.state}/>

    </div>
  }
}