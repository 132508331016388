import React from "react";
import capture1 from "../../../resources/images/howItWorksPage/capture1.PNG";
import capture2 from "../../../resources/images/howItWorksPage/capture2.PNG";
import capture3 from "../../../resources/images/howItWorksPage/capture3.PNG";
import capture4 from "../../../resources/images/howItWorksPage/capture4.PNG";

export default class MeasuringYourMat extends React.Component {

    render() {
        return <div className="landing-page-container">

            <div className="product-offering-header">How to measure your your matboard</div>

            <div className={"how-to-measure-video-section"}>
                {/*<img src={sizingGuidePhoto} className={"matboard-size-selection-demo-photo"}/>*/}
                <iframe width="100%" height="100%" src="https://www.youtube.com/embed/t-YQxHYTkUQ" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen></iframe>
            </div>

            <div className={"how-it-works-leader-container"}>
                Please refer to our explainer video.
            </div>

            <div className={"how-it-works-description-section"}>
                <div className="product-offering-header">Step 1: How to measure the mat size</div>
                <div className="how-it-works-box">
                    <div className={"how-it-works-box-left"}>
                        <picture>
                            <source type="image/webp" srcSet={capture1}/>
                            <source type="image/png" srcSet={capture1}/>
                            <img className="how-it-works-image-file-large" src={capture1}
                                 alt={"This is an illustrative diagram of how a ready made frame can look perfect with a custom cut mat boards."}/>
                        </picture>
                    </div>
                    <div className={"how-it-works-box-right"} >
                        <b>If you bought a retail frame,</b> you should have the measurements you need on the packaging.
                        This is the size of the matboard you need.<br/><br/>

                        The most common dimensions are 8x10, 9x9, 11x14, 12x16, 16x20, 18x24, 19.75x19.75, 20x20, 19.75x27.5, 20x28, 24x35.75, and 24x36 (inches).<br/><br/>

                        For example, for a 16x20 inch frame, our 16x20 inch matboard will fit in perfectly. Therefore, your matboard size should be 16x20 inch.

                    </div>
                </div>
            </div>

            <div className={"how-it-works-description-section"}>
                <div className="how-it-works-box">
                    <div className={"how-it-works-box-left"}>
                        <picture>
                            <source type="image/webp" srcSet={capture2}/>
                            <source type="image/png" srcSet={capture2}/>
                            <img className="how-it-works-image-file-large" src={capture2}
                                 alt={"This is an illustrative diagram of how a ready made frame can look perfect with a custom cut mat boards."}/>
                        </picture>
                    </div>
                    <div className={"how-it-works-box-right"} >
                        <b>If you don't have any information</b> or you want to confirm your measurements, please <b>flip</b> the frame over and measure the <b>height and width of the backing board.</b><br/><br/>
                        This is the size of the matboard you need. Your matboard should have a same size as your backing board.
                    </div>
                </div>
            </div>



            <div className={"how-it-works-description-section"}>
                <div className="product-offering-header">Step 2: How to measure your artwork windows</div>
                <div className="how-it-works-box">
                    <div className={"how-it-works-box-left"}>
                        <picture>
                            <source type="image/webp" srcSet={capture3}/>
                            <source type="image/png" srcSet={capture3}/>
                            <img className="how-it-works-image-file-large" src={capture3}
                                 alt={"This is an illustrative diagram of how a ready made frame can look perfect with a custom cut mat boards."}/>
                        </picture>
                    </div>
                    <div className={"how-it-works-box-right"} >
                        The <b style={{color: "#2A4EBF"}}>artwork window</b> is an opening cut in the mat that exposes the art below.<br/><br/>
                        The <b style={{color: "#2A4EBF"}}>window</b> must be <b>smaller</b> than your <b style={{color: "#E60023"}}>artwork</b> so the opening hides the edges of the work.<br/><br/>
                    </div>
                </div>
            </div>

            <div className={"how-it-works-description-section"}>
                <div className="how-it-works-box">
                    <div className={"how-it-works-box-left"}>
                        <picture>
                            <source type="image/webp" srcSet={capture4}/>
                            <source type="image/png" srcSet={capture4}/>
                            <img className="how-it-works-image-file-large" src={capture4}
                                 alt={"This is an illustrative diagram of how a ready made frame can look perfect with a custom cut mat boards."}/>
                        </picture>
                    </div>
                    <div className={"how-it-works-box-right"} >
                        To measure the artwork window size, measure the length and width of your art and then <b>substract</b> a quarter of an inch from each measurement. <br/><br/>
                        Example - <b style={{color: "#E60023"}}>5.0 x 7.0"</b> artwork minus 0.25 on the length and width = <b style={{color: "#2A4EBF"}}>4.75 x 6.75"</b> window.<br/><br/>
                        It is <b>important</b> to note that your <b style={{color: "#2A4EBF"}}>window</b> must be <b>smaller</b> than your <b style={{color: "#E60023"}}>artwork.</b><br/><br/><br/><br/>
                        Have any questions?<br/> Please send us an email to hello@custommat.ca or call us at 226-828-1705
                    </div>
                </div>

            </div>



            <a href="/customize-matboards">
                <div className="product-listing-action-button">Start Designing</div>
            </a>

        </div>
    }
}