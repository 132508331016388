import React from "react";
import {Helmet} from "react-helmet";
import IGLogo from "../../../resources/logo/IG-Logo.svg";

import allisonrietta from "../../../resources/images/inspirationsPhoto/allisonrietta-IG.PNG";
import watercolorsmakemesmile from "../../../resources/images/inspirationsPhoto/watercolorsmakemesmile-IG.PNG";
import allisonrietta2 from "../../../resources/images/inspirationsPhoto/allisonrietta-IG-2.PNG";
import allisonrietta3 from "../../../resources/images/inspirationsPhoto/allisonrietta-IG-3.PNG";
import thehomealbum from "../../../resources/images/inspirationsPhoto/thehomealbum-IG.PNG"
import melissamaryjenkinsart from "../../../resources/images/inspirationsPhoto/melissamaryjenkinsart-IG.PNG"
import lifeInTheOrchard from "../../../resources/images/inspirationsPhoto/life.in.the.orchard-IG.PNG";
import kendrafoundit2 from "../../../resources/images/inspirationsPhoto/kendrafoundit-ig-2.PNG";
import modernbohomom1 from "../../../resources/images/inspirationsPhoto/modernbohomom-IG.PNG";
import watercolorsmakemesmile1 from "../../../resources/images/inspirationsPhoto/watercolorsmakemesmile-IG-2.PNG";
import nestingdesign1 from "../../../resources/images/inspirationsPhoto/nestingdesign-IG.PNG";
import emilyKLewis from "../../../resources/images/inspirationsPhoto/emily.k.lewis-IG.PNG";
import nestingdesign2 from "../../../resources/images/inspirationsPhoto/nestingdesign-IG-2.PNG";
import jules_interiors1 from "../../../resources/images/inspirationsPhoto/jules_interiors-IG.PNG";
import nestingdesign3 from "../../../resources/images/inspirationsPhoto/nestingdesign-IG-3.PNG";
import jules_interiors2 from "../../../resources/images/inspirationsPhoto/jules_interiors-IG-2.PNG";
import jules_interiors3 from "../../../resources/images/inspirationsPhoto/jules_interiors-IG-3.PNG";
import thelittleYellowhouse1 from "../../../resources/images/inspirationsPhoto/thelittle.yellowhouse-IG.PNG";
import stephaniealoe from "../../../resources/images/inspirationsPhoto/stephaniealoe-IG.PNG";
import thelittleYellowhouse2 from "../../../resources/images/inspirationsPhoto/thelittle.yellowhouse-IG-2.PNG";
import modernbohomom2 from "../../../resources/images/inspirationsPhoto/modernbohomom-IG-2.PNG";
import modernbohomom3 from "../../../resources/images/inspirationsPhoto/modernbohomom-IG-3.PNG";
import chrisTeasPhotography from "../../../resources/images/inspirationsPhoto/chris.teas_photography-IG.PNG";
import watercolorsmakemesmile3 from "../../../resources/images/inspirationsPhoto/watercolorsmakemesmile-IG-3.PNG";
import jenniferkoper from "../../../resources/images/inspirationsPhoto/jenniferkoper-IG.PNG";
import lizanavPhoto from "../../../resources/images/inspirationsPhoto/lizanav.photo-IG.PNG";
import keemaJaymes1 from "../../../resources/images/inspirationsPhoto/keema_jaymes-IG.PNG";

import kristinadapaah1 from "../../../resources/images/inspirationsPhoto/kristinadapaah-IG.PNG";
import kristinadapaah2 from "../../../resources/images/inspirationsPhoto/kristinadapaah-IG-2.PNG";
import kristinadapaah3 from "../../../resources/images/inspirationsPhoto/kristinadapaah-IG-3.PNG";
import jules_interiors4 from "../../../resources/images/inspirationsPhoto/jules_interiors-IG-4.PNG";
import kendrafountit3 from "../../../resources/images/inspirationsPhoto/kendrafoundit-IG-3.PNG";
import keemaJaymes2 from "../../../resources/images/inspirationsPhoto/keema_jaymes-IG-2.PNG";
import kendrafoundit4 from "../../../resources/images/inspirationsPhoto/kendrafoundit-IG-4.PNG";
import inspirationHomeMtl from "../../../resources/images/inspirationsPhoto/inspiration.home.mtl-IG.PNG";
import letsTalkAwesome1 from "../../../resources/images/inspirationsPhoto/lets_talk_awesome-G.PNG";
import kendrafoundit5 from "../../../resources/images/inspirationsPhoto/kendrafoundit-IG-5.PNG";
import the78boler from "../../../resources/images/inspirationsPhoto/the78boler-IG-1.PNG";
import homeinoak from "../../../resources/images/inspirationsPhoto/homeinoak-IG.PNG";
import kendrafoundit6 from "../../../resources/images/inspirationsPhoto/kendrafoundit-IG-6.PNG";
import homeMadeLovely3 from "../../../resources/images/inspirationsPhoto/home.made.lovely-IG-3.PNG";
import homeMadeLovely4 from "../../../resources/images/inspirationsPhoto/home.made.lovely-IG-4.PNG";
import megboulerice from "../../../resources/images/inspirationsPhoto/megboulerice-IG.PNG";
import homeMadeLovely5 from "../../../resources/images/inspirationsPhoto/home.made.lovely-IG-5.PNG";
import kmerrilyhome from "../../../resources/images/inspirationsPhoto/kmerrilyhome-IG.PNG";
import alexaSeesInColour1 from "../../../resources/images/inspirationsPhoto/alexa__sees__in__colour-IG.PNG";
import lyssasky1 from "../../../resources/images/inspirationsPhoto/lyssasky-IG.PNG";
import julesInteriors5 from "../../../resources/images/inspirationsPhoto/jules_interiors-IG-5.PNG";
import thegrguide from "../../../resources/images/inspirationsPhoto/thegrguide-IG.PNG";
import the78boler2 from "../../../resources/images/inspirationsPhoto/the78boler-IG-2.PNG";
import julesondesign from "../../../resources/images/inspirationsPhoto/julesondesign-IG.PNG";
import kendrafoundit7 from "../../../resources/images/inspirationsPhoto/kendrafoundit-IG-5.PNG";
import astateofreverie from "../../../resources/images/inspirationsPhoto/astateofreverie-IG.PNG";
import thegrguide2 from "../../../resources/images/inspirationsPhoto/thegrguide-IG-2.PNG";
import westOfCedarhill from "../../../resources/images/inspirationsPhoto/west_of_cedarhill-IG.PNG";
import kendrafoundit8 from "../../../resources/images/inspirationsPhoto/kendrafoundit-IG-8.PNG";
import rachaelDesign1 from "../../../resources/images/inspirationsPhoto/rachael.design-IG-1.PNG";
import kendrafoundit9 from "../../../resources/images/inspirationsPhoto/kendrafoundit-9-IG.PNG"
import letsTalkAwesome2 from "../../../resources/images/inspirationsPhoto/lets_talk_awesome-IG-2.PNG";
import kendrafoundit10 from "../../../resources/images/inspirationsPhoto/kendrafoundit-IG-10.PNG";
import zolmerdesignco from "../../../resources/images/inspirationsPhoto/zolmerdesignco-IG.PNG";
import kendrafoundit11 from "../../../resources/images/inspirationsPhoto/kendrafoundit-IG-11.PNG";
import alexaSeesInColour3 from "../../../resources/images/inspirationsPhoto/alexa__sees__in__colour-IG-3.PNG";
import alexaSeesInColour4 from "../../../resources/images/inspirationsPhoto/alexa__sees__in__colour-IG-4.PNG";
import jessFindlay1 from "../../../resources/images/inspirationsPhoto/jess.findlay_-IG-1.PNG";
import skg_613 from "../../../resources/images/inspirationsPhoto/skg_613-IG.PNG";
import lyssasky2 from "../../../resources/images/inspirationsPhoto/lyssasky-IG-2.PNG";
import helloimlaurie1 from "../../../resources/images/inspirationsPhoto/helloimlaurie-IG-1.PNG";
import helloimlaurie2 from "../../../resources/images/inspirationsPhoto/helloimlaurie-IG2.PNG";
import brittandcointeriors from "../../../resources/images/inspirationsPhoto/brittandcointeriors-IG.PNG";
import madelaineLaine1 from "../../../resources/images/inspirationsPhoto/madelaine_laine-IG.PNG";
import madelaineLaine2 from "../../../resources/images/inspirationsPhoto/madelaine_laine-IG-2.PNG";
import somethingTurquoise from "../../../resources/images/inspirationsPhoto/something_turquoise-IG.PNG";
import lonicarroll from "../../../resources/images/inspirationsPhoto/lonicarroll-IG.PNG";

// import AlitaM from "../../../resources/images/blog/etsyCustomerPhotos/Alita M..webp";
// import AndreaS from "../../../resources/images/blog/etsyCustomerPhotos/Andrea S.webp";
// import AndrewC from "../../../resources/images/blog/etsyCustomerPhotos/Andrew C..webp";
// import AngelaM from "../../../resources/images/blog/etsyCustomerPhotos/Angela M.webp";
// import AnnaW from "../../../resources/images/blog/etsyCustomerPhotos/Anna W.webp";
// import AubreyC from "../../../resources/images/blog/etsyCustomerPhotos/Aubrey C.webp";
// import Carol from "../../../resources/images/blog/etsyCustomerPhotos/Carol.webp";
// import CarolineD from "../../../resources/images/blog/etsyCustomerPhotos/Caroline D.webp";
// import Cat from "../../../resources/images/blog/etsyCustomerPhotos/Cat.webp";
// import ChrissyM from "../../../resources/images/blog/etsyCustomerPhotos/Chrissy M.webp";
// import Donna from "../../../resources/images/blog/etsyCustomerPhotos/Donna.webp";
// import Dustin from "../../../resources/images/blog/etsyCustomerPhotos/Dustin.webp";
// import Heather from "../../../resources/images/blog/etsyCustomerPhotos/Heather.webp";
// import HeatherC from "../../../resources/images/blog/etsyCustomerPhotos/Heather C..webp";
// import Ingrid from "../../../resources/images/blog/etsyCustomerPhotos/Ingrid.webp";
// import Irene from "../../../resources/images/blog/etsyCustomerPhotos/Irene.webp";
// import JanM2 from "../../../resources/images/blog/etsyCustomerPhotos/Jan M 2.webp";
// import JanM from "../../../resources/images/blog/etsyCustomerPhotos/Jan M.webp";
// import JeanR from "../../../resources/images/blog/etsyCustomerPhotos/Jean R.webp";
// import JenniferB2 from "../../../resources/images/blog/etsyCustomerPhotos/Jennifer B 2.webp";
// import JeanniferB from "../../../resources/images/blog/etsyCustomerPhotos/Jennifer B.webp";



export default class Inspiration extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            french: false,
            photos: [
                {
                    path: allisonrietta,
                    externalSource: true,
                    source: {
                        photoSource: "ig",
                        igHandle: "allisonrietta",
                        igPath: "https://www.instagram.com/p/CQyaHpRtEPi/",
                    }
                }, {
                    path: watercolorsmakemesmile,
                    externalSource: true,
                    source: {
                        photoSource: "ig",
                        igHandle: "watercolorsmakemesmile",
                        igPath: "https://www.instagram.com/p/CQwA3tqJpbT/",
                    }
                }, {
                    path: allisonrietta2,
                    externalSource: true,
                    source: {
                        photoSource: "allisonrietta",
                        igHandle: "allisonrietta",
                        igPath: "https://www.instagram.com/p/CQt1f7WtlCy/",
                    }
                }, {
                    path: allisonrietta3,
                    externalSource: true,
                    source: {
                        photoSource: "ig",
                        igHandle: "allisonrietta",
                        igPath: "https://www.instagram.com/p/CQgXt4ghMA8/",
                    }
                }, {
                    path: thehomealbum,
                    externalSource: true,
                    source: {
                        photoSource: "ig",
                        igHandle: "thehomealbum",
                        igPath: "https://www.instagram.com/p/CQMolgsn04B/",
                    }
                },  {
                    path: melissamaryjenkinsart,
                    externalSource: true,
                    source: {
                        photoSource: "ig",
                        igHandle: "melissamaryjenkinsart",
                        igPath: "https://www.instagram.com/p/CQLlkyDnfdw/",
                    }
                }, {
                    path: lifeInTheOrchard,
                    externalSource: true,
                    source: {
                        photoSource: "ig",
                        igHandle: "life.in.the.orchard",
                        igPath: "https://www.instagram.com/p/CP_6gz5phac/",
                    }
                },
                {
                    path: kendrafoundit2,
                    externalSource: true,
                    source: {
                        photoSource: "ig",
                        igHandle: "kendrafoundit",
                        igPath: "https://www.instagram.com/p/CP1psmUsicr/",
                    }
                }, {
                    path: modernbohomom1,
                    externalSource: true,
                    source: {
                        photoSource: "ig",
                        igHandle: "modernbohomom",
                        igPath: "https://www.instagram.com/p/CPs2ouDjqPK/",
                    }
                }, {
                    path: watercolorsmakemesmile1,
                    externalSource: true,
                    source: {
                        photoSource: "ig",
                        igHandle: "watercolorsmakemesmile",
                        igPath: "https://www.instagram.com/p/CPf-bJbnYHj/",
                    }
                }, {
                    path: nestingdesign1,
                    externalSource: true,
                    source: {
                        photoSource: "ig",
                        igHandle: "nestingdesign",
                        igPath: "https://www.instagram.com/p/CPV_VKAsOvC/",
                    }
                },{
                    path: emilyKLewis,
                    externalSource: true,
                    source: {
                        photoSource: "ig",
                        igHandle: "emily.k.lewis",
                        igPath: "https://www.instagram.com/p/CPUYPJOA0PK/",
                    }
                }, {
                    path: nestingdesign2,
                    externalSource: true,
                    source: {
                        photoSource: "ig",
                        igHandle: "nestingdesign",
                        igPath: "https://www.instagram.com/p/CO6KmxrswhE/",
                    }
                }, {
                    path: jules_interiors1,
                    externalSource: true,
                    source: {
                        photoSource: "ig",
                        igHandle: "jules_interiors",
                        igPath: "https://www.instagram.com/p/CPKkQSthFNE/",
                    }
                }, {
                    path: nestingdesign3,
                    externalSource: true,
                    source: {
                        photoSource: "ig",
                        igHandle: "nestingdesign",
                        igPath: "https://www.instagram.com/p/CPDyfPqMrvD/",
                    }
                },
                {
                    path: jules_interiors2,
                    externalSource: true,
                    source: {
                        photoSource: "ig",
                        igHandle: "jules_interiors",
                        igPath: "https://www.instagram.com/p/CO-cqaxBdWb/",
                    }
                }, {
                    path: jules_interiors3,
                    externalSource: true,
                    source: {
                        photoSource: "ig",
                        igHandle: "jules_interiors",
                        igPath: "https://www.instagram.com/p/CO53edPB3jy/",
                    }
                }, {
                    path: thelittleYellowhouse1,
                    externalSource: true,
                    source: {
                        photoSource: "ig",
                        igHandle: "thelittle.yellowhouse",
                        igPath: "https://www.instagram.com/p/CO31RnGJ43G/",
                    }
                }, {
                    path: stephaniealoe,
                    externalSource: true,
                    source: {
                        photoSource: "ig",
                        igHandle: "stephaniealoe",
                        igPath: "https://www.instagram.com/p/COykdLwArnW/",
                    }
                }, {
                    path: thelittleYellowhouse2,
                    externalSource: true,
                    source: {
                        photoSource: "ig",
                        igHandle: "thelittle.yellowhouse",
                        igPath: "https://www.instagram.com/p/COlj2krpXoU/",
                    }
                }, {
                    path: modernbohomom2,
                    externalSource: true,
                    source: {
                        photoSource: "ig",
                        igHandle: "modernbohomom",
                        igPath: "https://www.instagram.com/p/COOK2J4DQSY/",
                    }
                }, {
                    path: modernbohomom3,
                    externalSource: true,
                    source: {
                        photoSource: "ig",
                        igHandle: "modernbohomom",
                        igPath: "https://www.instagram.com/p/CN-tgVjht93/",
                    }
                },
                {
                    path: chrisTeasPhotography,
                    externalSource: true,
                    source: {
                        photoSource: "ig",
                        igHandle: "chris.teas_photography",
                        igPath: "https://www.instagram.com/p/CN8oh8Hj1WO/",
                    }
                }, {
                    path: watercolorsmakemesmile3,
                    externalSource: true,
                    source: {
                        photoSource: "ig",
                        igHandle: "watercolorsmakemesmile",
                        igPath: "https://www.instagram.com/p/CNsQzM4n8bI/",
                    }
                }, {
                    path: jenniferkoper,
                    externalSource: true,
                    source: {
                        photoSource: "ig",
                        igHandle: "jenniferkoper",
                        igPath: "https://www.instagram.com/p/CNjBVF7Mv6R/",
                    }
                }, {
                    path: lizanavPhoto,
                    externalSource: true,
                    source: {
                        photoSource: "ig",
                        igHandle: "lizanav.photo",
                        igPath: "https://www.instagram.com/p/CNiaRIODJVk/",
                    }
                },{
                    path: keemaJaymes1,
                    externalSource: true,
                    source: {
                        photoSource: "ig",
                        igHandle: "keema_jaymes",
                        igPath: "https://www.instagram.com/p/CNg0tmFlWBm/",
                    }
                }, {
                    path: kristinadapaah1,
                    externalSource: true,
                    source: {
                        photoSource: "ig",
                        igHandle: "kristinadapaah",
                        igPath: "https://www.instagram.com/p/CNVhgwDFuCf/",
                    }
                },{
                    path: kristinadapaah2,
                    externalSource: true,
                    source: {
                        photoSource: "ig",
                        igHandle: "kristinadapaah",
                        igPath: "https://www.instagram.com/p/CNVhgwDFuCf/",
                    }
                },{
                    path: kristinadapaah3,
                    externalSource: true,
                    source: {
                        photoSource: "ig",
                        igHandle: "kristinadapaah",
                        igPath: "https://www.instagram.com/p/CNVhgwDFuCf/",
                    }
                },
                {
                    path: jules_interiors4,
                    externalSource: true,
                    source: {
                        photoSource: "ig",
                        igHandle: "jules_interiors",
                        igPath: "https://www.instagram.com/p/CNDOQH4B3JA/",
                    }
                },{
                    path: kendrafountit3,
                    externalSource: true,
                    source: {
                        photoSource: "ig",
                        igHandle: "kendrafountit",
                        igPath: "https://www.instagram.com/p/CMuZrjeLzC3/",
                    }
                }, {
                    path: keemaJaymes2,
                    externalSource: true,
                    source: {
                        photoSource: "ig",
                        igHandle: "keemaJaymes",
                        igPath: "https://www.instagram.com/p/CMnyz1VBvhW/",
                    }
                }, {
                    path: kendrafoundit4,
                    externalSource: true,
                    source: {
                        photoSource: "ig",
                        igHandle: "kendrafoundit",
                        igPath: "https://www.instagram.com/p/CMVjycvLY8Q/",
                    }
                }, {
                    path: inspirationHomeMtl,
                    externalSource: true,
                    source: {
                        photoSource: "ig",
                        igHandle: "inspiration.home.mtl",
                        igPath: "https://www.instagram.com/p/CL4HLOuHbS6/",
                    }
                }, {
                    path: letsTalkAwesome1,
                    externalSource: true,
                    source: {
                        photoSource: "ig",
                        igHandle: "letsTalkAwesome1",
                        igPath: "https://www.instagram.com/p/CL2BbqmBqe-/",
                    }
                }, {
                    path: kendrafoundit5,
                    externalSource: true,
                    source: {
                        photoSource: "ig",
                        igHandle: "kendrafoundit",
                        igPath: "https://www.instagram.com/p/CLmaUeelkYo/",
                    }
                },{
                    path: homeMadeLovely3,
                    externalSource: true,
                    source: {
                        photoSource: "ig",
                        igHandle: "home.made.lovely",
                        igPath: "https://www.instagram.com/p/CLmNy8PJZ8K/",
                    }
                },
                {
                    path: the78boler,
                    externalSource: true,
                    source: {
                        photoSource: "ig",
                        igHandle: "the78boler",
                        igPath: "https://www.instagram.com/p/CLVPkzohFdr/",
                    }
                }, {
                    path: homeinoak,
                    externalSource: true,
                    source: {
                        photoSource: "ig",
                        igHandle: "homeinoak",
                        igPath: "https://www.instagram.com/p/CLUkiL2BAgP/",
                    }
                }, {
                    path: kendrafoundit6,
                    externalSource: true,
                    source: {
                        photoSource: "ig",
                        igHandle: "kendrafoundit",
                        igPath: "https://www.instagram.com/p/CLPmXb8MVJO/",
                    }
                }, {
                    path: homeMadeLovely3,
                    externalSource: true,
                    source: {
                        photoSource: "ig",
                        igHandle: "home.made.lovely",
                        igPath: "https://www.instagram.com/p/CLMaEcBJQTq/",
                    }
                },{
                    path: homeMadeLovely4,
                    externalSource: true,
                    source: {
                        photoSource: "ig",
                        igHandle: "home.made.lovely",
                        igPath: "https://www.instagram.com/p/CK7Sip6JMfv/",
                    }
                }, {
                    path: megboulerice,
                    externalSource: true,
                    source: {
                        photoSource: "ig",
                        igHandle: "megboulerice",
                        igPath: "https://www.instagram.com/p/CK6u94Qjr6C/",
                    }
                }, {
                    path: homeMadeLovely5,
                    externalSource: true,
                    source: {
                        photoSource: "ig",
                        igHandle: "home.made.lovely",
                        igPath: "https://www.instagram.com/p/CKwRrB3p8Cw/",
                    }
                }, {
                    path: kmerrilyhome,
                    externalSource: true,
                    source: {
                        photoSource: "ig",
                        igHandle: "kmerrilyhome",
                        igPath: "https://www.instagram.com/p/CKnHSlNgTY0/",
                    }
                }, {
                    path: alexaSeesInColour1,
                    externalSource: true,
                    source: {
                        photoSource: "ig",
                        igHandle: "alexa__sees__in__colour",
                        igPath: "https://www.instagram.com/p/CKVfVQ7Muld/",
                    }
                },
                {
                    path: lyssasky1,
                    externalSource: true,
                    source: {
                        photoSource: "ig",
                        igHandle: "lyssasky",
                        igPath: "https://www.instagram.com/p/CKMZ6x0HM7T/",
                    }
                },{
                    path: julesInteriors5,
                    externalSource: true,
                    source: {
                        photoSource: "ig",
                        igHandle: "jules_interiors",
                        igPath: "https://www.instagram.com/p/CKGAPykhxaf/",
                    }
                },{
                    path: thegrguide,
                    externalSource: true,
                    source: {
                        photoSource: "ig",
                        igHandle: "thegrguide",
                        igPath: "https://www.instagram.com/p/CJ1BhZnBsxr/",
                    }
                },{
                    path: the78boler2,
                    externalSource: true,
                    source: {
                        photoSource: "ig",
                        igHandle: "the78boler",
                        igPath: "https://www.instagram.com/p/CJwVIZmhTE8/",
                    }
                },{
                    path: julesondesign,
                    externalSource: true,
                    source: {
                        photoSource: "ig",
                        igHandle: "julesondesign",
                        igPath: "https://www.instagram.com/p/CJbX9LoHPN-/",
                    }
                },{
                    path: kendrafoundit7,
                    externalSource: true,
                    source: {
                        photoSource: "ig",
                        igHandle: "kendrafoundit",
                        igPath: "https://www.instagram.com/p/CI9bE8kIJfZ/",
                    }
                },{
                    path: astateofreverie,
                    externalSource: true,
                    source: {
                        photoSource: "ig",
                        igHandle: "astateofreverie",
                        igPath: "https://www.instagram.com/p/CHvbaEjApSD/",
                    }
                },{
                    path: thegrguide2,
                    externalSource: true,
                    source: {
                        photoSource: "ig",
                        igHandle: "thegrguide",
                        igPath: "https://www.instagram.com/p/CIgoAAlBxrZ/",
                    }
                },{
                    path: westOfCedarhill,
                    externalSource: true,
                    source: {
                        photoSource: "ig",
                        igHandle: "west_of_cedarhill",
                        igPath: "https://www.instagram.com/p/CIdpdtuFBDz/",
                    }
                },
                {
                    path: kendrafoundit8,
                    externalSource: true,
                    source: {
                        photoSource: "ig",
                        igHandle: "kendrafoundit",
                        igPath: "https://www.instagram.com/p/CITT3dHj7-W/",
                    }
                },{
                    path: rachaelDesign1,
                    externalSource: true,
                    source: {
                        photoSource: "ig",
                        igHandle: "rachael.design",
                        igPath: "https://www.instagram.com/p/CIBgr1LBIr7/",
                    }
                }, {
                    path: kendrafoundit9,
                    externalSource: true,
                    source: {
                        photoSource: "ig",
                        igHandle: "kendrafoundit",
                        igPath: "https://www.instagram.com/p/CIE2NNeIAcL/",
                    }
                }, {path: letsTalkAwesome2, externalSource: true, source: {
                        photoSource: "ig",
                        igHandle: "lets_talk_awesome",
                        igPath: "https://www.instagram.com/p/CIBBZqbhpjD/",
                    }}, {
                    path: kendrafoundit10,
                    externalSource: true,
                    source: {
                        photoSource: "ig",
                        igHandle: "kendrafoundit",
                        igPath: "https://www.instagram.com/p/CHQBtkAnbIa/",
                    }
                },{
                    path: zolmerdesignco,
                    externalSource: true,
                    source: {
                        photoSource: "ig",
                        igHandle: "zolmerdesignco",
                        igPath: "https://www.instagram.com/p/CHBhUc_hNSs/",
                    }
                }, {
                    path: kendrafoundit11,
                    externalSource: true,
                    source: {
                        photoSource: "ig",
                        igHandle: "kendrafoundit",
                        igPath: "https://www.instagram.com/p/CG1DgAsKJ5j/",
                    }
                },  {
                    path: alexaSeesInColour3,
                    externalSource: true,
                    source: {
                        photoSource: "ig",
                        igHandle: "alexa__sees__in__colour",
                        igPath: "https://www.instagram.com/p/CF2K37ph6V3/",
                    }
                }, {
                    path: alexaSeesInColour4,
                    externalSource: true,
                    source: {
                        photoSource: "ig",
                        igHandle: "alexa__sees__in__colour",
                        igPath: "https://www.instagram.com/p/CFDKTrMhX_Q/",
                    }
                },{
                    path: jessFindlay1,
                    externalSource: true,
                    source: {
                        photoSource: "ig",
                        igHandle: "jess.findlay_",
                        igPath: "https://www.instagram.com/p/CEj9dRZlQiO/",
                    }
                },{
                    path: skg_613,
                    externalSource: true,
                    source: {
                        photoSource: "ig",
                        igHandle: "skg_613",
                        igPath: "https://www.instagram.com/p/CEK4yrwpKZq/",
                    }
                },{
                    path: lyssasky2,
                    externalSource: true,
                    source: {
                        photoSource: "ig",
                        igHandle: "lyssasky",
                        igPath: "https://www.instagram.com/p/CDy6DCCJk-B/",
                    }
                },{
                    path: helloimlaurie1,
                    externalSource: true,
                    source: {
                        photoSource: "ig",
                        igHandle: "helloimlaurie",
                        igPath: "https://www.instagram.com/p/CDmgfmkAaoH/",
                    }
                },{
                    path: helloimlaurie2,
                    externalSource: true,
                    source: {
                        photoSource: "ig",
                        igHandle: "helloimlaurie",
                        igPath: "https://www.instagram.com/p/CDmgfmkAaoH/",
                    }
                },
                {
                    path: brittandcointeriors,
                    externalSource: true,
                    source: {
                        photoSource: "ig",
                        igHandle: "brittandcointeriors",
                        igPath: "https://www.instagram.com/p/CC4CMRVpQrW/",
                    }
                },{
                    path: madelaineLaine1,
                    externalSource: true,
                    source: {
                        photoSource: "ig",
                        igHandle: "madelaine_laine",
                        igPath: "https://www.instagram.com/p/CBegb1lAVJM/",
                    }
                },{
                    path: madelaineLaine2,
                    externalSource: true,
                    source: {
                        photoSource: "ig",
                        igHandle: "madelaine_laine",
                        igPath: "https://www.instagram.com/p/CBegb1lAVJM/",
                    }
                },
                {
                    path: somethingTurquoise,
                    externalSource: true,
                    source: {
                        photoSource: "ig",
                        igHandle: "something_turquoise",
                        igPath: "https://www.instagram.com/p/CBlvEgKnpfN/",
                    }
                },
                {
                    path: lonicarroll,
                    externalSource: true,
                    source: {
                        photoSource: "ig",
                        igHandle: "lonicarroll",
                        igPath: "https://www.instagram.com/p/B6vCYiLF9-9/",
                    }
                },



            ],
            fetchIndex: 9,
            totalPhotos: 75,
        };

    }

    componentDidMount() {
        this.setState({
            french: (window.location.pathname.slice(-2) === "fr") ? true : false
        })
    };


    fetchInspirationPhotos() {

        return this.state.photos.map((item, key) => {

            if (key < this.state.fetchIndex) {
                return <div className="inspiration-posts-col">
                    <img className={"inspiration-post-col-photo"} id={"inspiration-post-col-photo-main-page"} alt={
                        this.state.french ? "Photos réelles de nos clients." : "Actual photos from our customers."
                    } src={item.path}/>
                    <div className={"inspiration-post"}>
                        <div className={"inspiration-logo-name"}>
                            @{item.source.igHandle}
                        </div>
                        <div className={"inspiration-logo-IG-POST-container"}>
                            <a href={item.source.igPath}>
                                <img className={"inspiration-logo-IG-POST"} src={IGLogo} alt={this.state.french ? "Cliquez ici pour visiter la page Instagram de la publication.\n" : "Click here to visit the Instagram page for the post."}/>
                            </a>

                        </div>
                    </div>
                </div>
            }

        })
    }


    render() {
        return <div>
            <Helmet>
                <title>
                    {this.state.french ? "PERSONNALISEZ LE PASSE-PARTOUT • Livraison gratuite au Canada • Livraison gratuite au Québec •  Pas de quantité minimum de commande" :
                        "Custom Matboards • Photo Mats • Picture Mats • Matboard Supplies • Free Shipping in Canada • No Minimum Order Quantity "}
                </title>
                <meta name="description" content={
                    this.state.french ? "Personnalisez vos passe-partout en ligne. Nous vous les expédions sous 48h. Pas de quantité minimum de commande. Des dizaines d'options de couleurs. Livraison gratuite au Canada et aux États-Unis à partir de 49 $." :
                        "Customize your matboards online. We ship them to you within 48 hours. No minimum order quantity. Dozens of colour options. Free shipping to Canada and the USA over $49."
                } />
            </Helmet>

            <div className={"inspiration-page-container"}>
                <a href={this.state.french ? "/inspiration-fr" : "/inspiration"}>
                    <div className={"inspiration-listing-header"}>
                        {this.state.french ? "TROUVEZ DE L’INSPIRATION" : "Find your inspiration"}
                    </div>
                </a>

                <div className={"inspiration-listing-desc"}>
                    {this.state.french ? "Découvrez ce que nous pouvons faire pour vous" : "SEE WHAT WE CAN DO FOR YOU"}
                </div>

                <div className={"inspiration-posts"}>
                    {this.fetchInspirationPhotos()}
                </div>

                <div className={ this.state.totalPhotos > this.state.fetchIndex ? "inspiration-load-more-button" : "no-display"} onClick={() => {this.setState({fetchIndex: this.state.fetchIndex + 10})}}>
                    {this.state.french ? "Charger Plus" : "Load More"}
                </div>

            </div>

            <div className={"landing-page-review-transition-ending-v2"}>
                <div className={"landing-page-review-transition-ending-header-v2"}>
                    {this.state.french ? "FAITES QUE VOS SOUVENIRS PARAISSENT AUSSI BIEN QUE CE QU’ILS VOUS RAPPELLENT" : "MAKE MEMORIES LOOK AS GOOD AS THEY FEEL"}

                </div>
                <a href={this.state.french ? "/customize-matboards-fr" : "/customize-matboards"}>
                    <div className={"landing-page-review-transition-ending-button-v2"}>
                        {this.state.french ? "Je crée" : "Start your custom mat"}

                    </div>
                </a>

            </div>

        </div>
    }
}