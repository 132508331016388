import React from "react";
import {Helmet} from "react-helmet";

export default class HowToMeasureAMatboard extends React.Component {

    constructor() {
        super();
        this.state = {
        };
    };
    componentDidMount() {
        this.setState({
            french: window.location.pathname.slice(-2) === "fr" ? true : false
        })
    };

    toggleModal(stateToToggle) {
        this.setState({[stateToToggle]: !this.state[stateToToggle]})
    }

    fetchResponse(fr, en) {
        return this.state.french ? `${fr}` : `${en}`
    }

    render() {
        return <div className={`blog-article-content`}>

            <Helmet>
                <title>CustomMat - Matboard Shop - How do I know what size of matboard I need?</title>
                <meta name="description" content="We are one of the biggest art suppliers specializing in matboards and picture framing supplies. We specialize in picture frame matboards."/>
            </Helmet>

            <div className={"faq-question"}>
                <div className={"faq-question-right"}>
                    How do I know what size of matboard I need?
                </div>
            </div>


            <div className={"faq-answer"}>
                <div className={"faq-answer-paragraph"}>
                    Frames are sized based on the print sizes that they hold.
                </div>

                <div className={"visit-custom-mat-button"}>
                    <a href={"/customize-matboards"} className={"carousel-read-more-button"}>
                        Customize Matboards
                    </a>
                </div>
                <div className={"faq-answer-paragraph"}>
                    <b>Quantity Discounts</b>

                    If you are interested in quantity orders, please check out this page -- <a href={"/pricing-and-discounts"}>Discount Info</a>.

                    We do provide you with a large quantity discount starting at 20% off on 2+ orders, 30% off on 4+ orders. and 40% off on 10+ orders.

                    For larger order, please contact us at hello@CustomMat.ca
                </div>
                <div className={"faq-answer-paragraph"}>
                    We started our business to provide budget friendly DIY picture framing solution. Instead of getting a custom frame, you can just get a standard sized frame with custom cut matboard. Your framed art will look similar to custom framing at a fraction of cost.
                </div>
                <div className={"faq-answer-paragraph"}>
                    Here at Custom Mat, we provide high-quality picture framing supplies, including matboards, mounting boards. 99% of our matboards come from Canada and the United States, and the rest comes from the United Kingdom. All our matboards are acid-free. They are processed to remove all the acidic components and buffe to meet your picture framing needs. They will last many years to come.
                </div>
            </div>

        </div>
    }
}