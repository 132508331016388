import React from 'react';
import photo1 from "../../../resources/images/galleryWall/coverPhoto2.jpg";
import signaturePiece from "../../../resources/images/galleryWall/signaturePiece.jpg";
import photo3 from "../../../resources/images/galleryWall/photo1.jpg";
import photo4 from "../../../resources/images/galleryWall/photo2.jpg";
import squareFrame from "../../../resources/images/galleryWall/SquareGalleryWall.jpg";
import kendraGalleryWall from "../../../resources/images/galleryWall/KendraGalleryWall1.jpg";
import staircaseGalleryWall from "../../../resources/images/galleryWall/staircase.jpg";
import LazyLoad from "react-lazyload";
import {Helmet} from "react-helmet";
import IGLogo from "../../../resources/logo/IG-Logo.svg";


export default class ShopByLooks extends React.Component {

    constructor(props) {
        super(props);
    };

    componentDidMount() {

    }


    fetchInspirationPhotos() {

        return this.state.photos.map((item, key) => {

            if (key < this.state.fetchIndex) {
                return <div className="inspiration-posts-col">
                    <img className={"inspiration-post-col-photo"} id={"inspiration-post-col-photo-main-page"} alt={
                        this.props.french ? "Photos réelles de nos clients." : "Actual photos from our customers."
                    } src={item.path}/>
                    <div className={"inspiration-post"}>
                        <div className={"inspiration-logo-name"}>
                            @{item.source.igHandle}
                        </div>
                        <div className={"inspiration-logo-IG-POST-container"}>
                            <a href={item.source.igPath}>
                                <img className={"inspiration-logo-IG-POST"} src={IGLogo} alt={this.props.french ? "Cliquez ici pour visiter la page Instagram de la publication.\n" : "Click here to visit the Instagram page for the post."}/>
                            </a>

                        </div>
                    </div>
                </div>
            }

        })
    }

    render() {
        return <div className={"blog-app-container"}>

            <Helmet>
                <title>
                    Shop by Looks: Gallery Wall Inspiration
                </title>
                <meta name="description" content="Explore our diverse range of gallery wall ideas and discover how to create stunning wall displays on a budget. From hotel-style hallway gallery walls to signature pieces and themed displays, get inspired by our customers' creative designs. Transform your living space with affordable frames and prints. Visit our Instagram page for more inspiration." />
            </Helmet>

            <h1 className={"carousel-subject-head"} style={{textAlign: "center"}}>
                Shop by Looks: Gallery Wall Inspiration
            </h1>



            <div className={"gallery-wall-content-container"}>
                <div className={"gallery-wall-content-left"}>
                    <div style={{fontSize: "26px", lineHeight: "1.5"}}>
                        1. Hotel Style
                        <br className={"mobile-no-display"}/>
                        Hallway Gallery Wall #1
                        <br className={"mobile-no-display"}/>
                        (Wooden Frame)

                    </div>

                    <br/><br/>
                    An adored, timeless layout that combines tradition and modernity. Make a row of hotel story gallery walls using black wooden frames.
                    <br/><br/>

                    <b>How to create this look?</b>
                    <ul>
                        <li>
                            <span>IKEA 24x35.75" </span>
                            <a target={"_blank"} href={"https://www.ikea.com/ca/en/p/ribba-frame-black-90301621"} style={{textDecoration: "underline"}}>
                            Ribba Frames
                            </a>
                        </li>
                        <li>
                            14x11" Black & White Photo Prints
                        </li>
                        <li>
                            <a target="_blank" href={"/customize-matboards?mWidth=24&mHeight=35.75&wWidth=13.75&wHeight=10.75&mTop=9&mLeft=5.125&mRight=5.125&mBottom=16&mColor=smoothWhite&dmColor=none&dmWidth=0&backing=false&oval=false&ctr=false"} style={{textDecoration: "underline"}}>24x35.75" Matboard</a>
                            <span> with opening for 14x11 inch prints</span>
                        </li>
                    </ul>

                    <br/>

                    <div className={"inspiration-post"} style={{background: "none"}}>
                        <div className={"inspiration-logo-name"} >
                            From our customer @inspiration.home.mtl
                        </div>
                        <div className={"inspiration-logo-IG-POST-container"}>
                            <a target={"_blank"} href={"https://www.instagram.com/p/CL4HLOuHbS6/"}>
                                <img className={"inspiration-logo-IG-POST"} src={IGLogo} alt={this.props.french ? "Cliquez ici pour visiter la page Instagram de la publication.\n" : "Click here to visit the Instagram page for the post."}/>
                            </a>

                        </div>
                    </div>

                </div>
                <div className={"gallery-wall-content-right"}>
                    <LazyLoad>
                        <img className={"gallery-wall-content-image"} src={"https://www.custommat.ca/static/media/inspiration.home.mtl-IG.a887c80d.PNG"}/>
                    </LazyLoad>

                </div>
            </div>

            <div className={"gallery-wall-content-container"}>
                <div className={"gallery-wall-content-left"}>
                    <LazyLoad>
                        <img className={"gallery-wall-content-image"} style={{float: "right"}} src={"https://www.custommat.ca/static/media/nestingdesign-IG.ed9a31f6.PNG"}/>
                    </LazyLoad>
                </div>
                <div className={"gallery-wall-content-right"}>
                    <div style={{fontSize: "26px", lineHeight: "1.5"}}>
                        2. Hotel Style <br className={"mobile-no-display"}/>
                        Hallway Gallery Wall #2 (Half Wall) <br className={"mobile-no-display"}/>
                    </div>

                    <br/><br/>
                    In rooms with classic moulding already adorning the bottom half of the walls, there's a simple yet effective way to elevate the aesthetic. Introduce thin, classic metal frames to fashion a gallery wall that effortlessly harmonizes with the room's overall ambiance.
                    <br/><br/>

                    <b>How to create this look?</b>
                    <ul>
                        <li>
                            <span>IKEA 19.75x27.5 inch </span>
                            <a target={"_blank"} href={"https://www.ikea.com/ca/en/p/lomviken-frame-gold-colour-80419602"} style={{textDecoration: "underline"}}>
                                Lomivken Frames
                            </a>
                        </li>
                        <li>
                            10x8 Inch Photo Prints
                        </li>
                        <li>
                            <a target="_blank" href={"/customize-matboards?mWidth=19.75&mHeight=27.5&wWidth=9.75&wHeight=7.75&mTop=5&mLeft=5&mRight=5&mBottom=14.75&mColor=smoothWhite&dmColor=none&dmWidth=0&backing=false&oval=false&ctr=false"} style={{textDecoration: "underline"}}>19.75 inch Matboards</a>
                            <span> with opening for 10x8 inch prints</span>
                        </li>
                    </ul>


                    <div className={"inspiration-post"} style={{background: "none"}}>
                        <div className={"inspiration-logo-name"} >
                            From our customer @nestingdesign
                        </div>
                        <div className={"inspiration-logo-IG-POST-container"}>
                            <a target={"_blank"} href={"https://www.instagram.com/p/CPV_VKAsOvC/"}>
                                <img className={"inspiration-logo-IG-POST"} src={IGLogo} alt={this.props.french ? "Cliquez ici pour visiter la page Instagram de la publication.\n" : "Click here to visit the Instagram page for the post."}/>
                            </a>

                        </div>
                    </div>
                </div>
            </div>

            <div className={"gallery-wall-content-container"}>
                <div className={"gallery-wall-content-left"}>
                    <div style={{fontSize: "26px", lineHeight: "1.5"}}>
                        3. Signature Piece
                        <br className={"mobile-no-display"}/>
                        Gallery Wall
                    </div>

                    <br/><br/>
                    Got a standout signature piece in mind? Let us craft a stunning gallery wall around it, complete with complementary artworks and frames. Elevate your room's ambiance with this stylish enhancement.

                    <br/><br/>

                    <b>How to create this look?</b>
                    <ul>
                        <li>
                            <span>Amazon's </span>
                            <a target={"_blank"} href={"https://www.amazon.ca/dp/B08264756M"} style={{textDecoration: "underline"}}>
                                20x20 inch Frame
                            </a>
                        </li>
                        <li>
                            10x10 inch Square Photo Prints
                        </li>
                        <li>
                            <a target="_blank" href={"/customize-matboards?mWidth=20&mHeight=20&wWidth=10&wHeight=10&mTop=5&mLeft=5&mRight=5&mBottom=5&mColor=smoothWhite&dmColor=none&dmWidth=0&backing=false&oval=false&ctr=true"} style={{textDecoration: "underline"}}>20x20 inch Matboards</a>
                            <span> with opening for 10x10 inch prints</span>
                        </li>
                    </ul>

                    <br/>

                    <div className={"inspiration-post"} style={{background: "none"}}>
                        <div className={"inspiration-logo-name"} >
                            From our customer @jules_interiors
                        </div>
                        <div className={"inspiration-logo-IG-POST-container"}>
                            <a target={"_blank"} href={"https://www.instagram.com/p/CYXFa0clrv7/"}>
                                <img className={"inspiration-logo-IG-POST"} src={IGLogo} alt={this.props.french ? "Cliquez ici pour visiter la page Instagram de la publication.\n" : "Click here to visit the Instagram page for the post."}/>
                            </a>

                        </div>
                    </div>

                </div>
                <div className={"gallery-wall-content-right"}>
                    <LazyLoad>
                        <img className={"gallery-wall-content-image"} src={signaturePiece} style={{width: "100%"}}/>
                    </LazyLoad>

                </div>
            </div>

            <div className={"gallery-wall-content-container"}>
                <div className={"gallery-wall-content-left"}>
                    <LazyLoad>
                        <img className={"gallery-wall-content-image"} style={{float: "right"}} src={"https://www.custommat.ca/static/media/helloimlaurie-IG-1.fd7d4b5a.PNG"}/>
                    </LazyLoad>
                </div>
                <div className={"gallery-wall-content-right"}>
                    <div style={{fontSize: "26px", lineHeight: "1.5"}}>
                       4. Mix & Match Artist Wall
                        <br className={"mobile-no-display"}/>
                    </div>

                    <br/><br/>
                    Elevate your workshop or home office with a dose of inspiration. Mix and match your favorite prints alongside your own artwork and photos, all beautifully displayed in a colorful array of frames.

                    <br/><br/>

                    <b>How to create this look?</b>
                    <ul>
                        <li>
                            <span>Various Frames from IKEA and Amazon</span>
                        </li>
                        <li>
                            Various Prints
                        </li>
                        <li>
                            <a target="_blank" href={"/customize-matboards"} style={{textDecoration: "underline"}}>Customized matboards</a>
                            <span> to find a perfect fit for all your artowrks.</span>
                        </li>
                    </ul>

                    <br/>

                    <div className={"inspiration-post"} style={{background: "none"}}>
                        <div className={"inspiration-logo-name"}>
                            From our customer @helloimlaurie
                        </div>
                        <div className={"inspiration-logo-IG-POST-container"}>
                            <a target={"_blank"} href={"https://www.instagram.com/p/CDmgfmkAaoH/"}>
                                <img className={"inspiration-logo-IG-POST"} src={IGLogo} alt={this.props.french ? "Cliquez ici pour visiter la page Instagram de la publication.\n" : "Click here to visit the Instagram page for the post."}/>
                            </a>

                        </div>
                    </div>
                </div>
            </div>

            <div className={"gallery-wall-content-container"}>
                <div className={"gallery-wall-content-left"}>
                    <div style={{fontSize: "26px", lineHeight: "1.5"}}>
                        5. Dining Room Gallery Wall
                    </div>

                    <br/><br/>
                    Turn your dining room into a cozy haven where cherished memories come to life. Create a timeless atmosphere by printing and displaying your favorite moments on a simple three-piece gallery wall.
                    <br/><br/>

                    <b>How to create this look?</b>
                    <ul>
                        <li>
                            <span>IKEA 24 x 35 3/4 inch </span>
                            <a target={"_blank"} href={"https://www.ikea.com/ca/en/p/ribba-frame-white-30301624"} style={{textDecoration: "underline"}}>
                                White Ribba Frames
                            </a>
                        </li>
                        <li>
                            12 x 12 Inch Square Photo Prints
                        </li>
                        <li>
                            <a target="_blank" href={"/customize-matboards?mWidth=24&mHeight=35.75&wWidth=11.75&wHeight=11.75&mTop=6.125&mLeft=6.125&mRight=6.125&mBottom=17.875&mColor=smoothWhite&dmColor=none&dmWidth=0&backing=false&oval=false&ctr=false"} style={{textDecoration: "underline"}}>24 x 35 3/4 Matboards</a>
                            <span> with opening for 12 x 12 inch prints</span>
                        </li>
                    </ul>

                    <br/>

                    <div className={"inspiration-post"} style={{background: "none"}}>
                        <div className={"inspiration-logo-name"}>
                            From our customer @the78boler
                        </div>
                        <div className={"inspiration-logo-IG-POST-container"}>
                            <a target={"_blank"} href={"https://www.instagram.com/p/CLVPkzohFdr/"}>
                                <img className={"inspiration-logo-IG-POST"} src={IGLogo} alt={this.props.french ? "Cliquez ici pour visiter la page Instagram de la publication.\n" : "Click here to visit the Instagram page for the post."}/>
                            </a>

                        </div>
                    </div>

                </div>
                <div className={"gallery-wall-content-right"}>
                    <LazyLoad>
                        <img className={"gallery-wall-content-image"} src={"https://www.custommat.ca/static/media/the78boler-IG-1.8c6636e9.PNG"}/>
                    </LazyLoad>

                </div>
            </div>

            <div className={"gallery-wall-content-container"}>
                <div className={"gallery-wall-content-left"}>
                    <LazyLoad>
                        <img className={"gallery-wall-content-image"} style={{float: "left", width: "80%", marginLeft: "10%"}} src={squareFrame}/>
                    </LazyLoad>
                </div>
                <div className={"gallery-wall-content-right"}>
                    <div style={{fontSize: "26px", lineHeight: "1.5"}}>
                        6. Grid Gallery Wall
                        <br className={"mobile-no-display"}/>
                        (Square Frames)
                    </div>

                    <br/><br/>
                    Transform Your Family Room with a Cozy Gallery Wall Grid! Capture precious family moments in square frames and create a warm and inviting atmosphere in your family room.
                    <br/><br/>

                    <b>How to create this look?</b>
                    <ul>
                        <li>
                            <span>Amazon's </span>
                            <a target={"_blank"} href={"https://www.amazon.ca/dp/B08264756M"} style={{textDecoration: "underline"}}>
                                20x20 inch Frame
                            </a>
                        </li>
                        <li>
                            10x10 inch Square Photo Prints
                        </li>
                        <li>
                            <a target="_blank" href={"/customize-matboards?mWidth=20&mHeight=20&wWidth=7.75&wHeight=7.75&mTop=6.125&mLeft=6.125&mRight=6.125&mBottom=6.125&mColor=smoothWhite&dmColor=none&dmWidth=0&backing=false&oval=false&ctr=true"} style={{textDecoration: "underline"}}>20x20 inch Matboards</a>
                            <span> with opening for 8x8 inch prints</span>
                        </li>
                    </ul>

                    <br/>

                    <div className={"inspiration-post"} style={{background: "none"}}>
                        <div className={"inspiration-logo-name"}>
                            From our customer @mama.norwood
                        </div>
                        <div className={"inspiration-logo-IG-POST-container"}>
                            <a target={"_blank"} href={"https://www.instagram.com/p/CWHiLgfrbdX/"}>
                                <img className={"inspiration-logo-IG-POST"} src={IGLogo} alt={this.props.french ? "Cliquez ici pour visiter la page Instagram de la publication.\n" : "Click here to visit the Instagram page for the post."}/>
                            </a>

                        </div>
                    </div>
                </div>
            </div>

            <div className={"gallery-wall-content-container"}>
                <div className={"gallery-wall-content-left"}>
                    <div style={{fontSize: "26px", lineHeight: "1.5"}}>
                        7. Grid Galley Wall <br className={"mobile-no-display"}/>
                        (Metal Frames)
                    </div>

                    <br/><br/>
                    In the quest for a chic and heartwarming home makeover, look no further than the transformative power of grid-style metallic frames. Picture your living space adorned with an array of cherished family photos, emanating a warm sense of belonging.

                    <br/><br/>

                    <b>How to create this look?</b>
                    <ul>
                        <li>
                            <span>Amazon's 16x20 inch </span>
                            <a target={"_blank"} href={"https://www.amazon.ca/dp/B082637W42"} style={{textDecoration: "underline"}}>
                                 Silver Metal Frames
                            </a>
                        </li>
                        <li>
                            6 x 4 inch photo Prints
                        </li>
                        <li>
                            <a target="_blank" href={"/16x20-inch-matboard"} style={{textDecoration: "underline"}}>16x20 inch Matboards</a>
                            <span> with opening for 6 x 4 inch prints</span>
                        </li>
                    </ul>

                    <br/>

                    <div className={"inspiration-post"} style={{background: "none"}}>
                        <div className={"inspiration-logo-name"}>
                            From our customer @kendrafoundit
                        </div>
                        <div className={"inspiration-logo-IG-POST-container"}>
                            <a target={"_blank"} href={"https://www.kendrafoundit.com/blog/how-to-make-an-oversized-mat-gallery-wall-grid"}>
                                <img className={"inspiration-logo-IG-POST"} src={IGLogo} alt={this.props.french ? "Cliquez ici pour visiter la page Instagram de la publication.\n" : "Click here to visit the Instagram page for the post."}/>
                            </a>

                        </div>
                    </div>

                </div>
                <div className={"gallery-wall-content-right"}>
                    <LazyLoad>
                        <img className={"gallery-wall-content-image"} src={kendraGalleryWall} style={{width: "70%", marginLeft: "15%"}}/>
                    </LazyLoad>

                </div>
            </div>

            <div className={"gallery-wall-content-container"}>
                <div className={"gallery-wall-content-left"}>
                    <LazyLoad>
                        <img className={"gallery-wall-content-image"} style={{float: "right"}} src={"https://www.custommat.ca/static/media/home.made.lovely-IG-4.ea49a1b2.PNG"}/>
                    </LazyLoad>
                </div>
                <div className={"gallery-wall-content-right"}>
                    <div style={{fontSize: "26px", lineHeight: "1.5"}}>
                        8. Library Style Gallery Wall <br className={"mobile-no-display"}/>
                        with Square Frames
                    </div>

                    <br/><br/>
                    The allure of a traditional library-style look is truly timeless. It's a design choice that effortlessly marries the classic with the contemporary, making it the perfect addition to any interior space.
                    <br/><br/>

                    <b>How to create this look?</b>
                    <ul>
                        <li>
                            <span>Amazon Home's </span>
                            <a target={"_blank"} href={"https://www.amazon.ca/dp/B08264756M"} style={{textDecoration: "underline"}}>
                                20x20 inch Frame
                            </a>
                        </li>
                        <li>
                            4x4 inch Square Photo Prints
                        </li>
                        <li>
                            <a target="_blank" href={"/20x20-inch-matboard-4x4-inch-opening"} style={{textDecoration: "underline"}}>20x20 inch Matboards</a>
                            <span> with opening for 4x4 inch Photo</span>
                        </li>
                    </ul>

                    <br/>

                    <div className={"inspiration-post"} style={{background: "none"}}>
                        <div className={"inspiration-logo-name"} >
                            From our customer @home.made.lovely
                        </div>
                        <div className={"inspiration-logo-IG-POST-container"}>
                            <a target={"_blank"} href={"https://www.instagram.com/p/CK7Sip6JMfv/"}>
                                <img className={"inspiration-logo-IG-POST"} src={IGLogo} alt={this.props.french ? "Cliquez ici pour visiter la page Instagram de la publication.\n" : "Click here to visit the Instagram page for the post."}/>
                            </a>

                        </div>
                    </div>
                </div>
            </div>

            <div className={"gallery-wall-content-container"}>
                <div className={"gallery-wall-content-left"}>
                    <div style={{fontSize: "26px", lineHeight: "1.5"}}>
                        9. Staircase Gallery Wall <br className={"mobile-no-display"}/>
                    </div>

                    <br/><br/>
                    When it comes to infusing your home with timeless elegance, few spaces are as captivating as the staircase walls. It's a canvas waiting to come to life, and with a touch of design finesse, you can achieve a look that harmonizes seamlessly with your staircase or rail color.
                    <br/><br/>

                    <b>How to create this look?</b>
                    <ul>
                        <li>
                            <span>IKEA 19 ¾x27 ½ inch</span>
                            <a target={"_blank"} href={"https://www.ikea.com/ca/en/p/ribba-frame-black-50268874"} style={{textDecoration: "underline"}}>
                                Black Ribba Frames
                            </a>
                        </li>
                        <li>
                            10 x 8 Inch Photo Prints
                        </li>
                        <li>
                            <a target="_blank" href={"/customize-matboards?mWidth=19.75&mHeight=27.5&wWidth=9.75&wHeight=7.75&mTop=5&mLeft=5&mRight=5&mBottom=14.75&mColor=smoothWhite&dmColor=none&dmWidth=0&backing=false&oval=false&ctr=false"} style={{textDecoration: "underline"}}>19 ¾x27 ½ inch Matboards</a>
                            <span> with opening for 10 x 8 inch prints</span>
                        </li>
                    </ul>

                    <br/>

                    <div className={"inspiration-post"} style={{background: "none"}}>
                        <div className={"inspiration-logo-name"}>
                            From our customer @ourhomeinthewoodstock
                        </div>
                        <div className={"inspiration-logo-IG-POST-container"}>
                            <a target={"_blank"} href={"https://www.instagram.com/p/CXNFu61rAdJ/"}>
                                <img className={"inspiration-logo-IG-POST"} src={IGLogo} alt={this.props.french ? "Cliquez ici pour visiter la page Instagram de la publication.\n" : "Click here to visit the Instagram page for the post."}/>
                            </a>

                        </div>
                    </div>

                </div>
                <div className={"gallery-wall-content-right"}>
                    <LazyLoad>
                        <img className={"gallery-wall-content-image"} src={staircaseGalleryWall} style={{width: "75%", marginLeft: "12.5%"}}/>
                    </LazyLoad>

                </div>
            </div>

            <div className={"gallery-wall-content-container"}>
                <div className={"gallery-wall-content-left"}>
                    <LazyLoad>
                        <img className={"gallery-wall-content-image"} style={{width: "100%"}} src={"https://www.custommat.ca/static/media/3.efd79706.jpeg"}/>
                    </LazyLoad>
                </div>
                <div className={"gallery-wall-content-right"}>
                    <div style={{fontSize: "26px", lineHeight: "1.5"}}>
                        10. Sports Themed Gallery Wall
                    </div>
                    <br/>
                    Calling all sports enthusiasts! Transform your space into a captivating gallery showcasing the jerseys, photographs, and trading cards of your beloved athletes. If you have a dedicated game-watching area where you gather with family and friends, consider embracing a vibrant and spirited theme. Alternatively, for a subtler touch, opt for a white mat complemented by a double matting in your team's signature colors.
                    <br/><br/>

                    <b>How to create this look?</b>
                    <ul>
                        <li>
                            <span>Michael's 30x40"</span>
                            <a target={"_blank"} href={"https://canada.michaels.com/en/studio-decor-large-shadowbox-black/10174578.html"} style={{textDecoration: "underline"}}>
                                Shadow Box (+ Smaller Frames)
                            </a>
                        </li>
                        <li>
                            Large Size Jersey and 8x10" Photos
                        </li>
                        <li>
                            <a target="_blank" href={"/customize-matboards?mWidth=30&mHeight=40&wWidth=20&wHeight=30&mTop=5&mLeft=5&mRight=5&mBottom=5&mColor=smoothWhite&dmColor=none&dmWidth=0&backing=false&oval=false&ctr=true"} style={{textDecoration: "underline"}}>30x40" Custom Design Mats</a>
                            <span> (Email us for more info) </span>
                        </li>
                    </ul>

                    <br/>

                    <div className={"inspiration-post"} style={{background: "none"}}>
                        <div className={"inspiration-logo-name"}>
                            From our customer @jivan
                        </div>
                        <div className={"inspiration-logo-IG-POST-container"}>
                            <a target={"_blank"} href={"https://www.instagram.com/p/B6vCYiLF9-9/"}>
                                <img className={"inspiration-logo-IG-POST"} src={IGLogo} alt={this.props.french ? "Cliquez ici pour visiter la page Instagram de la publication.\n" : "Click here to visit the Instagram page for the post."}/>
                            </a>

                        </div>
                    </div>
                </div>
            </div>

            <div className={"visit-custom-mat-button"}>
                <a href={"https://www.custommat.ca/gallery-wall"} className={"carousel-read-more-button"}>
                    Learn More about Each Step
                </a>
            </div>


        </div>
    }
}