import React from 'react';
import photo1 from "../../../resources/images/blog/wedding/weddingSignaturePhotos.png";
import photo2 from "../../../resources/images/gallery/circular/photo1.jpg";
import photo3 from "../../../resources/images/gallery/circular/photo2.webp";
import video4 from "../../../resources/images/gallery/circular/video3.mp4";
import photo5 from "../../../resources/images/gallery/circular/photo4.webp";
import photo6 from "../../../resources/images/gallery/circular/photo5.png";
import photo7 from "../../../resources/images/gallery/circular/photo6.png";


import LazyLoad from "react-lazyload";
import {Helmet} from "react-helmet";
import photo4 from "../../../resources/images/designInspiration/4.png";
import photo21 from "../../../resources/images/blog/photoset2/photo21.PNG";
import WhatIsMatboard from "../page-howItWorks/whatIsMatboard";

export default class DiyWeddingPhotoFrames extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        };
    };

    componentDidMount() {

    }

    render() {
        return <div className={"blog-app-container"}>

            <Helmet>
                <title>
                    8 Creative Ways to Display Your Wedding Photos at Home | Custom Matboards & Gallery Frames
                </title>
                <meta name="description" content="
                    Discover 8 unique and budget-friendly ideas to beautifully display your wedding photos at home. Learn how to create a stunning gallery wall using standard picture frames and custom matboards, preserving cherished memories with elegance. Explore various framing styles, canvas prints, digital frames, and more to relive your special day in style. Custom Matboards offers creative solutions for framing your wedding photos and adding a touch of personalization to your living space.
                "/>
            </Helmet>

            <div className={"carousel-subject-head"} style={{textAlign: "center"}}>
                8 Creative Ways to Display Your Wedding Photos at Home | Custom Matboards & Gallery Frames
            </div>

            <div className={"carousel-subject-subhead-type2"}>
                Ideas
            </div>

            <div className={"carousel-subject-subhead-type3"}>
                Written by Meghan L<br/>Custom Mat Project Blogger
            </div>

            <LazyLoad>
                <img className="blog-carousel-photo" src={"https://www.custommat.ca/static/media/socialLandingPhoto1.6f4f084b.png"}/>
            </LazyLoad>

            <br/>

            <div className="blog-article-photo-description">
                Customer Photo: Example of wedding and family photos that can be displayed in any common space like the master bedroom, dining room, hallway, or foyer. Instead of custom framing, you can use standard picture frames from Amazon or IKEA and add a customized matboard to create a gallery fame and gallery style look for your home.
                <br/>
                <br/>
                #HomeDecorSavingTips
            </div>


            <div className={"blog-article-content"}>


                <LazyLoad>
                    <video className={"what-is-mat-image-v2"} loop="true" autoPlay="autoplay" controls muted
                           src="https://s3.ca-central-1.amazonaws.com/custommat.landing.v2/0_What-is-a-matboard_1920x1290_v1+(1).mp4"></video>
                </LazyLoad>


                <div className="blog-article-photo-description">
                    Quick Intro -- What we do here at CustomMat
                </div>


                <b>What are the most common ways that people display their wedding photos at home?</b>

                <br/><br/>

                Preserving the cherished memories of your wedding day becomes even more special with custom framing. It offers a personalized touch, ensuring the perfect fit and complementing the unique style of your living room decor.

                <br/><br/>

                Couples have a wide variety of frame styles to choose from, allowing them to find the perfect frame that enhances the beauty of their wedding photos and adds elegance to their living room. Using high-quality framing materials ensures that these precious moments are protected and displayed with care, allowing the couple and their guests to relive the joyous occasion every time they look at the framed photos.

                <br/><br/>

                As a timeless addition to any home, custom-framed wedding photos serve as a heartwarming reminder of the love and happiness shared on that special day.

                <br/><br/>


                <LazyLoad>
                    <img className="blog-carousel-photo" src={"https://www.custommat.ca/static/media/photo5.6c0419e2.jpg"}/>
                </LazyLoad>

                <br/>

                <div className="blog-article-photo-description">
                    Photo from our customer: By using standard picture frames along with our custom matboards, you can create a stunning gallery wall at a fraction of the cost of traditional custom framing. Emily, one of our customers, used IKEA Ribba 24 x 35.75 inch frames to create this beautiful gallery wall for her stairs.
                    <br/><br/>
                    <a href={"https://www.instagram.com/p/CPUYPJOA0PK/"}>
                        Photo Credit @emily.k.lewis
                    </a>
                </div>

                <br/><br/>

                <b>Here are some common ways:</b>

                <br/><br/>

                <b>1. Framed Prints: </b> Classic and elegant, couples place their favorite wedding photos in decorative frames on walls, shelves, or mantels.

                <br/><br/>

                <LazyLoad>
                    <img className="blog-carousel-photo" src={"https://www.custommat.ca/static/media/IMG_3419.71790d4e.JPG"}/>
                </LazyLoad>

                <br/>

                <div className="blog-article-photo-description">
                    Photo: Here's an example of a wedding photo gallery wall that celebrates the big day using a 16 x 20 inch IKEA Ramsborg frame with a custom matboard to fit an 8 x 10 inch digital photo taken from an iPhone.
                    <br/><br/>
                    <a href={"https://www.instagram.com/p/CPUYPJOA0PK/"}>
                        Photo Credit @kendrafoundit
                    </a>
                </div>

                <br/><br/>

                <b>2. Guestbook & Guest Signatures: </b>

                The Wedding Signature Mat is a trendy framing piece where guests sign their names on your wedding day. Unlike wedding books, it's easily accessible and displayed when framed.
                <br/><br/>

                <LazyLoad>
                    <img src={photo1} style={{width: "100%"}}/>
                </LazyLoad>

                <br/>

                <div className="blog-article-photo-description">
                    Customer Photo: One of our Etsy customers ordered this Wedding Signature Mat to use as a guest book. It's a special way for wedding guests to be part of the celebration, and their visits to be remembered. They framed it in an 18x24 inch picture frame as a cherished memory
                    <br/><br/>
                    <a href={"https://www.instagram.com/p/CPUYPJOA0PK/"}>
                        Photo Credit Shelli S
                    </a>
                </div>

                <br/><br/>

                <b>3. Photo Albums: </b>

                Traditional albums or customized photo books are popular for organizing and preserving wedding memories.

                <br/><br/>

                <LazyLoad>
                    <img className="blog-carousel-photo" src={"https://www.marthastewart.com/thmb/4XzoHNC90zC1I2DdS8X2h6uAkyc=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/best-wedding-photo-albums-lead-0223-cf400cf6720346c5a9255c477fca74d0.jpg"}/>
                </LazyLoad>

                <br/>

                <div className="blog-article-photo-description">
                    Wedding Albums
                    <br/><br/>
                    <a href={"https://www.marthastewart.com/8261491/best-wedding-photo-albums"}>
                        Click here to learn more from this post
                    </a>
                </div>

                <b>4. Canvas Prints: </b>

                Modern and artistic, printing wedding photos on canvas allows for easy hanging of large images.

                <br/><br/>


                <LazyLoad>
                    <img className="blog-carousel-photo" src={"https://thebudgetsavvybride.com/app/uploads/2014/06/090508canvas.png"}/>
                </LazyLoad>

                <br/>


                <div className="blog-article-photo-description">
                    Wedding Canvas
                    <br/><br/>
                    <a href={"https://thebudgetsavvybride.com/buy-canvas-prints/"}>
                        Click here to learn more from this blogpost
                    </a>
                </div>

                <br/><br/>


                <b>5. Collage Walls: </b>

                Trendy gallery walls combine various-sized prints and frames for a personalized look.

                <br/><br/>


                <LazyLoad>
                    <img className="blog-carousel-photo" src={"https://www.custommat.ca/static/media/frame-mat-photo%201.923ed33f.PNG"}/>
                </LazyLoad>

                <br/>


                <div className="blog-article-photo-description">
                    Customer Photos: our customers have used various sizes of frames and photos to create a collage wall.
                </div>

                <br/><br/>

                <b>5. Digital Picture Frames: </b>

                Versatile digital frames display rotating slideshows of wedding photos, providing a dynamic option. It’s a great wedding gift if you are looking something for newlyweds.

                <br/><br/>

                <b>6. Acrylic Prints: </b>

                Versatile digital frames display rotating slideshows of wedding photos, providing a dynamic option. It’s a great wedding gift if you are looking something for newlyweds.

                <br/><br/>


                <b>7. Shadow Boxes: </b>

                Versatile digital frames display rotating slideshows of wedding photos, providing a dynamic option. It’s a great wedding gift if you are looking something for newlyweds.

                <br/><br/>

                <b>8. String Lights and Clips: </b>

                Romantic and whimsical, string lights with photo clips add a magical touch to wedding photo displays.

                <br/><br/>

                <LazyLoad>
                    <img src={"https://www.custommat.ca/static/media/photo1.d97f1ef0.jpg"} className={"blog-carousel-photo"}/>
                </LazyLoad>

                <br/>

                <div className="blog-article-photo-description">
                    Customer Photo: Home Office Wedding & Family Gallery Wall
                    <br/><br/>
                    Photo Credit @kendrafondit
                </div>

                <b>Home Office Wedding Gallery Wall</b>

                With more families working from home, a popular trend is decorating home offices with wedding and family photos. They use 16 x 20 inch IKEA Lomviken frames with custom matboards to fit 4 x 6 inch digital photos taken by iPhone. Large white mat boards create a consistent and cohesive gallery wall.

                <br/><br/>

                <LazyLoad>
                    <img src={"https://www.custommat.ca/static/media/photo9.c829067c.jpg"} className={"blog-carousel-photo"}/>
                </LazyLoad>

                <br/>

                <div className="blog-article-photo-description">
                    Customer Photo: Home Office Wedding & Family Gallery Wall
                    <br/><br/>
                    Photo Credit @kendrafondit
                </div>

                <br/><br/>

                Keep in mind that people have different tastes and home decor styles, so couples may mix and match these display methods or create their own creative ways to showcase their special wedding memories.

                <br/><br/>

                Framing wedding invitations or signed logs from the wedding date is a great idea, and there are affordable framing options available for this. However, getting custom wedding frames from a picture framing shop might be expensive due to the effort involved in custom work.

            </div>



        </div>
    }
}