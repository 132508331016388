import React from 'react';
import ArticleIndexFetcher from "./articleIndexFetcher";
import ArticleListFetcher from "./articleListFetcher";
import {galleryArticles} from "./articles";
import {Helmet} from "react-helmet";
import renderImg from "../comp-renderImg/renderImg";
import moveToArticle from "./moveToArticle";
import analyticsDataToDB from "../comp-analyticsLog/dbLogging";


export default class TheGallery extends React.Component {

  constructor() {
    super();
    this.state = {
      french: false,
    }
  };

  componentDidMount(){
    this.logToDbUserRecord();
    this.setState({
      french: (window.location.pathname.slice(-2) === "fr" || this.props.language === "fr") ? true : false
    })
  }

  logToDbUserRecord() {
    analyticsDataToDB("fetch-article", "gallery-main-page");
  }


  fetchSecondFeaturedArticle() {
    return <div className={`overview-section-right`} onClick={() => moveToArticle(this.state.french ? galleryArticles.article2.urlFr : galleryArticles.article2.url)}>
      {renderImg(galleryArticles.article2.photosUnCompressed[0], galleryArticles.article2.photos[0], "overview-section-left-image", "")}

      {/*<img className={`overview-section-left-image`}*/}
      {/*     src={}/>*/}
      <div className={`overview-section-header-container-alternative`}>
        <div className={`overview-section-header-alternative`}>
          {galleryArticles.article2.title}
        </div>
        <div className={`overview-section-subheader-alternative`}>
          {galleryArticles.article2.subtitle}
        </div>
      </div>
    </div>

  }



  render() {
    return <div className={`gallery-container`}>

      <Helmet>
        <title>Custom Cut Mat Boards • Elegant Picture Frames at Excellent Prices</title>
        <meta name="description" content="We custom cut your mat boards. Upgrade your retail picture frames. Make them look custom! Ship anywhere in Canada and US." />
      </Helmet>

      <div className={`overview-section-container`}>
        <ArticleListFetcher/>
        {this.fetchSecondFeaturedArticle()}
        <ArticleIndexFetcher/>
      </div>

    </div>
  }
}