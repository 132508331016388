import React from "react";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {Helmet} from "react-helmet";


export default class OrderChange extends React.Component {

  constructor() {
    super();
    this.state = {
      inquiryType: "modify",
      name: undefined,
      email: undefined,
      deliveryAddress: undefined,
      phone: undefined,
      inquiryContent: undefined,
      orderTimestamp: undefined,
    };
  }

  componentDidMount() {
    this.executeQueryParams();
  }

  executeQueryParams() {
    var urlQueryParams = (window.location.pathname + window.location.search);
    var urlQueryArray = urlQueryParams.split("/");
    var inquiryType, name, email, orderTimestamp, deliveryAddress;

    inquiryType = urlQueryArray[1] ?  urlQueryArray[1].substr(10) : "";
    name = urlQueryArray[2] ? urlQueryArray[2].replace(/%20/gi," ") : "";
    email = urlQueryArray[3] ? urlQueryArray[3].replace(/%20/gi," ") : "";
    orderTimestamp = urlQueryArray[4] ? urlQueryArray[4].replace(/%20/gi," "): "";
    deliveryAddress = urlQueryArray[5] ? urlQueryArray[5].replace(/%20/gi," ") : "";

    this.setState({
      inquiryType: inquiryType,
      name: name,
      email: email,
      orderTimestamp: orderTimestamp,
      deliveryAddress: deliveryAddress,
    });

  }

  showOrderChangeForm(props) {

    return <div className={"order-change-forms-cover"}>
      <div className={"order-change-form-left"}>
        {this.showInquiryType()}
        <div className={"order-change-input"}>
          <TextField
            id="inquiry-form-user-email"
            label="Name"
            value={this.state.name}
            onChange={(evt) => {this.setState({email: evt.target.value})}}
            margin="normal"
          />
        </div>
        <div className={"order-change-input"}>
          <TextField
            id="inquiry-form-user-email"
            label="Email"
            value={this.state.email}
            onChange={(evt) => {this.setState({email: evt.target.value})}}
            margin="normal"
          />
        </div>
        <div className={"order-change-input"}>
          <TextField
            id="inquiry-form-user-address"
            label="Street Address"
            value={this.state.deliveryAddress}
            onChange={(evt) => {this.setState({deliveryAddress: evt.target.value})}}
            margin="normal"
          />
        </div>
        <div className={"order-change-input"}>
          <TextField
            id="inquiry-form-user-phone-number"
            label="Phone Number (Optional)"
            value={this.state.phone}
            onChange={(evt) => {this.setState({phone: evt.target.value})}}
            margin="normal"
          />
        </div>
      </div>
      <div className={"order-change-form-right"}>
        <TextField
          id="filled-multiline-flexible"
          label="Please let us know."
          multiline
          rowsMax="10"
          value={this.state.phone}
          margin="normal"
          helperText="Please let us know. We typically respond within hours."
          variant="filled"
        />
      </div>
    </div>
  }

  showInquiryType() {
    return <FormControl component="fieldset">
      <FormLabel component="legend">
        Inquiry Type
      </FormLabel>
      <RadioGroup
        aria-label="Gender"
        name="gender1"
        value={this.state.inquiryType}
        onChange={(evt) => {this.setState({inquiryType: evt.target.value})}}
        row
      >
        <FormControlLabel value="modify" control={<Radio />} label="Modify Order" />
        <FormControlLabel value="cancel" control={<Radio />} label="Cancel Order" />
        <FormControlLabel value="others" control={<Radio />} label="Other Questions" />
      </RadioGroup>
    </FormControl>

  }

  showSubmitButton() {
    return <div className={"order-change-form-submit-button"}>
      <Button variant="contained" color="primary">
        Submit
      </Button>
    </div>
  }

  render() {
    return <div className={"payments-container"}>

      <Helmet>
        <title>Custom Mat Board • Order Online • Change your Order</title>
        <meta name="description" content="We custom cut your mat boards. Upgrade your retail picture frames. Make them look custom! Ship anywhere in Canada and US." />
      </Helmet>

      {this.showOrderChangeForm()}
      {this.showSubmitButton()}
    </div>
  }
}