import React from 'react';
import getWindowKeys from "./getWindowKeys";
import TextField from "@material-ui/core/TextField";
import arrowUp from "../../../../../resources/svgIcons/keyboard-arrow-up.svg";
import arrowLeft from "../../../../../resources/svgIcons/keyboard-arrow-left.svg";
import arrowRight from "../../../../../resources/svgIcons/keyboard-arrow-right.svg";
import arrowDown from "../../../../../resources/svgIcons/keyboard-arrow-down.svg";
import windowCssRendering  from "./windowCssRendering";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import imageCropIcon from "../../../../../resources/svgIcons/image-crop.svg";
import imageCropRotateIcon from "../../../../../resources/svgIcons/image-crop-rotate.svg";
import imageAddIcon from "../../../../../resources/svgIcons/add-photo-icon-colored.svg";
import refreshIconColored from "../../../../../resources/svgIcons/refresh-colored.svg"
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import numberRounding from "../../../comp-number-rounding/numberRounding";
import matWindowIllustrationImage from "../../../../../resources/images/selectMat/reduced/how-to-measure-matboard-windows.png";

export default class ShowMattingWindows extends React.Component {

  constructor() {
    super();
    this.state = {
      windowHeightPixel: 0,
      src: null,
      crop: {
        unit: "%",
        width: 30,
        // aspect: 16/9,
      },
      cropImageButtonClicked: false,
      rotationDegrees: 0,
    }
  };

  setStateCallback(state, value) {
    this.setState({[state] : value});
  };

  cropImageButtonClicked() {
    this.setState({cropImageButtonClicked: true});
  };

  rotateClockWiseClicked() {
    if (this.state.rotationDegrees === 0) {
      this.setState({rotationDegrees: 90});
      var image = new Image();
      image.src = this.state.croppedImageUrl;
      image.style.transform = "rotate(90deg)";
    } else if (this.state.rotationDegrees === 90) {
      this.setState({rotationDegrees: 180})
    } else if (this.state.rotationDegrees === 180) {
      this.setState({rotationDegrees: 270})
    } else {
      this.setState({rotationDegrees: 0})
    }
  };

  onSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
          this.setState({ src: reader.result })
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  onImageLoaded = image => {
    this.imageRef = image;
  };

  onCropComplete = crop => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
          this.imageRef,
          crop,
          "newFile.jpeg"
      );
      this.setState({ croppedImageUrl });
    }
  };

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error("Canvas is empty");
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, "image/jpeg");
    });
  };

  fetchUploadArtworkTopicHeader() {
    if (this.state.src === null) {
      return <div>
        Please upload your artwork image.<br/>
        First crop your image and rotate if you need to rotate.
      </div>
    } else {
      if (this.state.cropImageButtonClicked) {
        return <div>
          Click "Next" when you are done.<br/>
          Click "Rotate" if image needs to be rotated.
        </div>
      }
      else {
        return <div>
          Please select your artwork area.<br/>
          Click "Crop Image" button once you are complete.
        </div>
      }
    }
  };

  displayWindowMattingSize(direction, windowProperty, windowCssRenderingValues, windowKeyToUpdate, frameWidth, frameHeight) {
    //
    // var marginIndicatorBottomAdjustment, marginIndicatorRightAdjustment, marginIndicatorLeftAdjustment;
    // // var selectedMatColour = productDB.matboards[this.props.selectedMatColour].img;
    // //
    // // if (this.props.deviceType === "web") {
    // //   // marginIndicatorBottomAdjustment = "1.6vw";
    // //   // marginIndicatorRightAdjustment = "1.6vw";
    // //
    // // } else if (this.props.deviceType === "tablet") {
    // //   marginIndicatorBottomAdjustment = "2.5vw";
    // //   marginIndicatorRightAdjustment = "3vw";
    // // } else {
    // //   //mobile
    // //   marginIndicatorBottomAdjustment = "4.5vw";
    // //   marginIndicatorRightAdjustment = "20.5vw";
    // //   marginIndicatorLeftAdjustment = "1.5vw";
    // // }


    if (direction === "up") {
      return <div
          className={numberRounding(windowProperty.marginTop === 0) ? "no-display" : `artwork-margin-indicator artwork-margin-indicator-${direction}`}
          style={{
            height: windowCssRenderingValues.marginIndicatorTop,
            borderColor: this.props.guideDark ? "white" : "#3B3B3B",
          }}
        >
        <div className={`artwork-margin-indicator-number-${direction}`}
             style={{
               color: this.props.guideDark ? "white" : "#3B3B3B",
             }}
        >
          {numberRounding(windowProperty.marginTop)}
          {/*<input*/}
          {/*    type={"number"}*/}
          {/*  value={numberRounding(windowProperty.marginTop - this.props.secondMatWidth)}*/}
          {/*  onChange={(evt) => {this.props.moveWindowCallBack(direction, windowKeyToUpdate, windowCssRenderingValues, evt.target.value, this.props.frame)}}*/}
          {/*/>*/}
        </div>
      </div>
    } else if (direction === "down") {
      return <div className={ numberRounding(windowProperty.marginBottom === 0) ? "no-display" : `artwork-margin-indicator artwork-margin-indicator-${direction}`}
                  style={{
                    height: windowCssRenderingValues.marginIndicatorBottom,
                    marginBottom: windowCssRenderingValues.marginBottomMinus,
                    backgroundColor: "selectedMatColour",
                    // marginTop: `calc(${windowCssRenderingValues.padding} + ${marginIndicatorBottomAdjustment})`
                    borderColor: this.props.guideDark ? "white" : "#3B3B3B",
                  }}>
        <div className={`artwork-margin-indicator-number-${direction}`}
             style={{
               color: this.props.guideDark ? "white" : "#3B3B3B",
             }}
        >
          {numberRounding(windowProperty.marginBottom)}
        </div>
      </div>
    } else if (direction === "left") {
      return <div className={ numberRounding(windowProperty.marginLeft === 0) ? "no-display" :`artwork-margin-indicator artwork-margin-indicator-${direction}`}
                  style={{
                    width: windowCssRenderingValues.marginIndicatorLeft,
                    borderColor: this.props.guideDark ? "white" : "#3B3B3B",
                  }}>
        <div className={`artwork-margin-indicator-number-${direction}`}
             style={{
               color: this.props.guideDark ? "white" : "#3B3B3B",
             }}
        >
          {numberRounding(windowProperty.marginLeft)}
        </div>
      </div>
    } else if (direction === "right") {
      return <div className={`artwork-margin-indicator artwork-margin-indicator-${direction}`}
                  style={{width: windowCssRenderingValues.marginIndicatorRight,
                    marginRight: windowCssRenderingValues.marginRightMinus,
                    borderColor: this.props.guideDark ? "white" : "#3B3B3B",
                    // marginLeft: `calc(${windowCssRenderingValues.padding} + ${marginIndicatorRightAdjustment})`
                  }}
      >
        <div className={ numberRounding(windowProperty.marginRight === 0) ? "no-display" : `artwork-margin-indicator-number-${direction}`}
             style={{
               color: this.props.guideDark ? "white" : "#3B3B3B",
             }}
        >
          {numberRounding(windowProperty.marginRight)}
        </div>
      </div>
    }
  };

  displayArtworkMarginController(direction, icon, cssProperties, windowKeyToUpdate, windowProperty, isWindowBig, windowCssRenderingValues, frameWidth, frameHeight) {

    if (direction === "down") {

      return <Tooltip title={`Click here to move ${direction} by 0.125 in.`} placement={"top"}>
        <div className={`artwork-margin-controller artwork-margin-controller-${direction}`} style={cssProperties}
             onClick={(evt) => this.props.moveWindowCallBack(direction, windowKeyToUpdate)}
        >
          <img className={`iconSize artwork-margin-move-${direction}`} src={[icon]} alt={`Click here to move the window ${direction}`}/>
          {this.displayWindowMattingSize(direction, windowProperty, windowCssRenderingValues, windowKeyToUpdate, frameWidth, frameHeight)}
        </div>
      </Tooltip>
    } else {
      return <Tooltip title={`Click here to move ${direction} by 0.125 in.`} placement={"top"}>
        <div className={`artwork-margin-controller artwork-margin-controller-${direction}`} style={cssProperties}
             onClick={(evt) => this.props.moveWindowCallBack(direction, windowKeyToUpdate)}
        >
          {this.displayWindowMattingSize(direction, windowProperty, windowCssRenderingValues, windowKeyToUpdate, frameWidth, frameHeight)}
          <img className={`iconSize artwork-margin-move-${direction}`} src={[icon]} alt={`Click here to move the window ${direction}`}/>
        </div>
      </Tooltip>
    }
  };

  isWindowBigToShowIcons(width, height) {
    if (parseFloat(width) < 14 || parseFloat(height) < 14) {
      return false
    } else {
      return true
    }
  };

  calculateCtrlPanelMarginFn(direction, device, windowCssRenderingValues) {
    if (device === "mobile") {
      return `calc((${windowCssRenderingValues} - 4vw - ${this.props.direction === "center" ? "12vw" : "12vw"}) /2)`;
    } else if ( device === "tablet") {
      return `calc((${windowCssRenderingValues} - 4vw - ${this.props.direction === "center" ? "8vw" : "7vw"}) /2)`;
    } else {
      return `calc((${windowCssRenderingValues} - 4vw - ${this.props.direction === "center" ? "3vw" : "1vw"}) /2)`;
    }
  };

  //component 1-1
  fetchUserMattingWindow(windowCssRenderingValues, key, item, isWindowBig, frameWidth, frameHeight) {
    return <div className={`select-matboard-artwork`} style={{marginTop: `calc(-${windowCssRenderingValues.height} - 0.5vmin)`, borderRadius: this.props.isWindowOval ? "100%" : "0"}}>
      {this.displayArtworkMarginController("up", arrowUp, {}, key, this.props.window[item], isWindowBig, windowCssRenderingValues)}
      <div className={`matboard-window-central-controller`} style={{}}>
        {this.displayArtworkMarginController("left", arrowLeft, {}, key, this.props.window[item], isWindowBig, windowCssRenderingValues)}
        <div className={"window-size-indicator"}
            // this section should be if the thing is large enough, it should be -17vmin to cover the whole text. if it shrinks,
            // this whole section needs to shrink as well.
             style={{marginTop: this.calculateCtrlPanelMarginFn("center", this.props.deviceType, windowCssRenderingValues.height)}}>
          {/*<div className={"matboard-window-action-icon-container"}>*/}
          {/*  <Tooltip title={"Upload your artwork."} placement={"top"}>*/}
          {/*    <img className={isWindowBig ? "display" : "no-display"}*/}
          {/*         src={addPhotoIcon} alt={"Upload your artwork."}*/}
          {/*         onClick={(evt) => this.props.updateWindowModalState("showBackgroundImageModal", key)}*/}
          {/*    />*/}
          {/*  </Tooltip>*/}
          {/*</div>*/}

            {/*<div className={"matboard-window-size-indicator"}*/}
            {/*     // onClick={(evt) => this.props.updateWindowModalState("showModal", key)}*/}
            {/*>*/}
            {/*  <div className={isWindowBig ? "display" : "no-display"}>*/}
            {/*    {numberRounding(this.props.window[item].width)} x {numberRounding(this.props.window[item].height)} in*/}
            {/*  </div>*/}
            {/*</div>*/}

        </div>
        {this.displayArtworkMarginController("right", arrowRight,
            {marginTop: this.calculateCtrlPanelMarginFn("right", this.props.deviceType, {})},
            key, this.props.window[item], isWindowBig, windowCssRenderingValues)}
      </div>
      {this.displayArtworkMarginController("down", arrowDown, {}, key, this.props.window[item], isWindowBig, windowCssRenderingValues, frameWidth, frameHeight)}
    </div>
  };

  //component 1-2
  fetchUserArtworkImage(windowValues, item, isWindowOval) {
    return <img className={"user-window-image"} src={windowValues.backgroundImage} style={{transform: `rotate(${this.props.window[item].rotationDegrees}deg)`, borderRadius: isWindowOval ? "100%" : "0"}} alt={""}/>
  };

  //component 2
  fetchMatBoardSizingModal(item, key) {
    return <div className={`select-matboard-size-modal ${this.props.window[item].showModal ? "display" : "no-display"}`}>
      <div className={`select-matboard-input-modal`}>
        <div className={`select-matboard-modal-title`}>
          Matboard Window Sizing
        </div>
        <div className={"artwork-dimension-view"}>
          <div className={"window-opening-field-container"}>
            <div className={"window-opening-field"}>
              <TextField
                  label="WIDTH"
                  value={numberRounding(this.props.window[item].width)}
                  onChange={(evt) => {this.props.updateWindowSizeCallBack(`window${key+1}`, "width", evt.target.value)}}
                  margin="normal"
                  type={"number"}
              />
            </div>
            <div className={"window-opening-field-bridge"}>
              x
            </div>
            <div className={"window-opening-field"}>
              <TextField
                  label="HEIGHT"
                  value={numberRounding(this.props.window[item].height)}
                  onChange={(evt) => {this.props.updateWindowSizeCallBack(`window${key+1}`, "height", evt.target.value)}}
                  margin="normal"
                  type="number"
              />
            </div>
          </div>

          <div className={"window-opening-field-form-aid"}>
            in inches
          </div>
        </div>

        <div className={"window-opening-field-exit-container"}>
          <div className={"window-opening-field-exit"}  onClick={() => {this.props.updateWindowModalState("showModal", false)}}>
            NEXT
          </div>
        </div>

        <div className={"window-opening-illustration-image"}>
          <img className={"window-opening-illustration-image-file"} src={matWindowIllustrationImage}
               alt={`This is an illustrative diagram for measuring mat window.`}/>
        </div>

        <div className={`select-matboard-modal-subtitle`}>
          Matboard window shows your art.
          The window needs to be <b>smaller</b> than your artwork.
          Please make your window width & height are 0.25 - 0.5 inch smaller to your art.
          <span className={"no-wrap"}> <u>Click here to learn more</u>.</span>
        </div>
      </div>
    </div>
  };

  //component 3
  fetchBackgroundImageModal(item, src, crop, croppedImageUrl, key) {
    return <div className={this.props.window[item].showBackgroundImageModal ? "photo-cropping-tool-container" : "no-display"}>

      <div className={"photo-cropping-tool-cover"}>



        <div className={"photo-cropping-tool-cover-topic-header"}>
          Add Image

          <div className={"close-add-image-modal-button"} onClick={() => {this.props.updateWindowSizeCallBack(`window${key+1}`, "backgroundImage", croppedImageUrl ? croppedImageUrl : src, this.state.rotationDegrees)}}>
            X
          </div>
        </div>

        <div className={"image-crop-section"}>

          <div className={"upload-artwork-topic-header"}>
            {this.fetchUploadArtworkTopicHeader()}
          </div>

          {(this.state.cropImageButtonClicked === false) && (
              <ReactCrop
                  src={src ? src : ""}
                  crop={crop}
                  onImageLoaded={this.onImageLoaded}
                  onComplete={this.onCropComplete}
                  onChange={this.onCropChange}
              />
          )}

          {this.state.cropImageButtonClicked &&
          croppedImageUrl && (<img className={this.state.rotationDegrees === 0 ? "" : "image-rotation-clicked"} alt="Crop" style={{width: "100%", transform: `rotate(${this.state.rotationDegrees}deg)`}} src={croppedImageUrl} />)
          }

          <div className={"image-crop-action-tools"}>
            <input type="file" name="file" id="file" className={"no-display"} onChange={this.onSelectFile} />

            <Tooltip title={this.state.src === null ? "Upload your artwork" : "Change your artwork"} placement={"top"}>
              <Button className={"crop-icon-container"} id={"add-image-icon"}
                      onClick={() => {this.setStateCallback("cropImageButtonClicked", false); this.setStateCallback("cropAndRotateImageButtonClicked", false)}}>
                <label htmlFor="file">
                  <img className={"crop-icon"} src={imageAddIcon} alt={""}/>
                </label>
              </Button>
            </Tooltip>

            <Tooltip title={"Crop & rotate"} placement={"top"}>
              <Button className="crop-icon-container" id={"rotate-and-crop-icon"} style={{display: this.state.cropImageButtonClicked ? "none" : "inline"}}
                      onClick={() => {this.setStateCallback("cropImageButtonClicked", true); this.rotateClockWiseClicked()}}>
                <img className={"crop-icon"} src={imageCropRotateIcon} alt={""}/>
              </Button>
            </Tooltip>

            <Tooltip title={"Redo Cropping"} placement={"top"}>
              <Button className="crop-icon-container" id={"redo-cropping-icon"} style={{display: this.state.cropImageButtonClicked ? "inline" : "none"}}
                      onClick={() => {this.setStateCallback("cropImageButtonClicked", false)}}>
                <img className={"crop-icon"} src={refreshIconColored} alt={""}/>
              </Button>
            </Tooltip>


            <Tooltip title={"Rotate Clockwise"} placement={"top"}>
              <Button className="crop-icon-container" id={"rotate-and-crop-icon"} style={{display: this.state.cropImageButtonClicked ? "inline" : "none"}}
                      onClick={() => {this.rotateClockWiseClicked()}}>
                <img className={"crop-icon"} src={imageCropRotateIcon} alt={""}/>
                {/*<div className={"crop-icon-container-text"}>Crop & Rotate</div>*/}
              </Button>
            </Tooltip>

            <Tooltip title={"Crop Image"} placement={"top"}>
              <Button className="crop-icon-container" id={"crop-image-icon"} style={{display: this.state.cropImageButtonClicked ? "none" : "inline"}}
                      onClick={() => {this.setStateCallback("cropImageButtonClicked", true)}}>
                <img className={"crop-icon"} src={imageCropIcon} alt={""}/>
                {/*<div className={"crop-icon-container-text"}>Crop</div>*/}
              </Button>
            </Tooltip>

            <Tooltip title={"Move to Next Step"} placement={"top"}>
              <div className={"crop-icon-container"} id={"confirm-cropping-icon"} onClick={() => {this.props.updateWindowSizeCallBack(`window${key+1}`, "backgroundImage", croppedImageUrl ? croppedImageUrl : src, this.state.rotationDegrees)}}>
                Next
              </div>
            </Tooltip>

          </div>
        </div>
      </div>
    </div>
  };

  render() {
    var objKeys = getWindowKeys(this.props.window);
    var { crop, croppedImageUrl, src } = this.state;

    return objKeys.map((item, key) => {

      var windowValues = this.props.window[item];
      var windowCssRenderingValues = windowCssRendering(
          windowValues.width,
          windowValues.height,
          this.props.frame.width,
          this.props.frame.height,
          this.props.frame.renderingStandardLength,
          this.props.renderingToolHeight,
          "",
          windowValues.marginTop,
          windowValues.marginBottom,
          windowValues.marginLeft,
          windowValues.marginRight,
          this.props.secondMatWidth,
          this.props.secondMatColour,
          this.props.isWindowOval
      );
      var isWindowBig = this.isWindowBigToShowIcons(windowCssRenderingValues.width, windowCssRenderingValues.height);

      return <div className={(windowValues.width === "" || windowValues.height ==="") ? "no-display" : `select-matboard-window`} key={`select-matboard-window-${key}`} style={windowCssRenderingValues}>

        {this.fetchUserArtworkImage(windowValues, item, this.props.isWindowOval)}

        {this.fetchMatBoardSizingModal(item, key)}

        {this.fetchUserMattingWindow(windowCssRenderingValues, key, item, isWindowBig, this.props.frame.width, this.props.frame.height)}

        {this.fetchBackgroundImageModal(item, src, crop, croppedImageUrl, key)}

      </div>
    });
  }
}