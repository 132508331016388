const STRIPE_PUBLISHABLE = process.env.NODE_ENV === 'production'
  ? 'pk_live_DLVTVK7TtIHuHx8MaqCZPbB6'
  : 'pk_live_DLVTVK7TtIHuHx8MaqCZPbB6';

// //below is the test key
//
// const STRIPE_PUBLISHABLE = process.env.NODE_ENV === 'production'
//   ? 'pk_test_Xgh1SiUC6ysftUB14Txu3QDB'
//   : 'pk_test_Xgh1SiUC6ysftUB14Txu3QDB';

export default STRIPE_PUBLISHABLE;