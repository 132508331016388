import React from 'react';
import photo1 from "../../../resources/images/blog/ikeaSeries/oversized-mat-photo-frame-3.jpg";
import photo2 from "../../../resources/images/blog/oversizedMatRamsborgFrames/IMG_3419.JPG";
import photo3 from "../../../resources/images/blog/photoset2/photo1.jpg";
import photo4 from "../../../resources/images/blog/photoset2/photo8.png";
import photo5 from "../../../resources/images/blog/photoset2/photo9.jpg";
import {ikeaFrameSizes} from "../articleDB/ikeaFrameSizes";
import LazyLoad from "react-lazyload";
import {Helmet} from "react-helmet";

export default class IkeaFrameSizingGuide extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        };
    };

    componentDidMount() {

    }

    goToDivId(id) {
        document.getElementById(`${id}`).scrollIntoView()
    }

    render() {
        return <div className={"blog-app-container"}>

            <Helmet>
                <title>IKEA Picture Frame Mat Board Sizing Guide</title>
                <meta name="description" content="At custommat.ca we are the picture framing matboards experts. We are here to custom tailor your matboards to your art and your IKEA frames. "/>
            </Helmet>

            <div className={"carousel-subject-head"} style={{textAlign: "center"}}>
                Our ultimate IKEA Picture Frame Mat Board Sizing Guide
            </div>

            <div className={"carousel-subject-subhead-type2"}>
                IKEA HACK
            </div>

            <div className={"carousel-subject-subhead-type3"}>
                Written by Danielle M<br/>Custom Mat Project Blogger
            </div>

            <LazyLoad>
                <img src={photo1} className={"blog-content-photo"}/>
            </LazyLoad>


            <div className={"blog-article-content"}>

                <b>IKEA Ribba Frames</b>

                <br/><br/>

                Looking to complete your gallery wall? Want to upgade your wall decor? Want to finally frame up your family photos for your living room?

                <br/><br/>

                In addition to Amazon, IKEA is a great source for picture frames. They come in great prices and they are usually around us. It is indeed the ultimate place to complete our home decor.

                <br/><br/>

                IKEA’s frames come in variety of colours, sizes, and materials. They also come with damage resistant acryllic glazing. IKEA picture frames are definitely the top choice if you are looking for a mass-produced picture frames to frame your wall art.

                <br/><br/>

                Here’s a quick summary of IKEA Frames:

                <br/><br/>


                <table className="pricing-table">

                    <tbody>
                        <tr>
                            <th className="faq-table-row">
                                Picture Frame Type<br/>
                                (Click for More info)
                            </th>
                            <th className="faq-table-row">
                                Colours
                            </th>
                            <th className="faq-table-row">
                                Materials
                            </th>
                        </tr>
                    </tbody>

                    <tbody>
                        <tr>
                            <th className="faq-table-row" onClick={() => this.goToDivId('ribba-series')}>
                                <u>Ribba</u>
                            </th>
                            <th className="faq-table-row">
                                Black and White
                            </th>
                            <th className="faq-table-row">
                                Wood
                            </th>
                        </tr>
                    </tbody>

                    <tbody>
                    <tr>
                        <th className="faq-table-row"  onClick={() => this.goToDivId('knoppang-series')}>
                            <u>Knoppang</u>
                        </th>
                        <th className="faq-table-row">
                            Black and White
                        </th>
                        <th className="faq-table-row">
                            Wood
                        </th>
                    </tr>
                    </tbody>

                    <tbody>
                    <tr>
                        <th className="faq-table-row" onClick={() => this.goToDivId('silverhojden-series')}>
                            <u>Silverhojden</u>
                        </th>
                        <th className="faq-table-row">
                            Gold and Silver
                        </th>
                        <th className="faq-table-row">
                            Wood
                        </th>
                    </tr>
                    </tbody>

                    <tbody>
                    <tr>
                        <th className="faq-table-row" onClick={() => this.goToDivId('edsbruk-series')}>
                            <u>Edsbruk</u>
                        </th>
                        <th className="faq-table-row">
                            Black and White
                        </th>
                        <th className="faq-table-row">
                            Wood
                        </th>
                    </tr>
                    </tbody>

                    <tbody>
                    <tr>
                        <th className="faq-table-row" onClick={() => this.goToDivId('hovsta-series')}>
                            <u>Hovsta</u>
                        </th>
                        <th className="faq-table-row">
                            Birch and Dark Brown
                        </th>
                        <th className="faq-table-row">
                            Wood
                        </th>
                    </tr>
                    </tbody>

                    <tbody>
                    <tr>
                        <th className="faq-table-row" onClick={() => this.goToDivId('lomviken-series')}>
                            <u>Lomviken</u>
                        </th>
                        <th className="faq-table-row">
                            Gold and Silver
                        </th>
                        <th className="faq-table-row">
                            Metal
                        </th>
                    </tr>
                    </tbody>

                    <tbody>
                    <tr>
                        <th className="faq-table-row" onClick={() => this.goToDivId('fiskbo-series')}>
                            <u>Fiskbo</u>
                        </th>
                        <th className="faq-table-row">
                            Black, White, Pink, and Mint
                        </th>
                        <th className="faq-table-row">
                            Wood
                        </th>
                    </tr>
                    </tbody>

                    <tbody>
                    <tr>
                        <th className="faq-table-row" onClick={() => this.goToDivId('viserum-series')}>
                            <u>Viserum</u>
                        </th>
                        <th className="faq-table-row">
                            Gold
                        </th>
                        <th className="faq-table-row">
                            Wood
                        </th>
                    </tr>
                    </tbody>

                    <tbody>
                    <tr>
                        <th className="faq-table-row" onClick={() => this.goToDivId('dalskarr-series')}>
                            <u>Dalskarr</u>
                        </th>
                        <th className="faq-table-row">
                            Light Brown
                        </th>
                        <th className="faq-table-row">
                            Wood
                        </th>
                    </tr>
                    </tbody>


                </table>

                <br/>

                IKEA picture frames come in many different sizes. They can be a great alternatives to custom framing —  if you use them with custom matboards.

                <br/><br/>

                If you got an artwork or photo print that fits into the existing matboard of the IKEA frames — great! But if they are off slightly. Or, you want a custom cut mat board to find a perfect fit between your frame and artwork, we are here.

                <br/><br/>

                <LazyLoad>
                    <video className={"what-is-mat-image-v2"} loop="true" autoPlay="autoplay" controls muted
                           src="https://s3.ca-central-1.amazonaws.com/custommat.landing.v2/0_What-is-a-matboard_1920x1290_v1+(1).mp4"></video>
                </LazyLoad>

                <br/><br/>

                That’s what we do. At custommat.ca we are the picture framing matboards experts. We are here to custom tailor your matboards to your art and your IKEA frames. Custom matboards do make difference by find a perfect fit & color between your artwork and your picture frame. We have matte, suede, and foiled matboards in 26 colors for your customization.

                <br/><br/>

                All our matboards are high-quality, acid-free (acid-neturalized) materials are sourced from our suppliers in Canada, the United States, and the United Kingdom. Next, they are expertly crafted to suit your frames in our frame shops. The best part is, we deliver to your door in a damage proof packaging anywhere in Canada and the United States for $9.95. For orders above $49, your shipping will be free.

                <br/><br/>

                <b>100% Customizable Dimensions</b>

                <br/><br/>

                If your artwork sizes are unique or nonstandard? No problem. We have options to customize your matboard opening sizes to fit any artwork within your IKEA picture frames. You can choose any custom size mat, design, shape, or cutouts for the matboard opening. Custom matting is an excellent alternative to custom framing.

                <br/><br/>

                We have customized hundreds of thousands of picture frame matboards for our customers all over the world. With our photo mats, our customers were able to create beautiful photo walls at a bargain.

                <br/><br/>

                They have been used for art exhibition’s gallery frames and home décor gallery walls. We can custom fit and elevate diplomas, awards and certificates. We offer custom calligraphy personalization options as well as paper etching. If you are looking for tutorial, please check out the link <u><a href={"/design-guide"}>here</a></u>.

                <br/><br/>

                <b>Single Matting & Double matting</b>

                <br/><br/>

                We make both single mat and double mat options. You can also do custom designs, including round and multiple openings. All our matboard opening edges are bevelled in 45 degrees like the popular matboards that we see around us. This provides visual depth and centres the framed image. Our white core when exposed through the bevelling accentuates the highlighted dimensions of the final product.

                <br/><br/>

                <b>IKEA Photo Frame Sizes</b>

                <br/><br/>

                Please click each size for more sizing information.


                <br/><br/>

                <div id={"ribba-series"}>
                    <b>IKEA Ribba Series</b>

                    <table className="pricing-table">
                        <tbody>
                        <tr>
                            <th className="faq-table-row">
                                Picture Frame Type
                            </th>
                            <th className="faq-table-row">
                                Matboard Size (inch)
                            </th>
                            <th className="faq-table-row">
                                Matboard Size (cm)
                            </th>
                        </tr>
                        </tbody>

                        <tbody>

                        {
                            Object.keys(ikeaFrameSizes.ribba.inchClean).map((size) => {
                                var urlInch = `${ikeaFrameSizes.ribba.inchClean[size]}-inch-ikea-ribba-matboards`;
                                var urlCm = `${ikeaFrameSizes.ribba.cm[size]}-cm-ikea-ribba-matboards`;
                                return <tr>
                                    <td className="faq-table-row">IKEA Ribba</td>
                                    <td className="faq-table-row"><a href={urlInch}>{ikeaFrameSizes.ribba.inchClean[size]} inch</a></td>
                                    <td className="faq-table-row"><a href={urlCm}>{ikeaFrameSizes.ribba.cm[size]} cm</a></td>
                                </tr>
                            })
                        }

                        </tbody>

                    </table>

                    <div className={"visit-ikea-page"}>
                        <u><a href={"https://www.ikea.com/us/en/cat/ribba-series-16456/"}>Click here to visit IKEA website.</a></u>
                    </div>
                </div>


                <div id={"knoppang-series"}>
                    <b>IKEA Knoppang Series</b>
                    <table className="pricing-table">
                        <tbody>
                        <tr>
                            <th className="faq-table-row">
                                Picture Frame Type
                            </th>
                            <th className="faq-table-row">
                                Matboard Size (inch)
                            </th>
                            <th className="faq-table-row">
                                Matboard Size (cm)
                            </th>
                        </tr>
                        </tbody>

                        <tbody>

                        {
                            Object.keys(ikeaFrameSizes.knoppang.inchClean).map((size) => {
                                var urlInch = `${ikeaFrameSizes.knoppang.inchClean[size]}-inch-ikea-knoppang-matboards`;
                                var urlCm = `${ikeaFrameSizes.knoppang.cm[size]}-cm-ikea-knoppang-matboards`;
                                return <tr>
                                    <td className="faq-table-row">IKEA Knoppang</td>
                                    <td className="faq-table-row">
                                        <a href={urlInch}>{ikeaFrameSizes.knoppang.inchClean[size]} inch</a>
                                    </td>
                                    <td className="faq-table-row">
                                        <a href={urlCm}>{ikeaFrameSizes.knoppang.cm[size]} cm</a>
                                    </td>
                                </tr>
                            })
                        }

                        </tbody>

                    </table>
                    <div className={"visit-ikea-page"}>
                        <u><a href={"https://www.ikea.com/us/en/cat/knoppaeng-series-30631/"}>Click here to visit IKEA website.</a></u>
                    </div>
                </div>



                <div id={"silverhojden-series"}>
                    <b>IKEA Silverhojden Series</b>
                    <table className="pricing-table">
                        <tbody>
                        <tr>
                            <th className="faq-table-row">
                                Picture Frame Type
                            </th>
                            <th className="faq-table-row">
                                Matboard Size (inch)
                            </th>
                            <th className="faq-table-row">
                                Matboard Size (cm)
                            </th>
                        </tr>
                        </tbody>

                        <tbody>

                        {
                            Object.keys(ikeaFrameSizes.silverhojden.inchClean).map((size) => {
                                var urlInch = `${ikeaFrameSizes.silverhojden.inchClean[size]}-inch-ikea-silverhojden-matboards`;
                                var urlCm = `${ikeaFrameSizes.silverhojden.cm[size]}-cm-ikea-silverhojden-matboards`;
                                return <tr>
                                    <td className="faq-table-row">IKEA Silverhojden</td>
                                    <td className="faq-table-row">
                                        <a href={urlInch}>{ikeaFrameSizes.silverhojden.inchClean[size]} inch</a>
                                    </td>
                                    <td className="faq-table-row">
                                        <a href={urlCm}>{ikeaFrameSizes.silverhojden.cm[size]} cm</a>
                                    </td>
                                </tr>
                            })
                        }

                        </tbody>

                    </table>
                    <div className={"visit-ikea-page"}>
                        <u><a href={"https://www.ikea.com/us/en/cat/silverhoejden-series-30633/"}>Click here to visit IKEA website.</a></u>
                    </div>
                </div>


                <div id={"edsbruk-series"}>
                    <b>IKEA Edsbruk Series</b>
                    <table className="pricing-table">
                        <tbody>
                        <tr>
                            <th className="faq-table-row">
                                Picture Frame Type
                            </th>
                            <th className="faq-table-row">
                                Matboard Size (inch)
                            </th>
                            <th className="faq-table-row">
                                Matboard Size (cm)
                            </th>
                        </tr>
                        </tbody>

                        <tbody>

                        {
                            Object.keys(ikeaFrameSizes.edsbruk.inchClean).map((size) => {
                                var urlInch = `${ikeaFrameSizes.edsbruk.inchClean[size]}-inch-ikea-edsbruk-matboards`;
                                var urlCm = `${ikeaFrameSizes.edsbruk.cm[size]}-cm-ikea-edsbruk-matboards`;
                                return <tr>
                                    <td className="faq-table-row">IKEA Edsbruk</td>
                                    <td className="faq-table-row">
                                        <a href={urlInch}>{ikeaFrameSizes.edsbruk.inchClean[size]} inch</a>

                                    </td>
                                    <td className="faq-table-row">
                                        <a href={urlCm}>{ikeaFrameSizes.edsbruk.cm[size]} cm</a>
                                    </td>
                                </tr>
                            })
                        }

                        </tbody>

                    </table>
                    <div className={"visit-ikea-page"}>
                        <u><a href={"https://www.ikea.com/us/en/cat/edsbruk-series-44863/"}>Click here to visit IKEA website.</a></u>
                    </div>
                </div>

                <div id={"hovsta-series"}>
                    <b>IKEA Hovsta Frames</b>

                    <table className="pricing-table">
                        <tbody>
                        <tr>
                            <th className="faq-table-row">
                                Picture Frame Type
                            </th>
                            <th className="faq-table-row">
                                Matboard Size (inch)
                            </th>
                            <th className="faq-table-row">
                                Matboard Size (cm)
                            </th>
                        </tr>
                        </tbody>

                        <tbody>

                        {
                            Object.keys(ikeaFrameSizes.hovsta.inchClean).map((size) => {
                                var urlInch = `${ikeaFrameSizes.hovsta.inchClean[size]}-inch-ikea-hovsta-matboards`;
                                var urlCm = `${ikeaFrameSizes.hovsta.cm[size]}-cm-ikea-hovsta-matboards`;
                                return <tr>
                                    <td className="faq-table-row">IKEA Hovsta</td>
                                    <td className="faq-table-row">
                                        <a href={urlInch}> {ikeaFrameSizes.hovsta.inchClean[size]} inch</a>

                                    </td>
                                    <td className="faq-table-row">
                                        <a href={urlCm}>{ikeaFrameSizes.hovsta.cm[size]} cm</a>

                                    </td>
                                </tr>
                            })
                        }

                        </tbody>

                    </table>
                    <div className={"visit-ikea-page"}>
                        <u><a href={"https://www.ikea.com/us/en/p/hovsta-frame-birch-effect-80365755/"}>Click here to visit IKEA website.</a></u>
                    </div>
                </div>

                <div id={"lomviken-series"}>
                    <b>IKEA Lomviken Frames</b>

                    <table className="pricing-table">
                        <tbody>
                        <tr>
                            <th className="faq-table-row">
                                Picture Frame Type
                            </th>
                            <th className="faq-table-row">
                                Matboard Size (inch)
                            </th>
                            <th className="faq-table-row">
                                Matboard Size (cm)
                            </th>
                        </tr>
                        </tbody>

                        <tbody>

                        {
                            Object.keys(ikeaFrameSizes.lomviken.inchClean).map((size) => {
                                var urlInch = `${ikeaFrameSizes.lomviken.inchClean[size]}-inch-ikea-lomviken-matboards`;
                                var urlCm = `${ikeaFrameSizes.lomviken.cm[size]}-cm-ikea-lomviken-matboards`;
                                return <tr>
                                    <td className="faq-table-row">IKEA Lomviken</td>
                                    <td className="faq-table-row">
                                        <a href={urlInch}>{ikeaFrameSizes.lomviken.inchClean[size]} inch</a>

                                    </td>
                                    <td className="faq-table-row">
                                        <a href={urlCm}>{ikeaFrameSizes.lomviken.cm[size]} cm</a>

                                    </td>
                                </tr>
                            })
                        }

                        </tbody>

                    </table>
                    <div className={"visit-ikea-page"}>
                        <u><a href={"https://www.ikea.com/us/en/cat/lomviken-series-38435/"}>Click here to visit IKEA website.</a></u>
                    </div>
                </div>

                <div id={"fiskbo-series"}>
                    <b>IKEA Fiskbo Frames</b>

                    <table className="pricing-table">
                        <tbody>
                        <tr>
                            <th className="faq-table-row">
                                Picture Frame Type
                            </th>
                            <th className="faq-table-row">
                                Matboard Size (inch)
                            </th>
                            <th className="faq-table-row">
                                Matboard Size (cm)
                            </th>
                        </tr>
                        </tbody>

                        <tbody>

                        {
                            Object.keys(ikeaFrameSizes.fiskbo.inchClean).map((size) => {
                                var urlInch = `${ikeaFrameSizes.fiskbo.inchClean[size]}-inch-ikea-fiskbo-matboards`;
                                var urlCm = `${ikeaFrameSizes.fiskbo.cm[size]}-cm-ikea-fiskbo-matboards`;
                                return <tr>
                                    <td className="faq-table-row">IKEA Fiskbo</td>
                                    <td className="faq-table-row">
                                        <a href={urlInch}>{ikeaFrameSizes.fiskbo.inchClean[size]} inch</a>

                                    </td>
                                    <td className="faq-table-row">
                                        <a href={urlCm}>{ikeaFrameSizes.fiskbo.cm[size]} cm</a>

                                    </td>
                                </tr>
                            })
                        }

                        </tbody>

                    </table>
                    <div className={"visit-ikea-page"}>
                        <u><a href={"https://www.ikea.com/us/en/cat/fiskbo-series-33614/"}>Click here to visit IKEA website.</a></u>
                    </div>
                </div>

                <div id={"viserum-series"}>
                    <b>IKEA Virserum Frames</b>

                    <table className="pricing-table">
                        <tbody>
                        <tr>
                            <th className="faq-table-row">
                                Picture Frame Type
                            </th>
                            <th className="faq-table-row">
                                Matboard Size (inch)
                            </th>
                            <th className="faq-table-row">
                                Matboard Size (cm)
                            </th>
                        </tr>
                        </tbody>

                        <tbody>

                        {
                            Object.keys(ikeaFrameSizes.virserum.inchClean).map((size) => {
                                var urlInch = `${ikeaFrameSizes.virserum.inchClean[size]}-inch-ikea-virserum-matboards`;
                                var urlCm = `${ikeaFrameSizes.virserum.cm[size]}-cm-ikea-virserum-matboards`;
                                return <tr>
                                    <td className="faq-table-row">IKEA Virserum</td>
                                    <td className="faq-table-row">
                                        <a href={urlInch}>{ikeaFrameSizes.virserum.inchClean[size]} inch</a>

                                    </td>
                                    <td className="faq-table-row">
                                        <a href={urlCm}>{ikeaFrameSizes.virserum.cm[size]} cm</a>

                                    </td>
                                </tr>
                            })
                        }

                        </tbody>

                    </table>
                    <div className={"visit-ikea-page"}>
                        <u><a href={"https://www.ikea.com/us/en/cat/virserum-series-18537/"}>Click here to visit IKEA website.</a></u>
                    </div>
                </div>

                <div id={"dalskarr-series"}>
                    <b>IKEA Dalskarr Frames</b>

                    <table className="pricing-table">
                        <tbody>
                        <tr>
                            <th className="faq-table-row">
                                Picture Frame Type
                            </th>
                            <th className="faq-table-row">
                                Matboard Size (inch)
                            </th>
                            <th className="faq-table-row">
                                Matboard Size (cm)
                            </th>
                        </tr>
                        </tbody>

                        <tbody>

                        {
                            Object.keys(ikeaFrameSizes.dalskarr.inchClean).map((size) => {
                                var urlInch = `${ikeaFrameSizes.dalskarr.inchClean[size]}-inch-ikea-dalskarr-matboards`;
                                var urlCm = `${ikeaFrameSizes.dalskarr.cm[size]}-cm-ikea-dalskarr-matboards`;
                                return <tr>
                                    <td className="faq-table-row">IKEA Dalskarr</td>
                                    <td className="faq-table-row">
                                        <a href={urlInch}>{ikeaFrameSizes.dalskarr.inchClean[size]} inch</a>
                                    </td>
                                    <td className="faq-table-row">
                                        <a href={urlCm}>{ikeaFrameSizes.dalskarr.cm[size]} cm</a>
                                    </td>
                                </tr>
                            })
                        }

                        </tbody>

                    </table>
                    <div className={"visit-ikea-page"}>
                        <u><a href={"https://www.ikea.com/us/en/p/dalskaerr-frame-wood-effect-light-brown-40387603/"}>Click here to visit IKEA website.</a></u>
                    </div>
                </div>





                If you have any questions, please email us at hello@custommat.ca


                <div className={"visit-custom-mat-button"}>
                    <a href={"/"} className={"carousel-read-more-button"}>
                        Visit Custom Mat
                    </a>
                </div>

                <div className={"carousel-subject-subhead-type2"}>
                    Photo Gallery
                </div>

                <LazyLoad>
                    <img src={photo1} className={"blog-content-photo"}/>
                    <img src={photo2} className={"blog-content-photo"}/>
                    <img src={photo3} className={"blog-content-photo"}/>
                    <img src={photo4} className={"blog-content-photo"}/>
                    <img src={photo5} className={"blog-content-photo"}/>
                </LazyLoad>

                <div className={"visit-custom-mat-button"}>
                    <a href={"/"} className={"carousel-read-more-button"}>
                        Visit Custom Mat
                    </a>
                </div>


            </div>



            <div>
                <div className="elfsight-app-7688ab8e-8d1b-4130-9172-7c7f3e0d45b4"></div>
            </div>



        </div>
    }
}