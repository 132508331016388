import React from "react";
import {faqContents} from "../pages-faq/faqContents";
import ReactHtmlParser from "react-html-parser";

export default class PricingAndShippingPage extends React.Component {

  fetchAnswersInParagraph(answerArray) {
    var answerHtml = "";

    answerArray.map((paragraph, key) => {
      answerHtml = answerHtml + `<div class="faq-paragraph">${paragraph}</div>`
    });

    return <div>
      {ReactHtmlParser(answerHtml)}
    </div>
  }

  render() {
    return <div className={`how-it-works-container`} id={`faq-container`}>
      <div className={"faq-header"}>Our Pricing & Shipping Information</div>
      <div className={`faq-category-header`}>Our Products</div>
      <div className={`faq-question`}>{faqContents.pricing.questions.question1.question}</div>
      {this.fetchAnswersInParagraph(faqContents.pricing.questions.question1.answers)}
      <div className={`faq-category-header`}>Shipping Info</div>
      <div className={`faq-question`}>{faqContents.shipping.questions.question3.question}</div>
      {this.fetchAnswersInParagraph(faqContents.shipping.questions.question3.answers)}
      <div className={`faq-question`}>{faqContents.shipping.questions.question5.question}</div>
      {this.fetchAnswersInParagraph(faqContents.shipping.questions.question5.answers)}
      <div className={`faq-question`}>{faqContents.shipping.questions.question6.question}</div>
      {this.fetchAnswersInParagraph(faqContents.shipping.questions.question6.answers)}
    </div>
  }
}