import React from 'react';
import axios from 'axios';
import StripeCheckout from 'react-stripe-checkout';
import STRIPE_PUBLISHABLE from './constants/stripe';
import PAYMENT_SERVER_URL from './constants/server';

// const fromDollarToCent = amount => parseInt(amount * 100);


const fromDollarToCent = amount => parseInt(amount * 100);

const successPayment = () => {
    window.location.href="/confirmation";
};

const successPaymentFr = () => {
    window.location.href="/confirmation-fr";
};

const errorPayment = () => {
    window.location.href="/error";
};

const errorPaymentFr = () => {
    window.location.href="/error-fr";
};

const onToken = (amount, description, tokenCallback, email, userName, pickUpOrShipping, shoppingCartItems, discountCodeUsed, orderReferenceNumber, deliveryAddress, quantityDiscountFactor, discountCodeDiscountFactor, itemsSubtotal, itemsShipping,  itemsTaxes, french, cadUsdRate, currency) => token => {
  //once a transaction is entered, fetch spinner starts spinning
  tokenCallback();

  var parseUserName = userName.split(" ");

  axios.post(PAYMENT_SERVER_URL,
    {
      description: description,
      source: token.id,
      currency: currency,
      amount: fromDollarToCent(amount),
    })
    .then(
        (data) => {
            axios.post(
                `https://us-central1-sendgridmailer-91a7a.cloudfunctions.net/sendMail?dest=${email}&name=${parseUserName[0]}&shipping=${pickUpOrShipping === "shipping" ? "1" : "2"}&orderReferenceNumber=${orderReferenceNumber}`,
                {
                    discountCodeUsed: discountCodeUsed,
                    quantityDiscountFactor: quantityDiscountFactor,
                    discountCodeDiscountFactor: discountCodeDiscountFactor,
                    itemsSubtotal: itemsSubtotal,
                    itemsShipping: itemsShipping,
                    itemsTaxes: itemsTaxes,
                    totalAmount: amount,
                    shoppingCartItems: shoppingCartItems,
                    deliveryName: userName,
                    deliveryAddress: deliveryAddress,
                    cadUsdRate: cadUsdRate,
                    currency: currency,
                })
                .then(() =>{
                    french ? successPaymentFr() : successPayment();
                })
                .catch(
                (error) => {
                    //this is in case email pinging fails.
                    french ? successPaymentFr() : successPayment();
                }
            )
        }
    )
    .catch(
        //this one catches the failure in payment
        (data) => {
            // axios.post(`https://us-central1-ellies-prod.cloudfunctions.net/sendMail?dest=${"won.hong@stern.nyu.edu"}&name=${"ERROR"}-${email}&shipping=${"1"}`).then(errorPayment)
            axios.post(
                `https://us-central1-sendgridmailer-91a7a.cloudfunctions.net/sendMail?dest=hello@custommat.ca&name=ERROR&shipping=${pickUpOrShipping === "shipping" ? "1" : "2"}&orderReferenceNumber=${orderReferenceNumber}`,
                {
                    discountCodeUsed: discountCodeUsed,
                    quantityDiscountFactor: quantityDiscountFactor,
                    discountCodeDiscountFactor: discountCodeDiscountFactor,
                    itemsSubtotal: itemsSubtotal,
                    itemsShipping: itemsShipping,
                    itemsTaxes: itemsTaxes,
                    totalAmount: amount,
                    shoppingCartItems: shoppingCartItems,
                    deliveryName: userName,
                    deliveryAddress: deliveryAddress,
                    cadUsdRate: cadUsdRate,
                    currency: currency,
                })
                .then(() => {
                    french ? errorPaymentFr() : errorPayment();;
                })
                .catch(() => {
                    french ? errorPaymentFr() : errorPayment();;
                })
        }
    );
};

const Checkout = ({ name, description, amount, email, currency, startSpinner, userName, pickUpOrShipping, shoppingCartItems, discountCodeUsed, orderReferenceNumber, deliveryAddress, quantityDiscountFactor, discountCodeDiscountFactor, itemsSubtotal, itemsShipping,  itemsTaxes, french, cadUsdRate }) =>

  <StripeCheckout
    name={name}
    description={description}
    amount={fromDollarToCent(amount)}
    token={onToken(amount, description, startSpinner, email, userName, pickUpOrShipping, shoppingCartItems, discountCodeUsed, orderReferenceNumber, deliveryAddress, quantityDiscountFactor, discountCodeDiscountFactor, itemsSubtotal, itemsShipping,  itemsTaxes, french, cadUsdRate, currency)}
    panelLabel="Pay"
    currency={currency}
    stripeKey={STRIPE_PUBLISHABLE}
    email={email}
    image={`https://www.custommat.ca/static/media/logo-v2.03346907.svg`}
    // zipCode
    allowRememberMe
  />

export default Checkout;