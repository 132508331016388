import React from "react";
import ReactHtmlParser from "react-html-parser";
import {faqContents} from "./faqContents"

export default class FaqPage extends React.Component {

  fetchFaq(questionCategory) {
    var faqContentsArray = Object.keys(faqContents);
    return faqContentsArray.map((questionCategoryKey, key) => {
      var questionListArray = [];
      questionListArray = Object.keys(faqContents[questionCategoryKey].questions);
      return questionListArray.map((questionKey, key) => {
        var answerArray = faqContents[questionCategoryKey].questions[questionKey].answers;
        var answerHtml = "";

        answerArray.map((paragraph, key) => {
          return answerHtml = answerHtml + `<div class="faq-paragraph">${paragraph}</div>`
        });

        return <div>
          <div className={`faq-category-header`}>{questionKey === "question1" ? faqContents[questionCategoryKey].headerTitle : ""}</div>
          <div className={`faq-question`}>{faqContents[questionCategoryKey].questions[questionKey].question}</div>
          {ReactHtmlParser(answerHtml)}
        </div>
      })
    })
  }

  render() {

    var questionCategory = "";

    return <div className={`how-it-works-container`} id={`faq-container`}>
      {this.fetchFaq(questionCategory)}
    </div>
  }
}