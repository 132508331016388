import React from 'react';
import photo1 from "../../../resources/images/blog/vintageFrames/IMG_5089.JPG";
import photo2 from "../../../resources/images/blog/vintageFrames/IMG_5096.JPG";
import photo3 from "../../../resources/images/blog/vintageFrames/IMG_4833.JPG";
import photo4 from "../../../resources/images/blog/vintageFrames/IMG_5082.JPG";
import photo5 from "../../../resources/images/blog/photoset2/photo5.jpg";
import photo6 from "../../../resources/images/blog/photoset2/photo6.jpg";
import photo7 from "../../../resources/images/blog/photoset2/photo7.jpg";
import photo8 from "../../../resources/images/blog/photoset2/photo8.png";
import photo9 from "../../../resources/images/blog/photoset2/photo9.jpg";
import photo10 from "../../../resources/images/blog/photoset2/photo10.jpg";

import photo11 from "../../../resources/images/blog/photoset2/photo11.jpg";
import photo12 from "../../../resources/images/blog/photoset2/photo12.jpg";
import photo13 from "../../../resources/images/blog/photoset2/photo13.jpg";
import photo14 from "../../../resources/images/blog/photoset2/photo14.jpg";
import photo15 from "../../../resources/images/blog/photoset2/photo15.jpg";
import photo16 from "../../../resources/images/blog/photoset2/photo16.jpg";
import photo17 from "../../../resources/images/blog/photoset2/photo17.jpg";
import photo18 from "../../../resources/images/blog/photoset2/photo18.jpg";
import photo19 from "../../../resources/images/blog/photoset2/photo19.jpg";
import photo20 from "../../../resources/images/blog/photoset2/photo20.jpg";
import photo21 from "../../../resources/images/blog/photoset2/photo21.PNG";
import photo22 from "../../../resources/images/blog/photoset2/photo22.PNG";
import photo23 from "../../../resources/images/blog/photoset2/23.k.brooke.home.PNG";
import {Helmet} from "react-helmet";
import LazyLoad from "react-lazyload";

export default class AchievingCheapCustomFrames extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        };
    };

    componentDidMount() {

    }

    fetchImage(img) {
        return <LazyLoad>
            <img src={img} className={"blog-content-photo"}/>
        </LazyLoad>
    }

    render() {
        return <div className={"blog-app-container"}>

            <Helmet>
                <title>How to achieve cheap custom frames by being your own middleman</title>
                <meta name="description" content="The answer is yes. Quality wall frames and picture framing supplies can be sourced independently with the same quality and customization options without the mark up."/>
            </Helmet>

            <div className={"carousel-subject-head"} style={{textAlign: "center"}}>
                How to achieve cheap custom frames by being your own middleman.
            </div>

            <div className={"carousel-subject-subhead-type2"}>
                Framing on Budget
            </div>
            <div className={"carousel-subject-subhead-type3"}>
                Written by Danielle M<br/>Custom Mat Project Blogger
            </div>

            <LazyLoad>
                <img src={photo1} className={"blog-carousel-photo"}/>
            </LazyLoad>


            <div className={"blog-article-content"}>


                In an article written by Aditi Shrikant of Vox titled <a href={"https://www.vox.com/the-goods/2019/4/29/18516769/frames-framing-pictures-expensive-price"}><u>“Why is framing a picture so expensive?”</u> </a>

                he states, “young adults are decorating homes and apartments with more budget-friendly art. And while the price of prints may have dropped, the price of frames has not, leaving shoppers to wonder if they should invest in a frame that’s triple the cost of what it’s preserving.”

                <br/><br/>

                He later goes on to quote that “It’s the sheer amount of mattings, moldings, glazing’s, and frames a shop provides that drives up prices. The fewer options a business offers, the more able they are to order in bulk, therefore cutting down costs.”

                <br/><br/>

                <br/><br/>
                {this.fetchImage(photo1)}
                <br/><br/>

                With this in mind online framing services still hold too many options and a custom frame still comes at a high price. Let’s ask ourselves what are custom picture frames? Are frame options like quality frames and quality materials in nonstandard or standard sizes even accessible self sourced?

                <br/><br/>

                The answer is yes. Quality wall frames and picture framing supplies can be sourced independently with the same quality and customization options without the mark up. Online photo printing from a print shop specifically will come at a much better point of quality and price vs a picture framing service even when ordering custom frames online.

                <br/><br/>

                <br/><br/>
                {this.fetchImage(photo2)}
                <br/><br/>

                First, I got inexpensive picture frames made with quality materials from Amazon. I am sure similar quality frames are available at other retail stores like IKEA, Michael’s, Target, Homesense, etc as well).

                <br/><br/>

                High end frame styles are available at great prices to the public on Amazon from some of the same suppliers of these frame shops. Mat boards for framing pictures can be sourced much cheaper and with higher quality picture mats at a significantly lower cost. It's hard to imagine purchasing a lesser product at a higher unit price vs the urge to order custom frames online.

                <br/><br/>

                Next, I order picture frame mat boards (passé-partout) from a shop that SPECIALIZES exclusively in mat board. The website is www.custommat.ca. The online design tools let me be the framer. I can order custom cut matting or standard sizes.

                <br/><br/>

                <br/><br/>
                {this.fetchImage(photo3)}
                <br/><br/>

                Then, I just pop them in a framed photo. The final becomes luxury and elevation. A matboard is a paper-based layer positioned between the frame and glass. Every artist or photographer knows the secret to framing a work of art and custom posters is to create depth in the framing. Mat board is a 4-ply material at its standard depth and sets the stage for a showpiece. Home décor can also have a professional gallery wall feel with high quality photo matting.

                <br/><br/>
                {this.fetchImage(photo4)}
                <br/><br/>

                <br/><br/>

                The frame size does not matter with custommat.ca. I’m not concerned with acrylic plexiglass vs actual glass. Considering I am not a professional artist on any level, glass for picture frames is irrelevant for me. Sometimes I just have an art print and I want to frame it.

                <br/><br/>

                Custom size frames online are not necessary if I can go up a frame size or two in standard sizes. Beautiful metal frames, shadowboxes, wood frames, basically interior design gold are just sitting on shelves completely underrated at retail stores around us.

                <br/><br/>

                After self sourcing retail frames, I used a professional print from a LOCAL specialty print shop (no shipping cost and supporting local. Last came ordering a custom cut mat board from the above mentioned workshop www.custommat.ca). Finally I was left with professionally framed pictures through what was a total DIY.

                <br/><br/>

                My cost savings compared to using a custom framing shop online was $80 per photo with a total of 6 photos. That is a total savings of $480, which is simply incredible. I was brought back to a time when my mother spent $800 on a set of frames to display precious family photos.

                <br/><br/>

                If only she was able to cut out the middleman like I have learned through this experiment. This is simply due to lack of knowledge of just how much mark-up comes from unnecessary variety suffered by custom picture framing suppliers Toronto and other major cities offer.

                <br/><br/>

                Even the larger online custom framers for a “one-stop-shop” are not much cheaper. These options come with the risk of drop-shipping middlemen. The year is 2022, I am fully capable of ordering the same $ .99 frame from Wish and paying the heavy shipping.

                <br/><br/>

                It will still be cheaper than working with drop shippers when all is said and done. Achieve cheap custom framed pieces by being your own middleman self-sourcing from print to frame to mat board if you also feel like saving those millennial dollars! See photo gallery below!

                <br/><br/>


                <div className={"visit-custom-mat-button"}>
                    <a href={"/"} className={"carousel-read-more-button"}>
                        Visit Custom Mat
                    </a>
                </div>

                {this.fetchImage(photo1)}
                {this.fetchImage(photo2)}
                {this.fetchImage(photo3)}
                {this.fetchImage(photo4)}
                {this.fetchImage(photo5)}
                {this.fetchImage(photo6)}
                {this.fetchImage(photo7)}
                {this.fetchImage(photo8)}
                {this.fetchImage(photo9)}
                {this.fetchImage(photo10)}
                {this.fetchImage(photo11)}
                {this.fetchImage(photo12)}
                {this.fetchImage(photo13)}
                {this.fetchImage(photo14)}
                {this.fetchImage(photo15)}
                {this.fetchImage(photo16)}
                {this.fetchImage(photo17)}
                {this.fetchImage(photo18)}
                {this.fetchImage(photo19)}
                {this.fetchImage(photo20)}

                <div className={"visit-custom-mat-button"}>
                    <a href={"/"} className={"carousel-read-more-button"}>
                        Visit Custom Mat
                    </a>
                </div>


            </div>

            <div>
                <div className="elfsight-app-7688ab8e-8d1b-4130-9172-7c7f3e0d45b4"></div>
            </div>







        </div>
    }
}