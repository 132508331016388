import React from 'react';
import {productDB} from "../../../../javascript/components/comp-pricing/productDB";
import Tooltip from '@material-ui/core/Tooltip';
import arrowUp from "../../../../resources/svgIcons/select-mat-arrow-up.svg";
import warningIcon from "../../../../resources/svgIcons/warning-icon.svg";
import arrowDown from "../../../../resources/svgIcons/select-mat-arrow-down.svg";

export default class MatBoardActions extends React.Component {

    constructor() {
        super();
        this.state = {
            colorModalOpen: true,
        };
    };

    fetchModal() {
        this.setState({colorModalOpen: !this.state.colorModalOpen});
    }

    fetchSelectedMatColor() {
        if (productDB.matboards[this.props.matColour]) {
            return this.props.french ? productDB.matboards[this.props.matColour].colorFr : productDB.matboards[this.props.matColour].color;
        } else {
            return "";
        }
    }

  render() {
    return <div className={`select-mat-section-frame-buttons`}>

        <div className={"select-mat-row"}>
            <img className={"select-matboard-window-caption-arrow add-margin-buffer-5px"}
                 onClick={() => this.setState({colorModalOpen: !this.state.colorModalOpen})}
                 src={this.state.colorModalOpen ? arrowUp : arrowDown}
                 alt={this.props.french ? "Développez pour plus d'options." : "Expand for more options."}
            />


            <div className={"select-mat-row-divider"}>
                <div className="select-matboard-window-caption-text matboard-colour-name-desc" id={"select-matboard-window-caption-text-double-matting"}>
                    {this.props.fetchLanguage("Couleur", `Matboard ${this.props.location === "us" ? "Color" : "Colour"}`)}
                </div>
                <div className="select-matboard-window-caption-selected-colour-text" id={"select-matboard-window-captiaon-selected-colour-text-mobile"}>
                    <div className={"matboard-colour-name matboard-colour-name-desc"}>
                        {this.fetchSelectedMatColor()}
                    </div>
                    <picture>
                        <source type="image/webp" srcSet={productDB.matboards[this.props.matColour] ? productDB.matboards[this.props.matColour].webp : ""}/>
                        <source type="image/png" srcSet={productDB.matboards[this.props.matColour] ? productDB.matboards[this.props.matColour].img : ""}/>
                        <Tooltip title={productDB.matboards[this.props.matColour] ? productDB.matboards[this.props.matColour].color : ""} placement={"top"}>
                            <img alt={"Click to add window."} className={"matboard-selected-colour"} src={productDB.matboards[this.props.matColour] ? productDB.matboards[this.props.matColour].webp : ""}
                                alt={this.props.french ? "Développez pour plus d'options." : "Expand for more options."}
                            />
                        </Tooltip>
                    </picture>
                </div>
            </div>

        </div>

        <div className={this.state.colorModalOpen ? "select-mat-size-option-toggle" : "no-display"}>
            <div className={"select-mat-sub-row"}>

                {/*<div style={{width: "100%", overflow: "auto"}}>*/}
                {/*    <img className={"select-mat-guidance-icon"} src={warningIcon}*/}
                {/*         alt={this.props.french ? "Veuillez consulter le guide pour plus de détails sur les couleurs" : "Please see guide for colour details."}*/}
                {/*    />*/}
                {/*    <div className={"select-mat-guidance-desc"}>*/}
                {/*        {this.props.fetchLanguage("Veuillez consulter le guide pour plus de détails sur les couleurs", "Please see guide for colour details.")}*/}
                {/*    </div>*/}
                {/*    <div className={"select-mat-guidance-button"}>*/}
                {/*        <a href={this.props.fetchLanguage("colour-guide-fr", "colour-guide")}>*/}
                {/*            Guide*/}
                {/*        </a>*/}
                {/*    </div>*/}
                {/*</div>*/}


                <div className={"change-matboard-colour-layout"}>
                    {this.props.fetchMatboardColours("matColour")}
                </div>

                <div className="select-mat-input-field-direction-long" id={"colour-description-container"}>
                    {`${this.props.french ? productDB.matboards[this.props.matColour].colorFr: productDB.matboards[this.props.matColour].color}: ${this.props.french ? productDB.matboards[this.props.matColour].descFr: productDB.matboards[this.props.matColour].desc}`}
                </div>

                {/*</div>*/}
                <div className={"select-mat-request-samples"} onClick={() => {this.props.clickAddSampleButton()}}>
                    {this.props.fetchLanguage("Demander des échantillons de passe-partouts", "Request Matboard Samples")}
                </div>
            </div>
        </div>

    </div>



  }
}