import React from 'react';
import photo1 from "../../../resources/images/matboardBlogPage/singleMatEtsyListing.png";
import photo2 from "../../../resources/images/matboardBlogPage/DoubleMatEtsyListing.png";
import photo3 from "../../../resources/images/matboardBlogPage/bevelCut.png";
import photo4 from "../../../resources/images/backingMats/BackingRectangle2.jpg";
import photo5 from "../../../resources/images/backingMatBlogPage/backingMatDirections.jpg";
import photo6 from "../../../resources/images/backingMats/BackingRectangle3.jpg";

import LazyLoad from "react-lazyload";
import {Helmet} from "react-helmet";

export default class Matboards extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        };
    };

    componentDidMount() {

    }

    render() {
        return <div className={"blog-app-container"}>

            <Helmet>
                <title>
                    Artwork Presentation & Matboard Guide | Custom Matboards | Show Kits
                </title>
                <meta name="description" content="Explore the world of matboards and elevate your artwork presentation. Learn about matboard types, double matting, and the Show Kit. Find out how to choose the right matting for your frames."/>
            </Helmet>

            <h1 className={"carousel-subject-head"} style={{textAlign: "center"}}>
                Enhance Your Artwork Presentation: Matboard Types, Double Matting, and Show Kits
            </h1>

            <div className={"carousel-subject-subhead-type2"}>
                Ideas
            </div>

            <LazyLoad>
                <video className={"what-is-mat-image-v2"} loop="true" autoPlay="autoplay" alt={"Exploring Matboards: An Informative Illustrative Video. Discover the mechanics behind matboards in this engaging video. Learn about an innovative approach to custom framing—pairing a standard-sized frame from online or retail stores with our bespoke matboards."} controls muted
                       src="https://s3.ca-central-1.amazonaws.com/custommat.landing.v2/0_What-is-a-matboard_1920x1290_v1+(1).mp4"></video>
            </LazyLoad>

            <br/>

            <div className={"blog-article-content"}>

                <h2>What is a Matboard?</h2>

                <br/>

                A matboard** serves as a crucial element in framing artwork. This 4-ply dense layer of specialty art paper is positioned between the glass or acrylic of your frame and your artwork.

                <br/><br/>


                Its composition typically involves acid-neutralized or acid-free components, facilitating a seamless transition between the frame and your valuable piece. In fact, virtually every framed artwork you encounter incorporates a matboard.

                <br/><br/>

                ** Which also goes by various names like passepartout, matting, matting layer, picture frame matboard, frame mat, or matte board.

                <br/><br/>

                <LazyLoad>
                    <img className="blog-article-photo-two-image" src={photo1} alt={"Fully Customizable Single Layer Matboard (Single Mat) Listing."}/>
                    <img className="blog-article-photo-two-image" src={photo2} alt={"Fully Customizable Double Layer Matboard (Double or Accent Mat) Listing."}/>
                </LazyLoad>

                <br/><br/>

                <h2>Where Can You Find Matboards?</h2>

                <br/>

                If you're in search of matboards, whether customized to specific dimensions, pre-cut for convenience, available in standard sizes, or even provided in uncut mat board full sheet, look no further than CustomMat (internal link).

                <br/><br/>

                We offer a comprehensive selection, with convenient delivery to destinations across Canada and the United States. Plus, enjoy free shipping for orders exceeding $49.

                <br/><br/>

                Alternatively, if you prefer a traditional shopping experience, you can explore options at local picture framing stores or art supplies outlets. Keep in mind that while the variety might be somewhat limited, these establishments still offer a viable avenue for obtaining matboards.


                <div className={"visit-custom-mat-button"}>
                    <a href={"https://www.custommat.ca/customize-matboards"} className={"carousel-read-more-button"}>
                        Customize a Matboard
                    </a>
                </div>


                <h2>
                    Where and Who Manufactures Matboards?
                </h2>

                <br/>

                Matboards are crafted in various locations across Canada and the United States. For instance, Ontario is home to Peterboro Matboards, a notable producer of high-quality matboards. Similarly, Crescent matboards are both manufactured and distributed across the United States.

                <br/><br/>

                A common practice involves cutting these matboards with 45-degree bevels, enhancing the visual appeal of framed artworks. The bevel cut serves to draw the viewer's attention and elevates the overall presentation.


                <br/><br/>

                <LazyLoad>
                    <img className="blog-content-photo" src={photo3} style={{width: "80%", marginLeft: "10%"}} alt="Illustration demonstrating a 45-degree bevel cut on a matboard." />
                </LazyLoad>

                <br/><br/>

                <h2>
                    Is a Matboard Necessary for Every Framed Artwork?
                </h2>

                <br/>

                While not every picture frame requires a matboard, the majority of frames benefit from their inclusion. Matboards have a transformative effect on the appearance of framed pieces, enhancing their overall visual impact.

                <br/><br/>

                Custom framing often involves the use of custom-cut matboards. However, achieving an aesthetically pleasing result is equally possible by pairing standard-sized picture frames with our range of customizable matboards. This combination allows for the creation of stunning framed art that captivates viewers.

                <br/><br/>

                <h2>
                    Unveiling Our Secret Sauce: Making Your Artwork Fit Any Frame
                </h2>

                <LazyLoad>
                    <video className={"what-is-mat-image-v2"} loop="true" autoPlay="autoplay" alt={"Exploring Matboards: An Illustrative Video. Discover the mechanics behind matboards in this engaging video. Learn about an innovative approach to custom framing—pairing a standard-sized frame from online or retail stores with our bespoke matboards."} controls muted
                           src="https://s3.ca-central-1.amazonaws.com/custommat.landing.v2/0_What-is-a-matboard_1920x1290_v1+(1).mp4"></video>
                </LazyLoad>

                <br/>

                <b>
                    Unveiling the Process
                </b>

                <ul>
                    <li>
                        Step 1: Acquire a Standard-Sized Picture Frame from Amazon or a Store.
                    </li>
                    <li>
                        Step 2: Obtain a Custom Matboard from Us. Provide the Dimensions.
                    </li>
                    <li>
                        Step 3: Effortless Assembly in Less than 2 Minutes. Your Artwork is Ready to Grace Your Wall.
                    </li>
                </ul>

                <div className={"visit-custom-mat-button"}>
                    <a href={"https://www.custommat.ca/how-it-works"} className={"carousel-read-more-button"}>
                        More on How it Works
                    </a>
                </div>

                The majority of available matboards feature a white core. Occasionally, you might come across options like black core or production core matboards.

                <br/><br/>

                However, it's worth noting that white-core matboards dominate the market — and this is precisely what we offer at Custom Mat to cater to our valued customers' preferences.

                <br/><br/>

                <LazyLoad>
                    <img className="blog-article-photo-two-image" src={photo4} alt={"Illustrations showcasing the side view of a backing matboard or mounting matboard."}/>
                    <img className="blog-article-photo-two-image" src={photo5} alt={"Product description for our mounting mat (also known as backing matboard) available at CustomMat. These consist of matboards with overall size and no opening. Customize outer measurements and color. Quantity discounts offered."}/>
                </LazyLoad>

                <br/><br/>

                <b>Can Matboards Serve as Mounting Boards?</b>

                <br/><br/>

                In certain instances, matboards serve a dual purpose as backing boards, eliminating the need for a window cutout. This variation is used as a backing mat, effectively replacing foam boards.

                <br/><br/>

                These backing matboards play a crucial role in securely mounting the artwork, safeguarding it against potential creases and wrinkles when framing.

                <br/><br/>

                While opting for a backing matboard is undoubtedly a prudent choice, it's not an absolute necessity.

                <br/><br/>

                Not every artwork demands mounting with archival adhesives, and the decision ultimately depends on the individual piece's requirements.

                <div className={"visit-custom-mat-button"}>
                    <a href={"http://www.custommat.ca/backing-mat-boards"} className={"carousel-read-more-button"}>
                        Order Backing Matboards
                    </a>
                </div>

                <LazyLoad>
                    <img className="blog-content-photo" src={photo2} style={{width: "80%", marginLeft: "10%"}}  alt={"Description of Double Matting Kit and what it entails."}/>
                </LazyLoad>

                <h2>
                    What Exactly is Double Matting?
                </h2>

                <br/>

                Double matting involves an extra layer of matting strategically positioned beneath the initial mat board. Nestled between the primary mat and your artwork, this additional layer brings a new dimension to your framed piece.

                <br/><br/>

                By introducing an extra border and layer of depth, double matting enhances the visual impact of your work and directs focus to the framed content.

                <br/><br/>


                <LazyLoad>
                    <img className="blog-content-photo" style={{width: "80%", marginLeft: "10%"}}  src="https://www.custommat.ca/static/media/watercolorsmakemesmile-IG.b44ebc1f.PNG" alt={"Customer project with a double matted matboard."}/>
                </LazyLoad>

                <h2>
                    Choosing Your Double Matting Style
                </h2>

                <br/>

                The beauty of double matting lies in its versatility. You have the choice to match the color of your double matting with your primary mat or opt for a distinct color that makes your artwork truly stand out.

                <br/><br/>

                Often, selecting a dominant hue from your artwork as the double matting color accentuates the visual appeal.

                <br/><br/>


                <h2>
                    Understanding Double Matting Reveal
                    <br/>
                    What is double matting reveal?
                </h2>

                <br/>


                The term "double matting reveal" pertains to the width of the exposed area showcasing the double matting.

                <br/><br/>

                For instance, if you decide on a "smooth black" double matting with a reveal of 0.25 inches, there will be a visible 0.25 inches of the "smooth black" mat board between your artwork and the primary matboard.

                <br/><br/>


                <h2>
                    Is Double Matting Right for You?
                </h2>

                <br/>

                The choice to incorporate double matting is entirely yours. This technique introduces an added layer of depth, enhancing the overall aesthetics of your piece.
                <br/><br/>

                By providing an additional focal point, double matting can significantly elevate the impact of your artwork.
                <br/><br/>

                Feel free to play with color options for your double matting. Whether it harmonizes with the primary mat or presents a striking contrast, the chosen color can truly make your artwork come alive.
                <br/><br/>

                Often, the double matting color is drawn from a dominant shade in your artwork, serving as the accent color that captures attention.

                <div className={"visit-custom-mat-button"}>
                    <a href={"https://www.custommat.ca/customize-matboards"} className={"carousel-read-more-button"}>
                        Customize a Matboard
                    </a>
                </div>

                <h2>
                    Introducing the Show Kit: Elevating Your Artwork Presentation
                </h2>

                <br/><br/>

                <LazyLoad>
                    <img className="blog-content-photo" style={{width: "80%", marginLeft: "10%"}}  src="https://i.etsystatic.com/30485840/r/il/0464b2/4891298498/il_794xN.4891298498_lo7o.jpg" alt={"Description of Matboard Show Kit also known as Presentation Kit. This kit includes a custom-sized matboard with a personalized opening cutout in any size or shape, backing matboard for mounting, and a show bag with adhesive sleeves. Designed for artists and photographers, this kit ensures that their creations captivate and withstand their journey to the intended audience unscathed."}/>
                </LazyLoad>

                <br/><br/>

                <h2>
                    What is a show kit / presentation kit?
                </h2>

                <br/>

                The Show Kit is a comprehensive package consisting of three essential components:

                <br/><br/>

                <ol>
                    <li>
                        Custom Matboard (or Pre-cut Mats): Crafted to your specifications, these matboards add a touch of sophistication to your artwork presentation.
                    </li>
                    <li>
                        Backing Matboard: Providing structural support, the backing matboard ensures your artwork remains secure and wrinkle-free within the frame.
                    </li>
                    <li>
                        Clear Bag (or Show Bag) with Adhesive Strip: This protective layer not only shields your artwork from potential damage but also adds a professional touch to your presentation.
                    </li>
                </ol>


                <h2>
                    Who's in for the Show Kit?
                </h2>

                <br/>

                Artists, photographers, and thoughtful gift-givers are the primary enthusiasts of the Show Kit. This ensemble is the ideal choice for those who seek to showcase their creations in an elevated and aesthetically pleasing manner. Notably, the Show Kit effectively boosts the perceived value of the presented artwork.

                <div className={"visit-custom-mat-button"}>
                    <a href={"http://www.custommat.ca/show-presentation-kit"} className={"carousel-read-more-button"}>
                        Order Show Kit
                    </a>
                </div>

                <div className={"how-to-measure-video-section"} style={{height: "480px"}}>
                    {/*<img src={sizingGuidePhoto} className={"matboard-size-selection-demo-photo"}/>*/}
                    <iframe width="100%" height="100%" src="https://www.youtube.com/embed/t-YQxHYTkUQ" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            alt="A video on how to measure a matboard size and artwork window size."
                            allowFullScreen></iframe>
                </div>

                <br/><br/>

                <h2>
                    Sizing Up Your Matboard: A Quick Guide
                </h2>

                <br/>

                Determining the perfect matboard size involves two key measurements:

                <ol>
                    <li>
                        <b>Overall Matboard Size:</b> Tailored to fit your frame, this size is frame-dependent.
                    </li>
                    <li>
                        <b>Cutout / Window Size:</b> This dimension hinges on the size of your artwork.
                    </li>
                </ol>

                <br/>

                For precise instructions on measuring, head over to our comprehensive Design Guide. It offers step-by-step guidance to ensure your matboard perfectly complements your artwork and frame.


                <div className={"visit-custom-mat-button"}>
                    <a href={"https://www.custommat.ca/design-guide"} className={"carousel-read-more-button"}>
                        More on Measuring a Mat
                    </a>
                </div>

                <h2>
                    How Thick is a Matboard?
                </h2>

                <br/>

                When it comes to the thickness of matboards, the standard options usually come in what's known as 4-ply, which translates to about 1/16th of an inch in thickness. However, there are also thicker alternatives available.

                <br/><br/>

                You'll find 6-ply and 8-ply matboards in the market, and these are significantly thicker compared to the typical 4-ply ones you might come across in galleries or museums.

                <br/><br/>

                <h2>
                    How to Properly Cut a Matboard?
                </h2>

                <br/>

                Matboards consist of dense layers of art paper, and while it's technically possible to cut them at home using an Xacto Knife, it's not the most recommended approach.

                <br/><br/>

                Due to the density of matboards and the need for precision, especially with a beveled edge, it's best to seek specialized assistance.

                <br/><br/>

                Consider reaching out to a specialty matboard shop like ours (CustomMat) or a professional framer for expert guidance and proper cutting.

                <br/><br/>

                The intricacies involved in getting a clean, well-finished cut make professional help a valuable choice in working with matboards.


                <div className={"visit-custom-mat-button"}>
                    <a href={"https://www.custommat.ca/how-it-works"} className={"carousel-read-more-button"}>
                        Visit Custom Mat & Learn More
                    </a>
                </div>

            </div>



        </div>
    }
}