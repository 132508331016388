import React from "react";
import LazyLoad from 'react-lazyload';
import photo1_1 from "../../../resources/images/landingPage/igFeed/photo1-1.png";
import photo1_2 from "../../../resources/images/landingPage/igFeed/photo1-2.png";
import photo2_1 from "../../../resources/images/landingPage/igFeed/photo2-1.jpg";
import photo2_2 from "../../../resources/images/landingPage/igFeed/photo2-2.png";
import photo3_1 from "../../../resources/images/landingPage/igFeed/photo3-1.jpg";
import photo3_2 from "../../../resources/images/landingPage/igFeed/photo3-2.png";
import photo4_1 from "../../../resources/images/landingPage/igFeed/photo2-1.jpg";
import photo4_2 from "../../../resources/images/landingPage/igFeed/photo4-2.jpg";


export default class SatisfactionTransition extends React.Component {


  render() {
    return <div className={"landing-page-review-transition-ending-v3"} id={"satisfaction-guarantee-container"}>
      <div className={"landing-page-review-transition-ending-header-v3"}>
        {this.props.french ? "Notre alternative économique" : "Our Budget Friendly"}<br/>
        {this.props.french ? "à l'encadrement sur mesure" : "Alternative to Custom Framing"}
      </div>

      <div className={"transition-ig-feed"}>

        <div className={"transition-ig-feed-container mobile-no-display tablet-no-display"}>
          <div className={"transition-ig-feed-top"}>
            <div className={"transition-ig-feed-top-left"}>
              <LazyLoad>
                <img src={photo1_1} className={"transition-ig-feed-top-img"}/>
              </LazyLoad>
            </div>
            <div className={"transition-ig-feed-top-right"}>
              <b>RoamingFree</b><br/>
              @romular
            </div>
          </div>
          <div className={"transition-ig-feed-bottom"}>
            <LazyLoad>
              <img src={photo1_2} className={"transition-ig-feed-bottom-img"} />
            </LazyLoad>
          </div>
        </div>

        <div className={"transition-ig-feed-container"}>
          <div className={"transition-ig-feed-top"}>
            <div className={"transition-ig-feed-top-left tablet-no-display"}>
              <LazyLoad>
                <img src={photo2_1} className={"transition-ig-feed-top-img"}/>
              </LazyLoad>
            </div>
            <div className={"transition-ig-feed-top-right"}>
              <b>Olivia O.</b><br/>
              <span className={"mobile-no-display tablet-no-display"}>{this.props.french ? "Envoyé par email" : "Sent from Email"}</span>
            </div>
          </div>
          <div className={"transition-ig-feed-bottom"}>
            <LazyLoad>
              <img src={photo2_2} className={"transition-ig-feed-bottom-img"}/>
            </LazyLoad>
          </div>
        </div>

        <div className={"transition-ig-feed-container mobile-no-display tablet-no-display"}>
          <div className={"transition-ig-feed-top"}>
            <div className={"transition-ig-feed-top-left"}>
              <LazyLoad>
                <img src={photo3_1} className={"transition-ig-feed-top-img"}/>
              </LazyLoad>
            </div>
            <div className={"transition-ig-feed-top-right"}>
              <b>Obviunder</b><br/>
              @obvi
            </div>
          </div>
          <div className={"transition-ig-feed-bottom"}>
            <LazyLoad>
              <img src={photo3_2} className={"transition-ig-feed-bottom-img"}/>
            </LazyLoad>
          </div>
        </div>

        <div className={"transition-ig-feed-container mobile-no-display tablet-no-display"}>
          <div className={"transition-ig-feed-top"}>
            <div className={"transition-ig-feed-top-left"}>
              <LazyLoad>
                <img src={photo4_1} className={"transition-ig-feed-top-img"}/>
              </LazyLoad>
            </div>
            <div className={"transition-ig-feed-top-right"}>
              <b>Josh K.</b><br/>
              {this.props.french ? "Envoyé par email" : "Sent from Email"}
            </div>
          </div>
          <div className={"transition-ig-feed-bottom"}>
            <LazyLoad>
              <img src={photo4_2} className={"transition-ig-feed-bottom-img"}/>
            </LazyLoad>
          </div>
        </div>

      </div>

      <a href={"/inspiration"} className={"transition-ig-feed-text"}>
        {this.props.french ? "Voir plus de photos de nos clients" : "See more of our Customer photos"}
      </a>
    </div>
  }
}