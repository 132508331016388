import React from 'react';
import RegionFetcher from "../regionFetcher";
import photoWebp from "../../../../resources/images/region/webp/portland.webp";
import photoReduced from "../../../../resources/images/region/reduced/portland.jpg";
import {Helmet} from "react-helmet";

export default class ShowPortlandPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      cityName: "Portland",
      photoWebp: photoWebp,
      photoReduced: photoReduced,
    }
  }

  render() {
    return <div className={`gallery-container`}>
      <Helmet>
        <title>Custom Cut Mat Boards • Order Online • Deliver to Portland</title>
        <meta name="description" content="We cut custom mat board for your picture frames. We tailor mat boards and deliver to Portland. Explore ways save on picture framing."/>
      </Helmet>

      <RegionFetcher article={this.state}/>
    </div>
  }
}