export default function timestampEnc (encDec, timestamp) {
  var input = timestamp;
  var output;

  if (encDec === "enc") {
    output = input.split("1").join("z");
    output = output.split("2").join("c");
    output = output.split("3").join("k");
    output = output.split("4").join("y");
    output = output.split("5").join("x");
    output = output.split("6").join("t");
    output = output.split("7").join("l");
    output = output.split("8").join("m");
    output = output.split("9").join("q");
    output = output.split("-").join("r");
    return output;

  } else if (encDec === "dec") {
    output = input.split("z").join("1");
    output = output.split("z").join("1");
    output = output.split("c").join("2");
    output = output.split("k").join("3");
    output = output.split("y").join("4");
    output = output.split("x").join("5");
    output = output.split("t").join("6");
    output = output.split("l").join("7");
    output = output.split("m").join("8");
    output = output.split("q").join("9");
    output = output.split("r").join("-");
    return output;
  }
}