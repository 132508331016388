export const ikeaFrameSizes = {
    ribba: {
        inch: ["5x7", "7x5", "8x10", "10x8", "12x16", "16x12", "16x20", "20x16", "19.75x27.5", "19 3/4x27 1/2", "27.5x19.75", "27 1/2x19 3/4", "24x35.75", "24x35 3/4", "35.75x24", "35 3/4x24"],
        inchClean: ["5x7", "7x5", "8x10", "10x8", "12x16", "16x12", "16x20", "20x16", "19.75x27.5", "27.5x19.75", "24x35.75", "35.75x24"],
        cm: ["13x18", "18x13", "20x25", "25x20", "31x41", "41x31", "41x51", "51x41", "50x70", "70x50", "61x91", "91x61"],
    },
    knoppang: {
        inch: ["5x7", "7x5", "8x10", "10x8", "12x16", "16x12", "16x20", "20x16", "19.75x27.5", "19 3/4x27 1/2", "27.5x19.75", "27 1/2x19 3/4", "24x35.75", "24x35 3/4", "35.75x24", "35 3/4x24"],
        inchClean: ["5x7", "7x5", "8x10", "10x8", "12x16", "16x12", "16x20", "20x16", "19.75x27.5", "27.5x19.75", "24x35.75","35.75x24"],
        cm: ["13x18", "18x13", "20x25", "25x20", "31x41", "41x31", "41x51", "51x41", "50x70", "70x50", "61x91", "91x61"],
    },

    silverhojden: {
        inch: ["5x7", "7x5", "8x10", "10x8", "12x16", "16x12", "16x20", "20x16", "19.75x27.5", "19 3/4x27 1/2", "27.5x19.75", "27 1/2x19 3/4", "24x35.75", "24x35 3/4", "35.75x24", "35 3/4x24"],
        inchClean: ["5x7", "7x5", "8x10", "10x8", "12x16", "16x12", "16x20", "20x16", "19.75x27.5", "27.5x19.75", "24x35.75","35.75x24"],
        cm: ["13x18", "18x13", "20x25", "25x20", "31x41", "41x31", "41x51", "51x41", "50x70", "70x50", "61x91", "91x61"],
    },

    edsbruk: {
        inch: ["5x7", "7x5", "8x10", "10x8", "12x16", "16x12", "16x20", "20x16", "19.75x27.5", "19 3/4x27 1/2", "27.5x19.75", "27 1/2x19 3/4", "24x35.75", "24x35 3/4", "35.75x24", "35 3/4x24"],
        inchClean: ["5x7", "7x5", "8x10", "10x8", "12x16", "16x12", "16x20", "20x16", "19.75x27.5", "27.5x19.75", "24x35.75","35.75x24"],
        cm: ["13x18", "18x13", "20x25", "25x20", "31x41", "41x31", "41x51", "51x41", "50x70", "70x50", "61x91", "91x61"],
    },

    hovsta: {
        inch: ["8x10", "10x8", "12x16", "16x12", "16x20", "20x16", "19.75x27.5", "19 3/4x27 1/2", "27.5x19.75", "27 1/2x19 3/4", "24x35.75", "24x35 3/4", "35.75x24", "35 3/4x24"],
        inchClean: ["8x10", "10x8", "12x16", "16x12", "16x20", "20x16", "19.75x27.5", "27.5x19.75","24x35.75", "35.75x24"],
        cm: ["20x25", "25x20", "31x41", "41x31", "41x51", "51x41", "50x70", "70x50", "61x91", "91x61"],

    },


    lomviken: {
        inch: ["8x10", "10x8", "12x16", "16x12", "16x20", "20x16", "19.75x27.5", "19 3/4x27 1/2", "27.5x19.75", "27 1/2x19 3/4", "24x35.75", "24x35 3/4", "35.75x24", "35 3/4x24"],
        inchClean: ["8x10", "10x8", "12x16", "16x12", "16x20", "20x16", "19.75x27.5", "27.5x19.75","24x35.75", "35.75x24"],
        cm: ["20x25", "25x20", "31x41", "41x31", "41x51", "51x41", "50x70", "70x50", "61x91", "91x61"],
    },

    fiskbo: {
        inch: ["5x7", "7x5", "8x10", "10x8", "12x16", "16x12", "16x20", "20x16", "19.75x27.5", "19 3/4x27 1/2", "27.5x19.75", "27 1/2x19 3/4"],
        inchClean: ["5x7", "7x5", "8x10", "10x8", "12x16", "16x12", "16x20", "20x16", "19.75x27.5", "27.5x19.75"],
        cm: ["13x18", "18x13", "20x25", "25x20", "31x41", "41x31", "41x51", "51x41", "50x70", "70x50"],
    },


    virserum: {
        inch: ["5x7", "7x5", "16x12",],
        inchClean: ["5x7", "7x5", "16x12",],
        cm: ["13x18", "18x13", "31x41"],
    },

    dalskarr: {
        inch: ["16x12",],
        inchClean: ["16x12",],
        cm: ["31x41"],
    },

}