import React from "react";

import matboardImageImage from "../../../../resources/images/products/matboard/matboard.png";
import matboardImageWebp from "../../../../resources/images/products/matboard/matboard.webp";
import matAndFrameImage from "../../../../resources/images/products/frameAndMat/framendMat.png";
import matAndFrameWebp from "../../../../resources/images/products/frameAndMat/frameAndMat.webp";
import polaroidFrame from "../../../../resources/images/products/polaroidFrame/polaroid.png";
import polaroidWebp from "../../../../resources/images/products/polaroidFrame/polaroid.webp";
import checkLatestVersion from "./checkLatestVersion";

export default class ProductOffering extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      productList: {
        matboard: {
          href: "/customize-matboards",
          image: matboardImageImage,
          webp: matboardImageWebp,
          english: {
            header: "CUSTOM MATBOARD",
            productListingDesc: [
              `Guaranteed perfect fit`,
              `for your frame. Completely`,
              `customized from size to colour.`,
            ],
            price: `CA $8.95 - CA $39.95`,
          },
          french: {
            header: "Passe-Partout Personnalisés",
            productListingDesc: [
              `Personnalisez votre cadre`,
              `et passe-partout en ligne.`,
              `Haute qualité, bas prix, sans difficulté.`,
            ],
            price: `9,99$ - 19,99$`,
          },
        },
        frameAndMatboard: {
          // href: "/select-frames",
          href: "/frames-with-custom-matboards",
          image: matAndFrameImage,
          webp: matAndFrameWebp,
          english: {
            header: "FRAMES & CUSTOM MATBOARDS",
            productListingDesc: [
              `The perfect combination for your art.`,
              `Your customized mat with`,
              `your choice of frame.`,
            ],
            price: `CA $29.99 - CA $74.99`,
          },
          french: {
            header: "Cadre + passe-Partout",
            productListingDesc: [
              `La combinaison parfaite pour votre art.`,
              `Votre passe-partout personnalisé`,
              `avec votre choix de cadre.`,
            ],
            price: `9,99$ - 69,99$`,
          },
        },
        polaroidFrames: {
          // href: "/select-polaroid-type",
          href: "/frames-with-custom-matboards",
          image: polaroidFrame,
          webp: polaroidWebp,
          english: {
            header: "POLAROID FRAMES",
            productListingDesc: [
              `Make your memories`,
              `look as good as they felt.`,
              `Frames designed for polaroid photos.`,
            ],
            price: `CA $29.99 - CA $74.99`,
          },
          french: {
            header: "Cadres Polaroid",

            productListingDesc: [
              `Vos souvenirs seront aussi beaux que`,
              `dans votre mémoire. Cadres créés `,
              `spécifiquement pour les photos polaroid.`,
            ],
            price: `12,99$ - 69,99$`,
          }
        },
      }
    }
    ;
  }

  fetchProducts() {
    var productListKeys = Object.keys(this.state.productList);
    var productObjects = this.state.productList;
    return <div>
      {
        productListKeys.map((item, key) => {
          var productDesc = this.props.language === "en" ? productObjects[item].english : productObjects[item].french;
          return <div className={"product-list-item"} id={`product-list-item-box-${key}`} key={`product-list-item-box-key-${key}`}>
            <picture>
              <source type="image/webp" srcSet={productObjects[item].webp}/>
              <source type="image/png" srcSet={productObjects[item].image}/>
              <img className="product-list-item-image"
                   alt={`Click here to learn more about ${item}`}
                   src={productObjects[item].image}/>
            </picture>

            <div className={"product-divider"}>
            </div>

            <h1 className={"product-listing-header"}>
              {productDesc.header}
            </h1>

            <h3 className={"product-listing-desc"}>
              {productDesc.productListingDesc[0]} <br className={"mobile-no-display"}/>
              {productDesc.productListingDesc[1]} <br className={"mobile-no-display"}/>
              {productDesc.productListingDesc[2]}
            </h3>

            <div className={"product-listing-price"}>
              {productDesc.price}
            </div>

            <a href={productObjects[item].href}>
              <div className={"product-listing-action-button"}>
                {this.props.language === "en" ? "Customize" : "Personnalisez"}
              </div>
            </a>

          </div>
        })
      }
    </div>
  };

  componentDidMount() {
    checkLatestVersion();
  }



  render() {
    return <div className={`product-offering-container ${this.props.productOfferingSection ? "select-product-margin" : "display"}`}>

      <div className={`product-offering-header ${this.props.productOfferingSection ? "no-display" : "display"}`}>
        {this.props.language === "en" ? "OUR PRODUCTS" : "NOS PRODUITS"}
      </div>

      <div className={`product-offering-header ${this.props.productOfferingSection ? "display" : "no-display"}`}>
        {this.props.language === "en" ? "What do you want to create?" : "NOS PRODUITS"}
      </div>

      <div className={"product-list-container"}>
        {this.fetchProducts()}
      </div>

    </div>
  }
}