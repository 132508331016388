export default function updateWindowFunctions(stateToBeUpdated, currentState, direction, windowId, changedMattingNewValue, windowCssRenderingValues, frame) {
    // changedMattingNewValue = parseFloat(changedMattingNewValue);
    //
    // var frameHeightMattingTotal = frame.height -  stateToBeUpdated[windowId].height;
    // var frameWidthMattingTotal = frame.width - stateToBeUpdated[windowId].width;
    //
    // console.log(changedMattingNewValue, (changedMattingNewValue < 0.5) && (changedMattingNewValue > 0));

    var changeIncrements = 0.125;


    if (direction === "up") {
        // stateToBeUpdated[windowId].marginTop = changedMattingNewValue;
        // stateToBeUpdated[windowId].marginBottom = frameHeightMattingTotal - changedMattingNewValue;
        //
        // console.log(stateToBeUpdated)
        // // stateToBeUpdated[windowId].marginBottom = currentState[windowId].marginBottom + 0.25;
        stateToBeUpdated[windowId].marginTop = currentState[windowId].marginTop - changeIncrements;
        stateToBeUpdated[windowId].marginBottom = currentState[windowId].marginBottom + changeIncrements;
    } else if (direction === "down" && currentState[windowId].marginBottom >= changeIncrements) {
        stateToBeUpdated[windowId].marginTop = currentState[windowId].marginTop + changeIncrements;
        stateToBeUpdated[windowId].marginBottom = currentState[windowId].marginBottom - changeIncrements;
    } else if (direction === "left" && currentState[windowId].marginLeft >= changeIncrements) {
        stateToBeUpdated[windowId].marginLeft = currentState[windowId].marginLeft - changeIncrements;
        stateToBeUpdated[windowId].marginRight = currentState[windowId].marginRight + changeIncrements;
    } else if (direction === "right" && currentState[windowId].marginRight >= changeIncrements) {
        stateToBeUpdated[windowId].marginLeft = currentState[windowId].marginLeft + changeIncrements;
        stateToBeUpdated[windowId].marginRight = currentState[windowId].marginRight - changeIncrements;
    }

    // if (changedMattingNewValue) {
    //
    // } else if (changedMattingNewValue < 0) {
    //
    // }
    // // else if (currentState[windowId].marginTop >= 0.25  && direction === "up") {
    // //   stateToBeUpdated[windowId].marginTop = currentState[windowId].marginTop - 0.25;
    // //   stateToBeUpdated[windowId].marginBottom = currentState[windowId].marginBottom + 0.25;
    // // } else if (direction === "down" && currentState[windowId].marginBottom >= 0.25) {
    // //   stateToBeUpdated[windowId].marginTop = currentState[windowId].marginTop + 0.25;
    // //   stateToBeUpdated[windowId].marginBottom = currentState[windowId].marginBottom - 0.25;
    // // } else if (direction === "left" && currentState[windowId].marginLeft >= 0.25) {
    // //   stateToBeUpdated[windowId].marginLeft = currentState[windowId].marginLeft - 0.25;
    // //   stateToBeUpdated[windowId].marginRight = currentState[windowId].marginRight + 0.25;
    // // } else if (direction === "right" && currentState[windowId].marginRight >= 0.25) {
    // //   stateToBeUpdated[windowId].marginLeft = currentState[windowId].marginLeft + 0.25;
    // //   stateToBeUpdated[windowId].marginRight = currentState[windowId].marginRight - 0.25;
    // // }
  return stateToBeUpdated;
}